import { createContext, useContext, useEffect, useMemo, useState } from 'react';

import { ColumnsType, useGoalsTableQuery } from 'features/goal';

interface RoadmapDataContextType {
  roadmapData?: ColumnsType;
  setRoadmapData: React.Dispatch<React.SetStateAction<ColumnsType | undefined>>;
}

const RoadmapDataContext = createContext<RoadmapDataContextType | undefined>(
  undefined
);

export const RoadmapDataProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { data } = useGoalsTableQuery();
  const [roadmapData, setRoadmapData] = useState<ColumnsType | undefined>(
    () => data!.columns
  );

  useEffect(() => {
    setRoadmapData(data?.columns);
  }, [data]);

  const contextValue = useMemo(
    () => ({
      roadmapData,
      setRoadmapData,
    }),
    [roadmapData, setRoadmapData]
  );

  return (
    <RoadmapDataContext.Provider value={contextValue}>
      {children}
    </RoadmapDataContext.Provider>
  );
};

export const useRoadmapDataContext = () => {
  const roadmapDataContext = useContext(RoadmapDataContext);

  if (!roadmapDataContext) {
    throw new Error(
      'useRoadmapDataContext must be used within a RoadmapDataProvider'
    );
  }

  return roadmapDataContext;
};
