import Spacer from 'shared_DEPRECATED/components/Spacer';

import { Typography, TYPOGRAPHY_COLORS } from 'shared/components/Typography';

type SelectButtonContentPropTypes = {
  children: React.ReactNode;
  color: TYPOGRAPHY_COLORS;
};

export const SelectButtonSmallContent = ({
  children,
  color,
}: SelectButtonContentPropTypes) => (
  <Spacer size="sm md">
    <Spacer size="xsm zr">
      <Typography
        color={color}
        fontWeight="medium"
        textAlign="center"
        type="small"
      >
        {children}
      </Typography>
    </Spacer>
  </Spacer>
);

export const SelectButtonMediumContent = ({
  children,
  color,
}: SelectButtonContentPropTypes) => (
  <Spacer size="md">
    <Typography
      color={color}
      fontWeight="medium"
      textAlign="center"
      type="meta"
    >
      {children}
    </Typography>
  </Spacer>
);
