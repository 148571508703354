import { useReducer } from 'react';

const useToggle = ({ defaultValue = false } = {}): [
  boolean,
  React.DispatchWithoutAction
] => {
  const [isOpen, setIsOpen] = useReducer((state) => !state, defaultValue);

  return [isOpen, setIsOpen];
};

export default useToggle;
