import PropTypes from 'prop-types';
import { createContext, useContext } from 'react';

import useToggle from 'shared_DEPRECATED/hooks/useToggle';

const OptionsMenuContext = createContext(null);

export const OptionsMenuProvider = ({ children }) => {
  const toggleState = useToggle();

  return (
    <OptionsMenuContext.Provider value={toggleState}>
      {children}
    </OptionsMenuContext.Provider>
  );
};

OptionsMenuProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useOptionsMenuContext = () => {
  const optionsMenuContext = useContext(OptionsMenuContext);

  if (!optionsMenuContext) {
    throw new Error(
      'useOptionsMenuContext must be used in OptionsMenuContextProvider'
    );
  }

  return optionsMenuContext;
};
