import { TextEditorToolbarBaseButton } from 'shared_DEPRECATED/components/TextEditor/Toolbar/BaseButton';
import { TextEditorListType } from 'shared_DEPRECATED/components/TextEditor/config';

interface TextEditorToolbarListProps {
  type: TextEditorListType;
}

export const TextEditorToolbarList = ({ type }: TextEditorToolbarListProps) => {
  return <TextEditorToolbarBaseButton type="list" value={type} />;
};
