import { OptionsMenu } from 'shared_DEPRECATED/components/Floating/Menu/Options';
import { iconNames } from 'shared_DEPRECATED/components/Icon';

import {
  GoalChallengeCardMenuAddButton,
  GoalFocusAreaChallengeCardMenuEditButton,
  GoalFocusAreaChallengeCardMenuRemoveButton,
  GoalBreakdownChallengeType,
} from 'features/goal';

type TGoalFocusAreaChallengeCardMenu = {
  challenge: GoalBreakdownChallengeType;
  focusAreaId: string;
};

export const GoalFocusAreaChallengeCardMenu = ({
  challenge,
  focusAreaId,
}: TGoalFocusAreaChallengeCardMenu) => (
  <OptionsMenu
    //@ts-ignore
    icon={iconNames.ellipsisHorizontal}
  >
    <GoalChallengeCardMenuAddButton challenge={challenge} />
    <GoalFocusAreaChallengeCardMenuEditButton
      challenge={challenge}
      focusAreaId={focusAreaId}
    />
    <GoalFocusAreaChallengeCardMenuRemoveButton
      challengeId={challenge.customChallengeId}
      focusAreaId={focusAreaId}
    />
  </OptionsMenu>
);
