import Box from 'shared_DEPRECATED/components/Box';
import { Flex } from 'shared_DEPRECATED/components/Flex';
import Spacer from 'shared_DEPRECATED/components/Spacer';

import { Tool } from 'features/sessions/config/types';
import { useCoachingToolsQuery } from 'features/sessions/hooks';
import { getCoachingToolsLabel } from 'features/sessions/utils';

import { Dropdown, DropdownItem } from 'shared/components/Dropdown';

type CoachingToolsSelectorProps = {
  onSelect: (tool: Tool) => void;
  selected: string;
};

export const SessionsFormCoachingToolsSelector = ({
  onSelect,
  selected,
}: CoachingToolsSelectorProps) => {
  const { data: coachingTools } = useCoachingToolsQuery();

  const handleToolSelect = (toolValue: string) => {
    const toolLabel = getCoachingToolsLabel(coachingTools ?? [], toolValue);

    onSelect({ value: toolValue, label: toolLabel });
  };

  return (
    <Dropdown
      onItemSelect={handleToolSelect as () => void}
      anchorContent={
        <Spacer size="sm" dataTestid="session-tool-selector">
          {getCoachingToolsLabel(coachingTools ?? [], selected)}
        </Spacer>
      }
      selectedValue={selected}
    >
      <Box style={{ width: '15rem', maxHeight: '20rem', overflow: 'auto' }}>
        <Flex alignItems="stretch" flexDirection="column" width="100%">
          {coachingTools?.map(({ value, label }) => (
            <DropdownItem
              value={value}
              key={value}
              dataTestid="session-tool-dropdown-option"
            >
              {label}
            </DropdownItem>
          ))}
        </Flex>
      </Box>
    </Dropdown>
  );
};
