import { useParams } from 'react-router-dom';

import { USER_TYPES } from 'shared_DEPRECATED/types/User';

import { useParticipantQuery } from 'features/participants/hooks';
import { useUserContext } from 'features/user/context';
import { useUserMode } from 'features/user/hooks';

export const useCurrentModeUser = () => {
  const { email } = useParams();

  const userMode = useUserMode();
  const { user } = useUserContext();

  const { participant } = useParticipantQuery(email);

  return userMode === USER_TYPES.USER ? user : participant;
};
