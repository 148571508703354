import Box from 'shared_DEPRECATED/components/Box';
import { Flex } from 'shared_DEPRECATED/components/Flex';
import Spacer from 'shared_DEPRECATED/components/Spacer';

import SidebarMainHeaderCloseButton from 'shared/components/Sidebar/Button/SidebarMainHeaderCloseButton';
import SidebarHeaderTitle from 'shared/components/Sidebar/Header/SidebarHeaderTitle';
import { SidebarMainHeaderPropTypes } from 'shared/components/Sidebar/Header/SidebarMainHeader';

const SidebarMainHeaderWithoutBorder = ({ children, title }) => (
  <Box style={{ backgroundColor: 'var(--bgPrimary)' }}>
    <Spacer size="lg">
      <Flex as="header" alignItems="flex-start">
        <Flex>
          <Spacer size="zr lg zr lg" />
          <SidebarMainHeaderCloseButton />
          <SidebarHeaderTitle>{title}</SidebarHeaderTitle>
        </Flex>
        {children}
      </Flex>
    </Spacer>
  </Box>
);

SidebarMainHeaderWithoutBorder.propTypes = SidebarMainHeaderPropTypes;

export default SidebarMainHeaderWithoutBorder;
