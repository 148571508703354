import { createContext, useContext, useEffect, useMemo, useState } from 'react';

import { GoalBreakdownType, useGoalBreakdownQuery } from 'features/goal';

interface GoalBreakdownDataContextType {
  goalBreakdownData?: GoalBreakdownType;
  setGoalBreakdownData: React.Dispatch<
    React.SetStateAction<GoalBreakdownType | undefined>
  >;
}

const GoalBreakdownDataContext = createContext<
  GoalBreakdownDataContextType | undefined
>(undefined);

export const GoalBreakdownDataProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { data } = useGoalBreakdownQuery();
  const [goalBreakdownData, setGoalBreakdownData] = useState<
    GoalBreakdownType | undefined
  >(data);

  useEffect(() => {
    setGoalBreakdownData(data);
  }, [data]);

  const contextValue = useMemo(
    () => ({
      goalBreakdownData,
      setGoalBreakdownData,
    }),
    [goalBreakdownData, setGoalBreakdownData]
  );

  return (
    <GoalBreakdownDataContext.Provider value={contextValue}>
      {children}
    </GoalBreakdownDataContext.Provider>
  );
};

export const useGoalBreakdownDataContext = () => {
  const goalBreakdownDataContext = useContext(GoalBreakdownDataContext);

  if (!goalBreakdownDataContext) {
    throw new Error(
      'useGoalBreakdownDataContext must be used within a GoalBreakdownDataProvider'
    );
  }

  return goalBreakdownDataContext;
};
