import Image, { ImageFrame } from 'shared_DEPRECATED/components/Image';

import { RequireEmojiOrPicture } from 'features/challenge/config/types';

import styles from 'features/challenge/components/Info.module.css';

const CHALLENGE_ICON_CONFIG = {
  LG: {
    width: 40,
    height: 40,
  },
  SM: {
    width: 28,
    height: 28,
  },
  XSM: {
    width: 20,
    height: 20,
  },
};

type TChallengeInfoImageProps = {
  title: string;
  size?: keyof typeof CHALLENGE_ICON_CONFIG;
} & RequireEmojiOrPicture;

export const ChallengeInfoImage = ({
  emoji,
  pictureFileUrl,
  pictureUrl,
  title,
  size = 'LG',
}: TChallengeInfoImageProps) => {
  const noImageUrl = !(pictureFileUrl || pictureUrl);

  if (noImageUrl && !emoji) {
    return null;
  }

  if (noImageUrl && emoji) {
    return (
      <ImageFrame isIcon={true} size={size}>
        {emoji}
      </ImageFrame>
    );
  }

  return (
    <ImageFrame isIcon={false} size={size}>
      <Image
        className={styles['challenge-info__picture']}
        src={(pictureFileUrl || pictureUrl)!}
        alt={title}
        // @ts-ignore
        width={CHALLENGE_ICON_CONFIG[size].width}
        // @ts-ignore
        height={CHALLENGE_ICON_CONFIG[size].height}
      />
    </ImageFrame>
  );
};
