import { Flex, FlexItem } from 'shared_DEPRECATED/components/Flex';
import Spacer from 'shared_DEPRECATED/components/Spacer';

import { Typography } from 'shared/components/Typography';

export const GeneralWidgetError = () => (
  <Flex
    alignItems="center"
    justifyContent="center"
    flexDirection="column"
    width="100%"
    height="100%"
  >
    <FlexItem>
      <Spacer size="zr zr md">
        <Spacer size="zr zr sm">
          <header>
            <Spacer size="zr zr md">
              <Typography as="div" type="largeHeading" textAlign="center">
                🙈
              </Typography>
            </Spacer>
            <Typography
              as="h1"
              type="heading"
              fontWeight="semiBold"
              textAlign="center"
              color="black"
            >
              Something went wrong
            </Typography>
          </header>
        </Spacer>
      </Spacer>
    </FlexItem>
    <FlexItem>
      <Spacer size="zr zr lg">
        <Spacer size="zr zr md">
          <Typography as="p" color="gray" type="meta" textAlign="center">
            Sorry, we can't load this widget right now. <br />
            Try reopen it or come back later.
          </Typography>
        </Spacer>
      </Spacer>
    </FlexItem>
  </Flex>
);
