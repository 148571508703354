import { TYPOGRAPHY_COLORS } from 'shared/components/Typography';

export const getEnabledStyles = (
  disabled: boolean
): {
  backgroundColor: string;
  boxShadow?: string;
  opacity: number;
  color: TYPOGRAPHY_COLORS;
  cursor: string;
} =>
  disabled
    ? {
        backgroundColor: 'var(--bgCompSecondaryBig)',
        boxShadow: 'none',
        opacity: 0.5,
        color: 'white',
        cursor: 'auto',
      }
    : {
        backgroundColor: 'transparent',
        opacity: 1,
        color: 'black',
        cursor: 'pointer',
      };
