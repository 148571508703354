import { toastConfig } from 'lib/reactToastify/config';
import { toast } from 'react-toastify';

import { OptionsMenuButton } from 'shared_DEPRECATED/components/Floating/Menu/Options';

import {
  useAddGoalChallengeToSprintMutation,
  GoalBreakdownChallengeType,
} from 'features/goal';
import { useUserId } from 'features/user/hooks/useUserId';

import { Typography } from 'shared/components/Typography';

type TGoalChallengeCardMenuAddButton = {
  challenge: GoalBreakdownChallengeType;
};

export const GoalChallengeCardMenuAddButton = ({
  challenge,
}: TGoalChallengeCardMenuAddButton) => {
  const participantId = useUserId();

  const { mutateAsync } = useAddGoalChallengeToSprintMutation();

  const handleAdd = async () => {
    const toastId = toast.loading(`Adding challenge...`, toastConfig);

    try {
      await mutateAsync({
        ...challenge,
        participantId,
      });

      toast.update(toastId, {
        render: 'Challenge added!',
        type: 'success',
        isLoading: false,
        icon: false,
        ...toastConfig,
      });
    } catch (e) {
      const toastText = //@ts-ignore
        e.name === 'duplicate_challenge'
          ? 'This challenge is already on your sprint.'
          : "There's an error with adding challenge. Please try again later.";

      toast.update(toastId, {
        render: toastText,
        type: 'error',
        isLoading: false,
        icon: false,
        ...toastConfig,
      });
    }
  };

  return (
    <OptionsMenuButton onClick={handleAdd}>
      <Typography>Add to sprint</Typography>
    </OptionsMenuButton>
  );
};
