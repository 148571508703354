import PropTypes from 'prop-types';
import { useLayoutEffect, useRef } from 'react';

import styles from 'shared_DEPRECATED/components/Loader/Loader.module.css';

const loaderSizes = {
  sm: {
    'border-width': '0.25rem',
    dimension: '2rem',
  },
  lg: {
    'border-width': '0.5rem',
    dimension: '4rem',
  },
};

const LoaderPropTypes = {
  dataTestid: PropTypes.string,
  size: PropTypes.oneOf(Object.keys(loaderSizes)),
  color: PropTypes.string,
};

const Loader = ({
  dataTestid = 'loader',
  size = 'lg',
  color = 'var(--bgCompPrimaryInverse)',
}) => {
  const element = useRef(null);

  useLayoutEffect(() => {
    const elementRef = element.current;

    if (elementRef) {
      const loaderProps = loaderSizes[size];

      Object.entries(loaderProps).forEach(([prop, value]) =>
        elementRef.style.setProperty(`--${prop}`, value)
      );
      elementRef.style.setProperty('--color', color);
    }
  }, [element, size, color]);

  return (
    <div data-testid={dataTestid} className={styles.wrapper} ref={element}>
      <div className={styles['lds-ring']}>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};

Loader.propTypes = LoaderPropTypes;

export default Loader;
