import { ReactNode, createContext, useContext, useMemo } from 'react';

const DropdownContext = createContext({
  selectedValue: '' as string | undefined,
  onItemSelect: (value: string | number) => {},
});

type TDropdownContextProvider = {
  children: ReactNode;
  selectedValue?: string;
  onItemSelect: (value: string | number) => void;
};

export const DropdownContextProvider = ({
  children,
  selectedValue,
  onItemSelect,
}: TDropdownContextProvider) => {
  const providerValue = useMemo(
    () => ({
      selectedValue,
      onItemSelect,
    }),
    [selectedValue, onItemSelect]
  );

  return (
    <DropdownContext.Provider value={providerValue}>
      {children}
    </DropdownContext.Provider>
  );
};

export const useDropdownContext = () => {
  const dropdownContext = useContext(DropdownContext);

  if (!DropdownContext) {
    throw new Error(
      'useDropdownContext must be used in DropdownContextProvider'
    );
  }

  return dropdownContext;
};
