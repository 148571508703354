import { useFormContext } from 'react-hook-form';

import { Flex, FlexItem } from 'shared_DEPRECATED/components/Flex';
import { ErrorMessage } from 'shared_DEPRECATED/components/Form/ErrorMessage';
import RequiredText from 'shared_DEPRECATED/components/Form/RequiredText';
import Spacer from 'shared_DEPRECATED/components/Spacer';
import { firstLetterUpperCase } from 'shared_DEPRECATED/utils';

import DimensionCheckbox from 'features/challenge/components/Dimension/Checkbox';
import { DIMENSIONS_VALUES } from 'features/challenge/config';
import { GoalAddFormSection, useDimensionsField } from 'features/goal';

import { Typography } from 'shared/components/Typography';

export const GoalAddFormDimensionsSection = () => {
  const {
    formState: { errors },
  } = useFormContext();

  const { dimensions, onDimensionChange } = useDimensionsField();

  return (
    <GoalAddFormSection>
      <Flex>
        <Typography
          as="label"
          color="black"
          fontWeight="medium"
          type="smallHeading"
        >
          Choose related dimensions
        </Typography>
        <RequiredText />
      </Flex>
      <Spacer size="md" />
      <Flex flexWrap="wrap" justifyContent="flex-start">
        {DIMENSIONS_VALUES.map((dimension: string) => (
          <FlexItem key={dimension} flexShrink={0}>
            <Spacer size="zr md md zr">
              <DimensionCheckbox
                name={firstLetterUpperCase(dimension)}
                checked={dimensions.includes(dimension)}
                onChange={onDimensionChange}
              />
            </Spacer>
          </FlexItem>
        ))}
      </Flex>
      {errors?.dimensions?.root && (
        <Spacer size="sm zr zr">
          <ErrorMessage message={errors.dimensions.root.message as string} />
        </Spacer>
      )}
    </GoalAddFormSection>
  );
};
