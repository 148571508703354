export const SEARCH_DELAY = 200;

export const COACHING_STATUS = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
};

export const COACHING_STATUS_LABEL = {
  [COACHING_STATUS.ACTIVE]: 'Active',
  [COACHING_STATUS.INACTIVE]: 'Inactive',
};
export const COACHING_STATUS_DEFAULT_LABEL = 'No coaching';

export const COACHING_STATUS_SORTING_ORDER = [
  COACHING_STATUS.ACTIVE,
  COACHING_STATUS.INACTIVE,
];

export const SPRINT_WEEK_NUMBER = {
  TEST_WEEK: 1,
  WEEK_1: 2,
  WEEK_2: 3,
  WEEK_3: 4,
  WEEK_4: 5,
  WEEK_5: 6,
  FUTURE_SPRINT: 0,
  PAST_SPRINT: -1,
  NO_SPRINT: -2,
};

export const SPRINT_WEEK_ASC_SORTING_ORDER = [
  SPRINT_WEEK_NUMBER.TEST_WEEK,
  SPRINT_WEEK_NUMBER.WEEK_1,
  SPRINT_WEEK_NUMBER.WEEK_2,
  SPRINT_WEEK_NUMBER.WEEK_3,
  SPRINT_WEEK_NUMBER.WEEK_4,
  SPRINT_WEEK_NUMBER.WEEK_5,
  SPRINT_WEEK_NUMBER.FUTURE_SPRINT,
  SPRINT_WEEK_NUMBER.PAST_SPRINT,
  SPRINT_WEEK_NUMBER.NO_SPRINT,
];
export const SPRINT_WEEK_DESC_SORTING_ORDER = [
  SPRINT_WEEK_NUMBER.WEEK_5,
  SPRINT_WEEK_NUMBER.WEEK_4,
  SPRINT_WEEK_NUMBER.WEEK_3,
  SPRINT_WEEK_NUMBER.WEEK_2,
  SPRINT_WEEK_NUMBER.WEEK_1,
  SPRINT_WEEK_NUMBER.TEST_WEEK,
  SPRINT_WEEK_NUMBER.FUTURE_SPRINT,
  SPRINT_WEEK_NUMBER.PAST_SPRINT,
  SPRINT_WEEK_NUMBER.NO_SPRINT,
];

export enum COACHING_MODE {
  WEEKLY_60_MIN = 'weekly_60_min',
  WEEKLY_30_MIN = 'weekly_30_min',
  BIWEEKLY_60_MIN = 'biweekly_60_min',
  BIWEEKLY_30_MIN = 'biweekly_30_min',
  BOOKENDS_60_MIN = 'bookends_60_min',
  BOOKENDS_30_MIN = 'bookends_30_min',
  OPT_IN_60_MIN = 'opt_in_60_min',
  OPT_IN_30_MIN = 'opt_in_30_min',
}

export const COACHING_MODE_LABELS = {
  [COACHING_MODE.WEEKLY_60_MIN]: 'Weekly / 60 min',
  [COACHING_MODE.WEEKLY_30_MIN]: 'Weekly / 30 min',
  [COACHING_MODE.BIWEEKLY_60_MIN]: 'Bi-weekly / 60 min',
  [COACHING_MODE.BIWEEKLY_30_MIN]: 'Bi-weekly / 30 min',
  [COACHING_MODE.BOOKENDS_60_MIN]: 'Bookends / 60 min',
  [COACHING_MODE.BOOKENDS_30_MIN]: 'Bookends / 30 min',
  [COACHING_MODE.OPT_IN_60_MIN]: 'Opt-in / 60 min',
  [COACHING_MODE.OPT_IN_30_MIN]: 'Opt-in / 30 min',
};

export const SESSION_DATE_FORMAT = 'MMM d, h:mm aa';

export const SESSION_DATE_LABEL = 'Select date & time';
export const SESSION_TIME_INTERVAL = 15;

export const SESSION_TIME_DEFAULT_HOUR = 7;
