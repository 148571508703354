import { Typography } from 'shared/components/Typography';

type TGoalDeepDiveFormDescription = {
  text: string;
  highlightedText?: string;
};

export const GoalDeepDiveFormDescription = ({
  text,
  highlightedText,
}: TGoalDeepDiveFormDescription) => (
  <Typography color="black" type="meta" fontWeight="medium">
    {highlightedText}{' '}
    <Typography as="span" type="meta" color="gray">
      {text}
    </Typography>
  </Typography>
);
