import { useCallback } from 'react';

import { produce } from 'immer';

import { useGoalBreakdownDataContext } from 'features/goal/context/goalBreakdown';

export const useMoveGoalBreakdownChallenges = () => {
  const { setGoalBreakdownData } = useGoalBreakdownDataContext();

  return {
    moveToFocusArea: useCallback(
      (modifiedFocusAreaIndex: number) =>
        async (item: { id: string; focusAreaIndex: number; index: number }) => {
          if (item.focusAreaIndex === modifiedFocusAreaIndex) {
            return;
          }

          setGoalBreakdownData((prevGoalBreakdownData) => {
            if (!prevGoalBreakdownData) {
              return prevGoalBreakdownData;
            }

            const { focusAreas, challenges } = prevGoalBreakdownData;
            const { focusAreaIndex } = item;

            const challengeIndex =
              focusAreaIndex > -1
                ? focusAreas[focusAreaIndex].challenges.findIndex(
                    (challenge) => challenge.customChallengeId === item.id
                  )
                : challenges.findIndex(
                    (challenge) => challenge.customChallengeId === item.id
                  );

            const challenge =
              focusAreaIndex > -1
                ? focusAreas[focusAreaIndex].challenges[challengeIndex]
                : challenges[challengeIndex];

            const newGoalBreakdownData = produce(
              prevGoalBreakdownData,
              (draft) => {
                if (focusAreaIndex > -1) {
                  draft.focusAreas[focusAreaIndex].challenges.splice(
                    challengeIndex,
                    1
                  );
                } else {
                  draft.challenges.splice(challengeIndex, 1);
                }

                if (modifiedFocusAreaIndex > -1) {
                  draft.focusAreas[modifiedFocusAreaIndex].challenges.push(
                    challenge
                  );
                } else {
                  draft.challenges.push(challenge);
                }
              }
            );

            item.index = 0;
            item.focusAreaIndex = modifiedFocusAreaIndex;

            return newGoalBreakdownData;
          });
        },
      [setGoalBreakdownData]
    ),
    moveToChallenges: useCallback(
      (modifiedFocusAreaIndex: number) =>
        async (
          draggedItem: {
            id: string;
            focusAreaIndex: number;
            index: number;
          },
          hoveredItem: {
            id: string;
            focusAreaIndex: number;
          }
        ) => {
          setGoalBreakdownData((prevGoalBreakdownData) => {
            if (!prevGoalBreakdownData) {
              return prevGoalBreakdownData;
            }

            const { focusAreas, challenges } = prevGoalBreakdownData;

            const draggedChallengeIndex = (
              draggedItem.focusAreaIndex > -1
                ? focusAreas[draggedItem.focusAreaIndex].challenges
                : challenges
            ).findIndex(
              (challenge) => challenge.customChallengeId === draggedItem.id
            );
            const draggedChallenge = (
              draggedItem.focusAreaIndex > -1
                ? focusAreas[draggedItem.focusAreaIndex].challenges
                : challenges
            )[draggedChallengeIndex];
            const hoveredChallengeIndex =
              modifiedFocusAreaIndex === -1
                ? challenges.findIndex((challenge) => {
                    return challenge.customChallengeId === hoveredItem.id;
                  })
                : focusAreas[modifiedFocusAreaIndex].challenges.findIndex(
                    (challenge) => {
                      return challenge.customChallengeId === hoveredItem.id;
                    }
                  );

            const newGoalBreakdownData = produce(
              prevGoalBreakdownData,
              (draft) => {
                if (draggedItem.focusAreaIndex > -1) {
                  draft.focusAreas[
                    draggedItem.focusAreaIndex
                  ].challenges.splice(draggedChallengeIndex, 1);
                } else {
                  draft.challenges.splice(draggedChallengeIndex, 1);
                }

                if (modifiedFocusAreaIndex === -1) {
                  draft.challenges.splice(
                    hoveredChallengeIndex,
                    0,
                    draggedChallenge
                  );
                } else {
                  draft.focusAreas[modifiedFocusAreaIndex].challenges.splice(
                    hoveredChallengeIndex,
                    0,
                    draggedChallenge
                  );
                }
              }
            );

            draggedItem.index = hoveredChallengeIndex;
            draggedItem.focusAreaIndex = hoveredItem.focusAreaIndex;

            return newGoalBreakdownData;
          });
        },
      [setGoalBreakdownData]
    ),
  };
};
