import { Flex } from 'shared_DEPRECATED/components/Flex';
import { Icon } from 'shared_DEPRECATED/components/Icon';

import { MOMENTUM_DEFAULT_SCORE } from 'features/momentum/config';
import { getMomentumConfig } from 'features/momentum/utils';

import { Badge } from 'shared/components/Badge';
import { Typography, TYPOGRAPHY_COLORS } from 'shared/components/Typography';

type MomentumTrendPropsType = { currentScore: number; previousScore?: number };

export const MomentumTrend = ({
  currentScore,
  previousScore = MOMENTUM_DEFAULT_SCORE,
}: MomentumTrendPropsType) => {
  const scoreDiff = currentScore - previousScore;

  const { icon, sign, textColor } = getMomentumConfig(scoreDiff);

  return scoreDiff !== 0 ? (
    <Badge
      as="div"
      size="xsm"
      bgColor="lightGray"
      dataTestid="momentum-trend-badge"
    >
      <Flex
        justifyContent="flex-start"
        gap="var(--spacing01)"
        alignItems="center"
      >
        <Typography type="small" color={textColor as TYPOGRAPHY_COLORS}>
          {sign}
          {Math.abs(scoreDiff).toFixed(1)}
        </Typography>
        <Icon
          name={icon.name}
          stroke={icon.color}
          transform={`rotate(${icon.rotation})`}
          width={8}
          height={7}
        />
      </Flex>
    </Badge>
  ) : null;
};
