import { AbsoluteCenter, VStack } from '@chakra-ui/react';
import { useCallback, useMemo, useState } from 'react';
import { Else, If, Then } from 'react-if';

import { Box, Flex, Typography, VARIANTS } from 'shared';
import Loader from 'shared_DEPRECATED/components/Loader';

import { GoalPrioritizeModalList } from 'features/goal/components/PrioritizeModal/List';
import { MAX_PRIORITIZED_GOALS } from 'features/goal/config';
import { useGoalsQuery } from 'features/goal/hooks';
import { useSetPrioritizedGoals } from 'features/goal/hooks/mutation/useSetPrioritizedGoals';

import { ModalBody, ModalHeader, ModalFooter } from 'shared/components/Modal';
import { ModalButton } from 'shared/components/Modal/ModalButton';

export const GoalPrioritizeModal = () => {
  const { data: goals = [], isLoading } = useGoalsQuery();
  const setPrioritizedGoals = useSetPrioritizedGoals();

  const onSubmit = useCallback(
    (goalIds: string[]) => {
      setPrioritizedGoals.mutate({ goalIds });
    },
    [setPrioritizedGoals]
  );
  const [selectedIds, setSelectedIds] = useState<string[]>(
    goals.filter((goal) => !!goal.prioritizedAt).map((goal) => goal.goalId)
  );

  const isLimitReached = selectedIds.length >= MAX_PRIORITIZED_GOALS;

  const goalsExtended = useMemo(
    () =>
      goals.map((goal) => ({
        ...goal,
        isDisabled: isLimitReached && !selectedIds.includes(goal.goalId),
        isChecked: selectedIds.includes(goal.goalId),
      })),
    [selectedIds, isLimitReached, goals]
  );

  const toggleGoal = (selectedGoalId: string) => {
    if (selectedIds.includes(selectedGoalId)) {
      setSelectedIds(selectedIds.filter((goalId) => goalId !== selectedGoalId));
    } else {
      setSelectedIds([...selectedIds, selectedGoalId]);
    }
  };

  const handleSubmit = () => {
    onSubmit(selectedIds);
  };

  return (
    <>
      <ModalHeader>
        <VStack alignItems="flex-start" gap="var(--spacing01)">
          <Typography type="heading" fontWeight="semiBold">
            Select priorities
          </Typography>
          <Typography type="small" fontWeight="regular" color="gray">
            Select up to 3 prioritized goals. They will be displayed in
            participant's profile to other members in your group.
          </Typography>
        </VStack>
      </ModalHeader>
      <ModalBody>
        <Box h="400px">
          <If condition={isLoading}>
            <Then>
              <AbsoluteCenter>
                <Loader />
              </AbsoluteCenter>
            </Then>
            <Else>
              <GoalPrioritizeModalList
                goals={goalsExtended}
                onGoalToggle={toggleGoal}
              />
            </Else>
          </If>
        </Box>
      </ModalBody>
      <ModalFooter>
        <Flex gap="var(--spacing04)">
          <ModalButton variant={VARIANTS.SECONDARY}>Cancel</ModalButton>
          <ModalButton variant={VARIANTS.PRIMARY} onClick={handleSubmit}>
            Save
          </ModalButton>
        </Flex>
      </ModalFooter>
    </>
  );
};
