import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import { Typography } from 'shared';
import { Spacers } from 'shared_DEPRECATED/components/Spacer';
import { USER_TYPES } from 'shared_DEPRECATED/types/User';
import { firstLetterUpperCase } from 'shared_DEPRECATED/utils';

import {
  OTHER_PARTICIPANT_MODE,
  typeEmojies,
  USER_TYPE_REDIRECTS,
} from 'features/user/config';
import { useUserMode } from 'features/user/hooks';

import { Dropdown } from 'shared/components/Dropdown';

const UserTypeSelect = ({ children }) => {
  const navigate = useNavigate();
  const userMode = useUserMode();
  const selectAnchorText = `${
    typeEmojies[userMode] || typeEmojies.user
  } ${firstLetterUpperCase(
    userMode === USER_TYPES.USER || userMode === OTHER_PARTICIPANT_MODE
      ? 'participant'
      : userMode
  )}`;

  const handleFilterChange = (selectedType) => {
    navigate(USER_TYPE_REDIRECTS[selectedType]);
  };

  return (
    <Spacers sizes={['zr lg zr zr', 'zr lg zr zr']}>
      <Dropdown
        onItemSelect={handleFilterChange}
        anchorContent={
          <Typography
            type="meta"
            color="black"
            fontWeight="medium"
            dataTestid="user-mode-type"
          >
            {selectAnchorText}
          </Typography>
        }
        selectedValue={userMode}
        anchorSpacing={['sm md']}
      >
        {children}
      </Dropdown>
    </Spacers>
  );
};

UserTypeSelect.propTypes = { children: PropTypes.node.isRequired };

export default UserTypeSelect;
