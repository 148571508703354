import PropTypes from 'prop-types';
import React from 'react';

import { Flex } from 'shared_DEPRECATED/components/Flex';
import {
  OptionsMenuProvider,
  OptionsMenuFloatingPopover,
} from 'shared_DEPRECATED/components/Floating/Menu/Options';
import { iconNames } from 'shared_DEPRECATED/components/Icon';

const OptionsMenuPropTypes = {
  /**
   * Menu buttons.
   * @type {React.ReactNode}
   * @required
   * @memberof OptionsMenu
   * @name children
   * @example <OptionsMenu><OptionsMenuButton>Button 1</OptionsMenuButton></OptionsMenu>
   */
  children: PropTypes.node,
  offsetTop: PropTypes.number,
  icon: PropTypes.string,
  disabled: PropTypes.bool,
  dataTestid: PropTypes.string,
};

const OptionsMenu = ({
  children,
  offsetTop = 0,
  icon = iconNames.ellipsis,
  disabled = false,
  dataTestid = null,
}) => (
  <OptionsMenuProvider>
    <OptionsMenuFloatingPopover
      offsetTop={offsetTop}
      icon={icon}
      disabled={disabled}
      dataTestid={dataTestid}
    >
      <Flex flexDirection="column" alignItems="stretch" gap="var(--spacing01)">
        {children}
      </Flex>
    </OptionsMenuFloatingPopover>
  </OptionsMenuProvider>
);

OptionsMenu.propTypes = OptionsMenuPropTypes;

export default OptionsMenu;
