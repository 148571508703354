import PropTypes from 'prop-types';
import { forwardRef } from 'react';

import Box from 'shared_DEPRECATED/components/Box';

const TooltipArrowPropTypes = {
  staticSide: PropTypes.oneOf(['top', 'right', 'bottom', 'left']),
  x: PropTypes.number,
  y: PropTypes.number,
};

const TooltipArrow = forwardRef(({ staticSide, x, y }, ref) => (
  <Box
    ref={ref}
    style={{
      position: 'absolute',
      left: x != null ? `${x}px` : '',
      top: y != null ? `${y}px` : '',
      right: '',
      bottom: '',
      [staticSide]: `-0.25rem`,
      backgroundColor: 'var(--bgCompPrimaryInverse)',
      width: '0.5rem',
      height: '0.5rem',
      transform: 'rotate(45deg)',
    }}
  />
));

TooltipArrow.propTypes = TooltipArrowPropTypes;

export default TooltipArrow;
