import { useEffect } from 'react';

import { subscribe, unsubscribe } from 'shared_DEPRECATED/utils/events';
import { noop } from 'shared_DEPRECATED/utils/function';

/**
 * @typedef {string} eventName - event name to be subscribed to
 * @typedef {function} eventListener - event listener to be called when event is triggered
 * @param {[eventName, eventListener][]} events
 * @returns {function} events unsubscribe function
 * @example useEventsSubscription([['modal:open', () => {}], ['modal:close', () => {}]])
 */

const useEventsSubscription = (events, onInit = noop) => {
  useEffect(() => {
    events.forEach((event) => {
      subscribe(...event);
      onInit();
    });

    return () => {
      events.forEach((event) => {
        unsubscribe(...event);
      });
    };
  }, [events, onInit]);
};

export default useEventsSubscription;
