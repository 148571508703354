import { useMatch } from 'react-router-dom';

import { routeNames } from 'shared_DEPRECATED/utils/config';

export const useGoalPageMatcher = () => {
  const isCoachAtTheGoalPage = useMatch(coachGoalUrl);

  const isParticipantAtTheGoalPage = useMatch(participantGoalUrl);

  return !!isCoachAtTheGoalPage || !!isParticipantAtTheGoalPage;
};

const coachGoalUrl = `${routeNames.COACH}/participants/:email/${routeNames.GOALS}/:goalId`;
const participantGoalUrl = `${routeNames.GOALS}/:goalId`;
