import {
  MOMENTUM_TREND_CONFIG,
  MOMENTUM_TREND_TYPE,
} from 'features/momentum/config';

export const getMomentumConfig = (scoreDiff: number) => {
  const trend =
    scoreDiff > 0 ? MOMENTUM_TREND_TYPE.UP : MOMENTUM_TREND_TYPE.DOWN;

  return MOMENTUM_TREND_CONFIG[trend];
};

export const getLastAndSecondLastMomentumScore = (
  momentumData: {
    score: number;
  }[]
) => {
  //@ts-ignore
  const lastMomentumScoreIndex = momentumData.findLastIndex(
    //@ts-ignore
    (item) => item.score
  );
  const lastMomentumScore = momentumData[lastMomentumScoreIndex]?.score;
  const secondLastMomentumScoreIndex = momentumData
    .slice(0, lastMomentumScoreIndex)
    //@ts-ignore
    .findLastIndex((item) => item.score);
  const secondLastMomentumScore =
    momentumData[secondLastMomentumScoreIndex]?.score;

  return [lastMomentumScore, secondLastMomentumScore];
};
