import { IUserSprint } from 'features/sprint/config/types';

import { FinalSprint } from './FinalSprint';

export class UserSprint extends FinalSprint implements IUserSprint {
  readonly successRate: number;

  constructor(sprint: IUserSprint) {
    super(sprint);

    this.successRate = sprint.successRate || 0;
  }
}
