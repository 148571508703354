import { useColorMode } from '@chakra-ui/react';

import { HeaderMenu } from 'app/layout/components/Header/Menu';
import { Notifications } from 'app/notifications/components/Notifications';
import { Flex } from 'shared_DEPRECATED/components/Flex';
import SwitchTheme from 'shared_DEPRECATED/components/Switch/theme/Theme';
import { THEMES } from 'shared_DEPRECATED/context/theme';
import { USER_TYPES } from 'shared_DEPRECATED/types/User';
import { getPictureUrl } from 'shared_DEPRECATED/utils';

import { FeedbackButton } from 'features/feedback/';
import {
  UserTypeSelectAdmin,
  UserTypeSelectCoach,
} from 'features/user/components/TypeSelect';
import { useUserContext } from 'features/user/context';

const userTypeSelectMap = {
  [USER_TYPES.COACH]: UserTypeSelectCoach,
  [USER_TYPES.ADMIN]: UserTypeSelectAdmin,
  [USER_TYPES.USER]: () => null,
};

const Navigation = () => {
  const { user } = useUserContext();
  const userType = user?.type || USER_TYPES.USER;
  const UserTypeSelect = userTypeSelectMap[userType];
  const { colorMode, toggleColorMode } = useColorMode();

  return (
    <Flex alignItems="center" gap="1.5rem">
      <FeedbackButton />
      {user?.type !== USER_TYPES.USER && <Notifications />}
      <UserTypeSelect data-testid="user-type-select" />
      <HeaderMenu
        name={user?.name || ''}
        email={user?.email || ''}
        url={getPictureUrl(user?.picture)}
      />
      <SwitchTheme
        checked={colorMode === THEMES.LIGHT}
        toggleTheme={toggleColorMode}
      />
    </Flex>
  );
};

export default Navigation;
