import { useQuery } from 'react-query';

import { queryKeys } from 'api/config';
import { useQueryHTTPRequest } from 'api/hooks/useHTTPRequest';

const REFETCH_INTERVAL = 30000; //30s

export const useHasUnreadNotificationsQuery = () => {
  const { request } = useQueryHTTPRequest();

  const { data } = useQuery(
    [queryKeys.notifications, 'hasUnreadNotifications'],
    () => request({ url: '/api/coach/notifications?limit=0' }),
    {
      initialData: { hasUnreadNotifications: false },
      useErrorBoundary: false,
      refetchInterval: REFETCH_INTERVAL,
      refetchIntervalInBackground: true,
    }
  );

  return data?.hasUnreadNotifications;
};
