import { useEffect } from 'react';
import { useForm, useFormState } from 'react-hook-form';

import { VARIANTS } from 'shared_DEPRECATED/components/Button';
import { ErrorMessage } from 'shared_DEPRECATED/components/Form/ErrorMessage/ErrorMessage';
import { TextInput } from 'shared_DEPRECATED/components/Form/Input';
import Spacer, { Spacers } from 'shared_DEPRECATED/components/Spacer';

import {
  FORM_FOCUS_AREA_NAME_INPUT_LIMIT,
  getGoalFormLimitErrorText,
} from 'features/goal';

import {
  ModalBody,
  ModalHeader,
  ModalFooter,
  modal,
} from 'shared/components/Modal';
import { ModalButton } from 'shared/components/Modal/ModalButton';
import { Typography } from 'shared/components/Typography';

type TGoalAddFocusAreaDialog = {
  onSubmit: ({ title }: { title: string }) => Promise<void>;
  title: string;
};

const GoalAddFocusAreaDialog = ({
  onSubmit,
  title,
}: TGoalAddFocusAreaDialog) => {
  const { control, handleSubmit, reset, register, formState } = useForm({
    defaultValues: {
      title,
    },
  });

  const { errors } = useFormState({ control: control });

  const handleFormSubmit = handleSubmit(({ title }) => {
    onSubmit({ title });
  });

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (!event.shiftKey && event.key === 'Enter') {
      event.preventDefault();
      handleFormSubmit();
      modal.close();
    }
  };

  useEffect(() => {
    return () => {
      reset();
    };
  }, [reset]);

  return (
    <>
      <ModalHeader>
        <Typography as="h3" type="body" fontWeight="semiBold" color="black">
          Create focus area
        </Typography>
      </ModalHeader>
      <ModalBody>
        <Typography color="gray" type="small">
          Name
        </Typography>
        <Spacer size="md" />
        <TextInput
          {...register('title', {
            required: { value: true, message: 'This field is required' },
            maxLength: {
              value: FORM_FOCUS_AREA_NAME_INPUT_LIMIT,
              message: getGoalFormLimitErrorText(
                FORM_FOCUS_AREA_NAME_INPUT_LIMIT
              ),
            },
            setValueAs: (v) => v.trim(),
          })}
          //@ts-ignore
          error={!!errors.title}
          onKeyDown={handleKeyDown}
          placeholder="e.g. Define target audience (“Create my own restaurant brand” goal)"
        />
        <>
          {errors.title?.message && (
            <Spacer size="sm zr zr">
              <ErrorMessage message={errors.title.message as string} />
            </Spacer>
          )}
        </>
      </ModalBody>
      <ModalFooter>
        <Spacers sizes={['zr md zr zr', 'zr sm zr zr']}>
          <ModalButton variant={VARIANTS.SECONDARY}>
            <Spacers sizes={['md', 'sm']}>
              <Typography color="black" fontWeight="medium" type="meta">
                Cancel
              </Typography>
            </Spacers>
          </ModalButton>
        </Spacers>
        <ModalButton
          onClick={handleFormSubmit}
          shouldClose={formState.isValid}
          variant={VARIANTS.PRIMARY}
        >
          <Spacers sizes={['md', 'sm']}>
            <Typography color="whiteTotal" fontWeight="medium" type="meta">
              Save
            </Typography>
          </Spacers>
        </ModalButton>
      </ModalFooter>
    </>
  );
};

export default GoalAddFocusAreaDialog;
