import { USER_TYPES } from 'shared_DEPRECATED/types/User';

export const OTHER_PARTICIPANT_MODE = 'otherParticipant';

export const rolesOrder = [USER_TYPES.USER, USER_TYPES.COACH, USER_TYPES.ADMIN];

type UserModesType = {
  readonly [key: string]: string;
  readonly admin: string;
  readonly coach: string;
  readonly dashboard: string;
  readonly users: string;
};

export const USER_MODES: UserModesType = {
  admin: USER_TYPES.ADMIN,
  coach: USER_TYPES.COACH,
  dashboard: USER_TYPES.USER,
  users: OTHER_PARTICIPANT_MODE,
};

export const USER_TYPE_REDIRECTS = {
  [USER_TYPES.COACH]: 'coach',
  [USER_TYPES.ADMIN]: 'admin',
  [USER_TYPES.USER]: 'sprints',
};

export const typeEmojies = { coach: '⛰', user: '🎓', admin: '⭐' };

export const FORM_TEXT_INPUT_LIMIT = 512;

export const FORM_TEXT_INPUT_LIMIT_ERROR_TEXT = `The text is too long. The max. length is ${FORM_TEXT_INPUT_LIMIT} symbols.`;
