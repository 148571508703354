import { BaseChallenge } from 'features/challenge/BaseChallenge';
import {
  IBaseChallengeComputedProps,
  IRepeatedChallenge,
  WeekdayTargetProgressType,
  WeekdayTargetProgressTypeWithAmount,
  WeeklyTargetProgressType,
  WeeklyTargetProgressTypeWithAmount,
} from 'features/challenge/config/types';

import { CHALLENGE_FIELD_TYPES } from './config';

export class RepeatedChallenge
  extends BaseChallenge
  implements IRepeatedChallenge, IBaseChallengeComputedProps
{
  target:
    | WeekdayTargetProgressType
    | WeeklyTargetProgressType
    | WeekdayTargetProgressTypeWithAmount
    | WeeklyTargetProgressTypeWithAmount;
  type: typeof CHALLENGE_FIELD_TYPES[keyof typeof CHALLENGE_FIELD_TYPES];

  constructor(challenge: IRepeatedChallenge) {
    super(challenge);

    this.target = challenge.target;
    this.type = CHALLENGE_FIELD_TYPES.challenges;
  }

  getAmountOnCurrentDate(date: string): number {
    return this.target.dailyStatus[date]?.amountTotal;
  }
}
