import { useQuery } from 'react-query';

import { queryKeys } from 'api/config';
import { useQueryHTTPRequest } from 'shared_DEPRECATED/hooks';

export const useCoachProfileQuery = () => {
  const { request } = useQueryHTTPRequest();
  const { data, isLoading, status } = useQuery(
    queryKeys.coachProfile,
    () => request({ url: '/api/web/me/profile' }),
    {
      initialData: {},
    }
  );

  return { coachProfile: data?.coachProfile, isLoading, status };
};
