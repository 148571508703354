import { toastConfig } from 'lib/reactToastify/config';
import { toast } from 'react-toastify';

import { logError } from 'lib/sentry/logError';

import { useCoachProfileUpdateMutation } from 'features/coach/settings/hooks';
import { CoachProfile } from 'features/coach/settings/types';

export const useCoachSettingsFormSubmit = () => {
  const { mutateAsync } = useCoachProfileUpdateMutation();

  const onSubmit = async (coachProfile: CoachProfile) => {
    let toastId = toast.loading('Updating...', toastConfig);

    try {
      await mutateAsync(coachProfile);

      toast.update(toastId, {
        render: 'Profile was updated successfully!',
        type: 'success',
        isLoading: false,
        icon: false,
        ...toastConfig,
      });
    } catch (err) {
      toast.update(toastId, {
        render:
          "There's an error with profile updating. Please try again later.",
        type: 'error',
        isLoading: false,
        icon: false,
        ...toastConfig,
      });
      logError(err);
    }
  };

  return { onSubmit };
};
