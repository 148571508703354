import { Avatar, SIZES } from 'shared_DEPRECATED/components/Avatar';
import Button from 'shared_DEPRECATED/components/Button';
import {
  FloatingTooltip as Tooltip,
  PLACEMENTS,
} from 'shared_DEPRECATED/components/Floating';
import Spacer from 'shared_DEPRECATED/components/Spacer';

import { Typography } from 'shared/components/Typography';

import styles from 'app/layout/components/Header/Menu/Menu.module.css';

type HeaderMenuPopoverButtonPropTypes = {
  onClick: () => void;
  name: string;
  pictureUrl: string;
  dataTestid?: string;
};

export const HeaderMenuPopoverButton = ({
  onClick,
  name,
  pictureUrl,
  dataTestid = '',
}: HeaderMenuPopoverButtonPropTypes) => {
  return (
    <Tooltip
      anchor={
        <Button onClick={onClick} dataTestid={dataTestid}>
          <Spacer as="span" size="zr zr zr md">
            <div className={styles['user-avatar']}>
              <Avatar name={name} size={SIZES.MD} src={pictureUrl} />
            </div>
          </Spacer>
        </Button>
      }
      offsetValue={8}
      placement={PLACEMENTS.BOTTOM_END}
    >
      <Typography color="white" fontWeight="medium" type="small">
        Menu
      </Typography>
    </Tooltip>
  );
};
