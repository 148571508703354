import PropTypes from 'prop-types';
import React, { useContext, useLayoutEffect, useMemo, useState } from 'react';

import Loader from 'shared_DEPRECATED/components/Loader';

import { useCurrentSprint } from 'features/sprint/hooks/useCurrentSprint';

const CurrentSprintContext = React.createContext();

const CurrentSprintProviderPropTypes = {
  children: PropTypes.node.isRequired,
};

export const CurrentSprintProvider = ({ children }) => {
  const { data: currentSprint, isLoading } = useCurrentSprint();

  const defaultWeekIndex = currentSprint?.currentWeekNumber - 1 || 0;
  const maxWeekIndex = currentSprint?.weeksCount - 1;

  const [selectedWeekIndex, setSelectedWeekIndex] = useState(0);

  if (selectedWeekIndex > maxWeekIndex) {
    setSelectedWeekIndex(defaultWeekIndex);
  }

  useLayoutEffect(
    () => {
      currentSprint && setSelectedWeekIndex(defaultWeekIndex);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentSprint?.currentWeekNumber]
  );

  const providerValue = useMemo(
    () => ({
      currentSprint,
      setSelectedWeekIndex,
      selectedWeekIndex,
    }),
    [currentSprint, selectedWeekIndex, setSelectedWeekIndex]
  );

  if (isLoading) {
    return <Loader />;
  }

  return (
    <CurrentSprintContext.Provider value={providerValue}>
      {children}
    </CurrentSprintContext.Provider>
  );
};

CurrentSprintProvider.propTypes = CurrentSprintProviderPropTypes;

export const useCurrentSprintContext = () => {
  const currentSprintContext = useContext(CurrentSprintContext);

  if (!currentSprintContext) {
    throw new Error(
      'useCurrentSprintContext must be used in CurrentSprintProvider'
    );
  }

  return currentSprintContext;
};
