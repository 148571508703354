import PropTypes from 'prop-types';

import { Flex } from 'shared_DEPRECATED/components/Flex';

import { ChallengeFrequencyDialogCustomWeeklyField } from 'features/challenge/components/FrequencyDialog/CustomWeeklyField';
import { ChallengeFrequencyDialogNumberWeeklyField } from 'features/challenge/components/FrequencyDialog/NumberWeeklyField';
import { ChallengeFrequencyDialogWeekdayField } from 'features/challenge/components/FrequencyDialog/WeekdayField';

const frequencyPropType = PropTypes.shape({
  dueDate: PropTypes.string,
  type: PropTypes.string.isRequired,
  weekday: PropTypes.shape({
    fri: PropTypes.bool.isRequired,
    mon: PropTypes.bool.isRequired,
    sat: PropTypes.bool.isRequired,
    sun: PropTypes.bool.isRequired,
    thu: PropTypes.bool.isRequired,
    tue: PropTypes.bool.isRequired,
    wed: PropTypes.bool.isRequired,
  }).isRequired,
  weekly: PropTypes.arrayOf(PropTypes.number),
});

const FrequencyDialogBodyPropTypes = {
  frequency: frequencyPropType.isRequired,
  onFrequencyChange: PropTypes.func.isRequired,
  // sprintStartDate: PropTypes.string.isRequired,
  // sprintEndDate: PropTypes.string.isRequired,
  sprintWeeksCount: PropTypes.number.isRequired,
};

export const FrequencyDialogBody = ({
  frequency,
  onFrequencyChange,
  // sprintStartDate,
  // sprintEndDate,
  sprintWeeksCount,
}) => (
  <Flex flexDirection="column" alignItems="start">
    <ChallengeFrequencyDialogWeekdayField
      frequency={frequency}
      onFrequencyChange={onFrequencyChange}
    />
    <ChallengeFrequencyDialogNumberWeeklyField
      frequency={frequency}
      onFrequencyChange={onFrequencyChange}
    />
    {/* <Flex flexDirection="column" alignItems="start">
        <RadioButton
          checked={frequency.type === TARGET_TYPES.DUE_DATE}
          label="Once per sprint/Due date"
          onChange={() =>
            onFrequencyChange({
              type: TARGET_TYPES.DUE_DATE,
              dueDate: dayjs(frequency.dueDate || sprintEndDate).toDate(),
            })
          }
        />
        <Spacers sizes={['sm zr', 'xsm zr']} />
        {frequency.type === TARGET_TYPES.DUE_DATE && (
          <>
            <Flex gap={CHECKBOX_BUTTONS_GAP}>
              <Spacers sizes={['zr zr zr xsm']} />
              <DueDateField
                dueDate={frequency.dueDate}
                onDateChange={(date) =>
                  onFrequencyChange({
                    type: TARGET_TYPES.DUE_DATE,
                    dueDate: dayjs(date).format('YYYY-MM-DD'),
                  })
                }
                sprintStartDate={sprintStartDate}
                sprintEndDate={sprintEndDate}
              />
            </Flex>
            <Spacers sizes={['sm zr', 'xsm zr']} />
          </>
        )}
      </Flex> */}
    <ChallengeFrequencyDialogCustomWeeklyField
      frequency={frequency}
      onFrequencyChange={onFrequencyChange}
      sprintWeeksCount={sprintWeeksCount}
    />
  </Flex>
);

FrequencyDialogBody.propTypes = FrequencyDialogBodyPropTypes;
