import { publish } from 'shared_DEPRECATED/utils/events';

import { MODAL_SIZES } from 'shared/components/Modal/types';

export const MODAL_OPEN_EVENT = 'modal:open';
export const MODAL_CLOSE_EVENT = 'modal:close';

export const open = (modalConfig: {
  content: JSX.Element;
  props: {
    size: MODAL_SIZES;
    title?: string;
  };
  context?: object;
}) => publish(MODAL_OPEN_EVENT, modalConfig);

export const close = () => publish(MODAL_CLOSE_EVENT);
