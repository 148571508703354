import { Badge, Flex } from 'shared';
import Spacer from 'shared_DEPRECATED/components/Spacer';
import { capitalize } from 'shared_DEPRECATED/utils';

import { ChallengeGoalsType } from 'features/challenge/config/types';

type TChallengeInfoGoalsProps = {
  goals: ChallengeGoalsType;
};

export const ChallengeInfoGoals = ({ goals }: TChallengeInfoGoalsProps) => {
  if (!goals || !goals.length) {
    return null;
  }

  return (
    <>
      <Spacer size="sm zr zr" />
      <Flex gap="0.25rem" flexWrap="wrap">
        {goals.map((goal) => (
          <Badge>{capitalize(goal.title)}</Badge>
        ))}
      </Flex>
    </>
  );
};
