import { ReactNode } from 'react';

import Box from 'shared_DEPRECATED/components/Box';
import { Flex } from 'shared_DEPRECATED/components/Flex';
import { HotjarSuppression } from 'shared_DEPRECATED/components/Hotjar';
import Spacer from 'shared_DEPRECATED/components/Spacer';
import { capitalize } from 'shared_DEPRECATED/utils';

import { AddChallengeButton, GoalFocusAreaMenu } from 'features/goal';

import { Typography } from 'shared/components/Typography';

type TGoalFocusArea = {
  children: ReactNode;
  title: string;
  focusAreaId: string;
};

export const GoalFocusArea__NEW = ({
  children,
  title,
  focusAreaId,
}: TGoalFocusArea) => (
  <Box
    style={{
      width: '100%',
      backgroundColor: 'var(--bgCompSecondaryMid)',
      borderRadius: 'var(--border-radius4)',
    }}
  >
    <Spacer size="sm">
      <Flex width="100%" alignItems="flex-start">
        <HotjarSuppression>
          <Typography
            type="body"
            color="black"
            fontWeight="medium"
            wordBreak="break-word"
          >
            {capitalize(title)}
          </Typography>
        </HotjarSuppression>
        <Flex gap="8px">
          <AddChallengeButton focusAreaId={focusAreaId} />
          <GoalFocusAreaMenu focusAreaId={focusAreaId} title={title} />
        </Flex>
      </Flex>
      {children}
    </Spacer>
  </Box>
);
