import { Flex } from 'shared_DEPRECATED/components/Flex';

import { Typography } from 'shared/components/Typography';

export const SessionsParticipantEmptyState = () => (
  <Flex
    flexDirection="column"
    gap="var(--spacing02)"
    height="100%"
    justifyContent="center"
  >
    <Typography>🧐</Typography>
    <Typography type="heading" fontWeight="semiBold" color="black">
      No sessions yet
    </Typography>
    <Typography type="meta" fontWeight="regular" color="gray">
      You will be able to view your past sessions with the coach here.
    </Typography>
  </Flex>
);
