import { useRouteError } from 'react-router-dom';

import { HTTP_ERROR_STATUS_CODES } from 'api/config';
import NotFoundPage from 'app/pages/NotFound';
import { logError } from 'lib/sentry/logError';
import { GeneralError } from 'shared_DEPRECATED/components/Error/GeneralError';

const RootRouterErrorBoundary = () => {
  const error = useRouteError();

  if (error.message === HTTP_ERROR_STATUS_CODES.NOT_FOUND) {
    return <NotFoundPage />;
  }

  logError(error);

  return <GeneralError />;
};

export default RootRouterErrorBoundary;
