//more about Quill toolbar options https://quilljs.com/docs/modules/toolbar
import { ReactNode } from 'react';

import Box from 'shared_DEPRECATED/components/Box';
import { Flex, FlexItem } from 'shared_DEPRECATED/components/Flex';
import {
  TextEditorToolbarBaseButton,
  TextEditorToolbarBaseSelector,
  TextEditorToolbarFontButton,
  TextEditorToolbarList,
} from 'shared_DEPRECATED/components/TextEditor/Toolbar';
import {
  FONT_BUTTON_TYPES,
  TEXT_EDITOR_LIST_TYPES,
} from 'shared_DEPRECATED/components/TextEditor/config';

type TTextEditorToolbar = {
  children?: ReactNode;
  id: string;
};

export const TextEditorToolbar = ({ children, id }: TTextEditorToolbar) => (
  <Box id={id}>
    <Flex gap="0.5rem" justifyContent="flex-start">
      <FlexItem>
        <TextEditorToolbarBaseSelector type="header" options={['1', '2']} />
      </FlexItem>
      <FlexItem>
        <TextEditorToolbarFontButton type={FONT_BUTTON_TYPES.BOLD} />
        <TextEditorToolbarFontButton type={FONT_BUTTON_TYPES.ITALIC} />
        <TextEditorToolbarFontButton type={FONT_BUTTON_TYPES.STRIKE} />
        {/*        <TextEditorToolbarColorSelector type={COLOR_SELECTOR_TYPE.COLOR} />
        <TextEditorToolbarColorSelector type={COLOR_SELECTOR_TYPE.BACKGROUND} />*/}
      </FlexItem>
      <FlexItem>
        <TextEditorToolbarList type={TEXT_EDITOR_LIST_TYPES.ORDERED} />
        {/*<TextEditorToolbarList type={TEXT_EDITOR_LIST_TYPES.BULLET} />*/}
      </FlexItem>
      <FlexItem>
        <TextEditorToolbarBaseButton type="link" />
      </FlexItem>
      <FlexItem>
        <TextEditorToolbarBaseButton type="clean" />
      </FlexItem>
      {children}
    </Flex>
  </Box>
);
