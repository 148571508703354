import Box from 'shared_DEPRECATED/components/Box';
import { Flex } from 'shared_DEPRECATED/components/Flex';
import Spacer from 'shared_DEPRECATED/components/Spacer';

import { Typography } from 'shared/components/Typography';

export const MomentumFeedChartLegend = () => (
  <Spacer size="lg">
    <Flex justifyContent="center">
      <Flex justifyContent="center">
        <Box
          style={{
            width: '0.5rem',
            height: '0.5rem',
            borderRadius: '50%',
            backgroundColor: 'var(--bgCompPrimaryInverse)',
          }}
        />
        <Spacer size="sm" />
        <Typography type="small" color="gray">
          Current
        </Typography>
      </Flex>
      <Spacer size="md" />
      <Flex justifyContent="center">
        <Box
          style={{
            width: '0.5rem',
            height: '0.5rem',
            borderRadius: '50%',
            backgroundColor: 'var(--bgCompSecondaryTiny)',
          }}
        />
        <Spacer size="sm" />
        <Typography type="small" color="gray">
          Previous
        </Typography>
      </Flex>
    </Flex>
  </Spacer>
);
