import Spacer from 'shared_DEPRECATED/components/Spacer';

import { Typography } from 'shared/components/Typography';

export const NotificationsEmptyFilter = () => (
  <Spacer size="xlg zr">
    <Typography type="meta" textAlign="center">
      👋 There are no matches with a filter rules.
    </Typography>
  </Spacer>
);
