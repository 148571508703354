import { init, Replay } from '@sentry/react';

import { ENVS } from 'config/envVariables';

init({
  dsn: 'https://7a8f980f0fe74f98955e5ebaf37a47c3@o1065040.ingest.sentry.io/4504175220031488',
  environment: process.env.REACT_APP_FRONTEND_ENVIRONMENT || ENVS.LOCAL,
  release: process.env.REACT_APP_RELEASE || '',
  replaysOnErrorSampleRate: 0.1,

  integrations: [
    new Replay({
      maskAllText: false,
      blockAllMedia: true,
    }),
  ],
});
