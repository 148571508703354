import TagManager from 'react-gtm-module';

import * as amplitude from '@amplitude/analytics-browser';
import { AnalyticsEvent } from 'app/analytics/config';
import { snakecasedData } from 'shared_DEPRECATED/utils/object';
import { isProdEnv } from 'utils';

export const sendAmplitudeEvent = ({
  event,
  data,
}: {
  event: AnalyticsEvent;
  data: Record<string, string | number>;
}) => {
  amplitude.track({
    event_type: event,
    event_properties: data,
  });
};

export const sendTagManagerEvent = ({
  event,
  data,
}: {
  event: AnalyticsEvent;
  data: Record<string, string | number>;
}) => {
  TagManager.dataLayer({
    dataLayer: {
      event,
      ...data,
    },
  });
};

export const sendAnalyticsEvent = ({
  event,
  data,
}: {
  event: AnalyticsEvent;
  data?: Record<string, string | number | boolean>;
}) => {
  if (!isProdEnv()) {
    return;
  }

  sendTagManagerEvent({ event, data: snakecasedData(data) });
  sendAmplitudeEvent({ event, data: snakecasedData(data) });
};
