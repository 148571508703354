import { MenuItem, useColorMode } from '@chakra-ui/react';
import { ReactNode } from 'react';

import Box from 'shared_DEPRECATED/components/Box';
import { Flex } from 'shared_DEPRECATED/components/Flex';
import { Icon, iconNames } from 'shared_DEPRECATED/components/Icon';
import { Nullable } from 'shared_DEPRECATED/types';

import { Typography } from 'shared/components/Typography';

import { useDropdownContext } from './context';

type TDropdownMenuItem = {
  children: ReactNode;
  value: number | string;
  dataTestid?: Nullable<string>;
};

export const DropdownItem = ({
  children,
  value,
  dataTestid = null,
}: TDropdownMenuItem) => {
  const { colorMode: theme } = useColorMode();

  const { selectedValue, onItemSelect } = useDropdownContext();

  const isSelected = Array.isArray(selectedValue)
    ? selectedValue.includes(value)
    : selectedValue === value;

  return (
    <MenuItem
      value={value}
      data-testid={dataTestid}
      onClick={() => onItemSelect(value)}
    >
      <Box style={{ maxWidth: '15rem' }}>
        <Flex alignItems="center" justifyContent="flex-start">
          <Box
            style={{
              width: '1.5rem',
              minWidth: '1.5rem',
            }}
          >
            {isSelected && (
              <Icon
                name={iconNames.check}
                color={theme === 'light' ? 'black' : 'white'}
              />
            )}
          </Box>
          <Typography type="meta" fontWeight="regular" color="black" cropped>
            {children}
          </Typography>
        </Flex>
      </Box>
    </MenuItem>
  );
};
