import { toastErrorConfig } from 'lib/reactToastify/config';
import { useNavigate, useParams } from 'react-router-dom';
import { toast, ToastOptions } from 'react-toastify';

import { logError } from 'lib/sentry/logError';

import { SessionConfigType } from 'features/sessions/config/types';
import { useSessionCreateMutation } from 'features/sessions/hooks/mutation/useSessionCreate';

export const useSessionCreate = () => {
  const navigate = useNavigate();
  const { email } = useParams();
  const { mutateAsync } = useSessionCreateMutation();

  return async (sessionConfig: SessionConfigType) => {
    try {
      const newSession = await mutateAsync(sessionConfig);

      navigate(
        `/coach/participants/${email}/sessions/${newSession.coachingSessionId}`
      );
    } catch (e) {
      toast.error(
        "There's an error with creating new session.",
        toastErrorConfig as ToastOptions
      );

      logError(e);
    }
  };
};
