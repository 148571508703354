import { useCallback } from 'react';

import { GoalPrioritizeLimitReachedModal } from 'features/goal/components/PrioritizeLimitReachedModal';

import { modal, SIZES } from 'shared/components/Modal';

type UseOpenPrioritiesLimitReachedModalProps = {
  name: string;
  onSubmit: () => void;
};

export const useOpenPrioritizedGoalsLimitReachedModal = ({
  name,
  onSubmit,
}: UseOpenPrioritiesLimitReachedModalProps) =>
  useCallback(() => {
    modal.open({
      content: (
        <GoalPrioritizeLimitReachedModal name={name} onSubmit={onSubmit} />
      ),
      props: {
        size: SIZES.SM,
      },
      context: {},
    });
  }, [name, onSubmit]);
