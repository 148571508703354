import { useColorMode } from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';
import { When } from 'react-if';
import { useParams } from 'react-router-dom';

import { Flex } from 'shared_DEPRECATED/components/Flex';
import TextField from 'shared_DEPRECATED/components/Form/TextField';

import { CoachingToolsCardEditButtons } from 'features/sessions/components';
import {
  SessionsFormCoachingToolsEffectivenessRate,
  SessionsFormCoachingToolsLinkField,
  SessionsFormCoachingToolsSection,
  SessionsFormCoachingToolsSelector,
} from 'features/sessions/components/Form/CoachingTools';
import { COACHING_EXTERNAL_TOOL } from 'features/sessions/config';
import { CoachingToolNote, SessionType } from 'features/sessions/config/types';
import { useSessionUpdateMutation } from 'features/sessions/hooks';

type SessionsFormCoachingToolsCardEditProps = {
  formKey: string;
  coachingTool: CoachingToolNote;
  onDelete: () => void;
  canBeDeleted?: boolean;
};

export const SessionsFormCoachingToolsCardEdit = ({
  formKey,
  onDelete,
  canBeDeleted = false,
  coachingTool,
}: SessionsFormCoachingToolsCardEditProps) => {
  const { register, setValue, handleSubmit, reset } =
    useFormContext<SessionType>();
  const { sessionId } = useParams();
  const { mutate } = useSessionUpdateMutation(sessionId!);
  const { colorMode: theme } = useColorMode();
  const isThemeLight = theme === 'light';

  return (
    <>
      <SessionsFormCoachingToolsSection>
        <SessionsFormCoachingToolsSelector
          onSelect={(coachingTool) => {
            // @ts-ignore
            setValue(`${formKey}.tool` as keyof SessionType, coachingTool, {
              shouldDirty: true,
            });
          }}
          selected={coachingTool.tool.value}
        />
      </SessionsFormCoachingToolsSection>
      <When condition={coachingTool.tool.value === COACHING_EXTERNAL_TOOL}>
        <SessionsFormCoachingToolsLinkField formKey={formKey} />
      </When>
      <SessionsFormCoachingToolsSection title="Session specific notes">
        <TextField
          {...register(`${formKey}.notes` as keyof SessionType)}
          //@ts-ignore
          style={{
            height: '5rem',
            backgroundColor: isThemeLight ? 'var(--bgPrimary)' : 'transparent',
            color: 'var(--fgPrimary)',
            border: 'var(--border-secondary)',
          }}
        />
      </SessionsFormCoachingToolsSection>
      <SessionsFormCoachingToolsSection>
        <SessionsFormCoachingToolsEffectivenessRate
          currentRate={coachingTool.toolEffectivenessRate?.toString() || ''}
          formKey={formKey}
        />
      </SessionsFormCoachingToolsSection>
      <Flex gap="var(--spacing04)">
        <CoachingToolsCardEditButtons
          canBeDeleted={canBeDeleted}
          onDelete={onDelete}
          onSave={async () => {
            setValue(`${formKey}.editMode` as keyof SessionType, false);
            await handleSubmit((data) => mutate(data))();
            reset(undefined, { keepValues: true, keepDirty: false });
          }}
          disabled={!coachingTool.tool.value}
        />
      </Flex>
    </>
  );
};
