import { TableTextCell } from 'shared_DEPRECATED/components/Table/Text';

type TableSprintNumberCellPropType = {
  value: number;
};

export const TableSprintNumberCell = ({
  value: sprintNumber,
}: TableSprintNumberCellPropType) => (
  <TableTextCell dataTestId="participant-sprint-number">
    {sprintNumber}
  </TableTextCell>
);
