import { Badge, Flex, Spacer } from 'shared';
import { Icon, iconNames } from 'shared_DEPRECATED/components/Icon';

export const GoalHeaderPrioritizedBadge = () => {
  return (
    <Badge size="sm" dataTestid="prioritized-badge">
      <Flex alignItems="center" gap="var(--spacing00)">
        <Icon
          name={iconNames.flagNew}
          fill="var(--fgInteractive)"
          stroke="var(--fgInteractive)"
        />
        <Spacer />
        Prioritized
      </Flex>
    </Badge>
  );
};
