import { ReactNode } from 'react';

import { FlexItem } from 'shared_DEPRECATED/components/Flex';
import Spacer from 'shared_DEPRECATED/components/Spacer';

import { Typography } from 'shared/components/Typography';

type SessionsModalSectionProps = {
  title: string;
  children: ReactNode;
  dataTestid: string;
};

export const SessionsModalSection = ({
  title,
  children,
  dataTestid,
}: SessionsModalSectionProps) => {
  return (
    <FlexItem>
      <Typography color="gray" type="small" dataTestid={dataTestid}>
        {title}
      </Typography>
      <Spacer size="sm zr" />
      <>{children}</>
    </FlexItem>
  );
};
