import { Picture } from 'shared_DEPRECATED/types/Picture';

import { ICoachingTool } from 'features/coachingTool';

export class CoachingTool implements ICoachingTool {
  readonly id: string;
  readonly name: string;
  readonly description: string;
  readonly picture?: Picture;
  readonly deprecated: boolean;
  readonly createdAt: string;
  readonly updatedAt: string;

  constructor(coachingTool: ICoachingTool) {
    this.id = coachingTool.id;
    this.name = coachingTool.name;
    this.description = coachingTool.description;
    this.picture = coachingTool.picture;
    this.deprecated = coachingTool.deprecated;
    this.createdAt = coachingTool.createdAt;
    this.updatedAt = coachingTool.updatedAt;
  }
}
