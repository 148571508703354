import { SessionChallengeType } from 'features/sessions/config/types';
import { ISessionSprint } from 'features/sprint/config/types';

import { FinalSprint } from './FinalSprint';

export class SessionSprint extends FinalSprint implements ISessionSprint {
  readonly challenges: SessionChallengeType[];

  constructor(sprint: ISessionSprint) {
    super(sprint);

    this.challenges = sprint.challenges;
  }

  get challengesCount() {
    return this.challenges?.length || 0;
  }

  get challengesCountLabel() {
    const challengesCount = this.challengesCount;

    return `${challengesCount} ${
      challengesCount === 1 ? 'challenge' : 'challenges'
    }`;
  }

  get titleWithFormattedDateLabel() {
    return `${this.title} • ${this.formattedDate}`;
  }

  get weekNumberLabel() {
    return `week ${this.currentWeekNumber}`;
  }
}
