import PropTypes from 'prop-types';
import { useLayoutEffect } from 'react';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { ButtonIcon, VARIANTS } from 'shared_DEPRECATED/components/Button';
import { Flex } from 'shared_DEPRECATED/components/Flex';
import { PLACEMENTS } from 'shared_DEPRECATED/components/Floating';
import { DatePickerAnchor } from 'shared_DEPRECATED/components/Form/DatePicker/Anchor';
import { SIZES } from 'shared_DEPRECATED/components/Form/DatePicker/config';
import { iconNames } from 'shared_DEPRECATED/components/Icon';
import Spacer from 'shared_DEPRECATED/components/Spacer';
import useToggle from 'shared_DEPRECATED/hooks/useToggle';
import { dateUtils } from 'shared_DEPRECATED/utils';
import { noop } from 'shared_DEPRECATED/utils/function';

import { Typography } from 'shared/components/Typography';

import 'shared_DEPRECATED/components/Form/DatePicker/DatePickerOverride.css';

const DatePickerHeaderPropTypes = {
  date: PropTypes.instanceOf(Date),
  decreaseMonth: PropTypes.func,
  increaseMonth: PropTypes.func,
};

export const DatePickerHeader = ({ date, decreaseMonth, increaseMonth }) => (
  <Spacer size="lg">
    <Flex width="100%" justifyContent="space-between" alignItems="center">
      <Typography type="meta" dataTestid="datepicker-header-displayed-month">
        {dateUtils(date).format('MMM YYYY')}
      </Typography>
      <Flex>
        <ButtonIcon
          icon={iconNames.arrowLeft}
          onClick={decreaseMonth}
          dataTestid="datepicker-header-prev-month"
        />
        <ButtonIcon
          icon={iconNames.arrowRight}
          onClick={increaseMonth}
          dataTestid="datepicker-header-next-month"
        />
      </Flex>
    </Flex>
  </Spacer>
);

DatePickerHeader.propTypes = DatePickerHeaderPropTypes;

const DatePickerPropTypes = {
  date: PropTypes.instanceOf(Date),
  format: PropTypes.string,
  onChange: PropTypes.func,
  selectsRange: PropTypes.bool,
  startDate: PropTypes.instanceOf(Date),
  endDate: PropTypes.instanceOf(Date),
  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
  monthsShown: PropTypes.number,
  onCalendarClose: PropTypes.func,
  onCalendarOpen: PropTypes.func,
  customHeader: PropTypes.elementType,
  popperPlacement: PropTypes.string,
  showTimeSelect: PropTypes.bool,
  showTimeSelectOnly: PropTypes.bool,
  timeIntervals: PropTypes.number,
  anchorIcon: PropTypes.oneOf(Object.values(iconNames)),
  anchorVariant: PropTypes.oneOf([VARIANTS.TERTIARY, VARIANTS.SECONDARY]),
  size: PropTypes.oneOf([SIZES.SM, SIZES.LG]),
  disabled: PropTypes.bool,
  timeInputLabel: PropTypes.string,
  fixedHeight: PropTypes.bool,
};

export const DatePicker = ({
  format = 'MMM d, yyyy',
  date = null,
  onChange,
  selectsRange = false,
  startDate = null,
  endDate = null,
  minDate = null,
  maxDate = null,
  monthsShown = 1,
  onCalendarClose = noop,
  onCalendarOpen = noop,
  customHeader = DatePickerHeader,
  popperPlacement = PLACEMENTS.BOTTOM_START,
  anchorIcon,
  anchorVariant = VARIANTS.TERTIARY,
  size = SIZES.LG,
  timeInputLabel,
  fixedHeight = true,
  ...props
}) => {
  const [isOpen, toggleOpen] = useToggle();

  const handleCalendarOpen = () => {
    onCalendarOpen();
    toggleOpen();
  };

  const handleCalendarClose = () => {
    onCalendarClose();
    toggleOpen();
  };

  useLayoutEffect(() => {
    if (!timeInputLabel) {
      return;
    }

    const timeInputLabelElement = document.querySelector(
      '.react-datepicker-time__caption'
    );

    if (timeInputLabelElement) {
      timeInputLabelElement.textContent = timeInputLabel;
    }
  }, [timeInputLabel]);

  return (
    <ReactDatePicker
      selected={date}
      onChange={onChange}
      selectsRange={selectsRange}
      startDate={startDate}
      endDate={endDate}
      minDate={minDate}
      maxDate={maxDate}
      monthsShown={monthsShown}
      customInput={
        <DatePickerAnchor
          variant={anchorVariant}
          open={isOpen}
          icon={anchorIcon}
          size={size}
          disabled={props.disabled}
        />
      }
      dateFormat={format}
      renderCustomHeader={customHeader}
      formatWeekDay={(nameOfDay) => nameOfDay.substring(0, 3)}
      onCalendarClose={handleCalendarClose}
      onCalendarOpen={handleCalendarOpen}
      popperPlacement={popperPlacement}
      fixedHeight={fixedHeight}
      {...props}
    />
  );
};

DatePicker.propTypes = DatePickerPropTypes;
