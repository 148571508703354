import { Flex } from 'shared';
import { Icon, iconNames } from 'shared_DEPRECATED/components/Icon';
import Spacer from 'shared_DEPRECATED/components/Spacer';

import {
  SessionSidebarFieldset,
  SessionSidebarFieldsetLabel,
} from 'features/sessions/components';
import { useNextSessionMiroBoard } from 'features/sessions/hooks';

import { NextSessionFormMiroBoardLinkActions } from './MiroBoardLinkField/Actions';
import { NextSessionFormMiroBoardLink } from './MiroBoardLinkField/Link';

export const NextSessionFormMiroBoardLinkField = () => {
  const { onSave, onCopy, canLinkBeCopied, text, editMode, toggleEditMode } =
    useNextSessionMiroBoard();

  return (
    <SessionSidebarFieldset>
      <Flex>
        <Flex
          justifyContent="flex-start"
          width="100%"
          alignItems="center"
          h="2rem"
        >
          <Icon name={iconNames.miro} />
          <Spacer size="sm" />
          <SessionSidebarFieldsetLabel>
            Participant miro board
          </SessionSidebarFieldsetLabel>
        </Flex>
        <NextSessionFormMiroBoardLinkActions
          onSave={onSave}
          onEdit={toggleEditMode}
          onCopy={onCopy}
          canLinkBeCopied={canLinkBeCopied}
          isEditMode={editMode}
          isVisible={!!text.length}
        />
      </Flex>
      <NextSessionFormMiroBoardLink isEditMode={editMode} text={text} />
    </SessionSidebarFieldset>
  );
};
