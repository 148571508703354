import { Flex } from 'shared_DEPRECATED/components/Flex';
import { Spacers } from 'shared_DEPRECATED/components/Spacer';

import { MomentumFeedItemMinimumTrend } from 'features/momentum/components/Feed/Item/';

import { Typography } from 'shared/components/Typography';

type MomentumFeedItemMinimumGroupProps = {
  challenges: {
    title: string;
    frequency: string;
    targetAmount?: string;
  }[];
  firstChallengeTitle: string;
  trend: number;
  trendSign: '-' | '+';
};

export const MomentumFeedItemMinimumGroup = ({
  challenges,
  firstChallengeTitle,
  trend,
  trendSign,
}: MomentumFeedItemMinimumGroupProps) => (
  <Flex>
    <Spacers sizes={['zr zr zr lg', 'zr zr zr sm']}>
      <Typography type="small">Challenges slowing momentum</Typography>
      <Typography type="small" color="gray">
        {firstChallengeTitle}
        {`, +${challenges.length - 1} more`}
      </Typography>
    </Spacers>
    <Flex gap="var(--spacing02)">
      <MomentumFeedItemMinimumTrend
        challenges={challenges}
        trend={trend}
        trendSign={trendSign}
      />
    </Flex>
  </Flex>
);
