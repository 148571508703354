import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

type SeoTitleProps = {
  children: React.ReactNode;
  title: string;
};

export const SeoTitle = ({ title }: SeoTitleProps) => {
  const location = useLocation();

  useEffect(() => {
    document.title = title;
  }, [location, title]);

  return null;
};
