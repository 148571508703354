import { useCallback } from 'react';

import useUploadFileMutation from 'api/hooks/useUploadFileMutation';
import { useMutationHTTPRequest } from 'shared_DEPRECATED/hooks';

import { SessionRecapAttachmentType } from 'features/sessions/config/types';
import { getFileDetails } from 'features/sessions/utils';

export const useFileUpload = () => {
  const { request } = useMutationHTTPRequest();
  const { mutateAsync, isLoading } = useUploadFileMutation();

  const uploadFile = useCallback(
    async (file: File): Promise<SessionRecapAttachmentType> => {
      const data = await request({
        url: '/api/attachments/upload',
        headers: {
          'object-content-type': file.type,
        },
      });

      await mutateAsync({
        ...data,
        file,
      });

      const { attachmentId } = data;

      return {
        attachmentId,
        ...getFileDetails(file),
      };
    },
    [mutateAsync, request]
  );

  return { uploadFile, isLoading };
};
