import PropTypes from 'prop-types';

import Box from 'shared_DEPRECATED/components/Box';
import Spacer from 'shared_DEPRECATED/components/Spacer';

import ChallengeDimensionName from 'features/challenge/components/Dimension/Name/ChallengeDimensionName';
import { DIMENSIONS } from 'features/challenge/config';

import styles from './DimensionCheckbox.module.css';

const DimensionCheckBoxPropTypes = {
  name: PropTypes.oneOf(Object.values(DIMENSIONS)).isRequired,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  dataTestid: PropTypes.string,
};

const DimensionCheckbox = ({ name, checked, onChange, dataTestid = null }) => (
  <label className={styles['dimension-checkbox']} data-testid={dataTestid}>
    <Box
      style={{
        backgroundColor: checked
          ? 'var(--bgCompPrimaryInverse)'
          : 'transparent',
        border: 'var(--border-secondary)',
        borderRadius: 'var(--border-radius)',
      }}
      dataTestid="dimension-checkbox"
    >
      <Spacer size="sm md">
        <Spacer size="zr sm">
          <ChallengeDimensionName
            color={checked ? 'white' : 'black'}
            name={name}
          />
        </Spacer>
      </Spacer>
    </Box>
    <input checked={checked} name={name} onChange={onChange} type="checkbox" />
  </label>
);

DimensionCheckbox.propTypes = DimensionCheckBoxPropTypes;

export default DimensionCheckbox;
