import { useMutation, useQueryClient } from 'react-query';

import { useMutationHTTPRequest } from 'api/hooks/useHTTPRequest';
import { getParticipantsSprintsQueryKey } from 'api/utils';
import { logError } from 'lib/sentry/logError';

export const useChallengeBeyondSprintMutation = (sprint) => {
  const { request } = useMutationHTTPRequest();
  const queryClient = useQueryClient();

  return useMutation(
    async (editedChallenge) => {
      await request({
        url: `/api/sprints/${sprint.sprintId}/challenges/${editedChallenge.sprintChallengeId}/edit`,
        body: editedChallenge,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(
          getParticipantsSprintsQueryKey(sprint.userEmail)
        );
      },
      onError: (err) => {
        logError(err);
      },
    }
  );
};
