import PropTypes from 'prop-types';
import { forwardRef } from 'react';

import BaseInput from 'shared_DEPRECATED/components/Form/Input/BaseInput';
import { Icon, iconNames } from 'shared_DEPRECATED/components/Icon';

import styles from 'shared_DEPRECATED/components/Form/Input/Input.module.css';

const SearchInputPropTypes = {
  /**
   * @type {string}
   * @memberof SearchInputPropTypes
   * @name defaultValue
   * @example <BaseInput defaultValue="John Doe" />
   */
  defaultValue: PropTypes.string,
  /**
   * @type {string}
   * @memberof SearchInputPropTypes
   * @name name
   * @example <BaseInput name="John Doe" />
   */
  name: PropTypes.string.isRequired,
  /**
   * @type {string}
   * @memberof SearchInputPropTypes
   * @name placeholder
   * @example <BaseInput placeholder="John Doe" />
   */
  placeholder: PropTypes.string,
  /**
   * @type {('sm'|'lg')}
   * @required
   * @memberof SearchInputPropTypes
   * @name size
   * @example <BaseInput size="sm" />
   */
  size: PropTypes.oneOf(['sm', 'lg']),
  /**
   * @type {boolean}
   * @required
   * @memberof SearchInputPropTypes
   * @name error
   * @example <BaseInput error />
   */
  error: PropTypes.bool,
};

const SearchInput = forwardRef(
  ({ defaultValue = '', name, placeholder = '', ...props }, ref) => (
    <div className={styles.search__wrap}>
      <label
        htmlFor={name}
        className={styles.label__icon}
        data-testid="input-search-label"
      >
        <Icon
          name={iconNames.search}
          width={12}
          height={12}
          stroke="var(--fgPrimary)"
        />
      </label>
      <BaseInput
        ref={ref}
        type="search"
        defaultValue={defaultValue}
        placeholder={placeholder}
        name={name}
        data-testid="input-search-field"
        {...props}
      />
    </div>
  )
);

SearchInput.propTypes = SearchInputPropTypes;

export default SearchInput;
