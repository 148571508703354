import Box from 'shared_DEPRECATED/components/Box';

import { Typography } from 'shared/components/Typography';

type SessionsTableTitleCellProps = {
  value: string;
};

export const SessionsTableTitleCell = ({
  value,
}: SessionsTableTitleCellProps) => (
  <Box style={{ maxWidth: '12rem' }}>
    <Typography
      type="small"
      color="black"
      fontWeight="medium"
      cropped={true}
      dataTestid="session-title-cell"
    >
      {value}
    </Typography>
  </Box>
);
