export const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const PICTURE_STATUSES = {
  UPLOADING: 'uploading',
  UPLOADED: 'uploaded',
  UPLOAD_FAILED: 'upload_failed',
};

export const STORAGE_TYPE = {
  LOCAL: 'local',
  SESSION: 'session',
};

export const STORAGE_MAP = {
  [STORAGE_TYPE.LOCAL]: window.localStorage,
  [STORAGE_TYPE.SESSION]: window.sessionStorage,
};
