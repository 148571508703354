import PropTypes from 'prop-types';
import { forwardRef } from 'react';

import classNames from 'classnames/bind';

import styles from 'shared_DEPRECATED/components/Form/TextField/TextField.module.css';

const cx = classNames.bind(styles);

export const TextFieldProps = {
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.string,
  placeholder: PropTypes.string,
  border: PropTypes.bool,
  dataTestid: PropTypes.string,
};

export const TextField = forwardRef(
  (
    {
      name,
      defaultValue,
      border = true,
      placeholder = 'Type here',
      dataTestid = null,
      ...props
    },
    ref
  ) => {
    const classNames = cx('text-field', {
      'text-field--border': border,
    });

    return (
      <textarea
        ref={ref}
        name={name}
        className={classNames}
        defaultValue={defaultValue}
        placeholder={placeholder}
        data-testid={dataTestid}
        {...props}
      />
    );
  }
);

TextField.propTypes = TextFieldProps;
