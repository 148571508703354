import { Button } from 'shared';

import { useAddGoalFocusArea } from 'features/goal';

import { VARIANTS } from 'shared/components/Button';

export const GoalFocusAreaMenuAddButton = () => {
  const { onAddFocusAreaClick } = useAddGoalFocusArea();

  return (
    <Button
      variant={VARIANTS.TERTIARY}
      onClick={onAddFocusAreaClick}
      width="100%"
    >
      Focus area
    </Button>
  );
};
