import { NavLink } from 'react-router-dom';

import Box from 'shared_DEPRECATED/components/Box';
import { Flex } from 'shared_DEPRECATED/components/Flex';
import { Icon, TIconProps } from 'shared_DEPRECATED/components/Icon';
import Spacer, { Spacers } from 'shared_DEPRECATED/components/Spacer';

import { Typography } from 'shared/components/Typography';

type TNavigationLink = {
  iconConfig: TIconProps;
  link: string;
  title: string;
  spaces?: string[];
  dataTestid?: string;
};

export const NavigationLink = ({
  iconConfig,
  link,
  title,
  spaces = ['md'],
  dataTestid,
}: TNavigationLink) => {
  return (
    <NavLink
      to={link}
      //@ts-ignore
      exact="true"
      style={{ textDecoration: 'none' }}
    >
      {({ isActive }) => (
        <Box
          style={{
            borderRadius: 'var(--border-radius)',
            backgroundColor: isActive ? 'var(--bgSecondary)' : 'transparent',
          }}
          hoverStyle={{
            backgroundColor: 'var(--bgSecondary)',
          }}
          dataTestid={dataTestid}
        >
          <Spacers sizes={spaces}>
            <Flex alignItems="center" justifyContent="flex-start">
              <Icon {...iconConfig} />
              <Spacer size="zr md zr sm" />
              <Typography color="black" fontWeight="medium" type="meta">
                {title}
              </Typography>
            </Flex>
          </Spacers>
        </Box>
      )}
    </NavLink>
  );
};
