import SwitchButton from 'shared_DEPRECATED/components/Switch/Button';
import Switch from 'shared_DEPRECATED/components/Switch/Switch';
import { SwitchThemeLabel } from 'shared_DEPRECATED/components/Switch/theme/Label';

type TSwitchTheme = { checked: boolean; toggleTheme: () => void };

const SwitchTheme = ({ checked, toggleTheme }: TSwitchTheme) => (
  <Switch
    ariaLabel="Theme toggler"
    variant="secondary"
    size="md"
    checked={checked}
    dataTestid="theme-toggler"
  >
    <SwitchButton
      onChange={toggleTheme}
      checked={checked}
      disabled={false}
      dataTestid="theme-toggler-button"
      size="md"
    >
      <SwitchThemeLabel checked={checked} />
    </SwitchButton>
  </Switch>
);

export default SwitchTheme;
