import { useMemo, useState } from 'react';

import useEventsSubscription from 'shared_DEPRECATED/hooks/useEventsSubscription.js';

import { FEEDBACK_CALLBACK_EVENT } from 'features/feedback/config';
import { useFeedbackData, useFeedbackScript } from 'features/feedback/hooks';

export const useFeedback = () => {
  useFeedbackScript();
  useFeedbackData();

  const [openFeedbackDialog, setOpenFeedbackDialog] = useState<
    (() => void) | undefined
  >(undefined);

  const feedbackEvents = useMemo<Array<[string, Function]>>(
    () => [
      [
        FEEDBACK_CALLBACK_EVENT,
        ({ detail }: { detail: { openFeedbackDialog: () => void } }) => {
          setOpenFeedbackDialog(() => detail.openFeedbackDialog);
        },
      ],
    ],
    [setOpenFeedbackDialog]
  );

  useEventsSubscription(feedbackEvents);

  return {
    openFeedbackDialog,
  };
};
