import { When } from 'react-if';

import { useFeatureFlag } from 'featureFlags/hooks';

type RenderIfFFEnabledProps = {
  featureFlag: string;
  children: React.ReactNode;
};

export const RenderIfFFEnabled = ({
  featureFlag,
  children,
}: RenderIfFFEnabledProps) => {
  const isFeatureEnabled = useFeatureFlag(featureFlag);

  return <When condition={isFeatureEnabled}>{children}</When>;
};
