import React from 'react';

import Spacer, { Spacers } from 'shared_DEPRECATED/components/Spacer';

import { Typography } from 'shared/components/Typography';

type SessionsFormCoachingToolsSectionProps = {
  children: React.ReactNode;
  title?: string;
};

export const SessionsFormCoachingToolsSection = ({
  children,
  title,
}: SessionsFormCoachingToolsSectionProps) => (
  <Spacers sizes={['zr zr lg', 'zr zr md']}>
    {title && (
      <>
        <Typography type="small">{title}</Typography>
        <Spacer size="sm zr" />
      </>
    )}
    {children}
  </Spacers>
);
