import { ReactNode } from 'react';

import {
  GoalBreakdownDropZone,
  useMoveGoalBreakdownChallengesMutation,
  canItemBeDroppedInChallengeDropZone,
} from 'features/goal';

export const GoalFocusAreaDropZoneWrapper = ({
  children,
  isFocusAreaEmpty,
  focusAreaId,
  focusAreaIndex,
}: {
  children: ReactNode;
  isFocusAreaEmpty: boolean;
  focusAreaId: string;
  focusAreaIndex: number;
}) => {
  const { mutateAsync } = useMoveGoalBreakdownChallengesMutation({
    focusAreaId,
  });

  if (!isFocusAreaEmpty) {
    return <>{children}</>;
  }

  return (
    <GoalBreakdownDropZone
      handleCanDrop={({ index }) =>
        canItemBeDroppedInChallengeDropZone({
          index,
          dropZoneIndex: focusAreaIndex,
        })
      }
      accept={['challenge']}
      onDrop={mutateAsync}
      dropZoneIndex={focusAreaIndex}
      isLast={false}
    >
      {children}
    </GoalBreakdownDropZone>
  );
};
