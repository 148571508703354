import { useCallback } from 'react';

import { Image } from 'shared';
import Button from 'shared_DEPRECATED/components/Button/Button';

import { ChallengeNotesCardImageModal } from 'features/challengeNotes/components/Card';

import { SIZES, modal } from 'shared/components/Modal';

import styles from 'features/challengeNotes/components/Card/Card.module.css';

export const ChallengeNotesCardImageAttachment = ({ src }: { src: string }) => {
  const handleImageClick = useCallback(
    (src: string) =>
      modal.open({
        content: <ChallengeNotesCardImageModal src={src} />,
        props: {
          title: '',
          size: SIZES.SM,
        },
      }),
    []
  );

  if (!src) {
    return null;
  }

  return (
    <Button onClick={() => handleImageClick(src)}>
      <Image
        alt={src}
        boxSize="8rem"
        objectFit="cover"
        src={src}
        data-testId="challenge-notes-card-image"
        className={styles['card-image']}
      />
    </Button>
  );
};
