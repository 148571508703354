import {
  useChallengeNotesContext,
  useChallengeNotesFilterContext,
} from 'features/challengeNotes/context';

export const useFilterVisibility = () => {
  const { noNotes } = useChallengeNotesContext();
  const { notesFiltered } = useChallengeNotesFilterContext();
  const filterHidden = noNotes && !notesFiltered;

  return filterHidden;
};
