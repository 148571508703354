import PropTypes from 'prop-types';

import { DAILY_STATUS_VALUES } from 'shared_DEPRECATED/utils/config';

const StatusProgressShape = {
  status: PropTypes.oneOf(Object.values(DAILY_STATUS_VALUES)).isRequired,
  statusSetOn: PropTypes.string,
};

export const WeekdayTargetType = PropTypes.shape({
  type: PropTypes.string.isRequired,
  weekday: PropTypes.shape({
    mon: PropTypes.bool.isRequired,
    tue: PropTypes.bool.isRequired,
    wed: PropTypes.bool.isRequired,
    thu: PropTypes.bool.isRequired,
    fri: PropTypes.bool.isRequired,
    sat: PropTypes.bool.isRequired,
    sun: PropTypes.bool.isRequired,
  }).isRequired,
});

export const WeeklyTargetType = PropTypes.shape({
  type: PropTypes.string.isRequired,
  weekly: PropTypes.arrayOf(PropTypes.number).isRequired,
});

const DueDateTargetShape = {
  type: PropTypes.string.isRequired,
  dueDate: PropTypes.string.isRequired,
};

const DueDateTargetProgressShape = {
  ...DueDateTargetShape,
  ...StatusProgressShape,
};
const DueDateTargetType = PropTypes.shape(DueDateTargetShape);

const DueDateTargetProgressType = PropTypes.shape(DueDateTargetProgressShape);

export const ChallengeTargetProgressType = PropTypes.oneOfType([
  WeekdayTargetType,
  WeeklyTargetType,
  DueDateTargetProgressType,
]);

export const ChallengeTargetType = PropTypes.oneOfType([
  WeekdayTargetType,
  WeeklyTargetType,
  DueDateTargetType,
]);
