import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ReferenceDot,
  ReferenceLine,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import Box from 'shared_DEPRECATED/components/Box';
import { ChartDot } from 'shared_DEPRECATED/components/Chart/Dot';

import { SidebarSection } from 'shared/components/Sidebar';

import { MomentumFeedChartLegend } from './Chart/Legend';
import { MomentumFeedChartTooltip } from './Chart/Tooltip';
import { MomentumFeedXAxisTick } from './Chart/XAxisTick';

export const getVerticalGridLines = ({
  offset,
  xAxis,
}: {
  xAxis: { domain: number[]; padding: { right: number } };
  offset: {
    width: number;
    left: number;
  };
}) => {
  const linesCount = xAxis.domain.length;

  const lineStep = (offset.width - xAxis.padding.right) / (linesCount - 1);

  return new Array(linesCount)
    .fill(0)
    .map((_, index) => offset.left + lineStep * index);
};

type TMomentumFeedChart = {
  data: {
    prevScore?: number;
    date: string;
    score?: number;
  }[];
  referenceDotData?: {
    prevScore?: number;
    date: string;
    score?: number;
  };
};

export const MomentumFeedChart = ({
  data,
  referenceDotData,
}: TMomentumFeedChart) => {
  return (
    <SidebarSection>
      <Box dataTestid="momentum-feed-chart">
        <LineChart width={370} height={180} data={data}>
          <CartesianGrid
            strokeDasharray="3 3"
            horizontal={false}
            verticalCoordinatesGenerator={getVerticalGridLines}
          />
          <XAxis
            dataKey="date"
            axisLine={false}
            interval={0}
            padding={{ right: 10 }}
            type="category"
            tickLine={false}
            tick={
              /* @ts-ignore */
              <MomentumFeedXAxisTick />
            }
          />
          <YAxis
            axisLine={false}
            domain={[0, 100]}
            tickLine={false}
            tick={{
              fontSize: 'var(--font-size-small)',
              fill: 'var(--fgSecondary)',
            }}
            width={25}
          />
          <Tooltip
            content={
              /* @ts-ignore */
              <MomentumFeedChartTooltip />
            }
            cursor={{
              strokeDasharray: '3 3',
              stroke: 'var(--fgPrimary)',
            }}
          />
          <Legend content={MomentumFeedChartLegend} />
          <ReferenceDot
            x={referenceDotData?.date}
            y={referenceDotData?.score}
            r={3}
            fill="var(--fgPrimary)"
            shape={ChartDot}
          />
          <ReferenceLine
            x={referenceDotData?.date}
            stroke="var(--fgPrimary)"
            strokeDasharray="3 3"
          />
          <Line
            dot={false}
            type="monotone"
            dataKey="score"
            stroke="var(--fgPrimary)"
            fill="var(--fgPrimary)"
            strokeWidth={2}
            strokeLinecap="round"
            isAnimationActive={false}
          />
          <Line
            activeDot={false}
            dot={false}
            type="monotone"
            dataKey="prevScore"
            stroke="var(--fgSecondary)"
            fill="var(--fgSecondary)"
            strokeWidth={2}
            strokeLinecap="round"
            isAnimationActive={false}
          />
        </LineChart>
      </Box>
    </SidebarSection>
  );
};
