import { FlexItem } from 'shared_DEPRECATED/components/Flex';
import { Spacers } from 'shared_DEPRECATED/components/Spacer/Spacers';

import { CoachSessionsTable } from 'features/sessions/components/CoachTable';
import { SessionsEmptyState } from 'features/sessions/components/EmptyState';
import { SessionListItemType } from 'features/sessions/config/types';

import { Typography } from 'shared/components/Typography';

type TCoachSessionsContent = {
  sessions: SessionListItemType[];
};

export const CoachSessionsContent = ({ sessions }: TCoachSessionsContent) => {
  return sessions?.length > 0 ? (
    <>
      <Spacers sizes={['zr lg', 'zr md']}>
        <Typography type="small" textTransform="uppercase" color="black">
          {sessions.length} {sessions.length === 1 ? 'session' : 'sessions'}
        </Typography>
      </Spacers>
      <CoachSessionsTable sessions={sessions} />
    </>
  ) : (
    <FlexItem flexGrow={1}>
      <SessionsEmptyState />
    </FlexItem>
  );
};
