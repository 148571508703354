import { useFormContext, useWatch } from 'react-hook-form';

import { TextEditor } from 'shared_DEPRECATED/components/TextEditor';
import { TextEditorContainer } from 'shared_DEPRECATED/components/TextEditor/Container';
import { TextEditorToolbar } from 'shared_DEPRECATED/components/TextEditor/Toolbar';

type TGoalDeepDiveFormEditor = {
  keyField: string;
  id: string;
};

export const GoalDeepDiveFormEditor = ({
  keyField,
  id,
}: TGoalDeepDiveFormEditor) => {
  const { setValue } = useFormContext();
  const content = useWatch({ name: keyField });

  const handleContentChange = (value: string) => {
    setValue(keyField, value, { shouldDirty: true });
  };

  return (
    <TextEditorContainer dataTestId={`${keyField}-editor"`}>
      <TextEditorToolbar id={id} />
      <TextEditor value={content} setValue={handleContentChange} id={id} />
    </TextEditorContainer>
  );
};
