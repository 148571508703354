import { ButtonIcon } from 'shared_DEPRECATED/components/Button';
import { Flex } from 'shared_DEPRECATED/components/Flex';
import { iconNames } from 'shared_DEPRECATED/components/Icon';
import Spacer from 'shared_DEPRECATED/components/Spacer';
import { dateUtils } from 'shared_DEPRECATED/utils';

import { Typography } from 'shared/components/Typography';

type TDatePickerWithYearMonthSelectorHeader = {
  date: Date;
  increaseMonth: () => void;
  decreaseMonth: () => void;
  increaseYear: () => void;
  decreaseYear: () => void;
};

export const DatePickerWithYearMonthSelectorHeader = ({
  date,
  increaseMonth,
  decreaseMonth,
  increaseYear,
  decreaseYear,
}: TDatePickerWithYearMonthSelectorHeader) => (
  <Spacer size="lg">
    <Flex width="100%" justifyContent="space-between" alignItems="center">
      <Flex>
        <ButtonIcon
          icon={iconNames.arrowLeft}
          onClick={decreaseMonth}
          dataTestid="datepicker-header-prev-month"
        />
        <Spacer size="sm" />
        <Typography type="meta" dataTestid="datepicker-header-displayed-month">
          {dateUtils(date).format('MMM')}
        </Typography>
        <Spacer size="sm" />
        <ButtonIcon
          icon={iconNames.arrowRight}
          onClick={increaseMonth}
          dataTestid="datepicker-header-next-month"
        />
      </Flex>
      <Flex>
        <ButtonIcon
          icon={iconNames.arrowLeft}
          onClick={decreaseYear}
          dataTestid="datepicker-header-prev-year"
        />
        <Spacer size="sm" />
        <Typography type="meta" dataTestid="datepicker-header-displayed-year">
          {dateUtils(date).format('YYYY')}
        </Typography>
        <Spacer size="sm" />
        <ButtonIcon
          icon={iconNames.arrowRight}
          onClick={increaseYear}
          dataTestid="datepicker-header-next-year"
        />
      </Flex>
    </Flex>
  </Spacer>
);
