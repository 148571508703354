import PropTypes from 'prop-types';

import { File, FileTitle } from 'shared_DEPRECATED/components/File';
import { FlexItem } from 'shared_DEPRECATED/components/Flex';
import Loader from 'shared_DEPRECATED/components/Loader';

const LoadingFilePropTypes = {
  contentType: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

export const LoadingFile = ({ contentType, name }) => (
  <File>
    <FlexItem flexGrow={0} flexShrink={0}>
      <Loader size="sm" dataTestid="file-loader" />
    </FlexItem>
    <FileTitle title={name} contentType={contentType} />
  </File>
);

LoadingFile.propTypes = LoadingFilePropTypes;
