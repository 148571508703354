import {
  ButtonIcon,
  ButtonIconPropTypes,
  VARIANTS,
} from 'shared/components/Button';

type ToolbarButtonIconProps = ButtonIconPropTypes;

export const ToolbarButtonIcon = (props: ToolbarButtonIconProps) => {
  return (
    <ButtonIcon
      variant={VARIANTS.SYMBOL}
      _focus={{
        outline: 'none',
      }}
      _active={{
        bg: 'none',
      }}
      {...props}
    />
  );
};
