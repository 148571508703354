import { dateUtils } from 'shared_DEPRECATED/utils';

export const range = (start, end) => {
  return Array.from({ length: end - start + 1 }, (_, i) => start + i);
};

export const sortByDateDesc = (array, dateField) =>
  array.sort((item1, item2) =>
    dateUtils(item2[dateField]).isAfter(item1[dateField]) ? 1 : -1
  );

export const sortByDateAsc = (array, dateField) =>
  array.sort((item1, item2) =>
    dateUtils(item1[dateField]).isAfter(item2[dateField]) ? 1 : -1
  );

export const unionBy = (arr, ...args) => {
  let iteratee = args.pop();
  if (typeof iteratee === 'string') {
    const prop = iteratee;
    iteratee = (item) => item[prop];
  }

  return arr
    .concat(...args)
    .filter(
      (x, i, self) => i === self.findIndex((y) => iteratee(x) === iteratee(y))
    );
};

export const updateArrayById = ({ array, updatedItem, idProperty }) =>
  array.map((item) =>
    item[idProperty] === updatedItem[idProperty] ? updatedItem : item
  );

export const removeFromArrayById = ({ array, id, idProperty }) =>
  array.filter((item) => item[idProperty] !== id);
