import PropTypes from 'prop-types';

import { NotificationsFilter } from 'app/notifications/components/NotificationsFilter';
import { notificationFilterProps } from 'app/notifications/config';

import { SidebarMainHeader } from 'shared/components/Sidebar';

const notificationHeaderProps = {
  filter: notificationFilterProps,
  setFilter: PropTypes.func.isRequired,
};

export const NotificationsHeader = ({ filter, setFilter }) => {
  return (
    <SidebarMainHeader title="Notifications">
      <NotificationsFilter filter={filter} setFilter={setFilter} />
    </SidebarMainHeader>
  );
};

NotificationsHeader.propTypes = notificationHeaderProps;
