import { iconNames } from 'shared_DEPRECATED/components/Icon';

const CONTENT_TYPES = {
  PDF: 'application/pdf',
  RTF: 'application/rtf',
  DOC: 'application/msword',
  DOCX: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  XLS: 'application/vnd.ms-excel',
  XLSX: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  JPEG: 'image/jpeg',
  PNG: 'image/png',
};

export const CONTENT_TYPE_CATEGORIES = {
  [CONTENT_TYPES.PDF]: 'PDF',
  [CONTENT_TYPES.RTF]: 'Docs',
  [CONTENT_TYPES.DOC]: 'Docs',
  [CONTENT_TYPES.DOCX]: 'Docs',
  [CONTENT_TYPES.XLS]: 'Excel',
  [CONTENT_TYPES.XLSX]: 'Excel',
};

export const CONTENT_TYPE_CATEGORIES_ICONS_CONFIG = {
  PDF: iconNames.pdf,
  DOC: iconNames.word,
  XLS: iconNames.excel,
  IMAGE: iconNames.imagePlaceholder,
  OTHER: iconNames.file,
};

export const CONTENT_TYPES_ICON_CONFIG = {
  [CONTENT_TYPES.PDF]: CONTENT_TYPE_CATEGORIES_ICONS_CONFIG.PDF,
  [CONTENT_TYPES.DOC]: CONTENT_TYPE_CATEGORIES_ICONS_CONFIG.DOC,
  [CONTENT_TYPES.DOCX]: CONTENT_TYPE_CATEGORIES_ICONS_CONFIG.DOC,
  [CONTENT_TYPES.RTF]: CONTENT_TYPE_CATEGORIES_ICONS_CONFIG.DOC,
  [CONTENT_TYPES.XLS]: CONTENT_TYPE_CATEGORIES_ICONS_CONFIG.XLS,
  [CONTENT_TYPES.XLSX]: CONTENT_TYPE_CATEGORIES_ICONS_CONFIG.XLS,
  [CONTENT_TYPES.JPEG]: CONTENT_TYPE_CATEGORIES_ICONS_CONFIG.IMAGE,
  [CONTENT_TYPES.PNG]: CONTENT_TYPE_CATEGORIES_ICONS_CONFIG.IMAGE,
};

export const BYTES_IN_KILOBYTE = 1024;
export const BYTES_IN_MEGABYTE = Math.pow(BYTES_IN_KILOBYTE, 2);
export const FILE_UNIT_MEASURES = ['Bytes', 'KB', 'MB', 'GB'];
