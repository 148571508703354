import { Flex } from 'shared';

import {
  NextSessionFormMiroBoardLinkEdit,
  NextSessionFormMiroBoardLinkView,
} from './Link/';

type TNextSessionFormMiroBoardLink = {
  isEditMode: boolean;
  text: string;
};

export const NextSessionFormMiroBoardLink = ({
  isEditMode,
  text,
}: TNextSessionFormMiroBoardLink) => (
  <Flex minHeight="2.5rem" alignItems="center" width="100%">
    {isEditMode ? (
      <NextSessionFormMiroBoardLinkEdit />
    ) : (
      <NextSessionFormMiroBoardLinkView text={text} />
    )}
  </Flex>
);
