import { ReactNode } from 'react';

import { ACCEPTS, useGoalsBoardDrop, useMoveGoalMutation } from 'features/goal';

import { BoardDropZone } from 'shared/components/Board';

type TGoalBoardDropZone = {
  dropZonePath: number[];
  isLast: boolean;
  children?: ReactNode;
};

export const GoalBoardDropZone = ({
  dropZonePath,
  isLast,
  children,
}: TGoalBoardDropZone) => {
  const { mutateAsync } = useMoveGoalMutation();

  const { drop, isOver, canDrop } = useGoalsBoardDrop({
    dropZonePath,
    onDrop: mutateAsync,
    accept: ACCEPTS,
  });

  return (
    <BoardDropZone isLast={isLast} canDrop={canDrop} isOver={isOver} ref={drop}>
      {children}
    </BoardDropZone>
  );
};
