import { SPRINT_DURATION } from 'featureFlags/config';
import { useFeatureFlag, useParticipantsFeatureFlag } from 'featureFlags/hooks';

import { DEFAULT_SPRINT_WEEKS_COUNT } from 'features/sprint/config';
import { USER_MODES } from 'features/user/config';
import { useUserMode } from 'features/user/hooks';

export const useSprintDurationFeatureFlag = () => {
  const userMode = useUserMode();
  const participantWeeksCount = useParticipantsFeatureFlag(SPRINT_DURATION);
  const myWeeksCount = useFeatureFlag(SPRINT_DURATION);

  if (userMode === USER_MODES.dashboard) {
    return Number(myWeeksCount || DEFAULT_SPRINT_WEEKS_COUNT);
  }

  return Number(participantWeeksCount || DEFAULT_SPRINT_WEEKS_COUNT);
};
