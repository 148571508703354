type ChartDotPropsType = {
  cx: number;
  cy: number;
  radius?: number;
  fill?: string;
};

export const ChartDot = ({
  cx,
  cy,
  radius = 3,
  fill = 'black',
}: ChartDotPropsType) => <circle cx={cx} cy={cy} r={radius} fill={fill} />;
