import { useCallback, useEffect, useRef } from 'react';
import { FieldValues, UseFormReturn, useWatch } from 'react-hook-form';
import {
  BlockerFunction,
  Location,
  useBeforeUnload,
  useBlocker,
} from 'react-router-dom';

import { logError } from 'lib/sentry/logError';

import { useBrowserStorage } from './useBrowserStorage';
import useDebounce from './useDebounce';

const DEFAULT_TIMEOUT = 3000; //3sec.
const SAVE_ERROR = 'saveError';
const ROOT_SAVE_ERROR = `root.${SAVE_ERROR}` as const;

interface AutosaveProps<TFormValues extends FieldValues> {
  formMethods: UseFormReturn<TFormValues>;
  onMutate: (data: TFormValues) => Promise<void>;
  storageKey: string;
  timeout?: number;
  onSuccess?: () => void;
}

export const useFormAutosave = <TFormValues extends FieldValues>({
  formMethods,
  storageKey,
  onMutate,
  timeout = DEFAULT_TIMEOUT,
  onSuccess,
}: AutosaveProps<TFormValues>) => {
  const componentWillUnmount = useRef(false);
  const formData = useWatch({
    control: formMethods.control,
  });
  const debouncedFormData = useDebounce(formData, timeout);
  const { dirtyFields, isSubmitted, isSubmitting, isDirty, isValid } =
    formMethods.formState;

  const isFormDirty = isDirty || Object.keys(dirtyFields).length > 0;

  const { setStorageValue, removeStorageValue, getStorageValue } =
    useBrowserStorage<TFormValues>({ storageKey });

  const sendData = useCallback(async () => {
    try {
      await formMethods.handleSubmit(onMutate)();
    } catch (e) {
      logError(e);
      formMethods.reset(debouncedFormData);
      setStorageValue(debouncedFormData);
      formMethods.setError(ROOT_SAVE_ERROR, {
        type: SAVE_ERROR,
        message: 'Changes not saved',
      });
    }

    formMethods.reset(undefined, { keepValues: true, keepDirty: false });
    removeStorageValue();
    if (onSuccess) {
      onSuccess();
    }
  }, [
    debouncedFormData,
    onMutate,
    formMethods,
    setStorageValue,
    removeStorageValue,
    onSuccess,
  ]);

  useEffect(() => {
    return () => {
      componentWillUnmount.current = true;
    };
  }, []);

  // attempt to save previously unsaved values to BE
  useEffect(() => {
    const unsaveddebouncedFormData = getStorageValue();
    let parsedValue;

    try {
      parsedValue =
        unsaveddebouncedFormData && JSON.parse(unsaveddebouncedFormData);
    } catch (e) {
      logError(e);
    }

    if (parsedValue) {
      formMethods.reset(parsedValue);
      removeStorageValue();
      sendData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isReadyToSave = isValid && isFormDirty && !isSubmitted && !isSubmitting;

  //save on component unmount
  useEffect(
    () => () => {
      if (!componentWillUnmount.current || !isValid || !isFormDirty) {
        return;
      }

      sendData();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isFormDirty, isValid, componentWillUnmount]
  );

  //save after ${timeout} of inactivity
  useEffect(() => {
    if (isReadyToSave) {
      sendData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedFormData]);

  //save when browser tab is closed
  useBeforeUnload((evt) => {
    if (!isValid) {
      evt.preventDefault();

      return 'You have unsaved changes. Are you sure you want to leave?';
    }

    if (!isFormDirty) {
      return;
    }

    sendData();
  });

  let shouldBlock = useCallback<BlockerFunction>(
    ({
      currentLocation,
      nextLocation,
    }: {
      currentLocation: Location;
      nextLocation: Location;
    }) => !isValid && currentLocation.pathname !== nextLocation.pathname,
    [isValid]
  );

  useBlocker(shouldBlock);
};
