import { BoxProps, Text } from '@chakra-ui/react';

const fontWeights = {
  regular: '400',
  medium: '500',
  semiBold: '600',
  bold: '700',
} as const;

export type TYPOGRAPHY_COLORS =
  | 'black'
  | 'blackTotal'
  | 'gray'
  | 'grayTotal'
  | 'lightGray'
  | 'green'
  | 'orange'
  | 'red'
  | 'blue'
  | 'white'
  | 'whiteTotal';

export const Typography = ({
  children,
  type,
  fontWeight = 'regular',
  color,
  cropped,
  dataTestid,
  wordBreak = 'normal',
  ...props
}: Omit<BoxProps, 'fontWeight' | 'color'> & {
  type?:
    | 'xsm'
    | 'small'
    | 'meta'
    | 'body'
    | 'smallHeading'
    | 'heading'
    | 'semiLargeHeading'
    | 'largeHeading'
    | 'extraLargeHeading';
  fontWeight?: keyof typeof fontWeights;
  color?: TYPOGRAPHY_COLORS;
  cropped?: boolean;
  dataTestid?: string;
}) => (
  <Text
    size={type}
    fontWeight={fontWeights[fontWeight]}
    variant={color}
    isTruncated={cropped}
    data-testid={dataTestid}
    wordBreak={wordBreak}
    {...props}
  >
    {children}
  </Text>
);
