import PropTypes from 'prop-types';
import { Component, isValidElement } from 'react';

import { logError } from 'lib/sentry/logError';
import { noop } from 'shared_DEPRECATED/utils/function';

const initialState = { error: null };

const changedArray = (array1, array2) =>
  array1.length !== array2.length ||
  array1.some((item, index) => !Object.is(item, array2[index]));

const ErrorBoundaryProps = {
  onResetKeysChange: PropTypes.func,
  onReset: PropTypes.func,
  onError: PropTypes.func,
  resetKeys: PropTypes.array,
  fallback: PropTypes.element,
  FallbackComponent: PropTypes.elementType,
  fallbackRender: PropTypes.func,
  children: PropTypes.node.isRequired,
};

export class ErrorBoundary extends Component {
  static getDerivedStateFromError(error) {
    return { error };
  }

  state = initialState;
  resetErrorBoundary = (...args) => {
    this.props.onReset(...args);
    this.reset();
  };

  reset() {
    this.setState(initialState);
  }

  componentDidCatch(error, info) {
    this.props.onError(error, info);
    logError(error);
  }

  componentDidUpdate(prevProps, prevState) {
    const { error } = this.state;
    const { resetKeys } = this.props;

    // There's an edge case where if the thing that triggered the error
    // happens to *also* be in the resetKeys array, we'd end up resetting
    // the error boundary immediately. This would likely trigger a second
    // error to be thrown.
    // So we make sure that we don't check the resetKeys on the first call
    // of cDU after the error is set

    if (
      error !== null &&
      prevState.error !== null &&
      changedArray(prevProps.resetKeys, resetKeys)
    ) {
      this.props.onResetKeysChange(prevProps.resetKeys, resetKeys);
      this.reset();
    }
  }

  render() {
    const { error } = this.state;

    const { fallbackRender, FallbackComponent, fallback } = this.props;

    if (error !== null) {
      const props = {
        error,
        resetErrorBoundary: this.resetErrorBoundary,
      };
      if (isValidElement(fallback)) {
        return fallback;
      } else if (typeof fallbackRender === 'function') {
        return fallbackRender(props);
      } else if (FallbackComponent) {
        return <FallbackComponent {...props} />;
      } else {
        throw new Error(
          'react-error-boundary requires either a fallback, fallbackRender, or FallbackComponent prop'
        );
      }
    }

    return this.props.children;
  }
}

ErrorBoundary.propTypes = ErrorBoundaryProps;

ErrorBoundary.defaultProps = {
  fallback: null,
  onError: noop,
  onReset: noop,
  onResetKeysChange: noop,
  resetKeys: [],
};
