import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import {
  JIRA_SETTINGS_GLOBAL_FIELD,
  TFeedbackFormData,
  USER_ID_FIELD,
  WEB_BUILD_FIELD,
} from 'features/feedback/config';
import { useParticipantQuery } from 'features/participants/hooks';
import { useUserContext } from 'features/user/context';

export const useFeedbackData = () => {
  const { user } = useUserContext();
  const { email } = useParams();
  const { participant } = useParticipantQuery(email);
  const feedbackSettings = (window as any)[JIRA_SETTINGS_GLOBAL_FIELD] as {
    fieldValues: TFeedbackFormData;
  };

  useEffect(() => {
    if (feedbackSettings) {
      feedbackSettings.fieldValues.fullname = user.name;
      feedbackSettings.fieldValues.email = user.email;
      feedbackSettings.fieldValues[USER_ID_FIELD] = participant?.userId || '';
      feedbackSettings.fieldValues[WEB_BUILD_FIELD] =
        process.env.REACT_APP_RELEASE || '';
    }
  }, [feedbackSettings, participant?.userId, user.email, user.name]);
};
