import { OptionsMenuButton } from 'shared_DEPRECATED/components/Floating/Menu/Options';

import { useDeleteGoalBreakdownChallengeMutation } from 'features/goal';

import { Typography } from 'shared/components/Typography';

type TGoalBreakdownChallengeCardMenuRemoveButton = {
  challengeId: string;
};

export const GoalBreakdownChallengeCardMenuRemoveButton = ({
  challengeId,
}: TGoalBreakdownChallengeCardMenuRemoveButton) => {
  const { mutateAsync: deleteGoalBreakdownChallenge } =
    useDeleteGoalBreakdownChallengeMutation({
      challengeId,
    });

  return (
    <OptionsMenuButton onClick={deleteGoalBreakdownChallenge}>
      <Typography color="red">Remove</Typography>
    </OptionsMenuButton>
  );
};
