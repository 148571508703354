import PropTypes from 'prop-types';

import { Flex } from 'shared_DEPRECATED/components/Flex';

import { ParticipantsTableEmptyCell } from 'features/participants/components/Table';
import { SPRINT_STATES } from 'features/sprint/config';

import { Badge } from 'shared/components/Badge';
import { Typography } from 'shared/components/Typography';

const CurrentWeekCellPropTypes = {
  value: PropTypes.number,
  row: PropTypes.shape({
    original: PropTypes.shape({
      latestSprint: PropTypes.shape({
        state: PropTypes.oneOf(Object.values(SPRINT_STATES)),
        startDate: PropTypes.string,
        weekLabelForSprintDate: PropTypes.string.isRequired,
      }),
    }),
  }),
};

export const CurrentWeekCell = ({ value: currentWeekNumber, row }) => {
  const { latestSprint } = row.original;
  const hasWeekNumber = currentWeekNumber > 0;

  return latestSprint ? (
    <Flex>
      <Badge bgColor={hasWeekNumber ? 'lightBlue' : 'gray'} size="sm">
        <Typography
          as="span"
          type="small"
          color={hasWeekNumber ? 'blue' : 'black'}
          fontWeight="regular"
          dataTestid="participant-sprint-week"
        >
          {latestSprint.weekLabelForSprintDate}
        </Typography>
      </Badge>
    </Flex>
  ) : (
    <ParticipantsTableEmptyCell dataTestid="participant-sprint-week" />
  );
};

CurrentWeekCell.propTypes = CurrentWeekCellPropTypes;
