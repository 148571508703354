import { ReactNode } from 'react';

import Box from 'shared_DEPRECATED/components/Box/Box';
import { EMPTY_CELL_VALUE } from 'shared_DEPRECATED/config/table';

import { Typography } from 'shared/components/Typography';

type TableTextCellPropTypes = { children: ReactNode; dataTestId?: string };

export const TableTextCell = ({
  children,
  dataTestId,
}: TableTextCellPropTypes) => (
  <Box style={{ minWidth: '100px' }}>
    <Typography
      fontWeight="regular"
      type="small"
      color="black"
      data-testid={dataTestId}
    >
      {children || EMPTY_CELL_VALUE}
    </Typography>
  </Box>
);
