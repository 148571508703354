import PropTypes from 'prop-types';
import { useCallback, useEffect, useRef } from 'react';

import Box from 'shared_DEPRECATED/components/Box';

const FloatingContainerProps = {
  children: PropTypes.node.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export const FloatingContainer = ({ children, isOpen, onClose }) => {
  const dropdownRef = useRef(null);

  const handleClickOutside = useCallback(
    (e) => {
      if (
        isOpen &&
        dropdownRef.current &&
        !dropdownRef.current.contains(e.target)
      ) {
        onClose();
      }
    },
    [isOpen, onClose]
  );

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
    // eslint-disable-next-line
  }, [handleClickOutside]);

  return <Box ref={dropdownRef}>{children}</Box>;
};

FloatingContainer.propTypes = FloatingContainerProps;
