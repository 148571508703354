import { toastConfig } from 'lib/reactToastify/config';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import {
  useDeleteGoalMutation,
  useOpenDeleteGoalModal,
  useGoalsNavigationLink,
} from 'features/goal';

type TUseDeleteGoal = {
  goalId: string;
  columnId: string;
};

export const useDeleteGoal = ({ goalId, columnId }: TUseDeleteGoal) => {
  const navigate = useNavigate();

  const url = useGoalsNavigationLink();

  const { mutateAsync } = useDeleteGoalMutation({
    goalId,
    modifiedColumnId: columnId,
  });

  const onSubmit = async () => {
    try {
      await mutateAsync();

      navigate(url);
    } catch (err) {
      toast.error(
        "There's an error with deleting the goal. Please try again later.",
        toastConfig
      );
    }
  };

  const openDeleteGoalModal = useOpenDeleteGoalModal();

  return {
    onDeleteGoalClick: () =>
      openDeleteGoalModal({
        onSubmit,
      }),
  };
};
