import { useFormContext, useWatch } from 'react-hook-form';

import Box from 'shared_DEPRECATED/components/Box';
import { Flex } from 'shared_DEPRECATED/components/Flex';
import { DatePicker } from 'shared_DEPRECATED/components/Form/DatePicker';
import { Icon, iconNames } from 'shared_DEPRECATED/components/Icon';
import Spacer from 'shared_DEPRECATED/components/Spacer';
import { dateUtils } from 'shared_DEPRECATED/utils';

import { SessionsModalSection } from 'features/sessions/components/Modal';

import { Typography } from 'shared/components/Typography';

export const SessionsModalDatePicker = () => {
  const { setValue } = useFormContext();
  const sessionDate = useWatch({ name: 'coachingSessionDate' });

  return (
    <SessionsModalSection
      title="Session date"
      dataTestid="session-date-selector"
    >
      <DatePicker
        //@ts-ignore
        date={dateUtils(sessionDate).toDate()}
        onChange={(currentDate: Date) =>
          setValue('coachingSessionDate', dateUtils(currentDate).format())
        }
        anchorIcon={iconNames.calendar}
      />
      <Spacer size="xsm zr" />
      <Flex>
        <Box
          style={{
            backgroundColor: 'var(--bgCompMinorWarning)',
            borderRadius: 'var(--border-radius)',
          }}
        >
          <Spacer size="sm md">
            <Flex gap="var(--spacing02)">
              <Icon name={iconNames.info} stroke="var(--fgWarning)" />
              <Typography
                type="small"
                color="orange"
                dataTestid="date-picker-alert"
              >
                You will not be able to change the date after the session is
                created.
              </Typography>
            </Flex>
          </Spacer>
        </Box>
      </Flex>
    </SessionsModalSection>
  );
};
