import { compareStringsIgnoreCase, dateUtils } from 'shared_DEPRECATED/utils';

import {
  COACHING_STATUS_SORTING_ORDER,
  SESSION_TIME_INTERVAL,
  SPRINT_WEEK_ASC_SORTING_ORDER,
  SPRINT_WEEK_DESC_SORTING_ORDER,
} from 'features/participants/config';

const ignoreEmptyValuesInSorting = (valueA, valueB, isDesc) => {
  if (!valueA) {
    return isDesc ? -1 : 1;
  }

  if (!valueB) {
    return isDesc ? 1 : -1;
  }
};

export const getParticipantName = (userInfo) =>
  userInfo.fullName || userInfo.email;

export const sortTableByUserName = (rowA, rowB) => {
  const rowATitle = getParticipantName(rowA.original.userInfo);
  const rowBTitle = getParticipantName(rowB.original.userInfo);

  return compareStringsIgnoreCase(rowATitle, rowBTitle);
};

export const sortTableBySprintStartDate = (rowA, rowB) =>
  dateUtils.sortingDiff(
    rowA.original.latestSprint.startDate,
    rowB.original.latestSprint.startDate
  );

export const filterByUserName = (rows, id, filterValue) => {
  const searchString = filterValue.trim().toLowerCase();

  return rows.filter((row) => {
    const { fullName, email } = row.values[id];

    const userName = fullName?.toLowerCase() || '';
    const userEmail = email.toLowerCase();

    return userName.includes(searchString) || userEmail.includes(searchString);
  });
};

export const sortTableBySessionDate = (rowA, rowB, columnId, isDesc) => {
  const dateA = rowA.original[columnId]?.startAt;
  const dateB = rowB.original[columnId]?.startAt;

  //put null dates at the end of sorted list
  if (!dateA || !dateB) {
    return ignoreEmptyValuesInSorting(dateA, dateB, isDesc);
  }

  return dateUtils.sortingDiff(dateA, dateB);
};

export const sortTableByCoachingStatus = (rowA, rowB) => {
  const rowAIndex = COACHING_STATUS_SORTING_ORDER.indexOf(
    rowA.original.coachingStatus
  );
  const rowBIndex = COACHING_STATUS_SORTING_ORDER.indexOf(
    rowB.original.coachingStatus
  );

  return rowAIndex - rowBIndex;
};

export const sortTableBySprintNumber = (rowA, rowB, columnId, isDesc) => {
  const sprintNumberA = rowA.original[columnId];
  const sprintNumberB = rowB.original[columnId];

  return !sprintNumberA || !sprintNumberB
    ? ignoreEmptyValuesInSorting(sprintNumberA, sprintNumberB, isDesc)
    : sprintNumberA - sprintNumberB;
};

export const sortTableByWeekNumber = (rowA, rowB, columnId, isDesc) => {
  const sprintWeekSortingOrder = isDesc
    ? SPRINT_WEEK_DESC_SORTING_ORDER
    : SPRINT_WEEK_ASC_SORTING_ORDER;

  const rowAIndex = sprintWeekSortingOrder.indexOf(rowA.original[columnId]);
  const rowBIndex = sprintWeekSortingOrder.indexOf(rowB.original[columnId]);

  return isDesc ? rowBIndex - rowAIndex : rowAIndex - rowBIndex;
};

export const sortTableByMomentumScore = (rowA, rowB, columnId, isDesc) => {
  const rowAMomentum = rowA.original[columnId] || [];
  const rowBMomentum = rowB.original[columnId] || [];

  const rowAMomentumScore = rowAMomentum.at(-1)?.score;
  const rowBMomentumScore = rowBMomentum.at(-1)?.score;

  return rowAMomentum.length === 0 || rowBMomentum.length === 0
    ? ignoreEmptyValuesInSorting(
        rowAMomentumScore + 1,
        rowBMomentumScore + 1,
        isDesc
      )
    : rowAMomentumScore - rowBMomentumScore;
};

export const getSessionDateWithDefaultTime = (sessionDate) => {
  const currentDate = dateUtils();
  const remainingMinutes =
    SESSION_TIME_INTERVAL - (currentDate.minute() % SESSION_TIME_INTERVAL);

  const sessionDateWithCurrentTime = dateUtils(sessionDate)
    .set('hour', currentDate.hour())
    .set('minute', currentDate.minute() + remainingMinutes);

  return sessionDateWithCurrentTime.toDate();
};

export const getSessionDateUrl = (appointmentId) =>
  appointmentId
    ? `/api/coach/coaching-session-appointments/${appointmentId}/update`
    : '/api/coach/coaching-session-appointments/schedule-appointment';

export const getParticipantsData = ({ participants, participantsChats }) => {
  const participantsChatsMap = participantsChats?.reduce((acc, chat) => {
    chat.members.forEach((member) => {
      acc[member.userId] = chat;
    });

    return acc;
  }, {});

  return participants.map((participant) => {
    const chat = participantsChatsMap[participant.userInfo.userId];

    return {
      ...participant,
      isChatMarkedAsUnread: chat ? chat.markedAsReturnTo : false,
    };
  });
};
