import { toastConfig } from 'lib/reactToastify/config';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import {
  useGoalsNavigationLink,
  useAddGoalMutation,
  useOpenAddGoalModal,
  IGoal,
} from 'features/goal';

export const useAddGoal = () => {
  const navigate = useNavigate();
  const { mutateAsync } = useAddGoalMutation();

  const url = useGoalsNavigationLink();

  const onSubmit = async (goal: IGoal) => {
    try {
      await mutateAsync(goal);

      navigate(url, { replace: true });
    } catch (err) {
      toast.error(
        "There's an error with creating the goal. Please try again later.",
        toastConfig
      );
    }
  };

  const openGoalAddModal = useOpenAddGoalModal();

  return {
    onAddGoalClick: () => openGoalAddModal({ onSubmit }),
  };
};
