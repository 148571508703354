import React from 'react';
import { When } from 'react-if';

import { Box } from 'shared';
import { Flex } from 'shared_DEPRECATED/components/Flex';
import Loader from 'shared_DEPRECATED/components/Loader';
import Spacer from 'shared_DEPRECATED/components/Spacer';

import {
  GoalCard,
  GoalBoardDropZone as BoardDropZone,
  GOAL_ACCEPTS_TYPE,
  useGoalsTableQuery,
} from 'features/goal';
import { usePrioritizeToggleOnBoard } from 'features/goal/hooks/usePrioritizeToggleOnBoard';

import {
  BoardColumn,
  BoardColumnHeader,
  BoardColumnItem,
  BoardColumnWrapper,
} from 'shared/components/Board';
import { Typography } from 'shared/components/Typography';

export const GoalsBoard = () => {
  const { data, isLoading } = useGoalsTableQuery();

  const togglePrioritizeOnBoard = usePrioritizeToggleOnBoard();

  if (isLoading) {
    return <Loader size="sm" />;
  }

  return (
    <When condition={Boolean(data)}>
      {() => (
        <Flex
          width="100%"
          height="calc(100% - var(--logo-header-height)"
          alignItems="stretch"
          gap="4px"
        >
          <Spacer size="md" />
          {data?.columns.map((column, columnIndex) => (
            <BoardColumnWrapper
              fraction={data?.columns.length}
              key={`${column.label}-${columnIndex}`}
            >
              <BoardColumnHeader>
                <Box width="100%" p="10px">
                  <Typography color="gray" type="meta">
                    {column.label}
                  </Typography>
                </Box>
              </BoardColumnHeader>
              <BoardColumn>
                {column.goals.map((goal, index) => (
                  <React.Fragment key={goal.goalId}>
                    <BoardDropZone
                      dropZonePath={[columnIndex, index]}
                      isLast={false}
                    >
                      <BoardColumnItem
                        item={goal}
                        path={[columnIndex, index]}
                        type={GOAL_ACCEPTS_TYPE}
                      >
                        <GoalCard
                          columnId={goal.columnId}
                          columnLabel={goal.columnLabel}
                          completedAt={goal.completedAt}
                          goalId={goal.goalId}
                          name={goal.name}
                          dimensions={goal.dimensions}
                          deadline={goal.deadline}
                          deepDive={goal.deepDive}
                          dataTestid={`goal-${columnIndex}-${index}`}
                          imageUrl={goal.cover?.url}
                          note={goal.note}
                          prioritizedAt={goal.prioritizedAt}
                          togglePrioritize={togglePrioritizeOnBoard}
                        />
                      </BoardColumnItem>
                    </BoardDropZone>
                  </React.Fragment>
                ))}
                <BoardDropZone
                  dropZonePath={[columnIndex, column.goals.length]}
                  isLast={true}
                />
              </BoardColumn>
            </BoardColumnWrapper>
          ))}
          <Spacer size="md" />
        </Flex>
      )}
    </When>
  );
};
