import { Flex } from '@chakra-ui/react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { useCallback, useEffect, useState } from 'react';

import { mergeRegister } from '@lexical/utils';
import {
  $getSelection,
  $isRangeSelection,
  COMMAND_PRIORITY_CRITICAL,
  FORMAT_TEXT_COMMAND,
  SELECTION_CHANGE_COMMAND,
} from 'lexical';
import { Icon, iconNames } from 'shared_DEPRECATED/components/Icon';

import { ToolbarButtonIcon } from '../components/ToolbarButtonIcon';

export const BasicToolbarPlugin = () => {
  const [editor] = useLexicalComposerContext();
  const [isBold, setIsBold] = useState(false);
  const [isItalic, setIsItalic] = useState(false);

  const $updateToolbar = useCallback(() => {
    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
      // Update text format
      setIsBold(selection.hasFormat('bold'));
      setIsItalic(selection.hasFormat('italic'));
    }
  }, []);

  useEffect(() => {
    return editor.registerCommand(
      SELECTION_CHANGE_COMMAND,
      (_payload, newEditor) => {
        $updateToolbar();
        return false;
      },
      COMMAND_PRIORITY_CRITICAL
    );
  }, [editor, $updateToolbar]);

  useEffect(() => {
    return mergeRegister(
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          $updateToolbar();
        });
      })
    );
  }, [editor, $updateToolbar]);

  return (
    <Flex justify="space-between">
      <Flex alignItems="center">
        <ToolbarButtonIcon
          ariaLabel="bold"
          isActive={isBold}
          onClick={() => editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'bold')}
          icon={
            <Icon
              name={iconNames.textBold}
              fill={isBold ? 'var(--bgCompAccent)' : 'var(--fgPrimary)'}
            />
          }
        />
        <ToolbarButtonIcon
          ariaLabel="italic"
          isActive={isItalic}
          onClick={() => editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'italic')}
          icon={
            <Icon
              name={iconNames.textItalic}
              fill={isItalic ? 'var(--bgCompAccent)' : 'var(--fgPrimary)'}
            />
          }
        />
      </Flex>
    </Flex>
  );
};
