import { Flex } from 'shared_DEPRECATED/components/Flex';
import Spacer from 'shared_DEPRECATED/components/Spacer';

import { MomentumFeed } from 'features/momentum/components/Feed';
import { MomentumFeedChart } from 'features/momentum/components/Feed/Chart';
import { MomentumScore } from 'features/momentum/components/Score';
import { MomentumTrend } from 'features/momentum/components/Trend/Trend';
import { useMomentumFeedSprintScores } from 'features/momentum/hooks/useFeedSprintScores';
import { useWeekChartData } from 'features/momentum/hooks/useWeekChartData';

import { SidebarBody } from 'shared/components/Sidebar';

export const MomentumSidebarBody = () => {
  const sprintMomentumData = useMomentumFeedSprintScores();
  const { data, referenceDotData } = useWeekChartData();

  return (
    <SidebarBody>
      <Spacer size="lg">
        {sprintMomentumData.length > 0 && (
          <Flex justifyContent="start" gap="var(--spacing01)">
            <MomentumScore score={sprintMomentumData[0].score} size="lg" />
            <MomentumTrend
              previousScore={sprintMomentumData[1]?.score}
              currentScore={sprintMomentumData[0].score}
            />
          </Flex>
        )}
      </Spacer>
      <MomentumFeedChart data={data} referenceDotData={referenceDotData} />
      {/* @ts-ignore */}
      <MomentumFeed />
    </SidebarBody>
  );
};
