import Box from 'shared_DEPRECATED/components/Box';
import { Flex } from 'shared_DEPRECATED/components/Flex';
import Spacer from 'shared_DEPRECATED/components/Spacer';

import {
  DEFAULT_CHALLENGE_FILTER_VALUE,
  NOTE_FILTERS,
} from 'features/challengeNotes/config';
import { useChallengeNotesFilterContext } from 'features/challengeNotes/context';
import { useFilterVisibility } from 'features/challengeNotes/hooks';

import { Dropdown, DropdownItem } from 'shared/components/Dropdown';
import { useSidebarContext } from 'shared/components/Sidebar/context';
import { Typography } from 'shared/components/Typography';

export const ChallengeNotesChallengeFilter = () => {
  const { sprint } = useSidebarContext();
  const { filter, setFilter } = useChallengeNotesFilterContext();
  const filterHidden = useFilterVisibility();

  const challenges = sprint.allChallenges;

  if (filterHidden) {
    return null;
  }

  const setSelectedChallengeId = (challengeId) => {
    setFilter({
      [NOTE_FILTERS.FILTER_CHALLENGE_IDS]:
        challengeId === DEFAULT_CHALLENGE_FILTER_VALUE ? null : challengeId,
    });
  };

  const selectedChallengeId = filter[NOTE_FILTERS.FILTER_CHALLENGE_IDS];

  return (
    <Box
      style={{
        position: 'absolute',
        width: '100%',
        bottom: 0,
        left: 0,
        borderTop: 'var(--border-secondary)',
        backgroundColor: 'var(--bgPrimary)',
      }}
    >
      <Flex justifyContent="flex-end" width="100%">
        <Spacer size="md">
          <Dropdown
            onItemSelect={setSelectedChallengeId}
            anchorContent={
              <Box style={{ maxWidth: '18rem' }}>
                <Typography
                  cropped={true}
                  dataTestid="notes-sidebar-filter-by-challenge"
                >
                  {sprint.getChallenge(selectedChallengeId)?.title ||
                    DEFAULT_CHALLENGE_FILTER_VALUE}
                </Typography>
              </Box>
            }
            anchorSpacing={['md', 'xsm sm']}
            selectedValue={selectedChallengeId}
          >
            <Flex alignItems="stretch" flexDirection="column">
              <DropdownItem
                value={DEFAULT_CHALLENGE_FILTER_VALUE}
                dataTestid="notes-sidebar-filter-by-challenge-option"
              >
                All
              </DropdownItem>
              {challenges.map(({ sprintChallengeId, title }) => (
                <DropdownItem
                  key={sprintChallengeId}
                  value={sprintChallengeId}
                  dataTestid="notes-sidebar-filter-by-challenge-option"
                >
                  {title}
                </DropdownItem>
              ))}
            </Flex>
          </Dropdown>
        </Spacer>
      </Flex>
    </Box>
  );
};
