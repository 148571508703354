import PropTypes from 'prop-types';

import { Avatar, SIZES } from 'shared_DEPRECATED/components/Avatar';
import Box from 'shared_DEPRECATED/components/Box';
import { Flex, FlexItem } from 'shared_DEPRECATED/components/Flex';
import { HotjarSuppression } from 'shared_DEPRECATED/components/Hotjar';
import Spacer from 'shared_DEPRECATED/components/Spacer';
import { dateUtils } from 'shared_DEPRECATED/utils';

import { ChallengeNotesUnreadIndicator } from 'features/challengeNotes/components/Card';
import { NOTE_STATUS } from 'features/challengeNotes/config';

import { Typography } from 'shared/components/Typography';

const ChallengeNotesCardUserInfoPropTypes = {
  name: PropTypes.string.isRequired,
  pictureUrl: PropTypes.string,
  date: PropTypes.string.isRequired,
  noteReadStatus: PropTypes.oneOf(Object.values(NOTE_STATUS)),
};

export const ChallengeNotesCardUserInfo = ({
  name,
  pictureUrl,
  date,
  noteReadStatus,
}) => {
  return (
    <Flex justifyContent="flex-start">
      <FlexItem>
        <Box style={{ position: 'relative' }}>
          <ChallengeNotesUnreadIndicator
            visible={noteReadStatus === NOTE_STATUS.UNREAD}
          />
          <Avatar name={name} size={SIZES.SM} src={pictureUrl} />
        </Box>
      </FlexItem>
      <FlexItem>
        <Spacer size="zr zr zr md">
          <Spacer size="zr zr zr sm">
            <HotjarSuppression>
              <Flex justifyContent="flex-start">
                <Spacer size="zr md zr zr">
                  <Typography
                    as="div"
                    fontWeight="semiBold"
                    type="small"
                    color="black"
                    dataTestid="user-challenge-note-header-author"
                  >
                    {name}
                  </Typography>
                </Spacer>
                <Typography
                  as="div"
                  type="small"
                  dataTestid="user-challenge-note-header-date"
                  color="black"
                >
                  {dateUtils.abbreviatedMonthDate(date, false)}
                </Typography>
              </Flex>
            </HotjarSuppression>
          </Spacer>
        </Spacer>
      </FlexItem>
    </Flex>
  );
};

ChallengeNotesCardUserInfo.propTypes = ChallengeNotesCardUserInfoPropTypes;
