import { useMemo } from 'react';

import { getChallengeNotesQueryKey } from 'api/utils';
import { getQueryString } from 'shared_DEPRECATED/utils';

import { useChallengeNotesFilterContext } from 'features/challengeNotes/context';

export const useChallengeNotesQueryKeyWithoutFilterContext = (filter) => {
  const queryString = getQueryString(filter);

  return useMemo(() => getChallengeNotesQueryKey(queryString), [queryString]);
};

export const useChallengeNotesQueryKey = () => {
  const { filter } = useChallengeNotesFilterContext();

  return useChallengeNotesQueryKeyWithoutFilterContext(filter);
};
