import { OptionsMenu } from 'shared_DEPRECATED/components/Floating/Menu/Options';
import { iconNames } from 'shared_DEPRECATED/components/Icon';

import {
  GoalFocusAreaMenuEditButton,
  GoalFocusAreaDeleteDialog,
} from 'features/goal';

type TGoalFocusAreaMenu = {
  focusAreaId: string;
  title: string;
};

export const GoalFocusAreaMenu = ({
  focusAreaId,
  title,
}: TGoalFocusAreaMenu) => {
  return (
    <OptionsMenu
      //@ts-ignore
      icon={iconNames.ellipsisHorizontal}
    >
      <GoalFocusAreaMenuEditButton focusAreaId={focusAreaId} title={title} />
      <GoalFocusAreaDeleteDialog focusAreaId={focusAreaId} />
    </OptionsMenu>
  );
};
