import { createContext, useContext } from 'react';

const ModalContext = createContext(null);

export const ModalProvider = ({ children, ...props }: any) => (
  <ModalContext.Provider value={props}>{children}</ModalContext.Provider>
);

export const useModalContext = () => {
  const modalContext = useContext(ModalContext);

  if (!modalContext) {
    throw new Error('useModalContext must be used in ModalContextProvider');
  }

  return modalContext;
};
