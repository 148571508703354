export const ACTION_TYPES = {
  ADD: 'ADD',
  REMOVE: 'REMOVE',
};

export const DEFAULT_REACTIONS = [
  '👍',
  '💪',
  '🚀',
  '👌',
  '❤️',
  '🎉',
  '🔥',
  '😂',
  '😭',
  '🫠',
  '😔',
  '😱',
  '😡',
];
