import { useQuery } from 'react-query';

import { useQueryHTTPRequest } from 'shared_DEPRECATED/hooks';

export const useCoachTimezonesQuery = () => {
  const { request } = useQueryHTTPRequest();

  return useQuery(
    ['coaching-timezones'],
    () => request({ url: '/api/timezones' }),
    {
      initialData: { timezones: [] },
      select: (data) => data.timezones,
    }
  );
};
