import { TableSprintNumberCell } from 'shared_DEPRECATED/components/Table/SprintNumberCell';

import {
  CurrentWeekCell,
  ParticipantsTableCoachingModeCell,
  ParticipantsTableMomentumCell,
  ParticipantsTableSprintDurationCell,
  ParticipantsTableUserInfoCell,
  SessionDateCell,
} from 'features/participants/components/Table/';
import { ParticipantsTableActionsCell } from 'features/participants/components/Table/ActionsCell';
import {
  filterByUserName,
  sortTableByMomentumScore,
  sortTableBySessionDate,
  sortTableBySprintNumber,
  sortTableBySprintStartDate,
  sortTableByUserName,
  sortTableByWeekNumber,
} from 'features/participants/utils';

export const columnsData = [
  {
    id: 'status',
    accessor: 'status',
    disableSortBy: true,
  },
  {
    Header: 'Name',
    accessor: 'userInfo',
    Cell: ParticipantsTableUserInfoCell,
    sortType: sortTableByUserName,
    filter: filterByUserName,
  },
  {
    Header: 'Sprint number',
    accessor: 'coachedSprintsCount',
    Cell: TableSprintNumberCell,
    sortType: sortTableBySprintNumber,
  },
  {
    Header: 'Sprint dates',
    accessor: 'sprintDuration',
    sortType: sortTableBySprintStartDate,
    Cell: ParticipantsTableSprintDurationCell,
  },
  {
    Header: 'Sprint week',
    accessor: 'currentWeekNumber',
    Cell: CurrentWeekCell,
    sortType: sortTableByWeekNumber,
  },
  {
    Header: 'Coaching mode',
    accessor: 'coachingMode',
    Cell: ParticipantsTableCoachingModeCell,
  },
  {
    Header: 'Next session',
    accessor: 'nextCoachingSessionAppointment',
    Cell: SessionDateCell,
    sortType: sortTableBySessionDate,
  },
  {
    Header: 'Momentum',
    accessor: 'momentumScores',
    Cell: ParticipantsTableMomentumCell,
    sortType: sortTableByMomentumScore,
  },
  {
    Header: 'Actions',
    disableSortBy: true,
    Cell: ParticipantsTableActionsCell,
  },
];
