import PropTypes from 'prop-types';
import { forwardRef, useEffect, useState } from 'react';

import { Flex } from 'shared_DEPRECATED/components/Flex';
import { TextInput } from 'shared_DEPRECATED/components/Form/Input';
import { timeRegEx } from 'shared_DEPRECATED/config/date';

const DatePickerAnchorPropTypes = {
  value: PropTypes.string,
  name: PropTypes.string,
  onClick: PropTypes.func,
  onChange: PropTypes.func,
};

export const DatePickerTimeAnchor = forwardRef(
  ({ onClick, value, onChange }, ref) => {
    const [inputValue, setInputValue] = useState(value);

    useEffect(() => {
      setInputValue(value);
    }, [value]);

    const onBlurHandle = (e) => {
      const currentValue = e.currentTarget.value
        .replace(/\s/g, '')
        .toLowerCase();
      if (timeRegEx.test(currentValue)) {
        onChange(e);
      } else {
        setInputValue(value);
      }
    };

    return (
      <Flex width="5.5rem">
        <TextInput
          name="time-input"
          ref={ref}
          onClick={onClick}
          onChange={(e) => {
            setInputValue(e.currentTarget.value);
          }}
          onBlur={onBlurHandle}
          defaultValue={undefined}
          value={inputValue}
        />
      </Flex>
    );
  }
);

DatePickerTimeAnchor.propTypes = DatePickerAnchorPropTypes;
