import Box from 'shared_DEPRECATED/components/Box';

import { TABLE_CELL_DEFAULT_VALUE } from 'features/sessions/config';
import { Tool } from 'features/sessions/config/types';

import { Typography } from 'shared/components/Typography';

type SessionsTableCoachingToolsCellProps = {
  value: Tool[];
};

export const SessionsTableCoachingToolsCell = ({
  value: coachingTools,
}: SessionsTableCoachingToolsCellProps) => (
  <Box style={{ maxWidth: '12rem' }}>
    <Typography
      type="small"
      color="gray"
      fontWeight="regular"
      cropped={true}
      dataTestid="session-coaching-tool-cell"
    >
      {coachingTools?.length > 0
        ? coachingTools.map(({ label }) => label).join(', ')
        : TABLE_CELL_DEFAULT_VALUE}
    </Typography>
  </Box>
);
