import { useFormContext, useWatch } from 'react-hook-form';

import Box from 'shared_DEPRECATED/components/Box';
import { Flex } from 'shared_DEPRECATED/components/Flex';
import { RadioButton } from 'shared_DEPRECATED/components/Form/Radio';
import Spacer, { Spacers } from 'shared_DEPRECATED/components/Spacer';

import {
  GoalAddFormSection,
  PERIODS_FOR_ACHIEVEMENT,
  COLUMN_HEADER_LABELS,
} from 'features/goal';

import { Typography } from 'shared/components/Typography';

export const GoalAddFormTimelineSection = () => {
  const { register } = useFormContext();
  const columnId = useWatch({ name: 'columnId' });

  return (
    <GoalAddFormSection>
      <Typography
        as="label"
        color="black"
        fontWeight="medium"
        type="smallHeading"
      >
        Timeline for achievement (years)
      </Typography>
      <Spacers sizes={['sm zr', 'xsm zr']} />
      <Flex gap="var(--spacing06)">
        {Object.values(PERIODS_FOR_ACHIEVEMENT).map((period) => (
          <Box
            key={period}
            as="label"
            style={{
              cursor: 'pointer',
            }}
          >
            <Flex
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
            >
              <RadioButton
                checked={columnId === period}
                {...register(`columnId`)}
                //@ts-ignore
                value={period}
              />
              <Spacer size="lg lg zr sm">
                <Typography color="black" type="meta">
                  {COLUMN_HEADER_LABELS[period]}
                </Typography>
              </Spacer>
            </Flex>
          </Box>
        ))}
      </Flex>
    </GoalAddFormSection>
  );
};
