import { Flex } from 'shared_DEPRECATED/components/Flex';

import { Typography } from 'shared/components/Typography';

export const NotificationsEmptyList = () => (
  <Flex
    height="80%"
    alignItems="center"
    justifyContent="center"
    flexDirection="column"
  >
    <Typography as="div" type="largeHeading" textAlign="center">
      👋
    </Typography>
    <Typography color="black" fontWeight="medium" type="meta">
      No actual notifications yet
    </Typography>
    <Typography fontWeight="regular" type="meta">
      Any notifications you received will show up here.
    </Typography>
  </Flex>
);
