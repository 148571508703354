import { useCallback, useMemo } from 'react';

import {
  useChallengeBeyondSprintMutation,
  useOpenChallengeModal,
} from 'features/challenge/hooks';

import { useSidebarContext } from 'shared/components/Sidebar/context';

export const useChallengeEditModal = ({ challengeId }) => {
  const { sprint } = useSidebarContext();
  const mutation = useChallengeBeyondSprintMutation(sprint);
  const openChallengeModal = useOpenChallengeModal();

  const currentChallenge = useMemo(
    () => sprint.getChallenge(challengeId),
    [sprint, challengeId]
  );

  return useCallback(() => {
    openChallengeModal({
      challenge: currentChallenge,
      onChallengeSubmit: (challenge) => {
        mutation.mutate(challenge);
      },
      sprint,
    });
  }, [openChallengeModal, currentChallenge, mutation, sprint]);
};
