import PropTypes from 'prop-types';

import AvatarPlaceholderImage from 'shared_DEPRECATED/assets/avatar-placeholder.svg';

export const PIXELS_IN_1_REM = 16;
export const DEFAULT_IMAGE_SRC = '/image-placeholder.svg';

export const IMAGE_TYPES = {
  AVATAR: 'avatar',
};

export const IMAGE_PLACEHOLDERS = {
  [IMAGE_TYPES.AVATAR]: AvatarPlaceholderImage,
};

export const ImagePropTypes = {
  alt: PropTypes.string,
  className: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  src: PropTypes.string.isRequired,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  borderRadius: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  type: PropTypes.oneOf(Object.values(IMAGE_TYPES)),
};
