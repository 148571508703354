import { EditorThemeClasses } from 'lexical';

import { namespace } from '../constants';
import './textEditorTheme.css';

export const theme: EditorThemeClasses = {
  text: {
    bold: `${namespace}__textBold`,
    italic: `${namespace}__textItalic`,
    strikethrough: `${namespace}__textStrikethrough`,
    subscript: `${namespace}__textSubscript`,
    superscript: `${namespace}__textSuperscript`,
    underline: `${namespace}__textUnderline`,
    code: `${namespace}__textCode`,
  },
  list: {
    listitem: `${namespace}__listItem`,
    listitemChecked: `${namespace}__listItemChecked`,
    listitemUnchecked: `${namespace}__listItemUnchecked`,
    nested: {
      listitem: `${namespace}__nestedListItem`,
    },
    olDepth: [
      `${namespace}__ol1`,
      `${namespace}__ol2`,
      `${namespace}__ol3`,
      `${namespace}__ol4`,
      `${namespace}__ol5`,
    ],
    ul: `${namespace}__ul`,
  },
  heading: {
    h1: `${namespace}__heading-h1`,
    h2: `${namespace}__heading-h2`,
    h3: `${namespace}__heading-h3`,
    h4: `${namespace}__heading-h4`,
    h5: `${namespace}__heading-h5`,
    h6: `${namespace}__heading-h6`,
  },
  link: `${namespace}__link`,
  image: `${namespace}__image`,
};
