import PropTypes from 'prop-types';
import { forwardRef } from 'react';

import Box from 'shared_DEPRECATED/components/Box';

const FloatingBodyProps = {
  children: PropTypes.node.isRequired,
  position: PropTypes.string.isRequired,
  top: PropTypes.number,
  left: PropTypes.number,
};

export const FloatingBody = forwardRef(
  ({ children, position, top = 0, left = 0 }, ref) => {
    return (
      <Box
        ref={ref}
        style={{
          backgroundColor: 'var(--bgPrimary)',
          overflow: 'hidden',
          boxShadow: 'var(--dropdown-box-shadow)',
          borderRadius: 'var(--border-radius)',
          border: 'var(--border-secondary)',
          zIndex: 1,
          position,
          top,
          left,
        }}
      >
        {children}
      </Box>
    );
  }
);

FloatingBody.propTypes = FloatingBodyProps;
