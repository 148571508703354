export const TEST_WEEK_NUMBER = 1;

export const SPRINT_TYPES = { MY: 'my', SHARED: 'shared', COACHED: 'coached' };
export const SPRINT_STATES = {
  ACTIVE: 'active',
  FINISHED: 'finished',
  MISSING: 'missing',
  DRAFT: 'draft',
};

export const SPRINT_STATUS_LABELS = {
  [SPRINT_STATES.ACTIVE]: 'Active',
  [SPRINT_STATES.DRAFT]: 'Draft',
  [SPRINT_STATES.FINISHED]: 'Finished',
  [SPRINT_STATES.MISSING]: 'Missing',
};

export const calendarSections = {
  TODAY: 'Today',
  PAST_7_DAYS: 'Past 7 days',
  EARLIER: 'Earlier',
};

export const calendarConfig = {
  sameDay: `[${calendarSections.TODAY}]`,
  lastDay: `[${calendarSections.PAST_7_DAYS}]`, //TODO: yesterday?
  lastWeek: `[${calendarSections.PAST_7_DAYS}]`,
  sameElse: `[${calendarSections.EARLIER}]`,
};

export const SPRINT_HISTORY_CHANGE_TYPES = {
  SPRINT_CHALLENGE_FREQUENCY_CHANGED: 'SPRINT_CHALLENGE_FREQUENCY_CHANGED',
  SPRINT_CHALLENGE_ADDED: 'SPRINT_CHALLENGE_ADDED',
  SPRINT_CHALLENGE_REMOVED: 'SPRINT_CHALLENGE_REMOVED',
  SPRINT_TITLE_CHANGED: 'SPRINT_TITLE_CHANGED',
  SPRINT_CHALLENGE_TITLE_CHANGED: 'SPRINT_CHALLENGE_TITLE_CHANGED',
};

export const URLS = {
  SPRINT: (sprintId) => `/api/sprints/${sprintId}`,
  EDIT_SPRINT: (sprintId) => `/api/sprints/${sprintId}/edit`,
  REFLECTION: (sprintId) => `/api/sprints/${sprintId}/reflection`,
  STATISTIC: (sprintId) => `/api/sprints/${sprintId}/statistics`,
};

export const QUERY_KEYS = {
  SPRINT: (sprintId) => {
    return ['sprint', sprintId];
  },
};

export const DEFAULT_SPRINT_WEEKS_COUNT = 6;

export const SPRINT_SOURCE_TYPES = {
  QSS: 'qss',
  TRADITIONAL: 'traditional',
  QSS_EDITED: 'qss_edited',
};

export const SPRINT_PASS_TYPE = {
  WITH_COACH: 'withCoach',
  SELF_DIRECTED: 'selfDirected',
};

export const SPRINT_COACHING_LABEL = {
  [SPRINT_PASS_TYPE.WITH_COACH]: 'With coach',
  [SPRINT_PASS_TYPE.SELF_DIRECTED]: 'Self-directed',
};

export const SPRINT_STATE_TITLE_MAP = {
  [SPRINT_STATES.ACTIVE]: 'Active sprint',
  [SPRINT_STATES.DRAFT]: 'Draft sprint',
  [SPRINT_STATES.FINISHED]: 'Past sprints',
};
