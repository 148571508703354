import TagManager from 'react-gtm-module';

import { CONSTANTS } from 'config/envVariables';
import { isProdEnv } from 'utils';

if (isProdEnv()) {
  TagManager.initialize({
    // @ts-ignore
    gtmId: CONSTANTS.GOOGLE_TAG_MANAGER_ID as string,
  });
}
