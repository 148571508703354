import PropTypes from 'prop-types';
import ReactDatePicker from 'react-datepicker';

import { DatePickerTimeAnchor } from 'shared_DEPRECATED/components/Form/DatePicker/Anchor';

const DatePickerTimePropTypes = {
  date: PropTypes.instanceOf(Date),
  format: PropTypes.string,
  interval: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  filterTime: PropTypes.func,
};

export const DatePickerTime = ({
  date,
  interval = 15,
  onChange,
  filterTime,
}) => (
  <ReactDatePicker
    selected={date}
    onChange={onChange}
    showTimeSelect={true}
    showTimeSelectOnly={true}
    timeIntervals={interval}
    customInput={<DatePickerTimeAnchor />}
    dateFormat="h:mmaaa"
    filterTime={filterTime}
  />
);

DatePickerTime.propTypes = DatePickerTimePropTypes;
