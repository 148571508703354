import { ReactNode } from 'react';

import { Flex } from 'shared_DEPRECATED/components/Flex';
import Spacer from 'shared_DEPRECATED/components/Spacer';

export const ParticipantsTableActionWrapper = ({
  children,
}: {
  children: ReactNode;
}) => (
  <Spacer size="sm md">
    <Flex alignItems="start">{children}</Flex>
  </Spacer>
);
