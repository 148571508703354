import { dateUtils } from 'shared_DEPRECATED/utils';

import { Typography } from 'shared/components/Typography';

type SessionsTableDateCellProps = {
  value: string;
};

export const SessionsTableDateCell = ({
  value: sessionDate,
}: SessionsTableDateCellProps) => {
  const formattedDateString = dateUtils.abbreviatedMonthDate(sessionDate);

  return (
    <Typography
      type="small"
      color="gray"
      fontWeight="regular"
      dataTestid="session-date-cell"
    >
      {formattedDateString}
    </Typography>
  );
};
