import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';

import { REDO_COMMAND, UNDO_COMMAND } from 'lexical';
import { Icon, iconNames } from 'shared_DEPRECATED/components/Icon';

import { ToolbarButtonIcon } from '../ToolbarButtonIcon';
import { useUndoRedo } from './useUndoRedo';

export const UndoRedoButtons = () => {
  const [editor] = useLexicalComposerContext();

  const { canUndo, canRedo } = useUndoRedo(editor);

  return (
    <>
      <ToolbarButtonIcon
        ariaLabel="undo"
        disabled={!canUndo}
        onClick={() => editor.dispatchCommand(UNDO_COMMAND, undefined)}
        icon={<Icon name={iconNames.undo} />}
      />
      <ToolbarButtonIcon
        ariaLabel="redo"
        disabled={!canRedo}
        onClick={() => editor.dispatchCommand(REDO_COMMAND, undefined)}
        icon={<Icon name={iconNames.redo} />}
      />
    </>
  );
};
