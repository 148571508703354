import { useCallback } from 'react';

import { sidebar } from '.';

type useOpenSidebarParams = {
  content: React.ReactNode;
  props: {
    title: string;
  };
  context?: Record<string, any>;
};

export const useOpenSidebar = ({
  content,
  props,
  context,
}: useOpenSidebarParams) => {
  const openSidebar = useCallback(() => {
    sidebar.open({
      content,
      props,
      context,
    });
  }, [content, props, context]);

  return { openSidebar };
};
