import { CSSProperties, forwardRef, useLayoutEffect, useRef } from 'react';
import { mergeRefs } from 'react-merge-refs';

import classNames from 'classnames/bind';

import styles from 'shared_DEPRECATED/components/Box/Box.module.css';

const cx = classNames.bind(styles);

type BoxProps = {
  ariaLabel?: string;
  style?: CSSProperties;
  as?: React.ElementType;
  children?: React.ReactNode;
  role?: string;
  dataTestid?: string;
  hoverStyle?: CSSProperties;
  id?: string;
  title?: string;
};

const Box = forwardRef(
  (
    {
      ariaLabel = '',
      as: Component = 'div',
      role = 'generic',
      children,
      dataTestid = '',
      hoverStyle = {},
      style = {},
      ...otherProps
    }: BoxProps,
    ref
  ) => {
    const { backgroundColor = 'transparent', ...otherStyle } = style;
    const {
      backgroundColor: hoverBackgroundColor,
      borderColor: hoverBorderColor,
    } = hoverStyle;
    const elementRef = useRef(null);

    useLayoutEffect(() => {
      if (elementRef.current) {
        const element = elementRef.current as HTMLElement;

        element.style.setProperty('--background-color', backgroundColor);

        element.style.setProperty(
          '--hover-background-color',
          hoverBackgroundColor || backgroundColor
        );
        hoverBorderColor &&
          element.style.setProperty('--hover-border-color', hoverBorderColor);
      }
    }, [
      elementRef,
      hoverStyle,
      backgroundColor,
      hoverBackgroundColor,
      hoverBorderColor,
    ]);

    return (
      <Component
        className={cx({
          box: true,
          'box--hover': Object.keys(hoverStyle).length > 0,
        })}
        aria-label={ariaLabel}
        role={role}
        ref={mergeRefs([elementRef, ref])}
        data-testid={dataTestid}
        data-react-component="box"
        style={otherStyle}
        {...otherProps}
      >
        {children}
      </Component>
    );
  }
);

export default Box;
