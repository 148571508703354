import PropTypes from 'prop-types';

import Box from 'shared_DEPRECATED/components/Box';
import { Flex } from 'shared_DEPRECATED/components/Flex';
import Spacer from 'shared_DEPRECATED/components/Spacer';

import SidebarMainHeaderCloseButton from 'shared/components/Sidebar/Button/SidebarMainHeaderCloseButton';
import SidebarHeaderContainer from 'shared/components/Sidebar/Header/SidebarHeaderContainer';
import SidebarHeaderTitle from 'shared/components/Sidebar/Header/SidebarHeaderTitle';

export const SidebarMainHeaderPropTypes = {
  /**
   * Children to render to the right of the header. It could be a button or a dropdown.
   * @type {node}
   * @name children
   * @example <SidebarMainHeader title="Filters"> <Button>Apply</Button> </SidebarMainHeader>
   * @example <SidebarMainHeader title="Filters"> <Dropdown> <DropdownItem>Apply</DropdownItem> </Dropdown> </SidebarMainHeader>
   */
  children: PropTypes.node,
  /**
   * Sidebar title.
   * @type {string}
   * @required
   * @name title
   * @example <SidebarMainHeader title="Filters" />
   */
  title: PropTypes.string.isRequired,
};

const SidebarMainHeader = ({ children = null, title }) => (
  <Box style={{ backgroundColor: 'var(--bgPrimary)' }}>
    <SidebarHeaderContainer>
      <Flex>
        <Spacer size="zr zr zr lg" />
        <Spacer size="zr zr zr lg" />
        <SidebarMainHeaderCloseButton />
        <SidebarHeaderTitle>{title}</SidebarHeaderTitle>
      </Flex>
      {children}
    </SidebarHeaderContainer>
  </Box>
);

SidebarMainHeader.propTypes = SidebarMainHeaderPropTypes;

export default SidebarMainHeader;
