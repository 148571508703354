import PropTypes from 'prop-types';

import Box from 'shared_DEPRECATED/components/Box';
import Spacer from 'shared_DEPRECATED/components/Spacer';

import { Typography } from 'shared/components/Typography';

export const Banner = ({ children }) => (
  <Box style={{ backgroundColor: 'var(--bgCompAccent)' }}>
    <Spacer size="md lg">
      <Typography type="meta" color="whiteTotal" textAlign="center">
        {children}
      </Typography>
    </Spacer>
  </Box>
);

Banner.propTypes = { children: PropTypes.node.isRequired };
