import { Icon, iconNames } from 'shared_DEPRECATED/components/Icon';

import { useMomentumSidebar } from 'features/momentum/hooks/useSidebar';
import { ISprint, SprintsType } from 'features/sprint/config/types';

import { Button } from 'shared/components/Button';
import { Typography } from 'shared/components/Typography';

type TMomentumOpenSidebarButton = {
  sprint: ISprint;
  sprints: SprintsType;
};

export const MomentumOpenSidebarButton = ({
  sprint,
  sprints,
}: TMomentumOpenSidebarButton) => {
  const { openSidebar } = useMomentumSidebar({
    sprint,
    sprints,
  });

  return (
    <Button
      rightIcon={<Icon name={iconNames.arrowRight} height={10} width={6} />}
      onClick={openSidebar}
      size="sm"
    >
      <Typography type="small">View more</Typography>
    </Button>
  );
};
