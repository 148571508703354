import { $generateNodesFromDOM } from '@lexical/html';
import { LexicalEditor, LexicalNode } from 'lexical';

export const convertHtmlToLexicalNodes = (
  editor: LexicalEditor,
  htmlString: string = ''
): LexicalNode[] => {
  const parser = new DOMParser();
  const dom = parser.parseFromString(htmlString, 'text/html');
  return $generateNodesFromDOM(editor, dom);
};
