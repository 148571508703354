import { forwardRef } from 'react';

import Box from 'shared_DEPRECATED/components/Box';
import { Flex } from 'shared_DEPRECATED/components/Flex';
import {
  SelectButtonMediumContent,
  SelectButtonSmallContent,
} from 'shared_DEPRECATED/components/Form/SelectButton/SelectButtonContent';
import { Nullable } from 'shared_DEPRECATED/types';

import { SELECT_BUTTON_SIZES, SELECT_BUTTON_TYPES } from './config';
import { getEnabledStyles } from './utils';
import styles from 'shared_DEPRECATED/components/Form/SelectButton/CheckboxButton.module.css';

type SelectButtonPropTypes = {
  /**
   * Checkbox content.
   * @type {React.ReactNode}
   * @required
   * @name children
   * @example <SelectButton>Checkbox</SelectButton>
   */
  children: React.ReactNode;
  /**
   * Checkbox disabled state.
   * @type {boolean}
   * @name disabled
   * @example <SelectButton disabled={true}>Checkbox</SelectButton>
   * @default false
   * @see {@link https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input|MDN}
   */
  disabled?: boolean;
  /**
   * Checkbox checked state.
   * @type {boolean}
   * @name checked
   * @example <SelectButton checked={true}>Checkbox</SelectButton>
   * @default false
   * @see {@link https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input|MDN}
   */
  checked?: boolean;
  /**
   * Checkbox minimum width.
   * @type {string}
   * @name minWidth
   * @example <SelectButton minWidth="100px">Checkbox</SelectButton>
   * @default auto
   * @see {@link https://developer.mozilla.org/en-US/docs/Web/CSS/min-width|MDN}
   */
  minWidth?: string;
  /**
   * Checkbox change event handler.
   * @type {function}
   * @required
   * @name onChange
   * @example <SelectButton onChange={() => {}}>Checkbox</SelectButton>
   * @see {@link https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input|MDN}
   */
  onChange: () => void;
  /**
   * Checkbox size.
   * @type {'sm' | 'md'}
   * @name size
   * @example <SelectButton size="sm">Checkbox</SelectButton>
   * @required
   */
  size: typeof SELECT_BUTTON_SIZES[keyof typeof SELECT_BUTTON_SIZES];
  /**
   * Checkbox type.
   * @type {'single' | 'multi'}
   * @name size
   * @example <SelectButton type="single">Checkbox</SelectButton>
   * @required
   */
  type?: typeof SELECT_BUTTON_TYPES[keyof typeof SELECT_BUTTON_TYPES];
  dataTestid?: Nullable<string>;
  value?: string;
  defaultChecked?: boolean;
};

const selectButtonContent = {
  [SELECT_BUTTON_SIZES.SM]: SelectButtonSmallContent,
  [SELECT_BUTTON_SIZES.MD]: SelectButtonMediumContent,
};

const selectButtonType = {
  [SELECT_BUTTON_TYPES.SINGLE]: 'radio',
  [SELECT_BUTTON_TYPES.MULTI]: 'checkbox',
};

export const SelectButton = forwardRef<HTMLInputElement, SelectButtonPropTypes>(
  (
    {
      children,
      disabled = false,
      minWidth = 'auto',
      onChange,
      size,
      type = SELECT_BUTTON_TYPES.MULTI,
      dataTestid = null,
      ...props
    }: SelectButtonPropTypes,
    ref
  ) => {
    const { backgroundColor, color, cursor, opacity } =
      getEnabledStyles(disabled);
    const SelectButtonContentComponent = selectButtonContent[size];

    return (
      <Flex justifyContent="start">
        <Box
          as="label"
          style={{
            cursor,
          }}
        >
          <input
            className={styles['input-field']}
            ref={ref}
            type={selectButtonType[type]}
            hidden={true}
            onChange={onChange}
            disabled={disabled}
            data-testid={dataTestid}
            {...props}
          />
          <Box
            style={{
              backgroundColor,
              borderRadius: 'var(--border-radius)',
              minWidth,
            }}
          >
            <Box
              style={{
                opacity,
              }}
            >
              <SelectButtonContentComponent color={color}>
                {children}
              </SelectButtonContentComponent>
            </Box>
          </Box>
        </Box>
      </Flex>
    );
  }
);
