import { ReactNode } from 'react';

import Box from 'shared_DEPRECATED/components/Box';
import { Flex } from 'shared_DEPRECATED/components/Flex';
import Spacer from 'shared_DEPRECATED/components/Spacer/Spacer';

import { GoalDeepDiveStepNumber } from 'features/goal';

import { Typography } from 'shared/components/Typography';

type TGoalDeepDiveFormSection = {
  step?: number;
  children: ReactNode;
  title: string;
};

export const GoalDeepDiveFormSection = ({
  step,
  title,
  children,
}: TGoalDeepDiveFormSection) => (
  <Spacer size="md zr">
    <Box style={{ height: 'auto', width: '100%' }}>
      <Flex
        alignItems="center"
        justifyContent="space-between"
        height="100%"
        width="100%"
      >
        <Flex>
          {step && (
            <>
              <GoalDeepDiveStepNumber step={step} />
              <Spacer size="sm" />
            </>
          )}
          <Typography
            fontWeight="medium"
            color="black"
            dataTestid="goal-deep-dive-form-section-title"
          >
            {title}
          </Typography>
        </Flex>
      </Flex>
    </Box>
    <Spacer size="md" />
    <Flex width="100%" gap="1.5rem" flexDirection="column" alignItems="stretch">
      {children}
    </Flex>
  </Spacer>
);
