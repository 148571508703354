import { useMutation, useQueryClient } from 'react-query';

import { logError } from 'lib/sentry/logError';
import { useMutationHTTPRequest } from 'shared_DEPRECATED/hooks';
import { Nullable } from 'shared_DEPRECATED/types';

import { useUpdateParticipantsQuery } from 'features/participants/hooks/query/useUpdateParticipants';
import { getSessionDateUrl } from 'features/participants/utils';
import { NextCoachingSessionAppointment } from 'features/sessions/config/types';

type SourceDataType = {
  updatedDateString: Nullable<string>;
  currentDateString: string;
};
type ResourceDataType = {
  coachingSessionAppointmentId: string;
  startAt: string;
};

export const useSessionDateMutation = ({
  userEmail,
  appointmentId,
}: {
  userEmail: string;
  appointmentId: string;
}) => {
  const { request } = useMutationHTTPRequest();

  const updateParticipantsQuery = useUpdateParticipantsQuery({
    userEmail,
    fieldKey: 'nextCoachingSessionAppointment',
  });
  const queryClient = useQueryClient();

  return useMutation<ResourceDataType, unknown, SourceDataType>(
    async ({ updatedDateString }) =>
      request({
        url: getSessionDateUrl(appointmentId),
        body: {
          clientId: userEmail,
          startAt: updatedDateString,
        },
      }),
    {
      onMutate: ({ updatedDateString }) => {
        updateParticipantsQuery({ startAt: updatedDateString, appointmentId });
      },
      onSuccess: (res) => {
        if (!appointmentId) {
          updateParticipantsQuery({
            startAt: res.startAt,
            appointmentId: res.coachingSessionAppointmentId,
          });
        }

        queryClient.setQueryData<NextCoachingSessionAppointment>(
          ['coach-session-appointment', appointmentId],
          (oldVal) =>
            ({
              ...oldVal,
              startAt: res.startAt,
            } as NextCoachingSessionAppointment)
        );
      },
      onError: (err, { currentDateString }) => {
        updateParticipantsQuery(
          currentDateString && { startAt: currentDateString, appointmentId }
        );
        logError(err);
      },
    }
  );
};
