import { useParams } from 'react-router-dom';

import {
  FloatingTooltip as Tooltip,
  PLACEMENTS,
} from 'shared_DEPRECATED/components/Floating';
import { Icon, iconNames } from 'shared_DEPRECATED/components/Icon';
import Spacer from 'shared_DEPRECATED/components/Spacer';

import {
  MomentumFeedItemChallengeFrequency,
  MomentumFeedItemTrend,
  MomentumFeedItemChallengeTitle,
} from 'features/momentum/components/Feed/Item/';
import { useParticipantQuery } from 'features/participants/hooks';

import { Typography } from 'shared/components/Typography';

type MomentumFeedItemMinimumTrendProps = {
  challenges: {
    title: string;
    frequency: string;
    targetAmount?: string;
  }[];
  trend: number;
  trendSign: '-' | '+';
};

export const MomentumFeedItemMinimumTrend = ({
  challenges,
  trend,
  trendSign,
}: MomentumFeedItemMinimumTrendProps) => {
  const { email } = useParams();
  const { participant, isLoading } = useParticipantQuery(email);

  return (
    <>
      <MomentumFeedItemTrend
        textColor="red"
        trend={trend}
        trendSign={trendSign}
      />
      {isLoading ? null : (
        <Tooltip
          anchor={<Icon name={iconNames.info} />}
          offsetValue={8}
          placement={PLACEMENTS.TOP}
          width="17rem"
        >
          <Typography color="white" fontWeight="semiBold" type="meta">
            Minimum Momentum
          </Typography>
          <Spacer size="xsm zr zr" />
          <Typography color="white" type="small" textAlign="justify">
            These challenges are slowing down momentum for{' '}
            <Typography as="span" fontWeight="semiBold">
              {trend}
            </Typography>{' '}
            points. The core will improve quickly if {participant.userName}{' '}
            takes them on!
          </Typography>
          <Spacer size="zr zr md" />
          {challenges.map(({ title, frequency, targetAmount }, index) => (
            <Typography key={`${title}${index}`} color="white">
              <MomentumFeedItemChallengeTitle color="white">
                {title}
              </MomentumFeedItemChallengeTitle>
              <Spacer size="zr zr xsm" />
              <MomentumFeedItemChallengeFrequency
                frequency={frequency}
                targetAmount={targetAmount}
              />
              <Spacer size="zr zr md" />
            </Typography>
          ))}
        </Tooltip>
      )}
    </>
  );
};
