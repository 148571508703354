import { When } from 'react-if';

import {
  IMPROVED_GROUP_VIEW_FEATURE_FLAG,
  RenderIfFFEnabled,
} from 'featureFlags';
import { Flex as FlexNew } from 'shared';
import { Flex } from 'shared_DEPRECATED/components/Flex';
import Spacer from 'shared_DEPRECATED/components/Spacer';

import {
  GoalBadge,
  GoalTitle,
  GoalBackButton,
  GoalEditButton,
  GoalDeleteButton,
  GoalCardType,
  GoalImage,
} from 'features/goal';
import { usePrioritizeToggle } from 'features/goal/hooks/usePrioritizeToggle';
import { LifeDimensions } from 'features/lifeDimensions/components/LifeDimensions';

import { GoalHeaderPrioritizedBadge } from './Header/PrioritizedBadge';
import { GoalPrioritizeToggle } from './PrioritizeToggle';

export const GoalHeader = ({
  goalId,
  columnId,
  columnLabel,
  name,
  dimensions,
  deadline,
  deepDive,
  completedAt,
  imageUrl,
  note,
  prioritizedAt,
}: GoalCardType) => {
  const togglePrioritize = usePrioritizeToggle();

  return (
    <Flex
      as="header"
      justifyContent="space-between"
      width="100%"
      alignItems="center"
      dataTestid="goal-header"
    >
      <Flex flexDirection="column" alignItems="flex-start" width="100%">
        <GoalBackButton />
        <Flex width="100%" alignItems="center" justifyContent="space-between">
          <Flex>
            <Spacer size="lg lg zr zr">
              <GoalImage size="md" imageUrl={imageUrl} />
            </Spacer>
            <Flex
              flexDirection="column"
              alignItems="flex-start"
              height="100%"
              gap="1rem 0"
            >
              <GoalTitle title={name} />
              <LifeDimensions dimensions={dimensions} />
              <FlexNew alignItems="center" gap="var(--spacing02)" h={6}>
                <GoalBadge
                  deadline={deadline}
                  years={columnLabel}
                  completedAt={completedAt}
                />
                <RenderIfFFEnabled
                  featureFlag={IMPROVED_GROUP_VIEW_FEATURE_FLAG}
                >
                  <When condition={prioritizedAt}>
                    <GoalHeaderPrioritizedBadge />
                  </When>
                </RenderIfFFEnabled>
              </FlexNew>
            </Flex>
          </Flex>
          <FlexNew alignItems="center" gap="var(--spacing04)">
            <RenderIfFFEnabled featureFlag={IMPROVED_GROUP_VIEW_FEATURE_FLAG}>
              <GoalPrioritizeToggle
                goalId={goalId}
                isPrioritized={!!prioritizedAt}
                size="md"
                togglePrioritize={togglePrioritize}
              />
            </RenderIfFFEnabled>
            <GoalEditButton
              name={name}
              dimensions={dimensions}
              deadline={deadline}
              deepDive={deepDive}
              goalId={goalId}
              columnId={columnId}
              columnLabel={columnLabel}
              completedAt={completedAt}
              note={note}
            />
            <GoalDeleteButton goalId={goalId} columnId={columnId} />
          </FlexNew>
        </Flex>
      </Flex>
    </Flex>
  );
};
