import { SESSIONS_IN_CHAT_FEATURE_FLAG, useFeatureFlag } from 'featureFlags';

import { TextEditorFormats } from '../constants';

//TODO remove this hook after we completely migrate to markdown
export const useTextEditorFormat = () => {
  const isSessionsInChatFeatureFlagEnabled = useFeatureFlag(
    SESSIONS_IN_CHAT_FEATURE_FLAG
  );

  return isSessionsInChatFeatureFlagEnabled
    ? TextEditorFormats.markdown
    : TextEditorFormats.html;
};
