import { RefObject, useLayoutEffect, useRef } from 'react';

import data from '@emoji-mart/data';
//@ts-ignore
import { Picker, PickerProps } from 'emoji-mart';

import 'shared_DEPRECATED/components/Form/EmojiPicker/EmojiPicker.module.css';

type EmojiPickerPropTypes = {
  onEmojiSelect: ({ native }: { native: string }) => void;
  options: PickerProps;
};

const EmojiPicker = ({ onEmojiSelect, options }: EmojiPickerPropTypes) => {
  const ref: RefObject<HTMLDivElement> = useRef(null);

  useLayoutEffect(() => {
    new Picker({
      //@ts-ignore
      data,
      ref,
      onEmojiSelect,
      ...options,
    });
  }, [onEmojiSelect, options]);

  return <div className="emoji-picker" ref={ref} />;
};

export default EmojiPicker;
