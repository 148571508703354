import { Box, Typography } from 'shared';
import { Flex } from 'shared_DEPRECATED/components/Flex';
import { Icon, iconNames } from 'shared_DEPRECATED/components/Icon';
import Spacer from 'shared_DEPRECATED/components/Spacer';

export const GoalBreakdownEmptyState = () => (
  <Flex
    width="100%"
    height="100%"
    flexDirection="column"
    justifyContent="flex-start"
  >
    <Spacer size="lg" />
    <Flex width="100%" justifyContent="flex-end">
      <Box style={{ position: 'relative', top: 5, right: 0 }}>
        <Icon name={iconNames.arrowLargeCurveImage} />
      </Box>
    </Flex>
    <Icon name={iconNames.list} />
    <Spacer size="sm" />
    <Typography color="gray" type="meta">
      Add challenges
    </Typography>
  </Flex>
);
