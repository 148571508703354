import { Auth0Provider } from '@auth0/auth0-react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import { CONSTANTS } from 'config/envVariables';

const isCypress = window.localStorage.getItem('isCypress');
export const AuthProvider = ({ children }) => {
  const navigate = useNavigate();

  return (
    <Auth0Provider
      domain={CONSTANTS.AUTH_DOMAIN}
      clientId={CONSTANTS.AUTH_CLIENT_ID}
      redirectUri={CONSTANTS.AUTH_REDIRECT_URI}
      onRedirectCallback={(appState) => navigate(appState.returnTo)}
      audience={CONSTANTS.AUTH_AUDIENCE}
      scope={CONSTANTS.AUTH_SCOPE}
      cacheLocation={isCypress ? 'localstorage' : 'memory'}
      useRefreshTokens={true}
    >
      {children}
    </Auth0Provider>
  );
};

AuthProvider.propTypes = { children: PropTypes.node };
