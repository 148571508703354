import { TextEditorToolbarBaseSelector } from 'shared_DEPRECATED/components/TextEditor/Toolbar';
import {
  BASE_COLORS,
  COLOR_SELECTOR_TYPE,
} from 'shared_DEPRECATED/components/TextEditor/config';

interface TextEditorToolbarColorSelectorProps {
  type?: typeof COLOR_SELECTOR_TYPE[keyof typeof COLOR_SELECTOR_TYPE];
  colors?: string[];
}

export const TextEditorToolbarColorSelector = ({
  type = COLOR_SELECTOR_TYPE.COLOR,
  colors = BASE_COLORS,
}: TextEditorToolbarColorSelectorProps) => (
  <TextEditorToolbarBaseSelector type={type} options={colors} />
);
