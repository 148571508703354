import { toastConfig } from 'lib/reactToastify/config';
import { toast } from 'react-toastify';

import { logError } from 'lib/sentry/logError';
import { Button, VARIANTS } from 'shared';

import { useChallengeNotesContext } from 'features/challengeNotes/context';
import { useReadAllMutation } from 'features/challengeNotes/hooks/mutation/useReadAll';

export const ChallengeNotesReadAllButton = () => {
  const { unreadNotesCounter, isLoading } = useChallengeNotesContext();
  const { mutateAsync } = useReadAllMutation();

  if (unreadNotesCounter === 0 || isLoading) {
    return null;
  }

  const handleButtonClick = async () => {
    try {
      await mutateAsync();
    } catch (err) {
      toast.error(
        "There's an error with marking notes as read. Please try again later.",
        {
          icon: false,
          ...toastConfig,
        }
      );
      logError(err);
    }
  };

  return (
    <Button
      variant={VARIANTS.TERTIARY}
      onClick={handleButtonClick}
      dataTestid="notes-sidebar-read-all"
    >
      Read all
    </Button>
  );
};
