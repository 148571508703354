import { Flex } from 'shared_DEPRECATED/components/Flex';

import { MomentumTrend } from 'features/momentum/components';
import { MomentumScore } from 'features/momentum/components/Score';
import { MomentumType } from 'features/momentum/config';
import { ParticipantsTableEmptyCell } from 'features/participants/components/Table/ParticipantsTableEmptyCell';

type MomentumCellProps = {
  value?: MomentumType;
};

export const ParticipantsTableMomentumCell = ({ value }: MomentumCellProps) => {
  if (!value || value.length === 0) {
    return (
      <ParticipantsTableEmptyCell dataTestid="participants-table-cell-no-momentum" />
    );
  }

  const lastMomentumScore = value.at(-1)!.score;
  const secondLastMomentumScore = value.at(-2)?.score;

  return (
    <Flex
      justifyContent="flex-start"
      alignItems="center"
      gap="var(--spacing02)"
      dataTestid="momentum-current-state"
    >
      <MomentumScore score={lastMomentumScore} size="xsm" />
      <MomentumTrend
        currentScore={lastMomentumScore}
        previousScore={secondLastMomentumScore}
      />
    </Flex>
  );
};
