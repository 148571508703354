import { useCallback } from 'react';

import { produce } from 'immer';

import { useRoadmapDataContext } from 'features/goal/context/roadmapData';

export const useMoveRoadmapGoals = () => {
  const { setRoadmapData } = useRoadmapDataContext();

  return {
    moveItemInList: useCallback(
      async (
        draggedItem: {
          id: string;
          columnIndex: number;
          index: number;
          isColumnChanged: boolean;
        },
        hoveredItem: {
          id: string;
          columnIndex: number;
        }
      ) => {
        setRoadmapData((prevRoadmapData) => {
          if (!prevRoadmapData) {
            return prevRoadmapData;
          }

          const { columnIndex: draggedColumnIndex } = draggedItem;
          const { columnIndex: hoveredColumnIndex } = hoveredItem;
          const draggedGoalIndex = prevRoadmapData[
            draggedColumnIndex
          ].goals.findIndex((goal) => goal.goalId === draggedItem.id);
          const hoveredGoalIndex = prevRoadmapData[
            hoveredColumnIndex
          ].goals.findIndex((goal) => goal.goalId === hoveredItem.id);
          const draggedGoal =
            prevRoadmapData[draggedColumnIndex].goals[draggedGoalIndex];

          draggedItem.index = hoveredGoalIndex;
          draggedItem.columnIndex = hoveredColumnIndex;
          draggedItem.isColumnChanged =
            hoveredColumnIndex !== draggedColumnIndex;

          const newColumns = produce(prevRoadmapData, (draft) => {
            draft[draggedColumnIndex].goals.splice(draggedGoalIndex, 1);
            draft[hoveredColumnIndex].goals.splice(
              hoveredGoalIndex,
              0,
              draggedGoal
            );
          });

          return newColumns;
        });
      },
      [setRoadmapData]
    ),
    moveItemToColumn: useCallback(
      (modifiedColumnIndex: number) =>
        async (item: {
          id: string;
          columnIndex: number;
          index: number;
          isColumnChanged: boolean;
        }) => {
          if (item.columnIndex === modifiedColumnIndex) {
            return;
          }

          setRoadmapData((prevRoadmapData) => {
            if (!prevRoadmapData) {
              return prevRoadmapData;
            }

            const { columnIndex } = item;
            const goalIndex = prevRoadmapData[columnIndex].goals.findIndex(
              (goal) => goal.goalId === item.id
            );
            const goal = prevRoadmapData[columnIndex].goals[goalIndex];

            const newColumns = produce(prevRoadmapData, (draft) => {
              draft[columnIndex].goals.splice(goalIndex, 1);
              draft[modifiedColumnIndex].goals.push(goal);
            });

            item.columnIndex = modifiedColumnIndex;
            item.index = newColumns[modifiedColumnIndex].goals.length;
            item.isColumnChanged = true;

            return newColumns;
          });
        },
      [setRoadmapData]
    ),
  };
};
