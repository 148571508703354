import PropTypes from 'prop-types';
import { createContext, useContext } from 'react';

const SidebarContext = createContext(null);

export const SidebarProvider = ({ children, ...props }) => (
  <SidebarContext.Provider value={props}>{children}</SidebarContext.Provider>
);

SidebarProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useSidebarContext = () => {
  const sidebarContext = useContext(SidebarContext);

  if (!sidebarContext) {
    throw new Error('useSidebarContext must be used in SidebarContextProvider');
  }

  return sidebarContext;
};
