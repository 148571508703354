import PropTypes from 'prop-types';

import { Icon, iconNames } from 'shared_DEPRECATED/components/Icon';

const FileDownloadButtonPropTypes = {
  url: PropTypes.string.isRequired,
  name: PropTypes.string,
  stroke: PropTypes.string,
};

export const FileDownloadButton = ({ url, name }) => (
  <a
    href={url}
    download={name}
    style={{ height: '1rem' }}
    target="_blank"
    rel="noreferrer"
  >
    <Icon name={iconNames.download} stroke="var(--fgPrimary)" />
  </a>
);

FileDownloadButton.propTypes = FileDownloadButtonPropTypes;
