import PropTypes from 'prop-types';
import { forwardRef } from 'react';

import Box from 'shared_DEPRECATED/components/Box';
import Button from 'shared_DEPRECATED/components/Button';
import { VARIANTS } from 'shared_DEPRECATED/components/Button/config';
import { Flex } from 'shared_DEPRECATED/components/Flex';
import {
  CUSTOM_INPUT,
  SIZES,
} from 'shared_DEPRECATED/components/Form/DatePicker/config';
import { Icon, iconNames } from 'shared_DEPRECATED/components/Icon';
import Spacer, { Spacers } from 'shared_DEPRECATED/components/Spacer';

import { Typography } from 'shared/components/Typography';

const DatePickerAnchorPropTypes = {
  icon: PropTypes.oneOf(Object.values(iconNames)),
  value: PropTypes.string,
  dataTestId: PropTypes.string,
  onClick: PropTypes.func,
  variant: PropTypes.oneOf([VARIANTS.TERTIARY, VARIANTS.SECONDARY]),
  placeholder: PropTypes.string,
  size: PropTypes.oneOf([SIZES.SM, SIZES.LG]),
  disabled: PropTypes.bool,
};

export const DatePickerAnchor = forwardRef(
  (
    {
      disabled = false,
      value,
      icon,
      onClick,
      dataTestId,
      variant,
      placeholder,
      size = SIZES.LG,
    },
    ref
  ) => (
    <Box ref={ref}>
      <Button
        disabled={disabled}
        onClick={onClick}
        variant={variant}
        dataTestid={dataTestId}
      >
        <Spacers sizes={CUSTOM_INPUT[size].spacers}>
          <Flex alignItems="center" width="100%">
            {icon && (
              <>
                <Icon
                  name={icon}
                  width={CUSTOM_INPUT[size].icon.width}
                  height={CUSTOM_INPUT[size].icon.height}
                />
                <Spacer size="zr md zr zr" />
              </>
            )}
            {value ? (
              <Typography
                as="span"
                type={CUSTOM_INPUT[size].fontSize}
                color="black"
                fontWeight="medium"
              >
                {value}
              </Typography>
            ) : (
              <Typography
                as="span"
                type={CUSTOM_INPUT[size].fontSize}
                color="gray"
              >
                {placeholder}
              </Typography>
            )}
          </Flex>
        </Spacers>
      </Button>
    </Box>
  )
);

DatePickerAnchor.propTypes = DatePickerAnchorPropTypes;
