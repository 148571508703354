import { useRouteError } from 'react-router-dom';

import { HTTP_ERROR_STATUS_CODES } from 'api/config';

import { ParticipantAccessDenied } from 'features/participants/components/AccessDenied';

export const ParticipantRouterErrorBoundary = () => {
  const error = useRouteError();

  if (error.message === HTTP_ERROR_STATUS_CODES.FORBIDDEN) {
    return <ParticipantAccessDenied />;
  }

  throw error;
};
