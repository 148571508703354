//eslint-disable-next-line
import { NavigationLink } from 'app/layout/components/Layout/CoachSidebar/Navigation';
import { usePlanningFeatureFlag } from 'featureFlags/hooks/usePlanningFeatureFlag';
import Box from 'shared_DEPRECATED/components/Box';
import { Flex } from 'shared_DEPRECATED/components/Flex';
import { Icon, iconNames } from 'shared_DEPRECATED/components/Icon';
import Spacer, { Spacers } from 'shared_DEPRECATED/components/Spacer';
import { routeNames } from 'shared_DEPRECATED/utils/config';

import { useGoalsEnabled } from 'features/goal';

import { Typography } from 'shared/components/Typography';

type TNavigationRoadmapLinks = {
  linkCallback: (path: string) => string;
};

export const NavigationRoadmapLinks = ({
  linkCallback,
}: TNavigationRoadmapLinks) => {
  const isGoalsEnabled = useGoalsEnabled();
  const isPlanningEnabled = usePlanningFeatureFlag();

  if (!isGoalsEnabled && !isPlanningEnabled) {
    return null;
  }

  return (
    <>
      <Box
        style={{
          borderRadius: 'var(--border-radius)',
        }}
      >
        <Spacers sizes={['md']}>
          <Flex alignItems="center" justifyContent="flex-start">
            <Icon name={iconNames.roadmap} />
            <Spacer size="zr md zr sm" />
            <Typography color="black" fontWeight="medium" type="meta">
              Roadmap
            </Typography>
          </Flex>
        </Spacers>
      </Box>
      <Box
        style={{
          width: '11.8rem',
          overflow: 'auto',
          marginLeft: '1.9rem',
        }}
        role="listbox"
      >
        <Flex alignItems="stretch" flexDirection="column">
          {isGoalsEnabled && (
            <NavigationLink
              iconConfig={{
                name: 'goals',
                width: '1.3rem',
                height: '1.3rem',
              }}
              link={linkCallback(routeNames.GOALS)}
              title="Goals"
              dataTestid="roadmap-navigation-goals-item"
            />
          )}
          {isPlanningEnabled && (
            <NavigationLink
              iconConfig={{
                name: 'planning',
                width: '1.3rem',
                height: '1.3rem',
              }}
              link={linkCallback(routeNames.PLANNING)}
              title="Sprint planning"
              dataTestid="roadmap-navigation-planning-item"
            />
          )}
        </Flex>
      </Box>
    </>
  );
};
