import Button, {
  ButtonPropTypes,
} from 'shared_DEPRECATED/components/Button/Button';
import { Icon, iconsConfig } from 'shared_DEPRECATED/components/Icon';

import { VARIANTS } from './config';

type ButtonIconPropTypes = ButtonPropTypes & {
  icon: keyof typeof iconsConfig;
  iconWidth?: number;
  iconHeight?: number;
  stroke?: string;
};

export const ButtonIcon = ({
  icon,
  iconWidth = iconsConfig[icon].width,
  iconHeight = iconsConfig[icon].width,
  dataTestid,
  stroke,
  ...props
}: ButtonIconPropTypes) => {
  return (
    <Button {...props} variant={VARIANTS.SYMBOL} dataTestid={dataTestid}>
      <Icon name={icon} stroke={stroke} height={iconHeight} width={iconWidth} />
    </Button>
  );
};
