import { useCallback } from 'react';
import { createSearchParams } from 'react-router-dom';

import { snakecasedData } from 'shared_DEPRECATED/utils';
import replaceKeysWithCamelCase from 'shared_DEPRECATED/utils/replaceKeysWithCamelCase';

import { useGetAccessToken } from 'features/auth/hooks/useGetAccessToken';

const responseHandlers = {
  'application/json': async (res) => {
    const data = await res.json();

    return replaceKeysWithCamelCase(data);
  },
  'image/jpeg': async (res) => {
    const data = await res.blob();

    return URL.createObjectURL(data);
  },
  'image/png': async (res) => {
    const data = await res.blob();

    return URL.createObjectURL(data);
  },
};

const fetchData = async (url, options) => {
  let res, data;

  try {
    const urlWithEncodedParams = getEncodedUrlString(url);
    res = await fetch(urlWithEncodedParams, options);
    const contentType = res.headers.get('content-type');
    data = responseHandlers[contentType]
      ? await responseHandlers[contentType](res)
      : null;
  } catch (e) {
    throw new Error(e);
  }

  if (!res.ok) {
    const error = new Error(res.status);

    if (data) {
      error.name = data.errorCode;
      error.description = data.errorMessage;
    }

    throw error;
  }

  return data;
};

export const getEncodedUrlString = (url) => {
  if (url !== decodeURIComponent(url)) {
    return url;
  }
  const [urlString, searchString] = url.split('?');
  if (!searchString) {
    return urlString;
  }

  const searchParamsArray = searchString
    .split('&')
    .map((searchItem) => [
      searchItem.slice(0, searchItem.indexOf('=')),
      searchItem.slice(searchItem.indexOf('=') + 1),
    ]);
  const searchQuery = createSearchParams(searchParamsArray).toString();

  return `${urlString}?${searchQuery}`;
};

export const queryRequest = async ({ getAccessToken, url, headers = {} }) => {
  let token = await getAccessToken();

  const fetchOptions = {
    headers: {
      'hOS-API-Version': '12',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
      ...headers,
    },
    method: 'GET',
  };

  return await fetchData(url, fetchOptions);
};

export const mutationRequest = async ({
  getAccessToken,
  url,
  method = 'POST',
  body,
  headers = {},
  isHosApi = true,
}) => {
  let token = null;

  if (getAccessToken) {
    token = await getAccessToken();
  }

  const preparedBody =
    typeof body === 'object' &&
    !(body instanceof File) &&
    !(body instanceof Blob) &&
    !(body instanceof FormData)
      ? JSON.stringify(snakecasedData(body))
      : body;

  const fetchOptions = {
    headers: isHosApi
      ? {
          'hOS-API-Version': '12',
          'Content-Type': 'application/json',
          Authorization: token ? `Bearer ${token}` : '',
          ...headers,
        }
      : headers,
    method,
    body: preparedBody,
  };

  return await fetchData(url, fetchOptions);
};

export const useQueryHTTPRequest = () => {
  const getAccessToken = useGetAccessToken();

  const requestFn = useCallback(
    (args) => queryRequest({ getAccessToken, ...args }),
    [getAccessToken]
  );

  return { request: requestFn };
};

export const useMutationHTTPRequest = ({ isAuthRequired = true } = {}) => {
  const getAccessToken = useGetAccessToken();

  const requestFn = useCallback(
    (args) =>
      mutationRequest({
        getAccessToken: isAuthRequired ? getAccessToken : null,
        ...args,
      }),
    [getAccessToken, isAuthRequired]
  );

  return { request: requestFn };
};
