import PropTypes from 'prop-types';
import React from 'react';

import Box from 'shared_DEPRECATED/components/Box';
import { useNodeHighlight } from 'shared_DEPRECATED/hooks';

import { ChallengeNotesCardText } from 'features/challengeNotes/components/Card';

type ChallengeNotesCardTextProps = {
  nodeId: string;
  children: React.ReactNode;
};
export const ChallengeNotesCardTextWithHighlight = ({
  nodeId,
  children,
}: ChallengeNotesCardTextProps) => {
  const textNodeRef = useNodeHighlight({ nodeId });

  return (
    <Box ref={textNodeRef}>
      <ChallengeNotesCardText>
        {/* @ts-ignore */}
        {children}
      </ChallengeNotesCardText>
    </Box>
  );
};

ChallengeNotesCardText.propTypes = {
  children: PropTypes.node.isRequired,
};
