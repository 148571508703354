import { Button } from 'shared';
import { Flex } from 'shared_DEPRECATED/components/Flex';
import { FloatingPopover } from 'shared_DEPRECATED/components/Floating';
import { Icon, iconNames } from 'shared_DEPRECATED/components/Icon';
import useToggle from 'shared_DEPRECATED/hooks/useToggle';

import {
  GoalFocusAreaMenuAddChallengeButton,
  GoalFocusAreaMenuAddButton,
} from 'features/goal';

import { VARIANTS } from 'shared/components/Button';

export const GoalFocusAreaHeaderMenu = () => {
  const [open, toggleOpen] = useToggle();

  return (
    <FloatingPopover
      offsetTop={8}
      anchor={
        <Button
          onClick={toggleOpen}
          variant={VARIANTS.PRIMARY}
          w="32px"
          h="32px"
          size="xs"
        >
          <Icon
            name={iconNames.plus}
            width={22}
            height={22}
            stroke="white"
            strokeWidth={2}
          />
        </Button>
      }
      isOpen={open}
      onClose={toggleOpen}
    >
      <Flex flexDirection="column" justifyContent="start">
        <GoalFocusAreaMenuAddButton />
        <GoalFocusAreaMenuAddChallengeButton />
      </Flex>
    </FloatingPopover>
  );
};
