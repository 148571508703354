import PropTypes from 'prop-types';

import { getFileCategory } from 'shared_DEPRECATED/components/File/utils';
import { FlexItem } from 'shared_DEPRECATED/components/Flex';
import Spacer from 'shared_DEPRECATED/components/Spacer';

import { Typography } from 'shared/components/Typography';

const FileTitlePropTypes = {
  contentType: PropTypes.string,
  title: PropTypes.string,
};

export const FileTitle = ({ title, contentType }) => (
  <FlexItem flexGrow={1} flexShrink={1} overflow="hidden">
    <Spacer size="zr md">
      <Typography
        title={title}
        type="small"
        fontWeight="medium"
        color="black"
        cropped={true}
      >
        {title}
      </Typography>
      <Typography type="small" fontWeight="regular" color="gray">
        {getFileCategory(contentType, title)}
      </Typography>
    </Spacer>
  </FlexItem>
);

FileTitle.propTypes = FileTitlePropTypes;
