// @ts-nocheck
import {
  $isHorizontalRuleNode,
  $createHorizontalRuleNode,
} from '@lexical/react/LexicalHorizontalRuleNode';
import React from 'react';

import { $isCodeNode, $createCodeNode } from '@lexical/code';
import { $isLinkNode, $createLinkNode } from '@lexical/link';
import {
  $isListNode,
  $isListItemNode,
  $createListNode,
  $createListItemNode,
} from '@lexical/list';
import {
  $isHeadingNode,
  $isQuoteNode,
  $createHeadingNode,
  $createQuoteNode,
} from '@lexical/rich-text';
import {
  DecoratorNode,
  $isRootNode,
  $isParagraphNode,
  $isTextNode,
  $isElementNode,
  $createParagraphNode,
  $createTextNode,
  $isLineBreakNode,
} from 'lexical';
import { fromMarkdown } from 'mdast-util-from-markdown';
import { mdxToMarkdown, mdxFromMarkdown } from 'mdast-util-mdx';
import { toMarkdown } from 'mdast-util-to-markdown';
import { mdxjs } from 'micromark-extension-mdxjs';

const __defProp = Object.defineProperty;

const __defNormalProp = (obj, key, value) =>
  key in obj
    ? __defProp(obj, key, {
        enumerable: true,
        configurable: true,
        writable: true,
        value,
      })
    : (obj[key] = value);

const __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== 'symbol' ? key + '' : key, value);
  return value;
};

const IS_BOLD = 1;
const IS_ITALIC = 2;
const IS_UNDERLINE = 8;
const IS_CODE = 16;

function convertImageElement(domNode) {
  if (domNode instanceof HTMLImageElement) {
    const { alt: altText, src, title } = domNode;
    const node = $createImageNode({ altText, src, title });
    return { node };
  }
  return null;
}

class ImageNode extends DecoratorNode {
  constructor(src, altText, title, key) {
    super(key);
    __publicField(this, '__src');
    __publicField(this, '__altText');
    __publicField(this, '__title');
    this.__src = src;
    this.__title = title;
    this.__altText = altText;
  }
  static getType() {
    return 'image';
  }
  static clone(node) {
    return new ImageNode(node.__src, node.__altText, node.__title, node.__key);
  }
  static importJSON(serializedNode) {
    const { altText, title, src } = serializedNode;
    const node = $createImageNode({
      altText,
      title,
      src,
    });
    return node;
  }
  exportDOM() {
    const element = document.createElement('img');
    element.setAttribute('src', this.__src);
    element.setAttribute('alt', this.__altText);
    if (this.__title) {
      element.setAttribute('title', this.__title);
    }
    return { element };
  }
  static importDOM() {
    return {
      img: () => ({
        conversion: convertImageElement,
        priority: 0,
      }),
    };
  }
  exportJSON() {
    return {
      altText: this.getAltText(),
      title: this.getTitle(),
      src: this.getSrc(),
      type: 'image',
      version: 1,
    };
  }
  // View
  createDOM(config) {
    const span = document.createElement('span');
    const theme = config.theme;
    const className = theme.image;
    if (className !== void 0) {
      span.className = className;
    }
    return span;
  }
  updateDOM() {
    return false;
  }
  getSrc() {
    return this.__src;
  }
  getAltText() {
    return this.__altText;
  }
  getTitle() {
    return this.__title;
  }
  decorate() {
    return /* @__PURE__ */ React.createElement('img', {
      title: this.__title,
      src: this.__src,
      alt: this.__altText,
    });
  }
}

function $createImageNode({ altText, title, src, key }) {
  return new ImageNode(src, altText, title, key);
}

function $isImageNode(node) {
  return node instanceof ImageNode;
}

const LexicalRootVisitor = {
  testLexicalNode: $isRootNode,
  visitLexicalNode: ({ actions }) => {
    actions.addAndStepInto('root');
  },
};

const LexicalParagraphVisitor = {
  testLexicalNode: $isParagraphNode,
  visitLexicalNode: ({ actions }) => {
    actions.addAndStepInto('paragraph');
  },
};

const LexicalLinkVisitor = {
  testLexicalNode: $isLinkNode,
  visitLexicalNode: ({ lexicalNode, actions }) => {
    actions.addAndStepInto('link', { url: lexicalNode.getURL() });
  },
};

const LexicalHeadingVisitor = {
  testLexicalNode: $isHeadingNode,
  visitLexicalNode: ({ lexicalNode, actions }) => {
    const depth = parseInt(lexicalNode.getTag()[1], 10);
    actions.addAndStepInto('heading', { depth });
  },
};

const LexicalLinebreakVisitor = {
  testLexicalNode: $isLineBreakNode,
  visitLexicalNode: () => {},
};

const LexicalListVisitor = {
  testLexicalNode: $isListNode,
  visitLexicalNode: ({ lexicalNode, actions }) => {
    actions.addAndStepInto('list', {
      ordered: lexicalNode.getListType() === 'number',
      //TODO: figure out when spread can be true
      spread: false,
    });
  },
};

const LexicalListItemVisitor = {
  testLexicalNode: $isListItemNode,
  visitLexicalNode: ({ lexicalNode, mdastParent, actions }) => {
    const children = lexicalNode.getChildren();
    const firstChild = children[0];
    if (children.length === 1 && $isListNode(firstChild)) {
      const prevListItemNode = mdastParent.children.at(-1);
      actions.visitChildren(lexicalNode, prevListItemNode);
    } else {
      const listItem = actions.appendToParent(mdastParent, {
        type: 'listItem',
        spread: false,
        children: [{ type: 'paragraph', children: [] }],
      });
      actions.visitChildren(lexicalNode, listItem.children[0]);
    }
  },
};
const LexicalQuoteVisitor = {
  testLexicalNode: $isQuoteNode,
  visitLexicalNode: ({ lexicalNode, mdastParent, actions }) => {
    const blockquote = actions.appendToParent(mdastParent, {
      type: 'blockquote',
      children: [{ type: 'paragraph', children: [] }],
    });
    actions.visitChildren(lexicalNode, blockquote.children[0]);
  },
};

const LexicalCodeVisitor = {
  testLexicalNode: $isCodeNode,
  visitLexicalNode: ({ lexicalNode, actions }) => {
    actions.addAndStepInto('code', {
      lang: lexicalNode.getLanguage(),
      value: lexicalNode.getTextContent(),
    });
  },
};

function isMdastText(mdastNode) {
  return mdastNode.type === 'text';
}

const LexicalTextVisitor = {
  shouldJoin: (prevNode, currentNode) => {
    return (
      ['text', 'emphasis', 'strong', 'mdxJsxTextElement'].includes(
        prevNode.type
      ) && prevNode.type === currentNode.type
    );
  },
  join(prevNode, currentNode) {
    if (isMdastText(prevNode) && isMdastText(currentNode)) {
      return {
        type: 'text',
        value: prevNode.value + currentNode.value,
      };
    } else {
      return {
        ...prevNode,
        children: [...prevNode.children, ...currentNode.children],
      };
    }
  },
  testLexicalNode: $isTextNode,
  visitLexicalNode: ({ lexicalNode, mdastParent, actions }) => {
    const previousSibling = lexicalNode.getPreviousSibling();
    const prevFormat = $isTextNode(previousSibling)
      ? previousSibling.getFormat()
      : 0;
    const format = lexicalNode.getFormat() ?? 0;
    if (format & IS_CODE) {
      actions.addAndStepInto('inlineCode', {
        value: lexicalNode.getTextContent(),
      });
      return;
    }
    let localParentNode = mdastParent;
    if (prevFormat & format & IS_ITALIC) {
      localParentNode = actions.appendToParent(localParentNode, {
        type: 'emphasis',
        children: [],
      });
    }
    if (prevFormat & format & IS_BOLD) {
      localParentNode = actions.appendToParent(localParentNode, {
        type: 'strong',
        children: [],
      });
    }
    if (prevFormat & format & IS_UNDERLINE) {
      localParentNode = actions.appendToParent(localParentNode, {
        type: 'mdxJsxTextElement',
        name: 'u',
        children: [],
        attributes: [],
      });
    }
    if (format & IS_ITALIC && !(prevFormat & IS_ITALIC)) {
      localParentNode = actions.appendToParent(localParentNode, {
        type: 'emphasis',
        children: [],
      });
    }
    if (format & IS_BOLD && !(prevFormat & IS_BOLD)) {
      localParentNode = actions.appendToParent(localParentNode, {
        type: 'strong',
        children: [],
      });
    }
    if (format & IS_UNDERLINE && !(prevFormat & IS_UNDERLINE)) {
      localParentNode = actions.appendToParent(localParentNode, {
        type: 'mdxJsxTextElement',
        name: 'u',
        children: [],
        attributes: [],
      });
    }
    actions.appendToParent(localParentNode, {
      type: 'text',
      value: lexicalNode.getTextContent(),
    });
  },
};

const LexicalThematicBreakVisitor = {
  testLexicalNode: $isHorizontalRuleNode,
  visitLexicalNode({ actions }) {
    actions.addAndStepInto('thematicBreak');
  },
};

const LexicalImageVisitor = {
  testLexicalNode: $isImageNode,
  visitLexicalNode({ lexicalNode, actions }) {
    actions.addAndStepInto('image', {
      url: lexicalNode.getSrc(),
      alt: lexicalNode.getAltText(),
      title: lexicalNode.getTitle(),
    });
  },
};

const LexicalVisitors = [
  LexicalRootVisitor,
  LexicalParagraphVisitor,
  LexicalTextVisitor,
  LexicalLinkVisitor,
  LexicalHeadingVisitor,
  LexicalListVisitor,
  LexicalListItemVisitor,
  LexicalQuoteVisitor,
  LexicalCodeVisitor,
  LexicalThematicBreakVisitor,
  LexicalImageVisitor,
  LexicalLinebreakVisitor,
];

function isParent$1(node) {
  return node.children instanceof Array;
}

function traverseLexicalTree(root, visitors) {
  let unistRoot = null;
  visit(root, null);
  function appendToParent(parentNode, node) {
    if (unistRoot === null) {
      unistRoot = node;
      return unistRoot;
    }
    if (!isParent$1(parentNode)) {
      throw new Error('Attempting to append children to a non-parent');
    }
    const siblings = parentNode.children;
    const prevSibling = siblings.at(-1);
    if (prevSibling) {
      const joinVisitor = visitors.find((visitor) => {
        var _a;
        return (_a = visitor.shouldJoin) == null
          ? void 0
          : _a.call(visitor, prevSibling, node);
      });
      if (joinVisitor) {
        const joinedNode = joinVisitor.join(prevSibling, node);
        siblings.splice(siblings.length - 1, 1, joinedNode);
        return joinedNode;
      }
    }
    siblings.push(node);
    return node;
  }
  function visitChildren(lexicalNode, parentNode) {
    lexicalNode.getChildren().forEach((lexicalChild) => {
      visit(lexicalChild, parentNode);
    });
  }
  function visit(lexicalNode, mdastParent) {
    var _a;
    const visitor = visitors.find((visitor2) => {
      var _a2;
      return (_a2 = visitor2.testLexicalNode) == null
        ? void 0
        : _a2.call(visitor2, lexicalNode);
    });
    if (!visitor) {
      throw new Error(`no lexical visitor found for ${lexicalNode.getType()}`, {
        cause: lexicalNode,
      });
    }
    (_a = visitor.visitLexicalNode) == null
      ? void 0
      : _a.call(visitor, {
          lexicalNode,
          mdastParent,
          actions: {
            addAndStepInto(type, props = {}, hasChildren = true) {
              const newNode = {
                type,
                ...props,
                ...(hasChildren ? { children: [] } : {}),
              };
              appendToParent(mdastParent, newNode);
              if ($isElementNode(lexicalNode) && hasChildren) {
                visitChildren(lexicalNode, newNode);
              }
            },
            appendToParent,
            visitChildren,
          },
        });
  }
  if (unistRoot === null) {
    throw new Error('traversal ended with no root element');
  }
  return unistRoot;
}

function exportMarkdownFromLexical(root, options, visitors = LexicalVisitors) {
  return toMarkdown(traverseLexicalTree(root, visitors), {
    extensions: [mdxToMarkdown()],
    listItemIndent: 'one',
    ...options,
  });
}

const MdastRootVisitor = {
  testNode: 'root',
  visitNode({ actions, mdastNode, lexicalParent }) {
    actions.visitChildren(mdastNode, lexicalParent);
  },
};

const MdastParagraphVisitor = {
  testNode: 'paragraph',
  visitNode: function ({ mdastNode, lexicalParent, actions }) {
    if ($isListItemNode(lexicalParent) || $isQuoteNode(lexicalParent)) {
      actions.visitChildren(mdastNode, lexicalParent);
    } else {
      actions.addAndStepInto($createParagraphNode());
    }
  },
};

const MdastLinkVisitor = {
  testNode: 'link',
  visitNode({ mdastNode, actions }) {
    console.log('visitNode', mdastNode, actions);
    actions.addAndStepInto($createLinkNode(mdastNode.url));
  },
};

const MdastHeadingVisitor = {
  testNode: 'heading',
  visitNode: function ({ mdastNode, actions }) {
    actions.addAndStepInto($createHeadingNode(`h${mdastNode.depth}`));
  },
};

const MdastListVisitor = {
  testNode: 'list',
  visitNode: function ({ mdastNode, lexicalParent, actions }) {
    const lexicalNode = $createListNode(
      mdastNode.ordered ? 'number' : 'bullet'
    );
    if ($isListItemNode(lexicalParent)) {
      const dedicatedParent = $createListItemNode();
      dedicatedParent.append(lexicalNode);
      lexicalParent.insertAfter(dedicatedParent);
    } else {
      lexicalParent.append(lexicalNode);
    }
    actions.visitChildren(mdastNode, lexicalNode);
  },
};

const MdastListItemVisitor = {
  testNode: 'listItem',
  visitNode({ actions }) {
    actions.addAndStepInto($createListItemNode());
  },
};

const MdastBlockQuoteVisitor = {
  testNode: 'blockquote',
  visitNode({ actions }) {
    actions.addAndStepInto($createQuoteNode());
  },
};

const MdastFormattingVisitor = {
  testNode(mdastNode) {
    return (
      mdastNode.type === 'emphasis' ||
      mdastNode.type === 'strong' ||
      (mdastNode.type === 'mdxJsxTextElement' &&
        (mdastNode == null ? void 0 : mdastNode.name) === 'u')
    );
  },
  visitNode({ mdastNode, lexicalParent, actions }) {
    if (mdastNode.type === 'emphasis') {
      actions.addFormatting(IS_ITALIC);
    } else if (mdastNode.type === 'strong') {
      actions.addFormatting(IS_BOLD);
    } else if (
      mdastNode.type === 'mdxJsxTextElement' &&
      mdastNode.name === 'u'
    ) {
      actions.addFormatting(IS_UNDERLINE);
    }
    actions.visitChildren(mdastNode, lexicalParent);
  },
};

const MdastInlineCodeVisitor = {
  testNode: 'inlineCode',
  visitNode({ mdastNode, actions }) {
    actions.addAndStepInto($createTextNode(mdastNode.value).setFormat(IS_CODE));
  },
};

const MdastCodeVisitor = {
  testNode: 'code',
  visitNode({ mdastNode, actions }) {
    actions.addAndStepInto(
      $createCodeNode(mdastNode.lang).append($createTextNode(mdastNode.value))
    );
  },
};

const MdastTextVisitor = {
  testNode: 'text',
  visitNode({ mdastNode, actions }) {
    actions.addAndStepInto(
      $createTextNode(mdastNode.value).setFormat(actions.getParentFormatting())
    );
  },
};

const MdastThematicBreakVisitor = {
  testNode: 'thematicBreak',
  visitNode({ actions }) {
    actions.addAndStepInto($createHorizontalRuleNode());
  },
};

const MdastImageVisitor = {
  testNode: 'image',
  visitNode({ mdastNode, actions }) {
    actions.addAndStepInto(
      $createImageNode({
        src: mdastNode.url,
        altText: mdastNode.alt || '',
        title: mdastNode.title || '',
      })
    );
  },
};

const MdastVisitors = [
  MdastRootVisitor,
  MdastParagraphVisitor,
  MdastTextVisitor,
  MdastFormattingVisitor,
  MdastInlineCodeVisitor,
  MdastLinkVisitor,
  MdastHeadingVisitor,
  MdastListVisitor,
  MdastListItemVisitor,
  MdastBlockQuoteVisitor,
  MdastCodeVisitor,
  MdastThematicBreakVisitor,
  MdastImageVisitor,
];

function isParent(node) {
  return node.children instanceof Array;
}

function importMarkdownToLexical(root, markdown, visitors = MdastVisitors) {
  const formattingMap = /* @__PURE__ */ new WeakMap();
  const tree = fromMarkdown(markdown, {
    extensions: [mdxjs()],
    mdastExtensions: [mdxFromMarkdown()],
  });
  function visitChildren(mdastNode, lexicalParent) {
    if (!isParent(mdastNode)) {
      throw new Error('Attempting to visit children of a non-parent');
    }
    mdastNode.children.forEach((child) =>
      visit(child, lexicalParent, mdastNode)
    );
  }
  function visit(mdastNode, lexicalParent, mdastParent) {
    const visitor = visitors.find((visitor2) => {
      if (typeof visitor2.testNode === 'string') {
        return visitor2.testNode === mdastNode.type;
      }
      return visitor2.testNode(mdastNode);
    });
    if (!visitor) {
      throw new Error(`no unist visitor found for ${mdastNode.type}`, {
        cause: mdastNode,
      });
    }
    visitor.visitNode({
      //@ts-expect-error root type is glitching
      mdastNode,
      lexicalParent,
      actions: {
        visitChildren,
        addAndStepInto(lexicalNode) {
          lexicalParent.append(lexicalNode);
          if (isParent(mdastNode)) {
            visitChildren(mdastNode, lexicalNode);
          }
        },
        addFormatting(format) {
          formattingMap.set(
            mdastNode,
            format | (formattingMap.get(mdastParent) ?? 0)
          );
        },
        getParentFormatting() {
          return formattingMap.get(mdastParent) ?? 0;
        },
      },
    });
  }
  visit(tree, root, null);
}

export { exportMarkdownFromLexical, importMarkdownToLexical };
