import { ReactNode } from 'react';

import {
  useBreakdownBoardDrop,
  GoalBreakdownChallengeType,
  FocusAreaType,
} from 'features/goal';

import { BoardDropZone } from 'shared/components/Board';

type Item = GoalBreakdownChallengeType | (FocusAreaType & { index: number });

type TGoalBreakdownDropZone = {
  handleCanDrop: (item: Item) => boolean;
  dropZoneIndex: number;
  onDrop: any;
  accept: string[];
  children?: ReactNode;
  isLast: boolean;
};

export const GoalBreakdownDropZone = ({
  handleCanDrop,
  dropZoneIndex,
  onDrop,
  accept,
  children,
  isLast,
}: TGoalBreakdownDropZone) => {
  const { drop, isOver, canDrop } =
    useBreakdownBoardDrop<GoalBreakdownChallengeType>({
      onDrop,
      accept,
      handleCanDrop,
      dropZoneIndex,
    });

  return (
    <BoardDropZone isLast={isLast} canDrop={canDrop} isOver={isOver} ref={drop}>
      {children}
    </BoardDropZone>
  );
};
