import { useQuery } from 'react-query';

import { queryKeys } from 'api/config';
import { useQueryHTTPRequest } from 'shared_DEPRECATED/hooks';

import { Goal, GoalsResponse } from 'features/goal/config/types';
import { useUserId } from 'features/user/hooks/useUserId';

export const useGoalsQuery = () => {
  const { request } = useQueryHTTPRequest();
  const userId = useUserId();

  return useQuery<GoalsResponse, unknown, Goal[]>(
    [queryKeys.challengeGoals, userId],
    () => request({ url: `/api/web/goals?user_id=${userId}` }),
    {
      enabled: !!userId,
      select: (data) =>
        data.items.map((goal) => ({
          ...goal,
          imageUrl: goal?.cover?.url,
        })),
      staleTime: 20 * 1000,
    }
  );
};
