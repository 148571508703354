import { VARIANTS } from 'shared_DEPRECATED/components/Button';
import Spacer from 'shared_DEPRECATED/components/Spacer';
import { noop } from 'shared_DEPRECATED/utils/function';

import { ModalBody, ModalHeader, ModalFooter } from 'shared/components/Modal';
import { ModalButton } from 'shared/components/Modal/ModalButton';
import { Typography } from 'shared/components/Typography';

type UsersConfirmDialogContentPropTypes = {
  title: string;
  bodyText: string;
  submitButtonText: string;
  onSubmit: () => void;
};

export const UsersConfirmDialogContent = ({
  title,
  bodyText,
  submitButtonText,
  onSubmit = noop,
}: UsersConfirmDialogContentPropTypes) => {
  return (
    <>
      <ModalHeader>
        <Typography as="h3" type="body" fontWeight="semiBold" color="black">
          {title}
        </Typography>
      </ModalHeader>
      <ModalBody>
        <Typography color="gray" type="small">
          {bodyText}
        </Typography>
      </ModalBody>
      <ModalFooter>
        <Spacer size="zr md zr zr">
          <Spacer size="zr sm zr zr">
            <ModalButton variant={VARIANTS.SECONDARY}>
              <Spacer size="md">
                <Spacer size="sm">
                  <Typography color="black" fontWeight="medium" type="meta">
                    Cancel
                  </Typography>
                </Spacer>
              </Spacer>
            </ModalButton>
          </Spacer>
        </Spacer>
        <ModalButton onClick={onSubmit} variant={VARIANTS.PRIMARY}>
          <Spacer size="md">
            <Spacer size="sm">
              <Typography
                color="whiteTotal"
                fontWeight="medium"
                type="meta"
                dataTestid="delete-session-window-btn"
              >
                {submitButtonText}
              </Typography>
            </Spacer>
          </Spacer>
        </ModalButton>
      </ModalFooter>
    </>
  );
};
