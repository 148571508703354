import dayjs from 'dayjs';
import { Nullable } from 'shared_DEPRECATED/types';

import { NonRepeatedChallenge } from 'features/challenge/NonRepeatedChallenge';
import { RepeatedChallenge } from 'features/challenge/RepeatedChallenge';
import {
  CHALLENGE_FIELDS,
  MAX_SPRINT_CHALLENGES_COUNT,
} from 'features/challenge/config';
import {
  IChallenge,
  IRepeatedChallenge,
  INonRepeatedChallenge,
} from 'features/challenge/config/types';
import { ICoachingTool } from 'features/coachingTool';
import { CoachingTool } from 'features/coachingTool/CoachingTool';
import {
  SPRINT_PASS_TYPE,
  SPRINT_STATES,
  SPRINT_TYPES,
} from 'features/sprint/config';
import { IFinalSprint, ISprint } from 'features/sprint/config/types';

import { FinalSprint } from './FinalSprint';
import { getChallengesByWeekDates } from './utils';

export class Sprint extends FinalSprint implements IFinalSprint {
  challenges: IRepeatedChallenge[];
  readonly completionRate: { weekly: number[]; total: number };
  readonly createdAt: string;
  oneTimeChallenges: INonRepeatedChallenge[];
  readonly type: typeof SPRINT_TYPES[keyof typeof SPRINT_TYPES];
  readonly userEmail: string;
  readonly userName: string;
  readonly userPicture: any;
  readonly userSharingCount: number;
  readonly isCoached?: Nullable<boolean>;
  coachingTools: ICoachingTool[];

  constructor(sprint: ISprint) {
    super(sprint);

    this.challenges = sprint.challenges.map(
      (challenge) => new RepeatedChallenge(challenge)
    );
    this.completionRate = sprint.completionRate;
    this.createdAt = sprint.createdAt;
    this.oneTimeChallenges = sprint.oneTimeChallenges.map(
      (challenge) => new NonRepeatedChallenge(challenge)
    );
    this.type = sprint.type;
    this.userEmail = sprint.userEmail;
    this.userName = sprint.userName;
    this.userPicture = sprint.userPicture;
    this.userSharingCount = sprint.userSharingCount;
    this.isCoached = sprint.isCoached || false;
    this.coachingTools = sprint.coachingTools.map(
      (coachingTool) => new CoachingTool(coachingTool)
    );
  }

  get allChallenges(): IChallenge[] {
    return [...this.challenges, ...this.oneTimeChallenges];
  }

  get availableChallengeFields() {
    return Object.values(CHALLENGE_FIELDS).reduce((challengeFields, field) => {
      const challenges = this[field];
      challengeFields[field] = challenges.length < MAX_SPRINT_CHALLENGES_COUNT;

      return challengeFields;
    }, {} as Record<typeof CHALLENGE_FIELDS[keyof typeof CHALLENGE_FIELDS], boolean>);
  }

  get challengesCount() {
    return this.allChallenges.length;
  }

  get challengesCountLabel() {
    const challengesCount = this.challengesCount;

    return `${challengesCount} ${
      challengesCount === 1 ? 'challenge' : 'challenges'
    }`;
  }

  get isCurrentDaySameOrAfterLastDay(): boolean {
    return dayjs().isSameOrAfter(this.endDate, 'day');
  }

  get isEditable(): boolean {
    const isSprintActive = this.state === SPRINT_STATES.ACTIVE;
    const isMineSprint = this.type === SPRINT_TYPES.MY;
    const isClientSprint = this.type === SPRINT_TYPES.COACHED;

    return isSprintActive && (isMineSprint || isClientSprint);
  }

  get isFinishable(): boolean {
    const isSprintActive = this.state === SPRINT_STATES.ACTIVE;
    const isMineSprint = this.type === SPRINT_TYPES.MY;
    const isClientSprint = this.type === SPRINT_TYPES.COACHED;

    return isSprintActive && (isMineSprint || isClientSprint);
  }

  get passType() {
    return this.isCoached
      ? SPRINT_PASS_TYPE.WITH_COACH
      : SPRINT_PASS_TYPE.SELF_DIRECTED;
  }

  get titleWithStateLabel() {
    return `${this.title} • ${this.state}`;
  }

  getChallengesByType({
    fromDate,
    toDate,
  }: {
    fromDate: string;
    toDate: string;
  }) {
    return Object.values(CHALLENGE_FIELDS).reduce(
      (challenges, challengesField) => {
        challenges[challengesField] =
          fromDate && toDate
            ? getChallengesByWeekDates({
                challenges: this[challengesField],
                fromDate,
                toDate,
              })
            : this[challengesField];

        return challenges;
      },
      {} as Record<
        typeof CHALLENGE_FIELDS[keyof typeof CHALLENGE_FIELDS],
        RepeatedChallenge[] | NonRepeatedChallenge[]
      >
    );
  }

  getChallenge(id: string): IChallenge | undefined {
    return this.allChallenges.find(
      (challenge) => challenge.sprintChallengeId === id
    );
  }
}
