import ButtonLink from 'shared_DEPRECATED/components/Button/ButtonLink';
import { Flex, FlexItem } from 'shared_DEPRECATED/components/Flex';
import Spacer from 'shared_DEPRECATED/components/Spacer';

import { Typography } from 'shared/components/Typography';

const NotFound = () => (
  <Flex
    alignItems="center"
    justifyContent="center"
    flexDirection="column"
    width="100%"
    height="100%"
  >
    <FlexItem width="23rem">
      <Spacer size="zr zr md">
        <Spacer size="zr zr sm">
          <header>
            <Spacer size="zr zr md">
              <Typography as="div" type="largeHeading" textAlign="center">
                🙈
              </Typography>
            </Spacer>
            <Typography
              as="h1"
              type="heading"
              fontWeight="semiBold"
              textAlign="center"
              color="black"
            >
              Page not found
            </Typography>
          </header>
        </Spacer>
      </Spacer>
    </FlexItem>
    <FlexItem width="23rem">
      <Spacer size="zr zr lg">
        <Spacer size="zr zr md">
          <Typography as="p" color="gray" type="meta" textAlign="center">
            Sorry, this page does not exist.
          </Typography>
        </Spacer>
      </Spacer>
      <Flex justifyContent="center">
        <Spacer size="zr md zr zr">
          <ButtonLink to="/" variant="primary">
            <Spacer size="md">
              <Spacer size="xsm sm">
                <Flex>
                  <Typography
                    color="totalWhite"
                    fontWeight="medium"
                    type="meta"
                  >
                    Go Home
                  </Typography>
                </Flex>
              </Spacer>
            </Spacer>
          </ButtonLink>
        </Spacer>
      </Flex>
    </FlexItem>
  </Flex>
);

export default NotFound;
