import { useCallback } from 'react';

import { UserMode } from 'shared_DEPRECATED/types/User';
import { snakecasedData } from 'shared_DEPRECATED/utils';
import { isProdEnv } from 'utils';

import { useUserMode } from 'features/user/hooks';

import { AnalyticsEvent } from './config';
import { sendAmplitudeEvent, sendTagManagerEvent } from './utils';

const DEFAULT_DATA = {};

export const useSendAnalytics = () => {
  const userMode = useUserMode() as UserMode;

  return useCallback(
    ({
      event,
      data = DEFAULT_DATA,
    }: {
      event: AnalyticsEvent;
      data?: Record<string, string | number | boolean>;
    }) => {
      if (!isProdEnv()) {
        return;
      }

      sendTagManagerEvent({
        event,
        data: { ...snakecasedData(data), mode: userMode },
      });
      sendAmplitudeEvent({
        event,
        data: { ...snakecasedData(data), mode: userMode },
      });
    },
    [userMode]
  );
};
