import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';

import { useBrowserStorage } from 'shared_DEPRECATED/hooks';

export const THEMES = {
  LIGHT: 'light',
  DARK: 'dark',
};

export type TTheme = typeof THEMES[keyof typeof THEMES];

type TThemeContext = {
  theme: TTheme;
  setTheme: (theme: TTheme) => void;
};

const ThemeContextDefaultValue = {
  theme: THEMES.LIGHT,
  setTheme: () => {},
};

const ThemeContext = createContext<TThemeContext>(ThemeContextDefaultValue);

type TThemeProvider = {
  children: ReactNode;
};

export const ThemeProvider = ({ children }: TThemeProvider) => {
  const { setStorageValue, getStorageValue } = useBrowserStorage({
    storageKey: 'theme',
    raw: true,
  });

  const storageValue = getStorageValue() as string;

  const storedTheme: string = Object.values(THEMES).includes(storageValue)
    ? (storageValue as string)
    : THEMES.LIGHT;

  const [theme, setTheme] = useState<TTheme>(storedTheme);

  useEffect(() => {
    setStorageValue(theme);
  }, [theme, setStorageValue]);

  return (
    <ThemeContext.Provider value={{ theme, setTheme }}>
      <div data-theme={theme}>{children}</div>
    </ThemeContext.Provider>
  );
};

export const useThemeContext = () => {
  const themeContext = useContext(ThemeContext);

  if (!themeContext) {
    throw new Error('useThemeContext must be used in ThemeContext.Provider');
  }

  return themeContext;
};
