import { createContext, ReactNode, useContext, useMemo, useState } from 'react';

import { useSidebarContext } from 'shared/components/Sidebar/context';

const MomentumFeedContext = createContext({
  sprintId: '',
  setSprintId: (sprintId: string) => {},
});

type TMomentumFeedProvider = {
  children: ReactNode;
};

export const MomentumFeedProvider = ({ children }: TMomentumFeedProvider) => {
  const {
    sprint: { sprintId },
  } = useSidebarContext();
  const [selectedSprintId, setSelectedSprintId] = useState<string>(sprintId);

  const providerValue = useMemo(
    () => ({
      sprintId: selectedSprintId,
      setSprintId: setSelectedSprintId,
    }),
    [selectedSprintId, setSelectedSprintId]
  );

  return (
    <MomentumFeedContext.Provider value={providerValue}>
      {children}
    </MomentumFeedContext.Provider>
  );
};

export const useMomentumFeedContext = () => {
  const momentumFeedContext = useContext(MomentumFeedContext);

  if (!momentumFeedContext) {
    throw new Error(
      'useMomentumFeedContext must be used in MomentumFeedProvider'
    );
  }

  return momentumFeedContext;
};
