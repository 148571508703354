import { useFormContext } from 'react-hook-form';

import Box from 'shared_DEPRECATED/components/Box';
import { Flex } from 'shared_DEPRECATED/components/Flex';

import { SessionsModalSection } from 'features/sessions/components/Modal';
import { SprintsType } from 'features/sprint/config/types';

import { Dropdown, DropdownItem } from 'shared/components/Dropdown';

type SprintSelectorPropTypes = { sprints: SprintsType };

export const SessionsModalSprintSelector = ({
  sprints,
}: SprintSelectorPropTypes) => {
  const { setValue, watch } = useFormContext();

  const sprintId = watch('sprintId');

  const selectedSprint = sprints.find((sprint) => sprint.sprintId === sprintId);

  return (
    <SessionsModalSection
      title="Related sprint"
      dataTestid="session-sprint-selector"
    >
      <Dropdown
        //@ts-ignore
        dataTestid="session-sprint-selector-anchor"
        onItemSelect={
          ((sprintId: string) => setValue('sprintId', sprintId)) as (
            val: string | number
          ) => void
        }
        anchorContent={selectedSprint!.titleWithStateLabel}
        selectedValue={sprintId}
        anchorSpacing={['md']}
      >
        <Box style={{ width: '17rem' }}>
          <Flex alignItems="stretch" flexDirection="column" width="100%">
            {sprints.map((sprint) => (
              <DropdownItem
                value={sprint.sprintId}
                key={sprint.sprintId}
                dataTestid="session-sprint-dropdown-option"
              >
                {sprint.titleWithStateLabel}
              </DropdownItem>
            ))}
          </Flex>
        </Box>
      </Dropdown>
    </SessionsModalSection>
  );
};
