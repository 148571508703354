import { useCallback } from 'react';
import { useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';

import { queryKeys } from 'api/config';
import { OptionsMenuButton } from 'shared_DEPRECATED/components/Floating/Menu/Options';
import { updateArrayById } from 'shared_DEPRECATED/utils';

import { IChallenge } from 'features/challenge/config/types';
import {
  useEditCustomChallengeMutation,
  useOpenChallengeModal,
} from 'features/challenge/hooks';
import { GoalBreakdownChallengeType, GoalBreakdownType } from 'features/goal';

import { Typography } from 'shared/components/Typography';

type TGoalFocusAreaChallengeCardMenuEditButton = {
  challenge: GoalBreakdownChallengeType;
  focusAreaId: string;
};

export const GoalFocusAreaChallengeCardMenuEditButton = ({
  challenge,
  focusAreaId,
}: TGoalFocusAreaChallengeCardMenuEditButton) => {
  const queryClient = useQueryClient();

  const { goalId } = useParams();

  const openChallengeModal = useOpenChallengeModal();

  const { mutateAsync: editGoalBreakdownChallenge } =
    useEditCustomChallengeMutation({
      options: {
        onMutate: async (updatedChallenge: GoalBreakdownChallengeType) => {
          await queryClient.cancelQueries({
            queryKey: [queryKeys.goalBreakdown, goalId],
          });

          const previousValue = queryClient.getQueryData([
            queryKeys.goalBreakdown,
            goalId,
          ]);

          if (previousValue) {
            queryClient.setQueryData<GoalBreakdownType>(
              [queryKeys.goalBreakdown, goalId],
              (oldVal) => ({
                ...oldVal!,
                focusAreas: updateArrayById({
                  array: oldVal!.focusAreas,
                  updatedItem: {
                    ...oldVal!.focusAreas.find(
                      (focusArea) => focusArea.id === focusAreaId
                    ),
                    challenges: updateArrayById({
                      array:
                        oldVal!.focusAreas.find(
                          (focusArea) => focusArea.id === focusAreaId
                        )?.challenges || [],
                      updatedItem: updatedChallenge,
                      idProperty: 'customChallengeId',
                    }),
                  },
                  idProperty: 'id',
                }),
              })
            );
          }

          return { previousValue };
        },
        onSuccess: () => {
          queryClient.invalidateQueries([queryKeys.goalBreakdown, goalId]);
        },
        onError: (
          _: Error,
          __: any,
          context: { previousValue?: GoalBreakdownType }
        ) => {
          queryClient.setQueryData(
            [queryKeys.goalBreakdown, goalId],
            context?.previousValue
          );
        },
      },
    });

  const onSubmit = useCallback(
    async (updatedChallenge: IChallenge) =>
      await editGoalBreakdownChallenge(updatedChallenge),
    [editGoalBreakdownChallenge]
  );

  const onCreateGoalChallengeBtnClick = () =>
    openChallengeModal({
      challenge,
      onChallengeSubmit: onSubmit,
      title: 'Create Challenge',
      sprint: null,
    });

  return (
    <OptionsMenuButton onClick={onCreateGoalChallengeBtnClick}>
      <Typography>Edit</Typography>
    </OptionsMenuButton>
  );
};
