import { useMutation, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';

import { queryKeys } from 'api/config';
import { logError } from 'lib/sentry/logError';
import { useMutationHTTPRequest } from 'shared_DEPRECATED/hooks';

import {
  GoalBreakdownType,
  GoalBreakdownChallengeType,
  replaceChallenge,
} from 'features/goal';

type TUseMoveGoalBreakdownChallengesMutation = { focusAreaId?: string };

export const useMoveGoalBreakdownChallengesMutation = ({
  focusAreaId,
}: TUseMoveGoalBreakdownChallengesMutation) => {
  const { goalId } = useParams();
  const queryClient = useQueryClient();
  const { request } = useMutationHTTPRequest();

  return useMutation(
    ({ customChallengeId, index }: GoalBreakdownChallengeType) =>
      request({
        url: `/api/web/goals/${goalId}/breakdown/challenges/move`,
        body: { challengeId: customChallengeId, focusAreaId, index },
      }),
    {
      onMutate: async (item) => {
        await queryClient.cancelQueries({
          queryKey: [queryKeys.goalBreakdown, goalId],
        });

        const previousValue = queryClient.getQueryData([
          queryKeys.goalBreakdown,
          goalId,
        ]);

        queryClient.setQueryData<GoalBreakdownType>(
          [queryKeys.goalBreakdown, goalId],
          (oldVal) =>
            replaceChallenge({
              goalBreakdown: oldVal!,
              updatedChallenge: item,
              focusAreaId,
            })
        );

        return { previousValue };
      },
      onError: (err, _, context) => {
        queryClient.setQueryData(
          [queryKeys.goalBreakdown, goalId],
          context?.previousValue
        );
        logError(err);
      },
      onSuccess: () => {
        queryClient.invalidateQueries([queryKeys.goalBreakdown, goalId]);
      },
    }
  );
};
