import { OptionsMenuButton } from 'shared_DEPRECATED/components/Floating/Menu/Options';

import { useDeleteGoalBreakdownChallengeMutation } from 'features/goal';

import { Typography } from 'shared/components/Typography';

type TGoalFocusAreaChallengeCardMenuRemoveButton = {
  challengeId: string;
  focusAreaId: string;
};

export const GoalFocusAreaChallengeCardMenuRemoveButton = ({
  challengeId,
  focusAreaId,
}: TGoalFocusAreaChallengeCardMenuRemoveButton) => {
  const { mutateAsync: deleteGoalBreakdownChallenge } =
    useDeleteGoalBreakdownChallengeMutation({
      challengeId,
      focusAreaId,
    });

  return (
    <OptionsMenuButton onClick={deleteGoalBreakdownChallenge}>
      <Typography color="red">Remove</Typography>
    </OptionsMenuButton>
  );
};
