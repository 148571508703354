import { getLanguage } from 'app/locale/utils';
import { Typography } from 'shared';
import { Nullable } from 'shared_DEPRECATED/types';
import { dateUtils } from 'shared_DEPRECATED/utils';

type UserInfoTimezoneProps = {
  timezone: Nullable<string>;
};

export const UserInfoTimezone = ({ timezone }: UserInfoTimezoneProps) => {
  const tz = timezone || dateUtils.tz();

  return (
    <Typography color="black" type="small">
      {tz}{' '}
      {
        Intl.DateTimeFormat(getLanguage(), {
          timeZoneName: 'short',
          timeZone: tz,
        })
          .formatToParts()
          .find((i) => i.type === 'timeZoneName')?.value
      }
    </Typography>
  );
};
