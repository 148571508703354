import { ReactNode } from 'react';

import Box from 'shared_DEPRECATED/components/Box';
import {
  SWITCH_DIMENSIONS,
  COLORS,
} from 'shared_DEPRECATED/components/Switch/theme/config';

type Variants = 'primary' | 'secondary';

type Sizes = 'sm' | 'md';

type TSwitch = {
  ariaLabel?: string;
  checked: boolean;
  disabled?: boolean;
  dataTestid?: string;
  children: ReactNode;
  variant?: Variants;
  size?: Sizes;
};

const getSwitchStyle = ({
  variant,
  checked,
  disabled,
  size,
}: {
  variant: Variants;
  checked: boolean;
  disabled: boolean;
  size: Sizes;
}) => {
  return {
    ...SWITCH_DIMENSIONS[size],
    backgroundColor:
      COLORS[variant][checked && !disabled ? 'checked' : 'unchecked'],
    borderRadius: '1rem',
    display: 'flex',
    alignItems: 'center',
  };
};

const Switch = ({
  checked,
  disabled = false,
  children,
  variant = 'primary',
  size = 'sm',
  dataTestid,
}: TSwitch) => (
  <Box
    dataTestid={dataTestid}
    style={{ ...getSwitchStyle({ variant, checked, disabled, size }) }}
  >
    {children}
  </Box>
);

export default Switch;
