import { Avatar, SIZES } from 'shared_DEPRECATED/components/Avatar';
import { Flex } from 'shared_DEPRECATED/components/Flex';

import { UserInfoName, UserInfoSubtitle } from 'features/user/components/Info/';

type HeaderMenuUserInfoPropTypes = {
  name: string;
  email: string;
  pictureUrl: string;
};

export const HeaderMenuUserInfo = ({
  name,
  email,
  pictureUrl,
}: HeaderMenuUserInfoPropTypes) => {
  return (
    <Flex flexDirection="column" alignItems="center" gap="var(--spacing01)">
      <Avatar name={name} size={SIZES.XLG} src={pictureUrl} />
      <UserInfoName maxWidth="13rem">{name}</UserInfoName>
      <UserInfoSubtitle maxWidth="13rem">{email}</UserInfoSubtitle>
    </Flex>
  );
};
