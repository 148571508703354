import { ReactNode } from 'react';

import styles from 'app/layout/components/Layout/Layout.module.css';

type TUnauthorizedLayout = {
  children: ReactNode;
};

export const UnauthorizedLayout = ({ children }: TUnauthorizedLayout) => (
  <div className={styles.layout}>
    <div className={styles['main-wrapper']}>
      <main className={`${styles['main-content']}`}>{children}</main>
    </div>
  </div>
);
