import { DrawerCloseButton } from '@chakra-ui/react';

import { Icon, iconNames } from 'shared_DEPRECATED/components/Icon';

import { VARIANTS } from 'shared/components/Button';

const SidebarMainHeaderCloseButton = () => (
  <DrawerCloseButton
    right="0px"
    left="10px"
    top="10px"
    data-testid="notes-sidebar-close-btn"
    variant={VARIANTS.TERTIARY}
  >
    <Icon name={iconNames.chevronRight} />
  </DrawerCloseButton>
);

export default SidebarMainHeaderCloseButton;
