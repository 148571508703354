import { TChartTooltip } from 'shared_DEPRECATED/components/Chart/types';

import { Badge } from 'shared/components/Badge';
import { Typography } from 'shared/components/Typography';

export const MomentumFeedChartTooltip = ({
  active,
  payload,
}: TChartTooltip) => {
  const hasMomentumScore = payload && payload.length;
  const isNotPrevWeekMomentumScore =
    hasMomentumScore && payload[0].name !== 'prevScore';

  if (active && isNotPrevWeekMomentumScore) {
    return (
      <Badge bgColor="darkGray">
        <Typography type="small" color="white">
          {payload[0].value}
        </Typography>
      </Badge>
    );
  }

  return null;
};
