import { colors } from 'shared_DEPRECATED/components/Typography/Typography';

import { Typography, TYPOGRAPHY_COLORS } from 'shared/components/Typography';

type MomentumFeedItemTrendProps = {
  textColor: keyof typeof colors;
  trend: number;
  trendSign: '-' | '+';
};

export const MomentumFeedItemTrend = ({
  textColor,
  trend,
  trendSign,
}: MomentumFeedItemTrendProps) => (
  <Typography type="small" color={textColor as TYPOGRAPHY_COLORS}>
    {trend !== 0 ? `${trendSign}${Math.abs(trend)}` : ''}
  </Typography>
);
