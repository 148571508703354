import { useFormContext } from 'react-hook-form';

import { Flex } from 'shared_DEPRECATED/components/Flex';
import { ErrorMessage } from 'shared_DEPRECATED/components/Form/ErrorMessage';
import { TextInput } from 'shared_DEPRECATED/components/Form/Input';
import RequiredText from 'shared_DEPRECATED/components/Form/RequiredText';
import Spacer from 'shared_DEPRECATED/components/Spacer';

import {
  GoalAddFormSection,
  FORM_TEXT_INPUT_LIMIT,
  getGoalFormLimitErrorText,
} from 'features/goal';

import { Typography } from 'shared/components/Typography';

export const GoalAddFormNameSection = () => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <GoalAddFormSection>
      <Flex width="100%" justifyContent="space-between">
        <Typography
          as="label"
          color="black"
          fontWeight="medium"
          type="smallHeading"
        >
          Name
        </Typography>
        <RequiredText />
      </Flex>
      <Spacer size="sm" />
      <TextInput
        //@ts-ignore
        placeholder="Add name here..."
        autoComplete="off"
        dataTestid="goal-name-field"
        {...register('name', {
          required: { value: true, message: 'This field is required' },
          maxLength: {
            value: FORM_TEXT_INPUT_LIMIT,
            message: getGoalFormLimitErrorText(FORM_TEXT_INPUT_LIMIT),
          },
        })}
      />
      {errors?.name && (
        <Spacer size="sm zr zr">
          <ErrorMessage message={errors.name.message as string} />
        </Spacer>
      )}
    </GoalAddFormSection>
  );
};
