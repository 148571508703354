import PropTypes from 'prop-types';

import Box from 'shared_DEPRECATED/components/Box';
import Spacer from 'shared_DEPRECATED/components/Spacer';

import { SidebarSection } from 'shared/components/Sidebar';

const ChallengeNotesCardContainerPropTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  unread: PropTypes.bool,
  dataTestid: PropTypes.string,
};

export const ChallengeNotesCardContainer = ({
  children,
  title = '',
  unread,
  dataTestid = null,
}) => (
  <Box
    style={{
      backgroundColor: unread ? 'var(--bgSecondary)' : 'var(--bgPrimary)',
    }}
    dataTestid={dataTestid}
  >
    <SidebarSection title={title}>
      <Spacer size="sm zr">{children}</Spacer>
    </SidebarSection>
  </Box>
);

ChallengeNotesCardContainer.propTypes = ChallengeNotesCardContainerPropTypes;
