import { useFieldArray, useFormContext } from 'react-hook-form';

export const useDimensionsField = () => {
  const { control, getValues } = useFormContext();
  const { append, remove } = useFieldArray({
    control,
    name: 'dimensions',
    rules: {
      required: {
        value: true,
        message: 'At least one dimension should be selected',
      },
    },
  });

  const dimensions = getValues('dimensions');

  const onDimensionChange = (e: any) => {
    const { checked, name } = e.currentTarget;
    const dimensionName = name.toLowerCase();

    checked
      ? append(dimensionName)
      : remove(
          dimensions.findIndex(
            (dimension: string) => dimension === dimensionName
          )
        );
  };

  return { dimensions, onDimensionChange };
};
