import { USER_TYPES } from 'shared_DEPRECATED/types/User';

import { useUserContext } from 'features/user/context';

import ProtectedRoute from './ProtectedRoute';

type RoleProtectedRouteParams = {
  role: USER_TYPES;
};

const RoleProtectedRoute = ({ role }: RoleProtectedRouteParams) => {
  const { user } = useUserContext();

  const isAllowed = Array.isArray(role)
    ? role.includes(user?.type)
    : user?.type === role;

  return <ProtectedRoute isAllowed={isAllowed} />;
};

export default RoleProtectedRoute;
