import PropTypes from 'prop-types';

import { Flex } from 'shared_DEPRECATED/components/Flex';
import { Icon, iconNames } from 'shared_DEPRECATED/components/Icon';
import Spacer from 'shared_DEPRECATED/components/Spacer';

import { Typography } from 'shared/components/Typography';

import styles from './ParticipantsTable.module.css';

const TableHeaderContentPropTypes = {
  children: PropTypes.node,
  canSort: PropTypes.bool,
  isSorted: PropTypes.bool,
  isSortedDesc: PropTypes.bool,
};
const TableHeaderContent = ({ canSort, isSorted, isSortedDesc, children }) => (
  <Spacer size="lg lg lg zr">
    <Flex
      justifyContent="flex-start"
      dataTestid="participants-table-header"
      gap="0.5rem"
      alignItems="center"
    >
      <Typography
        fontWeight="medium"
        type="small"
        whiteSpace="nowrap"
        color="gray"
      >
        {children}
      </Typography>
      {canSort && (
        <Icon
          name={
            isSorted && !isSortedDesc
              ? iconNames.chevronUp
              : iconNames.chevronDown
          }
          width={8}
          stroke={isSorted ? 'var(--fgPrimary)' : 'var(--fgSecondary)'}
        />
      )}
    </Flex>
  </Spacer>
);

TableHeaderContent.propTypes = TableHeaderContentPropTypes;

const TableCellContentPropTypes = {
  children: PropTypes.node,
  isLastCell: PropTypes.bool,
};

const TableCellContent = ({ children, isLastCell }) => (
  <Spacer size={`lg ${isLastCell ? 'zr' : 'lg'} lg zr`}>
    <Spacer size={`zr ${isLastCell ? 'zr' : 'lg'} zr zr`}>{children}</Spacer>
  </Spacer>
);

TableCellContent.propTypes = TableCellContentPropTypes;

const ParticipantsTablePropTypes = {
  tableProps: PropTypes.shape({
    getTableProps: PropTypes.func,
    getTableBodyProps: PropTypes.func,
    headerGroups: PropTypes.array,
    rows: PropTypes.array,
    prepareRow: PropTypes.func,
  }).isRequired,
};

const ParticipantsTable = ({ tableProps }) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableProps;

  return (
    <table className={styles['participants-table']} {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup, index) => (
          <tr
            key={index}
            {...headerGroup.getHeaderGroupProps()}
            className={styles.row}
          >
            {headerGroup.headers.map((column) => (
              <th
                key={column.id}
                {...column.getHeaderProps(column.getSortByToggleProps())}
              >
                <TableHeaderContent
                  canSort={column.canSort}
                  isSorted={column.isSorted}
                  isSortedDesc={column.isSortedDesc}
                >
                  {column.render('Header')}
                </TableHeaderContent>
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);

          return (
            <tr
              key={row.id}
              {...row.getRowProps()}
              className={styles.row}
              data-testid="participants-table-row"
            >
              {row.cells.map((cell, cellIndex) => (
                <td key={`${cell.value}${cellIndex}`} {...cell.getCellProps()}>
                  <TableCellContent
                    isLastCell={cellIndex === row.cells.length - 1}
                  >
                    {cell.render('Cell')}
                  </TableCellContent>
                </td>
              ))}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

ParticipantsTable.propTypes = ParticipantsTablePropTypes;

export default ParticipantsTable;
