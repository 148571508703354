export const CHALLENGE_NOTE_STATUS = Object.freeze({
  EMPTY: 'empty',
  SUCCESS: 'success',
  FAIL: 'fail',
});

export const FAIL_REASONS = Object.freeze([
  'Lack of time',
  'Did not prioritize',
  'Unexpected blocker',
  'Lack of clarity',
  'Lack of focus',
  'Lack of motivation',
]);

export const NOTE_STATUS = {
  READ: 'read',
  UNREAD: 'unread',
};

export const NOTE_TYPES = {
  CHALLENGE_NOTE: 'challenge-note',
  CHALLENGE_PROOF_NOTE: 'challenge-proof-note',
};

export const NOTE_FILTERS = Object.freeze({
  FILTER_READ_STATUSES: 'filterReadStatuses',
  FILTER_REPLIED_BY_EMAILS: 'filterRepliedByEmails',
  FILTER_CHALLENGE_IDS: 'filterChallengeIds',
});

export const NOTE_FILTERS_DEFAULT_VALUE = Object.freeze({
  [NOTE_FILTERS.FILTER_READ_STATUSES]: null,
  [NOTE_FILTERS.FILTER_REPLIED_BY_EMAILS]: null,
  [NOTE_FILTERS.FILTER_CHALLENGE_IDS]: null,
});

export const PARTICIPANT_NOTES_COUNT_FIELDS_BY_TYPE = {
  [NOTE_TYPES.CHALLENGE_NOTE]: 'unreadChallengeNotesNumber',
  [NOTE_TYPES.SESSION_RECAP_NOTE]: 'unreadSessionRecapNotesNumber',
};

export const NOTE_FILTERS_COMPONENT_CONFIG = Object.freeze({
  [NOTE_FILTERS.FILTER_READ_STATUSES]: {
    label: 'Filter by unread',
    testid: 'by-unread',
  },
  [NOTE_FILTERS.FILTER_REPLIED_BY_EMAILS]: {
    label: 'Only with my replies',
    testid: 'with-replies',
  },
});

export const DEFAULT_CHALLENGE_FILTER_VALUE = 'All';
