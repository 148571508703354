export const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1);

export const firstLetterUpperCase = (str) =>
  str.split(' ').map(capitalize).join('');

export const camelToKebabCase = (str) => {
  return str
    .split('')
    .map((letter, i) =>
      letter.toUpperCase() === letter
        ? `${i !== 0 ? '-' : ''}${letter.toLowerCase()}`
        : letter
    )
    .join('');
};

export const compareStringsIgnoreCase = (strA, strB) =>
  strA.toLowerCase().localeCompare(strB.toLowerCase());
