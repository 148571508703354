import { Nullable } from 'shared_DEPRECATED/types';

import { GoalYears, GoalDeadline } from 'features/goal';

export const GoalBadge = ({ deadline, years, completedAt }: TGoalBadge) => {
  if (deadline || completedAt) {
    return <GoalDeadline deadline={deadline} completedAt={completedAt} />;
  }

  if (years) {
    return <GoalYears years={years} />;
  }

  return null;
};

type TGoalBadge = {
  deadline: Nullable<string>;
  years?: string;
  completedAt: Nullable<string>;
};
