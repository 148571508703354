import PropTypes from 'prop-types';

import Box from 'shared_DEPRECATED/components/Box';
import Spacer from 'shared_DEPRECATED/components/Spacer';

import SidebarBackButton from 'shared/components/Sidebar/Button/SidebarBackButton';
import SidebarSubHeaderCloseButton from 'shared/components/Sidebar/Button/SidebarSubHeaderCloseButton';
import SidebarHeaderContainer from 'shared/components/Sidebar/Header/SidebarHeaderContainer';

const SidebarSubHeaderPropTypes = {
  /**
   * Sidebar title to go to on back button click click
   * @type {string}
   * @required
   * @name mainSidebarTitle
   * @example <SidebarBackButton mainSidebarTitle="Filters" />
   */
  mainSidebarTitle: PropTypes.string.isRequired,
  /**
   * Back button click handler. Should close the current sidebar and open the main sidebar.
   * @type {function}
   * @required
   * @name onBackButtonClick
   * @example <SidebarSubHeader onBackButtonClick={() => {}} />
   */
  onBackButtonClick: PropTypes.func.isRequired,
};

const SidebarSubHeader = ({ mainSidebarTitle, onBackButtonClick }) => (
  <Box style={{ backgroundColor: 'var(--bgPrimary)' }}>
    <SidebarHeaderContainer>
      <SidebarBackButton
        mainSidebarTitle={mainSidebarTitle}
        onClick={onBackButtonClick}
      />
      <SidebarSubHeaderCloseButton />
      <Spacer size="zr lg zr zr" />
      <Spacer size="zr lg zr zr" />
    </SidebarHeaderContainer>
  </Box>
);

SidebarSubHeader.propTypes = SidebarSubHeaderPropTypes;

export default SidebarSubHeader;
