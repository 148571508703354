import Box from 'shared_DEPRECATED/components/Box';
import { Flex } from 'shared_DEPRECATED/components/Flex';
import { Icon, iconNames } from 'shared_DEPRECATED/components/Icon';

export const SwitchThemeLabel = ({ checked }: { checked: boolean }) => (
  <Box
    style={{
      width: '1.75rem',
      height: '1.75rem',
      borderRadius: '1rem',
      //TODO: Replace with colors from new palette https://hosinc.atlassian.net/browse/HOS-1104
      backgroundColor: checked ? '#66739933' : '#73798C66',
    }}
  >
    <Flex
      alignItems="center"
      justifyContent="center"
      width="100%"
      height="100%"
    >
      <Icon
        name={checked ? iconNames.sun : iconNames.moon}
        fill={checked ? 'black' : 'white'}
        width={16}
        height={16}
      />
    </Flex>
  </Box>
);
