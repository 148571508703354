import { Radio } from 'shared';
import { Flex } from 'shared_DEPRECATED/components/Flex';
import {
  SELECT_BUTTON_SIZES,
  SelectButton,
} from 'shared_DEPRECATED/components/Form/SelectButton';
import { Spacers } from 'shared_DEPRECATED/components/Spacer';
import { dateUtils, firstLetterUpperCase } from 'shared_DEPRECATED/utils';

import {
  CHECKBOX_BUTTONS_GAP,
  TARGET_TYPES,
  WEEKDAY_TARGET_DEFAULT,
} from 'features/challenge/config';
import { WeekdayTargetType } from 'features/challenge/config/types';
import { onFrequencyCheckboxChange } from 'features/challenge/utils';

type ChallengeFrequencyDialogWeekdayFieldProps = {
  frequency: {
    type: string;
    weekday: WeekdayTargetType;
  };
  onFrequencyChange: (frequency: {
    type: string;
    weekday: WeekdayTargetType;
  }) => void;
};

export const ChallengeFrequencyDialogWeekdayField = ({
  frequency,
  onFrequencyChange,
}: ChallengeFrequencyDialogWeekdayFieldProps) => {
  const currentWeekDayIndex = dateUtils().get('day');

  const currentWeekDay = dateUtils
    .weekDays('weekdaysShort')
    .at(currentWeekDayIndex - 1);

  const checked = frequency.type === TARGET_TYPES.WEEKDAY;
  const onRadioButtonChange = () => {
    if (!currentWeekDay) {
      return;
    }

    onFrequencyChange({
      type: TARGET_TYPES.WEEKDAY,
      weekday: {
        ...WEEKDAY_TARGET_DEFAULT,
        [currentWeekDay.toLowerCase()]: true,
      },
    });
  };

  return (
    <Flex flexDirection="column" alignItems="start">
      <Radio
        isChecked={checked}
        onChange={onRadioButtonChange}
        data-testid="challenge-frequency-weekday-radio"
      >
        Specific days weekly
      </Radio>
      <Spacers sizes={['sm zr', 'xsm zr']} />
      {checked && (
        <>
          <Flex justifyContent="start" gap={CHECKBOX_BUTTONS_GAP}>
            <Spacers sizes={['zr zr zr xsm']} />
            {dateUtils.weekDays('weekdaysShort').map((day: string) => {
              const value = frequency.weekday;
              const checked = !value
                ? false
                : value[day.toLowerCase() as keyof typeof value];
              const onSelectButtonChange = onFrequencyCheckboxChange(
                day,
                value,
                onFrequencyChange
              );

              return (
                <SelectButton
                  checked={checked}
                  key={day}
                  minWidth="3.25rem"
                  onChange={onSelectButtonChange}
                  size={SELECT_BUTTON_SIZES.SM}
                  dataTestid={`challenge-frequency-daily-day-${day.toLowerCase()}`}
                >
                  {firstLetterUpperCase(day)}
                </SelectButton>
              );
            })}
          </Flex>
          <Spacers sizes={['sm zr', 'xsm zr']} />
        </>
      )}
    </Flex>
  );
};
