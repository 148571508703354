import { useMutation, useQueryClient } from 'react-query';

import { queryKeys } from 'api/config';
import { getHtmlStringWithNestedLists } from 'shared_DEPRECATED/components/TextEditor/utils';
import { useMutationHTTPRequest } from 'shared_DEPRECATED/hooks';

import {
  GOAL_DEEP_DIVE_DEFAULT_VALUE,
  useGoalsUpdateQuery,
  useGoalUpdateQuery,
  IGoal,
  GoalDeepDiveType,
  useGoalPageMatcher,
} from 'features/goal';
import { useUserId } from 'features/user/hooks/useUserId';

const transformGoalDeepDive = (deepDive: GoalDeepDiveType) => {
  if (!deepDive) {
    return GOAL_DEEP_DIVE_DEFAULT_VALUE;
  }

  return Object.fromEntries(
    Object.entries(deepDive).map(([key, value]) => [
      key,
      getHtmlStringWithNestedLists(value),
    ])
  );
};

const mapGoalPostData = (goal: IGoal & { userId?: string }) => ({
  ...goal,
  deepDive: transformGoalDeepDive(goal.deepDive),
});

type TUseEditGoalMutation = {
  goalId: string;
  modifiedColumnId: string;
};

export const useEditGoalMutation = ({
  goalId,
  modifiedColumnId,
}: TUseEditGoalMutation) => {
  const queryClient = useQueryClient();
  const { request } = useMutationHTTPRequest();

  const isUserInTheGoalPage = useGoalPageMatcher();

  const userId = useUserId();

  const updateGoalQuery = useGoalUpdateQuery({ goalId });
  const updateGoalsQuery = useGoalsUpdateQuery({
    userId,
    modifiedColumnId,
  });

  return useMutation(
    (goal: IGoal) =>
      request({
        url: `/api/web/goals/${goal.goalId}/edit`,
        body: mapGoalPostData({ ...goal, userId }),
      }),
    {
      onMutate: (updatedGoal: IGoal) => {
        const updateQuery = isUserInTheGoalPage
          ? updateGoalQuery
          : updateGoalsQuery.updateGoal;

        //should be wrapped with mapGoalPostData
        //to save the structure of the text
        updateQuery(mapGoalPostData(updatedGoal) as IGoal);
      },
      onSuccess: () => {
        queryClient.refetchQueries([queryKeys.goals, userId]);
        queryClient.refetchQueries([queryKeys.goal, goalId]);
      },
    }
  );
};
