import { useMutation, useQueryClient } from 'react-query';

import { queryKeys } from 'api/config';
import { logError } from 'lib/sentry/logError';
import { useMutationHTTPRequest } from 'shared_DEPRECATED/hooks';

import { useGoalsUpdateQuery, IGoal } from 'features/goal';
import { useUserId } from 'features/user/hooks/useUserId';

export const useAddGoalMutation = () => {
  const queryClient = useQueryClient();
  const { request } = useMutationHTTPRequest();

  const userId = useUserId();

  const updateGoalsQuery = useGoalsUpdateQuery({
    userId,
    modifiedColumnId: '',
  });

  return useMutation(
    (goal: IGoal) => {
      return request({
        url: '/api/web/goals/create',
        body: { ...goal, userId },
      });
    },
    {
      onMutate: (goal: IGoal) => updateGoalsQuery.addGoal(goal),
      onError: (err, _, context) => {
        queryClient.setQueryData(
          [queryKeys.goals, userId],
          context?.previousValue
        );
        logError(err);
      },
      onSuccess: (updatedGoal) => {
        updateGoalsQuery.updateGoal(updatedGoal);
      },
      onSettled: () => {
        queryClient.invalidateQueries([queryKeys.goals, userId]);
        queryClient.invalidateQueries([queryKeys.challengeGoals, userId]);
      },
    }
  );
};
