import { ReactionsCountButton } from 'shared_DEPRECATED/components/Reactions';
import { ReactionsType } from 'shared_DEPRECATED/components/Reactions/types';
import Spacer from 'shared_DEPRECATED/components/Spacer';

import { Typography } from 'shared/components/Typography';

type ReactionsEntriesPropTypes = {
  reactions?: ReactionsType;
  onEmojiSelect: (emoji: string) => void;
  theme?: 'light' | 'dark';
};

export const ReactionsSelectionRow = ({
  reactions = {},
  onEmojiSelect,
  theme = 'light',
}: ReactionsEntriesPropTypes) => {
  const reactionEntries = Object.entries(reactions);

  return (
    <>
      {reactionEntries.map(([emoji, metaData]) => (
        <Spacer key={emoji} size="zr sm zr zr">
          <ReactionsCountButton
            isMy={metaData.isMy}
            onClick={() => onEmojiSelect(emoji)}
            tooltipText={metaData.tooltipText}
            dataTestid="reaction-count-button"
            theme={theme}
          >
            <Typography
              fontWeight="medium"
              type="small"
              data-testid="reaction-count"
              color={theme === 'light' ? 'blackTotal' : 'whiteTotal'}
            >
              {emoji} {metaData.count}
            </Typography>
          </ReactionsCountButton>
        </Spacer>
      ))}
    </>
  );
};
