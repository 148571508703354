import { ModalBody as ChakraBody } from '@chakra-ui/react';
import { CSSProperties, ReactNode } from 'react';

import { ErrorBoundary } from 'shared_DEPRECATED/components/Error/ErrorBoundary';
import { GeneralWidgetError } from 'shared_DEPRECATED/components/Error/GeneralWidgetError';

type ModalBodyProps = {
  children: ReactNode;
  styles?: CSSProperties;
};

export const ModalBody = ({ children, styles }: ModalBodyProps) => (
  <ChakraBody style={styles}>
    <ErrorBoundary FallbackComponent={GeneralWidgetError}>
      {children}
    </ErrorBoundary>
  </ChakraBody>
);
