import { useMemo } from 'react';

import { useMomentumFeedContext } from 'features/momentum/context/feed';
import { ISprint } from 'features/sprint/config/types';

import { useSidebarContext } from 'shared/components/Sidebar/context';

export const useMomentumFeedSprintScores = () => {
  const { sprintId } = useMomentumFeedContext();
  const { sprints } = useSidebarContext() as {
    sprints: ISprint[];
  };

  const selectedSprint = sprints.find((sprint) => sprint.sprintId === sprintId);

  return useMemo(
    () =>
      [...selectedSprint!.momentumScores]
        .map((momentumScore) => ({
          date: momentumScore.date,
          score: momentumScore.score,
        }))
        .reverse(),
    [selectedSprint]
  );
};
