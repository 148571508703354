import { ObjectValues } from 'types/common';

export enum USER_TYPES {
  COACH = 'coach',
  USER = 'user',
  ADMIN = 'admin',
}

export type UserMode = ObjectValues<typeof USER_TYPES>;

export type UserProfile = {
  email: string;
  name: string;
  firstName: string;
  lastName: string;
  type: USER_TYPES;
  pictureUrl: string;
  picture: {
    key: string;
    url: string;
    blurHash: string;
    status: 'uploaded';
  };
  timezone: null;
  assessmentAvailable: false;
  assessmentRequired: true;
  timezoneChangeBehavior: 'automatic';
  featureFlags: {
    [key: string]: boolean;
  };
  userId: string;
};
