import PropTypes from 'prop-types';

import Box from 'shared_DEPRECATED/components/Box';
import { Flex } from 'shared_DEPRECATED/components/Flex';

export const LayoutPageSidebar = ({ children }) => {
  return (
    <Box
      style={{
        height: '100%',
        width: 'var(--tabs-width)',
        borderRight: 'var(--border-secondary)',
        boxSizing: 'border-box',
      }}
    >
      <Flex
        height="100%"
        width="100%"
        flexDirection="column"
        justifyContent="flex-start"
        alignItems="stretch"
      >
        {children}
      </Flex>
    </Box>
  );
};

LayoutPageSidebar.propTypes = { children: PropTypes.node.isRequired };
