import { useColorMode } from '@chakra-ui/react';
import { ReactNode } from 'react';

import Box from 'shared_DEPRECATED/components/Box';
import Button from 'shared_DEPRECATED/components/Button';
import { FlexItem } from 'shared_DEPRECATED/components/Flex';
import { FloatingTooltip as Tooltip } from 'shared_DEPRECATED/components/Floating';
import Spacer from 'shared_DEPRECATED/components/Spacer';

import { Typography } from 'shared/components/Typography';

const getColorConfig = ({
  theme,
  isMy,
  isThemeLight,
}: {
  theme: 'light' | 'dark';
  isMy: boolean;
  isThemeLight: boolean;
}) => {
  switch (theme) {
    case 'dark':
      return {
        backgroundColor: 'var(--whiteAlpha30)',
        borderColor: isMy ? 'var(--white)' : 'transparent',
      };
    case 'light':
    default:
      return {
        backgroundColor: isMy
          ? isThemeLight
            ? 'var(--bgCompMinorAccent)'
            : 'transparent'
          : 'var(--gray25)',
        borderColor: isMy ? 'var(--fgInteractive)' : 'transparent',
      };
  }
};

type ReactionsCountButtonPropTypes = {
  children: ReactNode;
  isMy: boolean;
  onClick: () => void;
  tooltipText: string;
  dataTestid?: string;
  theme: 'light' | 'dark';
};

export const ReactionsCountButton = ({
  children,
  isMy,
  onClick,
  tooltipText,
  dataTestid = '',
  theme = 'light',
}: ReactionsCountButtonPropTypes) => {
  const { colorMode: curTheme } = useColorMode();
  const isThemeLight = curTheme === 'light';

  const { backgroundColor, borderColor } = getColorConfig({
    theme,
    isMy,
    isThemeLight,
  });

  return (
    <FlexItem flexShrink={0}>
      <Tooltip
        anchor={
          <Button onClick={onClick} dataTestid={dataTestid}>
            <Box
              style={{
                backgroundColor,
                borderRadius: 'var(--border-radius12)',
                border: `1px solid ${borderColor}`,
              }}
            >
              <Spacer size="sm">
                <Spacer size="zr xsm">{children}</Spacer>
              </Spacer>
            </Box>
          </Button>
        }
        offsetValue={8}
      >
        <Typography color="white" fontWeight="medium" type="small">
          {tooltipText}
        </Typography>
      </Tooltip>
    </FlexItem>
  );
};
