import { Flex } from 'shared_DEPRECATED/components/Flex';

import {
  ParticipantsTableMessages,
  ParticipantsTableNextSessionHint,
  ParticipantsTableNotes,
} from 'features/participants/components/Table/Actions/';
import {
  CoachingMode,
  ParticipantsTableRowDataType,
} from 'features/participants/config/types';

type ParticipantsTableActionsCellPropTypes = {
  value: CoachingMode;
  row: ParticipantsTableRowDataType;
};

export const ParticipantsTableActionsCell = ({
  row,
}: ParticipantsTableActionsCellPropTypes) => {
  const {
    nextCoachingSessionAppointment,
    userInfo,
    latestSprint,
    unreadNotesNumber,
    chatId,
    unreadChatMessagesNumber,
    isChatMarkedAsUnread,
  } = row.original;

  return (
    <Flex gap="1rem" justifyContent="flex-start">
      <ParticipantsTableNextSessionHint
        appointmentId={nextCoachingSessionAppointment?.appointmentId}
        userInfo={userInfo}
      />
      <ParticipantsTableNotes
        userInfo={userInfo}
        unreadNotes={unreadNotesNumber}
        latestSprint={latestSprint}
      />
      <ParticipantsTableMessages
        userInfo={userInfo}
        latestSprint={latestSprint}
        chatId={chatId}
        unreadChatMessagesNumber={unreadChatMessagesNumber}
        isChatMarkedAsUnread={isChatMarkedAsUnread}
      />
    </Flex>
  );
};
