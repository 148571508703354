import PropTypes from 'prop-types';

import { HotjarSuppression } from 'shared_DEPRECATED/components/Hotjar';
import Spacer from 'shared_DEPRECATED/components/Spacer';

export const ChallengeNotesCardBody = ({ children }) => {
  return (
    <Spacer size="zr zr zr lg">
      <Spacer size="zr zr zr lg">
        <Spacer size="zr zr zr sm">
          <HotjarSuppression>{children}</HotjarSuppression>
        </Spacer>
      </Spacer>
    </Spacer>
  );
};

ChallengeNotesCardBody.propTypes = { children: PropTypes.node.isRequired };
