import { CartesianGrid, Line, LineChart, ReferenceDot } from 'recharts';
import Box from 'shared_DEPRECATED/components/Box';
import { ChartDot } from 'shared_DEPRECATED/components/Chart/Dot';

type MomentumChartPropsType = {
  data: Array<{ date: string; score?: number }>;
  height?: number;
  width?: number;
};

export const MomentumChart = ({
  data,
  height = 60,
  width = 125,
}: MomentumChartPropsType) => (
  <Box dataTestid="momentum-small-chart">
    <LineChart width={width} height={height} data={data}>
      <CartesianGrid strokeDasharray="3 3" horizontal={false} />
      <ReferenceDot
        x={data.length - 1}
        y={data.at(-1)?.score}
        r={3}
        fill="var(--fgPrimary)"
        shape={ChartDot}
      />
      <Line
        dot={false}
        type="monotone"
        dataKey="score"
        stroke="var(--fgPrimary)"
        fill="var(--fgPrimary)"
        strokeWidth={2}
        strokeLinecap="round"
        isAnimationActive={false}
      />
    </LineChart>
  </Box>
);
