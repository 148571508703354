import { MomentumSidebarBody } from 'features/momentum/components/Sidebar/Body';
import { MomentumSidebarHeader } from 'features/momentum/components/Sidebar/Header';
import { MomentumFeedProvider } from 'features/momentum/context/feed';

export const MomentumSidebar = () => (
  <MomentumFeedProvider>
    <MomentumSidebarHeader />
    <MomentumSidebarBody />
  </MomentumFeedProvider>
);
