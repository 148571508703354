import { EMPTY_CELL_VALUE } from 'shared_DEPRECATED/config/table';

import { Typography } from 'shared/components/Typography';

type ParticipantsTableEmptyCellProps = {
  dataTestid?: string | null;
};

export const ParticipantsTableEmptyCell = ({
  dataTestid,
}: ParticipantsTableEmptyCellProps) => {
  return (
    <Typography
      fontWeight="regular"
      type="small"
      color="black"
      data-testid={dataTestid}
    >
      {EMPTY_CELL_VALUE}
    </Typography>
  );
};
