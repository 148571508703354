import { useCallback } from 'react';

import { GoalPrioritizeModal } from 'features/goal/components/PrioritizeModal';

import { modal, SIZES } from 'shared/components/Modal';

export const useOpenPrioritizedModal = () =>
  useCallback(() => {
    modal.open({
      content: <GoalPrioritizeModal />,
      props: {
        size: SIZES.MD,
      },
    });
  }, []);
