import { TChartAxisTick } from 'shared_DEPRECATED/components/Chart/types';
import { dateUtils } from 'shared_DEPRECATED/utils';

export const MomentumFeedXAxisTick = ({ x, y, payload }: TChartAxisTick) => {
  const date = dateUtils(payload.value);
  const currentWeekDayIndex = date.get('day') - 1;
  const currentWeekDay =
    dateUtils.weekDays('weekdaysMin')[
      currentWeekDayIndex >= 0 ? currentWeekDayIndex : 6
    ];

  return (
    <g>
      <g
        style={{
          fontSize: 'var(--font-size-small)',
          fill: 'var(--fgSecondary)',
        }}
      >
        <text x={x} y={y} dy={10} textAnchor="middle">
          {currentWeekDay}
        </text>
      </g>
      <g
        style={{
          fontSize: 'var(--font-size-small)',
          fill: 'var(--fgPrimary)',
        }}
      >
        <text x={x} y={y + 16} dy={10} textAnchor="middle">
          {date.format('D')}
        </text>
      </g>
    </g>
  );
};
