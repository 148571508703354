import PropTypes from 'prop-types';
import { useState } from 'react';
import TruncateMarkup from 'react-truncate-markup';

import Button from 'shared_DEPRECATED/components/Button';
import { RawHtml } from 'shared_DEPRECATED/components/RawHtml';

import { Typography } from 'shared/components/Typography';

//TODO: create TextTruncate shared component 1204004602812536

export const ChallengeNotesCardText = ({ children }) => {
  const [isTruncated, setIsTruncated] = useState(true);
  const ReadMoreEllipsis = (
    <>
      ...
      <br />
      <Button onClick={() => setIsTruncated(false)}>
        <Typography
          color="blue"
          dataTestid="user-challenge-note-text-view-more"
        >
          View more
        </Typography>
      </Button>
    </>
  );

  return (
    <Typography
      type="small"
      color="black"
      whiteSpace="pre-wrap"
      overflowWrap="break-word"
      dataTestid="user-challenge-note-text"
    >
      {isTruncated ? (
        <TruncateMarkup lines={1} ellipsis={ReadMoreEllipsis}>
          <RawHtml htmlString={children} />
        </TruncateMarkup>
      ) : (
        <>
          <RawHtml htmlString={children} />
          <br />
          <Button onClick={() => setIsTruncated(true)}>
            <Typography
              color="blue"
              dataTestid="user-challenge-note-text-view-less"
            >
              View less
            </Typography>
          </Button>
        </>
      )}
    </Typography>
  );
};

ChallengeNotesCardText.propTypes = {
  children: PropTypes.node.isRequired,
};
