import { useFieldArray, useFormContext } from 'react-hook-form';

import { Flex } from 'shared';
import { VARIANTS } from 'shared_DEPRECATED/components/Button';
import Button from 'shared_DEPRECATED/components/Button/Button';
import { Icon, iconNames } from 'shared_DEPRECATED/components/Icon';

import {
  SessionSidebarFieldset,
  SessionSidebarFieldsetLabel,
} from 'features/sessions/components';
import { NextSessionCoachingToolsCard } from 'features/sessions/components/NextSessionForm/CoachingToolsCard';
import { defaultCoachingTool } from 'features/sessions/config';

export const NextSessionCoachingToolsSection = () => {
  const { getValues } = useFormContext();
  const fieldKey = getValues('nextSessionFieldKey') || '';

  const {
    fields: coachingTools,
    append,
    remove,
    update,
  } = useFieldArray({
    name: `${fieldKey}.coachingTools`,
  });

  const handleCoachingToolsDelete = (index: number) => {
    if (coachingTools.length === 1) {
      update(index, defaultCoachingTool);
    } else {
      remove(index);
    }
  };

  return (
    <SessionSidebarFieldset>
      <Flex w="100%" justifyContent="space-between">
        <SessionSidebarFieldsetLabel>
          Next session coaching tools
        </SessionSidebarFieldsetLabel>
        <Button
          variant={VARIANTS.TERTIARY}
          onClick={() => append(defaultCoachingTool)}
        >
          <Icon name={iconNames.plus} />
        </Button>
      </Flex>
      {coachingTools.map((coachingTool, index) => (
        <NextSessionCoachingToolsCard
          key={coachingTool.id}
          onDelete={() => handleCoachingToolsDelete(index)}
          formKey={`${fieldKey}.coachingTools.${index}`}
          canBeDeleted={coachingTools.length > 1}
        />
      ))}
    </SessionSidebarFieldset>
  );
};
