import {
  Drawer,
  DrawerContent,
  DrawerOverlay,
  useDisclosure,
} from '@chakra-ui/react';
import { useCallback, useMemo, useRef, useState } from 'react';

import Box from 'shared_DEPRECATED/components/Box';
import { useOnClickOutside } from 'shared_DEPRECATED/hooks';
import useEventsSubscription from 'shared_DEPRECATED/hooks/useEventsSubscription';
import { isElementHasParent } from 'shared_DEPRECATED/utils';

import { SidebarProvider } from 'shared/components/Sidebar/context';
import {
  SIDEBAR_CLOSE_EVENT,
  SIDEBAR_OPEN_EVENT,
  SIDEBAR_SET_CONTEXT_EVENT,
} from 'shared/components/Sidebar/eventPublishers';

const initialContent = null;
const initialProps = { title: '' };
const initialContext = { isSidebarInited: false };

const SidebarContainer = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const sidebarRef = useRef(null);
  const [content, setContent] = useState(initialContent);
  const [context, setContext] = useState(initialContext);
  const [sidebarProps, setSidebarProps] = useState(initialProps);

  const onSidebarOpen = useCallback(
    (event) => {
      const data = event.detail;
      const { content, props: newProps, context: newContext } = data;

      setContent(content);
      setSidebarProps({ ...initialProps, ...newProps });
      setContext({ ...initialContext, ...newContext });
      onOpen();
    },
    [onOpen]
  );

  const onSidebarClose = useCallback(
    (event) => {
      const isDialogParentOfTarget = isElementHasParent(event.target, 'DIALOG');

      if (!content || isDialogParentOfTarget) {
        return;
      }

      setContent(initialContent);
      setSidebarProps(initialProps);
      setContext(initialContext);
      onClose();
    },
    [content, onClose]
  );

  const onContextSet = useCallback(
    (event) => {
      const data = event.detail;

      setContext((prevContext) => ({ ...prevContext, ...data }));
    },
    [setContext]
  );

  const onSidebarInit = useCallback(() => {
    setContext((prevContext) => ({ ...prevContext, isSidebarInited: true }));
  }, [setContext]);

  const events = useMemo(
    () => [
      [SIDEBAR_OPEN_EVENT, onSidebarOpen],
      [SIDEBAR_CLOSE_EVENT, onSidebarClose],
      [SIDEBAR_SET_CONTEXT_EVENT, onContextSet],
    ],
    [onSidebarOpen, onSidebarClose, onContextSet]
  );

  useEventsSubscription(events, onSidebarInit);

  useOnClickOutside(sidebarRef, onClose);

  return (
    <Box title={sidebarProps.title}>
      <Drawer
        isOpen={isOpen}
        placement="right"
        onClose={onClose}
        finalFocusRef={sidebarRef}
        size="menu"
      >
        <SidebarProvider {...context}>
          <DrawerOverlay />
          <DrawerContent>{content}</DrawerContent>
        </SidebarProvider>
      </Drawer>
    </Box>
  );
};

export default SidebarContainer;
