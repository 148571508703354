import {
  FloatingTooltip as Tooltip,
  PLACEMENTS,
} from 'shared_DEPRECATED/components/Floating';
import { Icon } from 'shared_DEPRECATED/components/Icon';

import { Typography } from 'shared/components/Typography';

export type TChallengeInfoPrivacyProps = {
  isPrivate?: boolean;
};

export const ChallengeInfoPrivacy = ({
  isPrivate = false,
}: TChallengeInfoPrivacyProps) =>
  isPrivate ? (
    <Tooltip
      anchor={<Icon name="visibility" width={12} height={12} />}
      offsetValue={8}
      placement={PLACEMENTS.TOP}
    >
      <Typography color="white" fontWeight="medium" type="small">
        This challenge is private
      </Typography>
    </Tooltip>
  ) : null;
