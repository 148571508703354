import { useFormContext } from 'react-hook-form';

import { FormSaveIndicator } from 'shared_DEPRECATED/components/Form/SaveIndicator';

import { useGoalQuery } from 'features/goal';

export const GoalDeepDiveSaveIndicator = () => {
  const {
    formState: { isSubmitting, isDirty, errors },
  } = useFormContext();

  const { data: goal, isLoading } = useGoalQuery();

  if (isLoading) {
    return null;
  }

  const savedAt = goal?.deepDive?.updatedAt;

  return (
    <FormSaveIndicator
      isSubmitting={isSubmitting}
      isDirty={isDirty}
      savedAt={savedAt}
      error={!!errors?.root?.saveError}
    />
  );
};
