import { Flex } from 'shared_DEPRECATED/components/Flex';
import Spacer from 'shared_DEPRECATED/components/Spacer';

import {
  COLOR_BY_SESSION_ATTENDANCE_STATUS,
  LATE_STATUS_TIME_BY_COACHING_MODE,
  SESSION_ATTENDANCE_LABEL,
} from 'features/sessions/config';
import {
  SessionAttendanceStatusType,
  SessionsTableRowDataType,
} from 'features/sessions/config/types';

import { BADGE_COLORS, Badge } from 'shared/components/Badge';
import { Typography } from 'shared/components/Typography';

type SessionsTableAttendanceCellProps = {
  value: SessionAttendanceStatusType;
  row: SessionsTableRowDataType;
};

export const SessionsTableAttendanceCell = ({
  value: attendanceStatus,
  row,
}: SessionsTableAttendanceCellProps) => {
  return (
    <Flex flexDirection="column" alignItems="flex-start">
      <Badge
        bgColor={
          COLOR_BY_SESSION_ATTENDANCE_STATUS[attendanceStatus] as BADGE_COLORS
        }
      >
        <Typography
          type="small"
          color="whiteTotal"
          textTransform="uppercase"
          dataTestid="session-attendance-cell"
        >
          {SESSION_ATTENDANCE_LABEL[attendanceStatus]}
        </Typography>
      </Badge>
      {row.original.isLate && (
        <Spacer size="md zr zr">
          <Typography
            color="gray"
            type="small"
            textTransform="uppercase"
            dataTestid="session-late-cell"
          >
            Late {LATE_STATUS_TIME_BY_COACHING_MODE[row.original.coachingMode]}+
            min
          </Typography>
        </Spacer>
      )}
    </Flex>
  );
};
