import PropTypes from 'prop-types';

import classNames from 'classnames/bind';
import { ButtonIcon } from 'shared_DEPRECATED/components/Button';
import { FloatingPopover } from 'shared_DEPRECATED/components/Floating';
import { useOptionsMenuContext } from 'shared_DEPRECATED/components/Floating/Menu/Options';
import { iconNames } from 'shared_DEPRECATED/components/Icon';

import styles from 'shared_DEPRECATED/components/Floating/Menu/Options/OptionsMenu.module.css';

const cx = classNames.bind(styles);

const OptionsMenuFloatingPopoverPropTypes = {
  children: PropTypes.node,
  offsetTop: PropTypes.number,
  icon: PropTypes.string,
  disabled: PropTypes.bool,
  dataTestid: PropTypes.string,
};

const OptionsMenuFloatingPopover = ({
  offsetTop,
  icon = iconNames.ellipsis,
  disabled = false,
  dataTestid,
  children,
}) => {
  const [open, toggleOpen] = useOptionsMenuContext();

  const handleToggleOpen = (e) => {
    e.preventDefault();

    toggleOpen();
  };

  return (
    <FloatingPopover
      offsetTop={offsetTop}
      anchor={
        <ButtonIcon
          className={cx({ 'menu-btn--active': open })}
          icon={iconNames[icon]}
          disabled={disabled}
          onClick={handleToggleOpen}
          dataTestid={dataTestid}
        />
      }
      isOpen={open}
      onClose={toggleOpen}
    >
      {children}
    </FloatingPopover>
  );
};

OptionsMenuFloatingPopover.propTypes = OptionsMenuFloatingPopoverPropTypes;

export default OptionsMenuFloatingPopover;
