import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import { TextEditorPlainClipboard } from 'shared_DEPRECATED/components/TextEditor/PlainClipboard';
import { customKeyboardBindings } from 'shared_DEPRECATED/components/TextEditor/config';
import { useEditorContext } from 'shared_DEPRECATED/components/TextEditor/context';

import './stylesOverride.css';

Quill.register('modules/clipboard', TextEditorPlainClipboard, true);

type TextEditorProps = {
  value: string;
  setValue: (v: string) => void;
  id: string;
};

export const TextEditor = ({ value, setValue, id }: TextEditorProps) => {
  const { editorRef } = useEditorContext();

  return (
    <ReactQuill
      className="data-hj-suppress"
      ref={editorRef}
      placeholder="Type here"
      modules={{
        toolbar: { container: `#${id}` },
        clipboard: {
          //fix issue with extra spacing between p and ol https://github.com/quilljs/quill/blob/1.3.6/docs/docs/modules/clipboard.md#matchvisual
          matchVisual: false,
        },
        keyboard: {
          //this and getHtmlStringWithNestedLists util fix issue with nested lists https://github.com/quilljs/quill/issues/979
          bindings: customKeyboardBindings,
        },
      }}
      defaultValue={value}
      onChange={setValue}
    />
  );
};
