import {
  BYTES_IN_KILOBYTE,
  CONTENT_TYPE_CATEGORIES,
  CONTENT_TYPE_CATEGORIES_ICONS_CONFIG,
  CONTENT_TYPES_ICON_CONFIG,
  FILE_UNIT_MEASURES,
} from 'shared_DEPRECATED/components/File/config';

export const getFileIconConfig = (contentType) => {
  return (
    CONTENT_TYPES_ICON_CONFIG[contentType] ||
    CONTENT_TYPE_CATEGORIES_ICONS_CONFIG.OTHER
  );
};

export const getFileCategory = (contentType, fileName) => {
  return CONTENT_TYPE_CATEGORIES[contentType] || fileName?.split('.').at(-1);
};

export const getFormattedFileSize = (sizeInBytes, fraction = 0) => {
  const roundedSizeInBytes = Math.ceil(sizeInBytes);

  const unitMeasureIndex =
    roundedSizeInBytes > 0
      ? Math.floor(Math.log(roundedSizeInBytes) / Math.log(BYTES_IN_KILOBYTE))
      : 0;

  if (unitMeasureIndex === 0) {
    return `${roundedSizeInBytes} ${FILE_UNIT_MEASURES[unitMeasureIndex]}`;
  }

  const convertedSize = (
    roundedSizeInBytes /
    BYTES_IN_KILOBYTE ** unitMeasureIndex
  ).toFixed(fraction);

  return `${convertedSize} ${FILE_UNIT_MEASURES[unitMeasureIndex]}`;
};

export const isImage = (contentType) => contentType.includes('image');

export const isVideo = (contentType) =>
  contentType.includes('video') ||
  contentType === 'application/vnd.apple.mpegurl';

export const isAudio = (contentType) => contentType.includes('audio');
