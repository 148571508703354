import { useNavigate } from 'react-router-dom';

import {
  ASSESSMENT_NOTIFICATION_TYPES,
  CHALLENGE_NOTE_NOTIFICATION_TYPES,
  NO_REDIRECT_NOTIFICATION_TYPES,
  notificationStatus,
  SPRINT_NOTIFICATION_TYPES,
} from 'app/notifications/config';
import {
  useNotificationStatusMutation,
  useNoteRepliesSidebar,
} from 'app/notifications/hooks';
import { sidebar } from 'shared';
import { getDashboardPageUrl } from 'utils';

import { getParticipantSprintPageUrl } from 'features/sprint/utils';

export const useNotificationCallback = (notification) => {
  const navigate = useNavigate();
  const mutation = useNotificationStatusMutation(notification.notificationId);

  const openNoteRepliesSidebar = useNoteRepliesSidebar(notification);

  const handleNotificationClick = (evt) => {
    evt.preventDefault();

    if (notification.status === notificationStatus.unread) {
      mutation.mutate();
    }
    sidebar.close();

    if (NO_REDIRECT_NOTIFICATION_TYPES.has(notification.type)) {
      return;
    }

    if (CHALLENGE_NOTE_NOTIFICATION_TYPES.has(notification.type)) {
      openNoteRepliesSidebar();

      return;
    }

    if (SPRINT_NOTIFICATION_TYPES.has(notification.type)) {
      navigate(
        getParticipantSprintPageUrl({
          email: notification.sharerEmail,
          sprintId: notification.sprintId,
        })
      );

      return;
    }

    if (ASSESSMENT_NOTIFICATION_TYPES.has(notification.type)) {
      navigate(
        getDashboardPageUrl(
          notification.clientEmail || notification.sharerEmail
        )
      );
    }
  };

  return handleNotificationClick;
};
