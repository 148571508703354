import PropTypes from 'prop-types';
import { forwardRef } from 'react';

import Box from 'shared_DEPRECATED/components/Box';
import { Flex } from 'shared_DEPRECATED/components/Flex';
import Spacer from 'shared_DEPRECATED/components/Spacer';

import { Typography } from 'shared/components/Typography';

const RadioButtonPropTypes = {
  /**
   * Boolean to indicate if the radio button is checked.
   * @type {boolean}
   * @memberof RadioButtonPropTypes
   * @name checked
   * @required
   * @example <RadioButton checked={true} />
   * @example <RadioButton checked={false} />
   */
  checked: PropTypes.bool.isRequired,
  /**
   * Label to render inside the radio button.
   * @type {string}
   * @memberof RadioButtonPropTypes
   * @name label
   * @required
   * @example <RadioButton label="Label" />
   */
  label: PropTypes.string.isRequired,
  /**
   * Radio button change event handler.
   * @type {function}
   * @required
   * @name onChange
   * @example <RadioButton onChange={() => {}}>Radio</RadioButton>
   * @see {@link https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input|MDN}
   */
  onChange: PropTypes.func.isRequired,
  dataTestid: PropTypes.string,
};

const RadioButton = forwardRef(
  ({ label, checked, onChange, dataTestid = null, ...props }, ref) => (
    <Box
      as="label"
      style={{
        cursor: 'pointer',
      }}
    >
      <input
        ref={ref}
        hidden={true}
        type="radio"
        checked={checked}
        onChange={onChange}
        data-testid={dataTestid}
        {...props}
      />
      <Flex>
        <Box
          style={{
            width: '1.25rem',
            height: '1.25rem',
            borderRadius: '50%',
            boxSizing: 'border-box',
            border: checked
              ? '6px solid var(--brdAccent)'
              : '1px solid var(--fgPrimary)',
          }}
        />
        <Spacer size="zr md zr zr" />
        <Typography type="meta" fontWeight="regular" color="black">
          {label}
        </Typography>
      </Flex>
    </Box>
  )
);

RadioButton.propTypes = RadioButtonPropTypes;

export default RadioButton;
