import PropTypes from 'prop-types';
import { useReducer } from 'react';

import classNames from 'classnames/bind';
import Button from 'shared_DEPRECATED/components/Button';
import { Flex } from 'shared_DEPRECATED/components/Flex';
import { Icon, iconNames } from 'shared_DEPRECATED/components/Icon';
import Spacer from 'shared_DEPRECATED/components/Spacer';

import styles from './Filter.module.css';

const cx = classNames.bind(styles);

const FilterProps = {
  isActive: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

export const Filter = ({ isActive = false, children }) => {
  const [isOpen, setIsOpen] = useReducer((state) => !state, false);

  const btnClassNames = cx({
    filter__btn: true,
    'filter__btn--active': isActive,
    'filter__btn--open': isOpen,
  });

  return (
    <div className={styles['filter']}>
      <Button
        dataTestid="filter-btn"
        className={btnClassNames}
        onClick={setIsOpen}
      >
        <Spacer size="md">
          <Flex>
            <Icon name={iconNames.filter} />
          </Flex>
        </Spacer>
      </Button>
      {isOpen && <div className={styles.filter__list}>{children}</div>}
    </div>
  );
};

Filter.propTypes = FilterProps;
