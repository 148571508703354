import { useCallback, useMemo, useState } from 'react';

import useDebounce from './useDebounce';

const filterCollection = (collection, predicate, query) => {
  if (Array.isArray(collection)) {
    return query
      ? collection.filter((item) => predicate(item, query))
      : collection;
  }

  if (typeof collection === 'object') {
    return query
      ? Object.keys(collection).reduce((acc, key) => {
          const filteredItems = collection[key].filter((item) =>
            predicate(item, query)
          );

          return filteredItems.length > 0
            ? { ...acc, [key]: filteredItems }
            : acc;
        }, {})
      : collection;
  }

  return collection;
};

const useSearch = (
  collection,
  predicate,
  { debounce = 0, initialQuery = '' } = {}
) => {
  const [query, setQuery] = useState(initialQuery);

  const debouncedQuery = useDebounce(query, debounce);

  const handleSearchChange = useCallback(
    (event) => {
      setQuery(typeof event === 'string' ? event : event.target.value);
    },
    [setQuery]
  );

  return useMemo(
    () => ({
      handleSearchChange,
      filteredCollection: filterCollection(
        collection,
        predicate,
        debouncedQuery
      ),
      query,
      setQuery,
    }),
    [handleSearchChange, collection, debouncedQuery, predicate, query, setQuery]
  );
};

export default useSearch;
