import { Flex } from 'shared_DEPRECATED/components/Flex';
import Spacer from 'shared_DEPRECATED/components/Spacer';

import { CoachSessionsCreateButton } from 'features/sessions/components';

import { Typography } from 'shared/components/Typography';

export const SessionsEmptyState = () => (
  <Flex
    flexDirection="column"
    gap="var(--spacing02)"
    height="100%"
    justifyContent="center"
  >
    <Typography>🧐</Typography>
    <Typography
      type="heading"
      fontWeight="semiBold"
      color="black"
      dataTestid="empty-session-list"
    >
      No sessions yet
    </Typography>
    <Typography type="meta" fontWeight="regular" color="gray">
      Start by adding a new session.
    </Typography>
    <Spacer size="lg zr zr">
      <CoachSessionsCreateButton />
    </Spacer>
  </Flex>
);
