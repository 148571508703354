import { toastConfig, toastErrorConfig } from 'lib/reactToastify/config';
import { useFormContext, useWatch } from 'react-hook-form';
import { toast, ToastOptions } from 'react-toastify';

import { logError } from 'lib/sentry/logError';

import {
  useSessionAnalyticsEvents,
  useSessionRecapMutation,
} from 'features/sessions/hooks';

export const useSessionRecapSend = () => {
  const { getValues, setValue } = useFormContext();

  const recapData = useWatch({ name: 'recap' });
  const { attachments = [], content } = recapData;

  const mutation = useSessionRecapMutation(getValues('coachingSessionId'));
  const { sendSessionRecapFinishEvent } = useSessionAnalyticsEvents();

  const onRecapSend = async () => {
    const recapPostData = {
      ...recapData,
      content: content,
      submittedAt: null,
    };

    try {
      const recap = await mutation.mutateAsync(recapPostData);
      sendSessionRecapFinishEvent({
        hasAttachments: !!attachments.length,
      });
      setValue('recap', recap);

      toast.success('Session recap was successfully sent', {
        icon: false,
        ...toastConfig,
      } as ToastOptions);
    } catch (e) {
      toast.error(
        "There's an error with sending recap. Please try again later",
        toastErrorConfig as ToastOptions
      );
      logError(e);
    }
  };

  return { onRecapSend, isLoading: mutation.isLoading };
};
