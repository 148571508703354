import { ReactNode } from 'react';

interface ToolbarBaseButtonProps {
  type: string;
  value?: string;
  children?: ReactNode;
  onClick?: () => void;
}

export const TextEditorToolbarBaseButton = ({
  type,
  children,
  ...props
}: ToolbarBaseButtonProps) => (
  <button type="button" className={`ql-${type}`} {...props}>
    {children}
  </button>
);
