import { MouseEvent, useCallback } from 'react';

import { GoalCardType, PrioritizeGoalCallback } from 'features/goal/types';

import { GoalPrioritizeToggleButton } from './PrioritizeToggle/Button';

export type PrioritizeGoalToggleProps = {
  goalId: GoalCardType['goalId'];
  columnId?: GoalCardType['columnId'];
  isPrioritized: boolean;
  size?: 'xs' | 'md';
  togglePrioritize: PrioritizeGoalCallback;
};

export const GoalPrioritizeToggle = ({
  size,
  goalId,
  isPrioritized,
  columnId,
  togglePrioritize,
}: PrioritizeGoalToggleProps) => {
  const handlePrioritizeClick = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      togglePrioritize(goalId, isPrioritized, columnId);
    },
    [togglePrioritize, columnId, goalId, isPrioritized]
  );

  return (
    <GoalPrioritizeToggleButton
      dataTestid="board-prioritized-goal"
      size={size}
      onClick={handlePrioritizeClick}
      isPrioritized={isPrioritized}
    />
  );
};
