import { Avatar } from 'shared_DEPRECATED/components/Avatar';
import { Flex } from 'shared_DEPRECATED/components/Flex';
import { HotjarSuppression } from 'shared_DEPRECATED/components/Hotjar';
import Spacer from 'shared_DEPRECATED/components/Spacer';

import { UserInfoName, UserInfoSubtitle } from 'features/user/components/Info/';
import { userInfo } from 'features/user/config/defaults';
import { UserInfoWithSubtitle as UserInfoWithSubtitleType } from 'features/user/config/types';

type UserInfoWithTitleProps = UserInfoWithSubtitleType;

export const UserInfoWithSubtitle = ({
  pictureUrl,
  name,
  subtitle,
  pictureSize = userInfo.pictureSize,
  maxWidth = userInfo.maxWidth,
}: UserInfoWithTitleProps) => (
  <HotjarSuppression>
    <Flex as="span" alignItems="center" justifyContent="flex-start">
      <Avatar name={name} size={pictureSize} src={pictureUrl} />
      <Spacer as="span" size="zr zr zr md">
        <Spacer size="zr zr xsm zr">
          <UserInfoName maxWidth={maxWidth}>{name}</UserInfoName>
        </Spacer>
        {subtitle !== name ? (
          <UserInfoSubtitle maxWidth={maxWidth}>{subtitle}</UserInfoSubtitle>
        ) : null}
      </Spacer>
    </Flex>
  </HotjarSuppression>
);
