import { Flex } from 'shared_DEPRECATED/components/Flex';
import Spacer from 'shared_DEPRECATED/components/Spacer';

import { ChallengeProofIcon } from 'features/challenge/components/ProofIcon';

import { Typography } from 'shared/components/Typography';

type ChallengeNotesCardTitlePropTypes = {
  title: string;
};

export const ChallengeNotesProofCardTitle = ({
  title,
}: ChallengeNotesCardTitlePropTypes) => (
  <Spacer size="md zr">
    <Flex justifyContent="flex-start">
      <ChallengeProofIcon />
      <Spacer size="zr zr zr md">
        <Typography
          type="small"
          fontWeight="semiBold"
          color="black"
          dataTestid="user-challenge-note-challenge-title"
        >
          {title}
        </Typography>
      </Spacer>
    </Flex>
  </Spacer>
);
