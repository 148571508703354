import { firstLetterUpperCase } from 'shared_DEPRECATED/utils';

export const getDimensionsText = (
  dimensions: string[],
  visibleDimensionsCount?: number
) => {
  dimensions = dimensions.map((dimension) => firstLetterUpperCase(dimension));

  if (!visibleDimensionsCount) {
    return dimensions.join(' • ');
  }
  const visibleDimensions = dimensions.slice(0, visibleDimensionsCount);

  let resultString = visibleDimensions.join(' • ');

  if (visibleDimensionsCount && dimensions.length > visibleDimensionsCount) {
    resultString += ` • ${dimensions.length - visibleDimensionsCount} more`;
  }

  return resultString;
};
