import { toastConfig } from 'lib/reactToastify/config';
import { toast } from 'react-toastify';

import { logError } from 'lib/sentry/logError';

import {
  GOAL_DEEP_DIVE_DEFAULT_VALUE,
  useEditGoalMutation,
  useOpenAddGoalModal,
  GoalCardType,
  IGoal,
} from 'features/goal';

export const useEditGoal = ({
  goalId,
  columnId,
  name,
  dimensions,
  deadline,
  deepDive,
  columnLabel,
  completedAt,
  note,
}: Omit<GoalCardType, 'prioritizedAt'>) => {
  const { mutateAsync } = useEditGoalMutation({
    goalId,
    modifiedColumnId: columnId,
  });

  const onSubmit = async (goal: IGoal) => {
    try {
      await mutateAsync(goal);
    } catch (err) {
      toast.error(
        "There's an error with editing the goal. Please try again later.",
        toastConfig
      );
      logError(err);
    }
  };

  const openEditGoalModal = useOpenAddGoalModal('Edit the goal');

  return {
    onEditGoalClick: () =>
      openEditGoalModal({
        onSubmit,
        defaultValues: {
          goalId,
          columnId,
          columnLabel,
          name,
          dimensions,
          deadline,
          deepDive: deepDive || GOAL_DEEP_DIVE_DEFAULT_VALUE,
          completedAt,
          note,
          cover: null,
          deepDiveAvailable: true,
          prioritizedAt: null,
        },
      }),
  };
};
