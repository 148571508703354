import { Flex, FlexItem } from 'shared_DEPRECATED/components/Flex';
import {
  ReactionsButton,
  ReactionsExpandButton,
} from 'shared_DEPRECATED/components/Reactions';

import { Spacers } from '../Spacer';

type ReactionsCommonPropTypes = {
  emojis: string[];
  onClick: (emoji: string) => void;
  toggleAll: () => void;
};

export const ReactionsCommon = ({
  emojis,
  onClick,
  toggleAll,
}: ReactionsCommonPropTypes) => {
  return (
    <Flex flexWrap="wrap" width="250px" justifyContent="flex-start">
      {emojis.map((emoji) => (
        <FlexItem flexShrink={1} key={emoji}>
          <ReactionsButton
            onClick={() => {
              onClick(emoji);
            }}
            dataTestid="reaction-button"
          >
            {emoji}
          </ReactionsButton>
        </FlexItem>
      ))}
      <Spacers sizes={['zr sm', 'zr xsm zr zr']} />
      <ReactionsExpandButton
        onClick={() => {
          toggleAll();
        }}
      />
    </Flex>
  );
};
