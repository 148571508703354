import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { useFormAutosave } from 'shared_DEPRECATED/hooks';

import {
  GOAL_DEEP_DIVE_DEFAULT_VALUE,
  useEditGoalMutation,
  IGoal,
} from 'features/goal';

type TGoalDeepDiveFormWrapper = {
  goal: IGoal;
  children: React.ReactNode;
};

export const GoalDeepDiveFormWrapper = ({
  children,
  goal,
}: TGoalDeepDiveFormWrapper) => {
  const formMethods = useForm<IGoal>({
    defaultValues: {
      ...goal,
      deepDive: goal.deepDive || GOAL_DEEP_DIVE_DEFAULT_VALUE,
    },
  });

  const { mutateAsync } = useEditGoalMutation({
    goalId: goal.goalId,
    modifiedColumnId: goal.columnId,
  });

  useFormAutosave({
    formMethods,
    onMutate: mutateAsync,
    storageKey: `goalId-${goal.goalId}`,
  });

  return <FormProvider {...formMethods}>{children}</FormProvider>;
};
