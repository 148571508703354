import { Flex } from 'shared_DEPRECATED/components/Flex';
import { Icon, iconNames } from 'shared_DEPRECATED/components/Icon';
import Spacer from 'shared_DEPRECATED/components/Spacer';

import { Typography } from 'shared/components/Typography';

export const FormSaveErrorIndicator = () => (
  <Flex justifyContent="flex-start" alignItems="center">
    <Spacer size="zr sm zr zr">
      <Icon name={iconNames.error} />
    </Spacer>
    <Typography type="small" color="red">
      Changes not saved...
    </Typography>
  </Flex>
);
