import { useParams } from 'react-router-dom';

import { USER_TYPES } from 'shared_DEPRECATED/types/User';
import { routeNames } from 'shared_DEPRECATED/utils/config';

import { useUserMode } from 'features/user/hooks';

export const useGoalsNavigationLink = () => {
  const { email } = useParams();
  const userMode = useUserMode();

  const userSpecificRoute =
    userMode === USER_TYPES.USER ? '' : `/coach/participants/${email}`;

  return `${userSpecificRoute}/${routeNames.GOALS}`;
};
