import { toastErrorConfig } from 'lib/reactToastify/config';
import { useMutation, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { queryKeys } from 'api/config';
import { logError } from 'lib/sentry/logError';
import { useMutationHTTPRequest } from 'shared_DEPRECATED/hooks';

import { useGoalBreakdownDataContext } from 'features/goal/context/goalBreakdown';
import { GoalBreakdownType } from 'features/goal/types';

export const useMoveGoalBreakdownChallengesMutation__NEW = () => {
  const { goalId } = useParams();
  const queryClient = useQueryClient();
  const { request } = useMutationHTTPRequest();
  const { setGoalBreakdownData, goalBreakdownData } =
    useGoalBreakdownDataContext();

  return useMutation(
    ({
      customChallengeId,
      index,
      focusAreaId,
    }: {
      customChallengeId: string;
      index: number;
      focusAreaId?: string;
    }) =>
      request({
        url: `/api/web/goals/${goalId}/breakdown/challenges/move`,
        body: { challengeId: customChallengeId, focusAreaId, index },
      }),
    {
      onMutate: async () => {
        await queryClient.cancelQueries({
          queryKey: [queryKeys.goalBreakdown, goalId],
        });

        const previousValue = queryClient.getQueryData<GoalBreakdownType>([
          queryKeys.goalBreakdown,
          goalId,
        ]);

        return { previousValue };
      },
      onError: (err, _, context) => {
        if (context) {
          queryClient.setQueryData(
            [queryKeys.goalBreakdown, goalId],
            context.previousValue
          );

          setGoalBreakdownData(context.previousValue);
        }

        logError(err);
        toast.error('Failed to move challenge', toastErrorConfig);
      },
      onSuccess: () => {
        queryClient.setQueryData(
          [queryKeys.goalBreakdown, goalId],
          goalBreakdownData
        );
      },
    }
  );
};
