import React from 'react';

import { Flex } from 'shared_DEPRECATED/components/Flex';

import {
  GoalFocusArea,
  GoalBreakdownDropZone,
  GoalBreakdownBoardChallenges,
  useMoveGoalFocusAreaMutation,
  GoalBreakdownChallengeType,
  FocusAreaType,
  canFocusAreaBeDroppedInDropZone,
  canItemBeDroppedInDropZone,
  GoalFocusAreaDropZoneWrapper,
} from 'features/goal';

import { BoardColumnItem } from 'shared/components/Board';

const handleCanDrop =
  (focusAreaId: string) =>
  (challengeIndex: number) =>
  (item: GoalBreakdownChallengeType) =>
    canItemBeDroppedInDropZone({
      parentId: item.parentFocusAreaId,
      itemId: focusAreaId,
      index: item.index,
      dropZoneIndex: challengeIndex,
    });

type TGoalBreakdownBoardWrapper = {
  focusAreas: FocusAreaType[];
};

export const GoalBreakdownBoardFocusAreas = ({
  focusAreas,
}: TGoalBreakdownBoardWrapper) => {
  const { mutateAsync } = useMoveGoalFocusAreaMutation();

  return (
    <Flex flexDirection="column" width="100%">
      {focusAreas.map((focusArea, focusAreaIndex) => (
        <React.Fragment key={focusArea.id}>
          <GoalBreakdownDropZone
            handleCanDrop={({ index }) =>
              canFocusAreaBeDroppedInDropZone({
                index,
                dropZoneIndex: focusAreaIndex,
              })
            }
            accept={['focus-area']}
            onDrop={mutateAsync}
            dropZoneIndex={focusAreaIndex}
            isLast={false}
          >
            <BoardColumnItem
              item={{ ...focusArea, index: focusAreaIndex }}
              type={'focus-area'}
            >
              <GoalFocusAreaDropZoneWrapper
                isFocusAreaEmpty={!focusArea.challenges.length}
                focusAreaId={focusArea.id}
                focusAreaIndex={focusAreaIndex}
              >
                <GoalFocusArea
                  title={focusArea.title}
                  focusAreaId={focusArea.id}
                  key={focusArea.id}
                >
                  <GoalBreakdownBoardChallenges
                    challenges={focusArea.challenges}
                    canDrop={handleCanDrop(focusArea.id)}
                    focusAreaId={focusArea.id}
                  />
                </GoalFocusArea>
              </GoalFocusAreaDropZoneWrapper>
            </BoardColumnItem>
          </GoalBreakdownDropZone>
        </React.Fragment>
      ))}
      <GoalBreakdownDropZone
        handleCanDrop={({ index }) =>
          canFocusAreaBeDroppedInDropZone({
            index,
            dropZoneIndex: focusAreas.length,
          })
        }
        dropZoneIndex={focusAreas.length}
        onDrop={mutateAsync}
        accept={['focus-area']}
        isLast={false}
      />
    </Flex>
  );
};
