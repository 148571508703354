import { useColorMode } from '@chakra-ui/react';
import PropTypes from 'prop-types';

import { Reactions } from 'shared_DEPRECATED/components/Reactions';
import { DEFAULT_REACTIONS } from 'shared_DEPRECATED/components/Reactions/config';
import { ReactionType } from 'shared_DEPRECATED/config/propTypes';

import { NOTE_STATUS } from 'features/challengeNotes/config';
import { useNoteStatusMutation } from 'features/challengeNotes/hooks';
import { useNoteReactionsMutation } from 'features/challengeNotes/hooks/mutation/useNoteReactions';
import useReactionsData from 'features/challengeNotes/hooks/useReactionsData';

import { useSidebarContext } from 'shared/components/Sidebar/context';

const ChallengeNotesCardReactionsPropTypes = {
  noteStatus: PropTypes.string.isRequired,
  noteType: PropTypes.string.isRequired,
  noteId: PropTypes.string.isRequired,
  reactions: PropTypes.objectOf(PropTypes.arrayOf(ReactionType)),
  userEmail: PropTypes.string.isRequired,
  onMutate: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  onSuccess: PropTypes.func,
};

export const ChallengeNotesCardReactions = ({
  noteStatus,
  noteType,
  noteId,
  reactions,
  userEmail,
  onMutate,
  onError,
  onSuccess,
}) => {
  const { colorMode: currentTheme } = useColorMode();

  const { user: currentUser } = useSidebarContext();
  const preparedReactions = useReactionsData(reactions, currentUser.email);

  const noteStatusMutation = useNoteStatusMutation({
    noteId,
    userEmail,
  });

  const reactionsMutation = useNoteReactionsMutation({
    noteId,
    userEmail,
    onMutate,
    onError,
    onSuccess,
  });

  return (
    <Reactions
      theme={currentTheme}
      reactions={preparedReactions}
      onEmojiSelect={({ emoji, type }) => {
        if (noteStatus === NOTE_STATUS.UNREAD) {
          noteStatusMutation.mutate({
            currentStatus: noteStatus,
            noteType,
          });
        }
        reactionsMutation.mutate({ emoji, type });
      }}
      emojis={DEFAULT_REACTIONS}
      options={{
        perLine: 7,
        skinTonePosition: 'none',
        theme: 'light',
        previewPosition: 'none',
        navPosition: 'none',
        categories: [
          'people',
          'nature',
          'foods',
          'activity',
          'places',
          'objects',
          'symbols',
          'flags',
        ],
      }}
    />
  );
};

ChallengeNotesCardReactions.propTypes = ChallengeNotesCardReactionsPropTypes;
