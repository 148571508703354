import { Box } from '@chakra-ui/react';
import { useFormContext, useWatch } from 'react-hook-form';

import { BasicTextEditor } from 'shared';

import {
  SessionSidebarFieldset,
  SessionSidebarFieldsetLabel,
} from 'features/sessions/components';

export const ParticipantProfileNote = () => {
  const { setValue, getValues } = useFormContext();
  const fieldKey = getValues('nextSessionFieldKey');
  const clientNotes =
    useWatch({ name: `${fieldKey}.coachingClientNote.text` }) || '';

  const handleChange = (value: string) => {
    setValue(`${fieldKey}.coachingClientNote.text`, value, {
      shouldDirty: true,
    });
  };

  return (
    <SessionSidebarFieldset>
      <SessionSidebarFieldsetLabel>
        Participant profile notes
      </SessionSidebarFieldsetLabel>
      <Box h="12rem">
        <BasicTextEditor
          value={clientNotes}
          onChange={handleChange}
          placeholder="Start writing here"
        />
      </Box>
    </SessionSidebarFieldset>
  );
};
