import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';

import { $generateHtmlFromNodes } from '@lexical/html';
import { $isRootTextContentEmpty } from '@lexical/text';
import { $getRoot, EditorState, LexicalEditor } from 'lexical';
import { exportMarkdownFromLexical } from 'lib/lexical-mdx-import-export/lexical-mdx-import-export';
import { logError } from 'lib/sentry/logError';

import { TextEditorFormats } from '../constants';
import { TextEditorFormat } from '../types';

type OnChangePluginProps = {
  onChange: (text: string) => void;
  format: TextEditorFormat;
};

export const TextEditorOnChangePlugin = ({
  onChange,
  format,
}: OnChangePluginProps) => {
  const handleChange = (editorState: EditorState, editor: LexicalEditor) => {
    editor.update(() => {
      try {
        const raw =
          format === TextEditorFormats.markdown
            ? // @ts-ignore
              exportMarkdownFromLexical($getRoot())
            : $generateHtmlFromNodes(editor, null);
        const isEmpty = $isRootTextContentEmpty(false);
        onChange(isEmpty ? '' : raw);
      } catch (error) {
        logError(error);
      }
    });
  };

  return <OnChangePlugin onChange={handleChange} ignoreSelectionChange />;
};
