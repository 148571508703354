import { logError } from 'lib/sentry/logError.js';

export const appendScript = ({
  content = '',
  src,
  id,
  async = true,
}: {
  content?: string;
  src: string;
  id: string;
  async?: boolean;
}): void => {
  if (document.getElementById(id)) {
    return;
  }

  const element = document.createElement('script');
  element.addEventListener('error', (error) => {
    logError(error);
  });

  element.id = id;
  element.async = async;
  element.src = src;
  element.innerText = content;

  document.head.appendChild(element);
};
