import { useParams } from 'react-router-dom';

import ButtonLink from 'shared_DEPRECATED/components/Button/ButtonLink';
import { Flex } from 'shared_DEPRECATED/components/Flex';
import { Icon, iconNames } from 'shared_DEPRECATED/components/Icon';
import Spacer from 'shared_DEPRECATED/components/Spacer';

import { Typography } from 'shared/components/Typography';

export const SessionsBackButton = () => {
  const { email } = useParams();

  return (
    <Spacer size="zr zr lg">
      <ButtonLink
        to={`/coach/participants/${email}/sessions`}
        dataTestid="back-session-btn"
      >
        <Flex justifyContent="flex-start" gap="var(--spacing01)">
          <Icon
            name={iconNames.arrowLeft}
            height={12}
            width={14}
            stroke="var(--fgInteractive)"
          />
          <Typography color="blue" type="small">
            Back
          </Typography>
        </Flex>
      </ButtonLink>
    </Spacer>
  );
};
