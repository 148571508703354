import { ButtonIcon } from 'shared_DEPRECATED/components/Button';
import { Flex } from 'shared_DEPRECATED/components/Flex';
import { iconNames } from 'shared_DEPRECATED/components/Icon';

import { useSessionDeletion } from 'features/sessions/hooks';

type SessionsTableDeleteButtonCellProps = {
  value: string;
};

export const SessionsTableDeleteButtonCell = ({
  value: sessionId,
}: SessionsTableDeleteButtonCellProps) => {
  const { openConfirmationModal } = useSessionDeletion(sessionId);

  return (
    <Flex justifyContent="flex-end" dataTestid="session-list-delete-button">
      <ButtonIcon
        icon={iconNames.bin}
        onClick={(e) => {
          e.stopPropagation();
          openConfirmationModal();
        }}
        dataTestid={`sessions-list-delete-btn-${sessionId}`}
      />
    </Flex>
  );
};
