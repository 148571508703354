import React from 'react';

import LazyFeatureFlagComponent from 'featureFlags/components/LazyComponent';
import { COACH_SEARCH_ENABLED } from 'featureFlags/config';
import lazyRetry from 'shared_DEPRECATED/utils/lazyRetry';

const LazyCoachSession = React.lazy(() =>
  lazyRetry(() => import('app/pages/coach/Search/CoachSearch'), 'CoachSearch')
);

export const CoachSearchPageWithFeatureFlag = () => (
  <LazyFeatureFlagComponent
    featureFlag={COACH_SEARCH_ENABLED}
    lazyComponent={LazyCoachSession}
  />
);
