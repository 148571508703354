import { OptionsMenu } from 'shared_DEPRECATED/components/Floating/Menu/Options';
import { iconNames } from 'shared_DEPRECATED/components/Icon';

import {
  GoalChallengeCardMenuAddButton,
  GoalBreakdownChallengeCardMenuEditButton,
  GoalBreakdownChallengeCardMenuRemoveButton,
  GoalBreakdownChallengeType,
} from 'features/goal';

type TGoalBreakdownChallengeCardMenu = {
  challenge: GoalBreakdownChallengeType;
};

export const GoalBreakdownChallengeCardMenu = ({
  challenge,
}: TGoalBreakdownChallengeCardMenu) => (
  <OptionsMenu
    //@ts-ignore
    icon={iconNames.ellipsisHorizontal}
  >
    <GoalChallengeCardMenuAddButton challenge={challenge} />
    <GoalBreakdownChallengeCardMenuEditButton challenge={challenge} />
    <GoalBreakdownChallengeCardMenuRemoveButton
      challengeId={challenge.customChallengeId}
    />
  </OptionsMenu>
);
