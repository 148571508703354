import PropTypes from 'prop-types';

import Box from 'shared_DEPRECATED/components/Box';

const ChallengeNotesUnreadIndicatorPropTypes = {
  position: PropTypes.oneOf(['left', 'right']),
  visible: PropTypes.bool.isRequired,
};

export const ChallengeNotesUnreadIndicator = ({
  position = 'left',
  visible,
}) => {
  if (!visible) {
    return null;
  }

  const styles = position === 'left' ? { left: 0 } : { right: 0 };

  return (
    <Box
      data-testid="user-challenge-note-unread-indicator"
      style={{
        position: 'absolute',
        top: 0,
        width: '0.5rem',
        height: '0.5rem',
        borderRadius: '50%',
        backgroundColor: 'var(--bgCompAccent)',
        border: 'var(--border-secondary)',
        ...styles,
      }}
    />
  );
};

ChallengeNotesUnreadIndicator.propTypes =
  ChallengeNotesUnreadIndicatorPropTypes;
