import { useQueryClient, useMutation } from 'react-query';
import { useParams } from 'react-router-dom';

import { queryKeys } from 'api/config';
import { logError } from 'lib/sentry/logError';
import { useMutationHTTPRequest } from 'shared_DEPRECATED/hooks';
import { removeFromArrayById, updateArrayById } from 'shared_DEPRECATED/utils';

import { GoalBreakdownType } from 'features/goal';

type TUseDeleteGoalChallengeMutation = {
  challengeId: string;
  focusAreaId?: string;
};

export const useDeleteGoalBreakdownChallengeMutation = ({
  challengeId,
  focusAreaId,
}: TUseDeleteGoalChallengeMutation) => {
  const { goalId } = useParams();
  const { request } = useMutationHTTPRequest();
  const queryClient = useQueryClient();

  return useMutation(
    () =>
      request({
        url: `/api/web/goals/${goalId}/breakdown/challenges/remove`,
        body: focusAreaId ? { challengeId, focusAreaId } : { challengeId },
      }),
    {
      onMutate: async () => {
        await queryClient.cancelQueries({
          queryKey: [queryKeys.goalBreakdown, goalId],
        });

        const previousValue = queryClient.getQueryData([
          queryKeys.goalBreakdown,
          goalId,
        ]);

        queryClient.setQueryData<GoalBreakdownType>(
          [queryKeys.goalBreakdown, goalId],
          (oldVal) => {
            let updatedFocusAreas = oldVal!.focusAreas;
            let updatedChallenges = oldVal!.challenges;

            if (focusAreaId) {
              updatedFocusAreas = updateArrayById({
                array: oldVal!.focusAreas,
                updatedItem: {
                  ...oldVal!.focusAreas.find(
                    (focusArea) => focusArea.id === focusAreaId
                  ),
                  challenges: removeFromArrayById({
                    array: updatedFocusAreas.find(
                      (focusArea) => focusArea.id === focusAreaId
                    )?.challenges,
                    id: challengeId,
                    idProperty: 'customChallengeId',
                  }),
                },
                idProperty: 'id',
              });
            } else {
              updatedChallenges = removeFromArrayById({
                array: updatedChallenges,
                id: challengeId,
                idProperty: 'customChallengeId',
              });
            }

            return {
              ...oldVal!,
              focusAreas: updatedFocusAreas,
              challenges: updatedChallenges,
            };
          }
        );
        return { previousValue };
      },
      onSettled: () => {
        queryClient.invalidateQueries([queryKeys.goalBreakdown, goalId]);
      },
      onError: (err) => {
        logError(err);
      },
    }
  );
};
