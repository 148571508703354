import { useEffect, useState } from 'react';

import { CONSTANTS } from 'config/envVariables';
import { logError } from 'lib/sentry/logError';

import { useGetAccessToken } from './useGetAccessToken';

export const useAuthToken = () => {
  const [token, setToken] = useState<string | null>(null);

  const getAccessToken = useGetAccessToken();

  useEffect(() => {
    async function getToken() {
      try {
        const token = await getAccessToken();
        if (token) {
          setToken(`${String(CONSTANTS.SOCKET_URL)}?token=${token}`);
        }
      } catch (e) {
        logError(e);
      }
    }

    getToken();
  }, [getAccessToken]);

  return token;
};
