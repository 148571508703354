import { Button, VARIANTS } from 'shared';
import { Icon, iconNames } from 'shared_DEPRECATED/components/Icon';

import { useAddGoal } from 'features/goal';

import { Typography } from 'shared/components/Typography';

export const GoalAddButton = () => {
  const { onAddGoalClick } = useAddGoal();

  return (
    <Button
      onClick={onAddGoalClick}
      variant={VARIANTS.PRIMARY}
      leftIcon={
        <Icon width={10} height={10} name={iconNames.plus} stroke="white" />
      }
      dataTestid="add-new-goal-btn"
    >
      <Typography type="small">Add goal</Typography>
    </Button>
  );
};
