import { useMemo } from 'react';

import { MAX_PRIORITIZED_GOALS } from 'features/goal/config';

import { useGoalsQuery } from './query';

export const usePrioritizedGoalsLimitReached = () => {
  const { data: goals = [] } = useGoalsQuery();

  const prioritizedGoalIds = useMemo(
    () =>
      goals.filter((goal) => !!goal.prioritizedAt).map((goal) => goal.goalId),
    [goals]
  );
  const isLimitReached = prioritizedGoalIds.length >= MAX_PRIORITIZED_GOALS;

  return isLimitReached;
};
