import { Avatar } from 'shared_DEPRECATED/components/Avatar';
import { Flex } from 'shared_DEPRECATED/components/Flex';
import Spacer from 'shared_DEPRECATED/components/Spacer';

import { UserInfoName } from 'features/user/components/Info/';
import { userInfo } from 'features/user/config/defaults';
import { UserInfo as UserInfoType } from 'features/user/config/types';

export const UserInfo = ({
  pictureUrl,
  name,
  pictureSize = userInfo.pictureSize,
  maxWidth = userInfo.maxWidth,
}: UserInfoType) => (
  <Flex
    as="span"
    alignItems="center"
    justifyContent="flex-start"
    dataTestid="user-info"
  >
    <Avatar name={name} size={pictureSize} src={pictureUrl} />
    <Spacer as="span" size="zr zr zr md">
      <UserInfoName maxWidth={maxWidth}>{name}</UserInfoName>
    </Spacer>
  </Flex>
);
