import { TextEditorToolbarBaseButton } from 'shared_DEPRECATED/components/TextEditor/Toolbar/BaseButton';
import { TextEditorFontButtonType } from 'shared_DEPRECATED/components/TextEditor/config';

interface ToolbarFontButtonProps {
  type: TextEditorFontButtonType;
}

export const TextEditorToolbarFontButton = ({
  type,
}: ToolbarFontButtonProps) => <TextEditorToolbarBaseButton type={type} />;
