import { Flex } from 'shared';
import { Icon, iconNames } from 'shared_DEPRECATED/components/Icon';
import Spacer from 'shared_DEPRECATED/components/Spacer';

import { Typography } from 'shared/components/Typography';

type TGoalYears = {
  years: string;
};

export const GoalYears = ({ years }: TGoalYears) => (
  <Flex alignItems="center">
    <Icon name={iconNames.flagNew} fill="var(--fgSuccess)" />
    <Spacer size="sm" />
    <Typography color="green" type="meta">
      {years}
    </Typography>
  </Flex>
);
