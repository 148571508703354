export const SIZES = Object.freeze({
  LG: 'lg',
  SM: 'sm',
});

export const CUSTOM_INPUT = Object.freeze({
  [SIZES.LG]: {
    fontSize: 'meta',
    icon: {
      width: 16,
      height: 16,
    },
    spacers: ['md', 'sm'],
  },
  [SIZES.SM]: {
    fontSize: 'small',
    icon: {
      width: 12,
      height: 14,
    },
    spacers: ['md'],
  },
});
