import PropTypes from 'prop-types';

import Box from 'shared_DEPRECATED/components/Box';
import { ButtonIcon } from 'shared_DEPRECATED/components/Button';
import { Flex } from 'shared_DEPRECATED/components/Flex';
import { iconNames } from 'shared_DEPRECATED/components/Icon';
import Spacer from 'shared_DEPRECATED/components/Spacer';
import { dateUtils } from 'shared_DEPRECATED/utils';

import { Typography } from 'shared/components/Typography';

const DatePickerTwoMonthsHeaderPropTypes = {
  monthDate: PropTypes.instanceOf(Date),
  customHeaderCount: PropTypes.number,
  decreaseMonth: PropTypes.func,
  increaseMonth: PropTypes.func,
};

export const DatePickerTwoMonthsHeader = ({
  monthDate,
  customHeaderCount,
  decreaseMonth,
  increaseMonth,
}) => {
  return (
    <Spacer size="lg">
      <Flex width="100%" justifyContent="space-between" alignItems="center">
        <Box
          style={{ visibility: customHeaderCount !== 1 ? 'visible' : 'hidden' }}
        >
          <ButtonIcon icon={iconNames.arrowLeft} onClick={decreaseMonth} />
        </Box>
        <Typography type="meta">
          {dateUtils(monthDate).format('MMM YYYY')}
        </Typography>
        <Box
          style={{ visibility: customHeaderCount !== 0 ? 'visible' : 'hidden' }}
        >
          <ButtonIcon icon={iconNames.arrowRight} onClick={increaseMonth} />
        </Box>
      </Flex>
    </Spacer>
  );
};

DatePickerTwoMonthsHeader.propTypes = DatePickerTwoMonthsHeaderPropTypes;
