import { useSprintDurationFeatureFlag } from 'featureFlags/hooks/useSprintDurationFeatureFlag';

import { FrequencyDialogContentWithBackButton } from 'features/challenge/components/FrequencyDialog';
import { useOpenChallengeModal } from 'features/challenge/hooks/useOpenChallengeModal';

import { modal, SIZES } from 'shared/components/Modal';

export const useChallengeFrequencyDialog = ({
  challenge,
  onChallengeSubmit,
  sprint,
}) => {
  const openChallengeModal = useOpenChallengeModal();
  const onBackBtnClick = () =>
    openChallengeModal({ challenge, onChallengeSubmit, sprint });

  const onFrequencyChange = (target) => {
    openChallengeModal({
      challenge: { ...challenge, target: { ...challenge.target, ...target } },
      onChallengeSubmit,
      sprint,
    });
  };

  const sprintDuration = useSprintDurationFeatureFlag();

  return () => {
    modal.open({
      content: (
        <FrequencyDialogContentWithBackButton
          onCancel={onBackBtnClick}
          onSave={onFrequencyChange}
          challengeTarget={challenge.target}
          sprintWeeksCount={sprint?.weeksCount || sprintDuration}
        />
      ),
      props: { size: SIZES.MD },
      context: { currentWeekNumber: sprint?.currentWeekNumber || 0 },
    });
  };
};
