import { useReducer } from 'react';

const ACTIONS_TYPE = {
  TOGGLE_ALL: 'toggleAll',
  CLEAR_ALL: 'clearAll',
  TOGGLE: 'toggle',
} as const;

type ToggleAction =
  | { type: typeof ACTIONS_TYPE.TOGGLE_ALL }
  | { type: typeof ACTIONS_TYPE.CLEAR_ALL }
  | { type: typeof ACTIONS_TYPE.TOGGLE; name: string };

type ToggleState = Record<string, boolean>;

const updateStateValues = (
  state: ToggleState,
  valueTransformer: (value: boolean) => boolean
): ToggleState =>
  Object.fromEntries(
    Object.entries(state).map(([key, value]) => [key, valueTransformer(value)])
  );

const useMultipleToggle = ({ toggleNames = [] }: { toggleNames: string[] }) => {
  const initialState: ToggleState = toggleNames.reduce(
    (acc, name) => ({ ...acc, [name]: false }),
    {}
  );

  const reducer = (state: ToggleState, action: ToggleAction): ToggleState => {
    switch (action.type) {
      case ACTIONS_TYPE.TOGGLE_ALL:
        return updateStateValues(state, (value) => !value);

      case ACTIONS_TYPE.CLEAR_ALL:
        return updateStateValues(state, () => false);

      case ACTIONS_TYPE.TOGGLE:
        return { ...state, [action.name]: !state[action.name] };
      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  const toggleAll = () => dispatch({ type: ACTIONS_TYPE.TOGGLE_ALL });
  const clearAll = () => dispatch({ type: ACTIONS_TYPE.CLEAR_ALL });
  const toggleOpen = (name: string) =>
    dispatch({ type: ACTIONS_TYPE.TOGGLE, name });

  return { state, toggleAll, clearAll, toggleOpen };
};

export default useMultipleToggle;
