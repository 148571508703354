import Box from 'shared_DEPRECATED/components/Box';
import Spacer from 'shared_DEPRECATED/components/Spacer';

import { COACH_SESSIONS_TABLE_COLUMNS_QTY } from 'features/sessions/config/coachTableColumnsData';
import {
  ParticipantSessionTableItemType,
  SessionTableItemType,
} from 'features/sessions/config/types';
import { getSessionTableSectionTitle } from 'features/sessions/utils';

import { Typography } from 'shared/components/Typography';

type SessionsTableSectionTitlePropsType = {
  currentSession: SessionTableItemType | ParticipantSessionTableItemType;
  prevSession?: SessionTableItemType | ParticipantSessionTableItemType;
};

export const SessionsTableSectionTitle = ({
  currentSession,
  prevSession,
}: SessionsTableSectionTitlePropsType) => {
  const isDifferentSprintId =
    currentSession.sprintDetails?.sprintId !==
    prevSession?.sprintDetails?.sprintId;

  const isDifferentSessionPhase =
    currentSession.coachingSessionPhase !== prevSession?.coachingSessionPhase;

  const hasTitle =
    !prevSession || isDifferentSprintId || isDifferentSessionPhase;

  return hasTitle ? (
    <Box
      as="tr"
      style={{
        backgroundColor: 'var(--bgCompSecondaryBig)',
        borderBottom: 'var(--border-secondary)',
      }}
    >
      {/* @ts-ignore */}
      <Box as="td" colSpan={COACH_SESSIONS_TABLE_COLUMNS_QTY}>
        <Spacer size="md lg">
          <Typography type="small" color="gray" textTransform="uppercase">
            {getSessionTableSectionTitle(currentSession)}
          </Typography>
        </Spacer>
      </Box>
    </Box>
  ) : (
    <></>
  );
};
