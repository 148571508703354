import { CONSTANTS, ENVS } from 'config/envVariables';
import { Banner } from 'shared_DEPRECATED/components/Banner';

export const HeaderEnvBanner = () => {
  const showBanner = CONSTANTS.ENV === ENVS.STAGING;

  return showBanner ? (
    <Banner>
      Staging environment. The changes applied won’t affect the real users data
    </Banner>
  ) : null;
};
