import { useColorMode } from '@chakra-ui/react';

import Box from 'shared_DEPRECATED/components/Box';
import Button from 'shared_DEPRECATED/components/Button';
import {
  FloatingTooltip as Tooltip,
  PLACEMENTS,
} from 'shared_DEPRECATED/components/Floating';
import { Icon, iconNames } from 'shared_DEPRECATED/components/Icon';
import Spacer from 'shared_DEPRECATED/components/Spacer';

import { Typography } from 'shared/components/Typography';

import './Reactions.module.css';

type ReactionsPopoverButtonPropTypes = {
  isClicked: boolean;
  onClick: () => void;
  dataTestid: string;
  theme: 'light' | 'dark';
};

const getColorConfig = ({
  theme,
  isClicked,
}: {
  theme: 'light' | 'dark';
  isClicked: boolean;
}) => {
  switch (theme) {
    case 'dark':
      return {
        backgroundColor: isClicked
          ? 'transparent'
          : 'var(--bgCompSecondaryTiny)',
        borderColor: isClicked ? 'var(--white)' : 'transparent',
        iconColor: 'var(--white)',
      };
    case 'light':
    default:
      return {
        backgroundColor: isClicked
          ? 'transparent'
          : 'var(--bgCompSecondaryTiny)',
        borderColor: isClicked ? 'var(--fgInteractive)' : 'transparent',
        iconColor: isClicked ? 'var(--fgInteractive)' : 'var(--black)',
      };
  }
};

export const ReactionsPopoverButton = ({
  isClicked = false,
  onClick,
  dataTestid = '',
  theme,
}: ReactionsPopoverButtonPropTypes) => {
  const { colorMode: currentTheme } = useColorMode();

  const isThemeLight = currentTheme === 'light';

  const { backgroundColor, borderColor, iconColor } = getColorConfig({
    theme,
    isClicked,
  });

  return (
    <Tooltip
      anchor={
        <Button onClick={onClick} dataTestid={dataTestid}>
          <Box
            style={{
              backgroundColor,
              borderRadius: 'var(--border-radius12)',
              border: `1px solid ${borderColor}`,
            }}
            hoverStyle={{
              borderColor:
                theme === 'dark' ? 'var(--white)' : 'var(--fgInteractive)',
            }}
          >
            <Spacer size="sm">
              <Spacer size="zr xsm">
                <Icon
                  stroke={iconColor}
                  width={16}
                  height={16}
                  name={iconNames.reaction}
                />
              </Spacer>
            </Spacer>
          </Box>
        </Button>
      }
      placement={PLACEMENTS.TOP}
    >
      <Typography
        color={isThemeLight ? 'white' : 'blackTotal'}
        fontWeight="medium"
        type="small"
      >
        Add reaction
      </Typography>
    </Tooltip>
  );
};
