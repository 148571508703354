import { toastErrorConfig } from 'lib/reactToastify/config';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';

import { queryKeys } from 'api/config';
import { logError } from 'lib/sentry/logError';
import { useMutationHTTPRequest } from 'shared_DEPRECATED/hooks';

import { useRoadmapDataContext } from 'features/goal/context/roadmapData';
import { ColumnsType } from 'features/goal/types';
import { useUserId } from 'features/user/hooks/useUserId';

export const useMoveGoalMutation__NEW = () => {
  const queryClient = useQueryClient();
  const { request } = useMutationHTTPRequest();
  const userId = useUserId();
  const { setRoadmapData } = useRoadmapDataContext();

  return useMutation(
    ({
      id,
      index,
      columnId,
    }: {
      id: string;
      index: number;
      columnId: string;
    }) => {
      return request({
        url: `/api/web/roadmaps/${userId}/move-goal`,
        body: {
          columnId,
          index,
          goalId: id,
        },
      });
    },
    {
      onMutate: async () => {
        await queryClient.cancelQueries({
          queryKey: [queryKeys.goals, userId],
        });

        const previousLayout = queryClient.getQueryData<{
          columns: ColumnsType;
        }>([queryKeys.goals, userId]);

        return { previousLayout };
      },
      onError: (err, _, context) => {
        if (context) {
          queryClient.setQueryData(
            [queryKeys.goals, userId],
            context.previousLayout
          );

          setRoadmapData(context.previousLayout?.columns);
        }

        logError(err);
        toast.error('Failed to move goal', toastErrorConfig);
      },
      onSuccess: () => {
        queryClient.invalidateQueries([queryKeys.goals, userId]);
      },
    }
  );
};
