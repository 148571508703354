import React, { Suspense } from 'react';

import { useFeatureFlag } from 'featureFlags/hooks/useFeatureFlag';
import Loader from 'shared_DEPRECATED/components/Loader/Loader';

type LazyFeatureFlagComponentProps = {
  featureFlag: string;
  lazyComponent: React.LazyExoticComponent<React.ComponentType<any>>;
  children?: React.ReactNode;
  dataTestid?: string;
};

const LazyFeatureFlagComponent = ({
  featureFlag,
  lazyComponent: LazyComponent,
  children,
  ...props
}: LazyFeatureFlagComponentProps) => {
  const isFeatureEnabled = useFeatureFlag(featureFlag);

  return (
    <Suspense fallback={<Loader size="sm" />}>
      {isFeatureEnabled ? <LazyComponent {...props} /> : children || null}
    </Suspense>
  );
};

export default LazyFeatureFlagComponent;
