import Box from 'shared_DEPRECATED/components/Box';
import { Flex } from 'shared_DEPRECATED/components/Flex';
import { Icon, iconNames } from 'shared_DEPRECATED/components/Icon';

export const ChallengeProofIcon = () => (
  <Box
    as="span"
    style={{
      display: 'inline-block',
      width: '1rem',
      height: '1rem',
      borderRadius: 'var(--border-radius4)',
      backgroundColor: 'var(--fgSecondary)',
    }}
    data-testid="user-challenge-proof-note-status"
  >
    <Flex
      justifyContent="center"
      alignItems="center"
      height="100%"
      width="100%"
    >
      <Icon name={iconNames.images} width={12} height={12} />
    </Flex>
  </Box>
);
