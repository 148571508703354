import { useMomentumFeedContext } from 'features/momentum/context/feed';
import { SprintDropdown } from 'features/sprint/components/Dropdown';

import { SidebarMainHeader } from 'shared/components/Sidebar';
import { useSidebarContext } from 'shared/components/Sidebar/context';

export const MomentumSidebarHeader = () => {
  const { sprints } = useSidebarContext();
  const { sprintId, setSprintId } = useMomentumFeedContext();

  return (
    <SidebarMainHeader title="Momentum Feed">
      {/* @ts-ignore */}
      <SprintDropdown
        sprints={sprints}
        selectedSprintId={sprintId}
        setSelectedSprintId={setSprintId}
      />
    </SidebarMainHeader>
  );
};
