import { useEffect } from 'react';

import { publish } from 'shared_DEPRECATED/utils/events';
import { appendScript } from 'shared_DEPRECATED/utils/html';

import {
  FEEDBACK_CALLBACK_EVENT,
  FEEDBACK_DEFAULT_FORM_DATA,
  JIRA_SCRIPT_LINK,
} from 'features/feedback/config';

export const useFeedbackScript = () => {
  useEffect(() => {
    if (!(window as any).ATL_JQ_PAGE_PROPS) {
      (window as any).ATL_JQ_PAGE_PROPS = {
        //The triggerFunction will be invoked by the issue collector after the $(document).ready() phase.
        triggerFunction: function (showCollectorDialog: () => void) {
          publish(FEEDBACK_CALLBACK_EVENT, {
            openFeedbackDialog: showCollectorDialog,
          });
        },
        //triggerFunction gets reference to this object during initialization
        //then we can use it to pass dynamic data to feedback form
        fieldValues: { ...FEEDBACK_DEFAULT_FORM_DATA },
      };

      appendScript({ src: JIRA_SCRIPT_LINK, id: 'jira-feedback-script' });
    }
  }, []);
};
