import snakecase from 'snakecase-keys';

export const snakecasedData = (data) =>
  snakecase(data, {
    deep: true,
    exclude: [/^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/],
  });

const getPropSafely = (object, propName) => object?.[propName];

export const getPictureUrl = (picture) => getPropSafely(picture, 'url');

export const filterFalseyValues = (object) =>
  Object.fromEntries(Object.entries(object).filter(([, value]) => !!value));

export const isElementHasParent = (target, tagName) => {
  let targetElement = target;
  // Check to prevent the sidebar from closing when clicking inside the modal window

  while (targetElement) {
    if (targetElement.tagName === tagName) {
      return true;
    }
    targetElement = targetElement.parentElement;
  }
  return false;
};
