import { OptionsMenuButton } from 'shared_DEPRECATED/components/Floating/Menu/Options';

import { useEditGoal, GoalCardType } from 'features/goal';

export const GoalMenuEditButton = ({
  goalId,
  columnId,
  name,
  dimensions,
  deadline,
  deepDive,
  columnLabel,
  completedAt,
  note,
}: Omit<GoalCardType, 'prioritizedAt'>) => {
  const { onEditGoalClick } = useEditGoal({
    goalId,
    columnId,
    name,
    dimensions,
    deadline,
    deepDive,
    columnLabel,
    completedAt,
    note,
  });

  return (
    <OptionsMenuButton onClick={onEditGoalClick}>
      Edit goal details
    </OptionsMenuButton>
  );
};
