import { Flex } from 'shared_DEPRECATED/components/Flex';
import { mapIconTypographyColor } from 'shared_DEPRECATED/components/Form/SaveIndicator/config';
import { Icon, iconNames } from 'shared_DEPRECATED/components/Icon';
import Spacer from 'shared_DEPRECATED/components/Spacer';

import { Typography } from 'shared/components/Typography';

export const FormSaveIndicatorSaving = () => (
  <Flex justifyContent="flex-start" alignItems="center">
    <Spacer size="zr sm zr zr">
      <Icon name={iconNames.time} stroke={mapIconTypographyColor.orange} />
    </Spacer>
    <Typography type="small" color="orange">
      Saving...
    </Typography>
  </Flex>
);
