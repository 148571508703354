import { SIZES } from 'shared/components/Modal/config';

const sizes = {
  [SIZES.SM]: {
    modal: { maxW: 'var(--modal-width-sm)', maxH: 'auto' }, //480px
  },
  [SIZES.MD]: {
    modal: { maxW: 'var(--modal-width-md)', maxH: 'auto' }, //480px
  },
  [SIZES.LG]: {
    modal: { maxW: 'var(--modal-width-lg)' }, // 736px
  },
};

const parts = [
  'overlay',
  'dialogContainer',
  'dialog',
  'header',
  'closeButton',
  'body',
  'footer',
];

export const modal = {
  parts,
  sizes,
};
