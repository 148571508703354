import { useLocation } from 'react-router-dom';

import { getPathParams } from 'shared_DEPRECATED/utils';
import { routeNames } from 'shared_DEPRECATED/utils/config';

import { SPRINT_TYPES } from '../config';

const SPRINT_TYPE_MAP = {
  [routeNames.COACH]: SPRINT_TYPES.COACHED,
  [routeNames.DASHBOARD]: SPRINT_TYPES.MY,
  [routeNames.CONNECTIONS]: SPRINT_TYPES.SHARED,
};

export const useSprintType = () => {
  const { pathname } = useLocation();
  let [firstPathParam] = getPathParams(pathname);

  return SPRINT_TYPE_MAP[firstPathParam];
};
