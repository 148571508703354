import { CSSProperties } from 'react';

import Box from 'shared_DEPRECATED/components/Box/Box';

import { userInfo } from 'features/user/config/defaults';

import { Typography } from 'shared/components/Typography';

type UserNameProps = {
  children: React.ReactNode;
  maxWidth?: CSSProperties['maxWidth'];
};

export const UserInfoName = ({
  children,
  maxWidth = userInfo.maxWidth,
}: UserNameProps) => (
  <Box style={{ maxWidth }}>
    <Typography
      color="black"
      fontWeight="medium"
      type="meta"
      cropped={true}
      data-testid="participant-info-name"
    >
      {children}
    </Typography>
  </Box>
);
