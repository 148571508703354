import Box from 'shared_DEPRECATED/components/Box';
import {
  DEFAULT_IMAGE_SRC,
  IMAGE_PLACEHOLDERS,
  ImagePropTypes,
  PIXELS_IN_1_REM,
} from 'shared_DEPRECATED/components/Image/config';

const getImageDimension = (dimension) =>
  typeof dimension === 'number'
    ? `${dimension / PIXELS_IN_1_REM}rem`
    : dimension;

const getBorderRadius = (borderRadius) => {
  if (typeof borderRadius === 'number') {
    return `${borderRadius}%`;
  }

  if (typeof borderRadius === 'string') {
    return borderRadius;
  }

  return '0';
};

const ImageComponent = ({
  alt = '',
  className = '',
  height = 'auto',
  src,
  width = 'auto',
  borderRadius,
  type,
  dataTestId = '',
}) => (
  <Box
    as="span"
    style={{
      display: 'block',
      width: getImageDimension(width),
      height: getImageDimension(height),
      borderRadius: getBorderRadius(borderRadius),
      overflow: 'hidden',
    }}
  >
    <img
      alt={alt}
      className={className}
      onError={(e) => {
        e.target.src = IMAGE_PLACEHOLDERS[type] || DEFAULT_IMAGE_SRC;
      }}
      data-testid={dataTestId}
      src={src}
      style={{ width: '100%', height: '100%', objectFit: 'cover' }}
      loading="lazy"
      height={height}
    />
  </Box>
);

ImageComponent.propTypes = ImagePropTypes;

export default ImageComponent;
