import PropTypes from 'prop-types';

import { Flex } from 'shared_DEPRECATED/components/Flex';
import Spacer from 'shared_DEPRECATED/components/Spacer';

export const ChallengeNotesCardFooter = ({ children }) => {
  return (
    <Spacer size="md zr zr lg">
      <Spacer size="sm zr zr lg">
        <Spacer size="zr zr zr sm">
          <Flex flexWrap="wrap">{children}</Flex>
        </Spacer>
      </Spacer>
    </Spacer>
  );
};

ChallengeNotesCardFooter.propTypes = {
  children: PropTypes.node.isRequired,
};
