import { filterFalseyValues } from 'shared_DEPRECATED/utils/object';
import snakecase from 'snakecase-keys';

export const getQueryString = (queryObject) => {
  const filledFilters = filterFalseyValues(queryObject);
  const snakecasedObject = snakecase(filledFilters);

  return Object.entries(snakecasedObject).reduce(
    (queryString, [key, value], i) => {
      const joinSymbol = i === 0 ? '' : '&';
      const queryParam = `${key}=${value}`;

      return queryString + joinSymbol + queryParam;
    },
    ''
  );
};

export const getPathParams = (pathname) => pathname.slice(1).split('/');
