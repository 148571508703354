import { toastConfig } from 'lib/reactToastify/config';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';

import { useMutationHTTPRequest } from 'api/hooks/useHTTPRequest';
import { getNoteRepliesQueryKey } from 'api/utils';
import { useNotesAnalytics } from 'app/analytics/useNotesAnalytics';
import { logError } from 'lib/sentry/logError';

import { useSidebarContext } from 'shared/components/Sidebar/context';

export const useNoteReplyMutation = ({ noteId, userEmail }) => {
  const { request } = useMutationHTTPRequest();
  const queryClient = useQueryClient();
  const { user } = useSidebarContext();
  const noteRepliesQueryKey = getNoteRepliesQueryKey(noteId);
  const { sendNoteReplyAddedAnalyticsEvent } = useNotesAnalytics();

  return useMutation(
    'add-note-replies',
    (text) =>
      request({
        url: `/api/notes/${noteId}/reply?note_owner_email=${userEmail}`,
        body: { text },
      }),
    {
      onMutate: (text) => {
        queryClient.setQueryData(noteRepliesQueryKey, (old) => ({
          note: old.note,
          replies: [
            ...old.replies,
            {
              replyId: 'new-reply',
              user: {
                email: user.email,
                name: user.name,
                picture: user.picture,
              },
              reactions: {},
              createdAt: new Date().toISOString(),
              text,
            },
          ],
        }));
      },
      onError: (err) => {
        toast.error(
          "There's an error with posting note. Please try again later.",
          {
            icon: false,
            ...toastConfig,
          }
        );

        logError(err);
      },
      onSettled: () => {
        queryClient.refetchQueries(noteRepliesQueryKey, { exact: true });
      },
      onSuccess: sendNoteReplyAddedAnalyticsEvent,
    }
  );
};
