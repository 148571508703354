import PropTypes from 'prop-types';

import Typography, {
  TYPOGRAPHY_COLORS,
} from 'shared_DEPRECATED/components/Typography';

import { DIMENSIONS } from 'features/challenge/config';

const ChallengeDimensionNamePropTypes = {
  name: PropTypes.oneOf(Object.values(DIMENSIONS)).isRequired,
  color: PropTypes.oneOf(Object.keys(TYPOGRAPHY_COLORS)).isRequired,
};

const ChallengeDimensionName = ({ name, color = 'black' }) => (
  <Typography as="span" color={color} type="small">
    {name}
  </Typography>
);

ChallengeDimensionName.propTypes = ChallengeDimensionNamePropTypes;

export default ChallengeDimensionName;
