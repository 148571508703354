import Spacer from './Spacer';

type SpacersProps = {
  sizes: string[] | string;
  children?: React.ReactNode;
  height?: string;
};

export const Spacers = ({ sizes, height, children }: SpacersProps) => {
  if (typeof sizes === 'string') {
    return <Spacer size={sizes}>{children}</Spacer>;
  }

  if (sizes.length === 0) {
    return <>{children}</>;
  }

  const [size, ...remainingSizes] = sizes;

  return (
    <Spacer height={height} size={size}>
      <Spacers height={height} sizes={remainingSizes}>
        {children}
      </Spacers>
    </Spacer>
  );
};
