import PropTypes from 'prop-types';

import styles from 'shared_DEPRECATED/components/Form/TextField/TextField.module.css';

const TextFieldAutoGrowingWrapperProps = {
  children: PropTypes.node.isRequired,
  text: PropTypes.string.isRequired,
};

export const TextFieldAutoGrowingWrapper = ({ text, children }) => (
  <div className={styles['autogrow-wrap']}>
    <div className={styles['replicated-block']}>{text} </div>
    {children}
  </div>
);

TextFieldAutoGrowingWrapper.propTypes = TextFieldAutoGrowingWrapperProps;
