import { UserMode } from 'shared_DEPRECATED/types/User';

import { ANALYTICS_EVENTS } from './config';
import { sendAnalyticsEvent } from './utils';

export const sendSessionRecapStartedAnalyticsEvent = ({
  userMode,
  sessionId,
  coachId,
  startRecapAt,
}: {
  userMode: UserMode;
  sessionId: string;
  coachId: string;
  startRecapAt: number;
}) =>
  sendAnalyticsEvent({
    event: ANALYTICS_EVENTS.SESSION_RECAP_STARTED,
    data: {
      userMode,
      sessionId,
      coachId,
      startRecapAt,
    },
  });

export const sendSessionRecapSentAnalyticsEvent = ({
  userMode,
  sessionId,
  coachId,
  finishRecapAt,
  hasAttachments,
}: {
  userMode: UserMode;
  hasAttachments: boolean;
  sessionId: string;
  coachId: string;
  finishRecapAt: number;
}) => {
  sendAnalyticsEvent({
    event: ANALYTICS_EVENTS.SESSION_RECAP_SENT,
    data: {
      userMode,
      has_attachments: hasAttachments,
      sessionId,
      coachId,
      finishRecapAt,
    },
  });
};

export const sendSessionRecapScheduledAnalyticsEvent = ({
  userMode,
  sessionId,
  coachId,
  finishRecapAt,
  hasAttachments,
}: {
  userMode: UserMode;
  hasAttachments: boolean;
  sessionId: string;
  coachId: string;
  finishRecapAt: number;
}) => {
  sendAnalyticsEvent({
    event: ANALYTICS_EVENTS.SESSION_RECAP_SENT,
    data: {
      userMode,
      has_attachments: hasAttachments,
      sessionId,
      coachId,
      finishRecapAt,
    },
  });
};
