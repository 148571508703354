import { Icon, iconNames } from 'shared_DEPRECATED/components/Icon';

import { usePinnedColumnsContext } from 'shared/components/Board/context';
import { ButtonIcon, VARIANTS } from 'shared/components/Button';

type BoardColumnPinButtonProps = {
  columnId: string;
};

export const BoardColumnPinButton = ({
  columnId,
}: BoardColumnPinButtonProps) => {
  const { pinnedColumns, setPinnedColumns } = usePinnedColumnsContext();

  return (
    <ButtonIcon
      ariaLabel="keep"
      icon={
        <Icon
          name={iconNames.keep}
          fill={
            pinnedColumns.has(columnId)
              ? 'var(--bgCompAccent)'
              : 'var(--fgPrimary)'
          }
          width={18}
          height={18}
        />
      }
      onClick={() => setPinnedColumns(columnId)}
      dataTestid="keep-btn"
      variant={VARIANTS.TERTIARY}
      isRound
      backgroundColor={
        pinnedColumns.has(columnId)
          ? 'var(--bgCompSecondaryMid)'
          : 'transparent'
      }
    />
  );
};
