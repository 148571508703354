import { Typography, TYPOGRAPHY_COLORS } from 'shared/components/Typography';

type MomentumFeedItemChallengeFrequencyProps = {
  color?: TYPOGRAPHY_COLORS;
  frequency: string;
  targetAmount?: string;
};

export const MomentumFeedItemChallengeFrequency = ({
  color = 'black',
  frequency,
  targetAmount,
}: MomentumFeedItemChallengeFrequencyProps) => {
  return (
    <Typography type="small" color={color}>
      {frequency}
      {targetAmount && ` • ${targetAmount}`}
    </Typography>
  );
};
