import { useWatch } from 'react-hook-form';

import Box from 'shared_DEPRECATED/components/Box/Box';
import { Flex } from 'shared_DEPRECATED/components/Flex';
import Spacer from 'shared_DEPRECATED/components/Spacer/Spacer';

import {
  NextSessionCoachingToolsCardEdit,
  NextSessionCoachingToolsCardView,
} from 'features/sessions/components/NextSessionForm';

type NextSessionCoachingToolsCardProps = {
  formKey: string;
  onDelete: () => void;
  canBeDeleted: boolean;
};

export const NextSessionCoachingToolsCard = ({
  formKey,
  onDelete,
  canBeDeleted,
}: NextSessionCoachingToolsCardProps) => {
  const coachingTool = useWatch({ name: formKey });
  return (
    <Spacer size="md zr">
      <Box
        style={{
          backgroundColor: 'var(--bgPrimary)',
          borderRadius: 'var(--border-radius)',
          border: 'var(--border-secondary)',
        }}
      >
        <Spacer size="lg">
          <Flex
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            gap="var(--spacing04)"
          >
            {coachingTool.editMode ? (
              <NextSessionCoachingToolsCardEdit
                formKey={formKey}
                onDelete={onDelete}
                canBeDeleted={canBeDeleted}
                coachingTool={coachingTool}
              />
            ) : (
              <NextSessionCoachingToolsCardView
                formKey={formKey}
                onDelete={onDelete}
                coachingTool={coachingTool}
              />
            )}
          </Flex>
        </Spacer>
      </Box>
    </Spacer>
  );
};
