import { MenuButton } from '@chakra-ui/react';
import { ReactNode } from 'react';

import { Flex } from 'shared_DEPRECATED/components/Flex';
import { Icon, iconNames } from 'shared_DEPRECATED/components/Icon';
import Spacer, { Spacers } from 'shared_DEPRECATED/components/Spacer';

type TDropdownAnchor = {
  anchorContent: ReactNode;
  open: boolean;
  spacing: string[];
  dataTestid?: string;
  withChevronIcon?: boolean;
};

export const DropdownAnchor = ({
  anchorContent,
  open,
  spacing,
  dataTestid,
  withChevronIcon = true,
}: TDropdownAnchor) => (
  // TODO: move icon inside of iconRight props of the MenuButton
  <MenuButton data-testid={dataTestid}>
    <Spacers sizes={spacing}>
      <Flex>
        {anchorContent}
        {withChevronIcon && (
          <>
            <Spacer size="sm" />
            <Icon name={open ? iconNames.chevronUp : iconNames.chevronDown} />
          </>
        )}
      </Flex>
    </Spacers>
  </MenuButton>
);
