import { useLayoutEffect, useRef } from 'react';

import {
  iconNames,
  iconsConfig,
} from 'shared_DEPRECATED/components/Icon/iconsConfig';
import { camelToKebabCase } from 'shared_DEPRECATED/utils';

import './Icon.module.css';

export type TIconProps = {
  name: keyof typeof iconNames;
  hoverStroke?: string;
  hoverFill?: string;
} & React.SVGProps<SVGSVGElement>;

export const Icon = ({
  name,
  stroke = iconsConfig[name]?.stroke || 'none',
  hoverStroke,
  fill = iconsConfig[name]?.fill || 'none',
  hoverFill,
  ...props
}: TIconProps) => {
  const element = useRef<SVGSVGElement | null>(null);

  useLayoutEffect(() => {
    const elementRef = element.current;

    if (elementRef) {
      elementRef.style.setProperty('--stroke', stroke);
      elementRef.style.setProperty('--hover-stroke', hoverStroke || stroke);
      elementRef.style.setProperty('--fill', fill);
      elementRef.style.setProperty('--hover-fill', hoverFill || fill);
    }
  }, [hoverStroke, stroke, fill, hoverFill]);

  if (!iconsConfig[name]) {
    return null;
  }

  const { path, ...iconProps } = iconsConfig[name];
  const svgProps = { ...iconProps, ...props };

  return (
    <svg
      {...svgProps}
      data-testid={camelToKebabCase(name)}
      xmlns="http://www.w3.org/2000/svg"
      ref={element}
    >
      {Array.isArray(path) ? (
        path.map((config, index) => <path key={index} {...config} />)
      ) : (
        <path d={path} />
      )}
    </svg>
  );
};
