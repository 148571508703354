import { USER_TYPES } from 'shared_DEPRECATED/types/User';

import { useUserContext } from 'features/user/context';
import { useUserMode } from 'features/user/hooks';

export const useGoalsEnabled = () => {
  const userMode = useUserMode();

  const { user } = useUserContext();

  const isGoalsEnabledForParticipant = user.roadmapAvailable;

  return userMode === USER_TYPES.COACH || isGoalsEnabledForParticipant;
};
