import Loader from 'shared_DEPRECATED/components/Loader/Loader';

import { useNextSessionQuery } from 'features/participants/hooks';
import { SessionSidebar } from 'features/sessions/components';
import { NextSessionCoachFormWithHeader } from 'features/sessions/components/NextSessionForm/Sidebar';

export const NextSessionSidebar = ({
  appointmentId,
}: {
  appointmentId: string;
}) => {
  const { data: sessionAppointment, isLoading } =
    useNextSessionQuery(appointmentId);

  if (isLoading) {
    return <Loader />;
  }

  return sessionAppointment ? (
    <SessionSidebar width="auto">
      <NextSessionCoachFormWithHeader sessionAppointment={sessionAppointment} />
    </SessionSidebar>
  ) : null;
};
