import { useFormContext } from 'react-hook-form';
import TextareaAutosize from 'react-textarea-autosize';

import { GoalDeepDiveFormSection } from 'features/goal';

export const GoalDeepDiveFormNoteSection = () => {
  const { register } = useFormContext();

  return (
    <GoalDeepDiveFormSection title={'Notes:'}>
      <TextareaAutosize
        {...register('note', {
          setValueAs: (v: string) => v.trim(),
        })}
        placeholder="Write here"
        style={{
          width: '100%',
          borderRadius: 'var(--border-radius4)',
          border: 'var(--border-secondary)',
          backgroundColor: 'transparent',
          color: 'var(--fgPrimary)',
          boxSizing: 'border-box',
          lineHeight: 'var(--line-height-medium)',
          resize: 'none',
          padding: '10px',
          outline: 'var(--fgPrimary)',
          overflowY: 'auto',
          overflowX: 'hidden',
        }}
        minRows={1}
        maxRows={6}
      />
    </GoalDeepDiveFormSection>
  );
};
