import { useFormContext } from 'react-hook-form';
import TextareaAutosize from 'react-textarea-autosize';

import Spacer from 'shared_DEPRECATED/components/Spacer';

import { GoalAddFormSection } from 'features/goal';

import { Typography } from 'shared/components/Typography';

export const GoalAddFormNoteSection = () => {
  const { register } = useFormContext();

  return (
    <GoalAddFormSection>
      <Typography
        as="label"
        color="black"
        fontWeight="medium"
        type="smallHeading"
      >
        Notes:
      </Typography>
      <Spacer size="md" />
      <TextareaAutosize
        {...register('note', {
          setValueAs: (v: string) => v.trim(),
        })}
        placeholder="Write here"
        style={{
          width: '100%',
          borderRadius: 'var(--border-radius4)',
          border: 'var(--border-secondary)',
          backgroundColor: 'transparent',
          color: 'var(--fgPrimary)',
          boxSizing: 'border-box',
          lineHeight: 'var(--line-height-medium)',
          resize: 'none',
          padding: '10px',
          outline: 'var(--fgPrimary)',
          overflow: 'auto',
        }}
        minRows={1}
        maxRows={6}
      />
    </GoalAddFormSection>
  );
};
