import { OptionsMenuButton } from 'shared_DEPRECATED/components/Floating/Menu/Options';

import { useDeleteGoal } from 'features/goal';

import { Typography } from 'shared/components/Typography';

type TGoalDeleteButton = {
  goalId: string;
  columnId: string;
};

export const GoalMenuDeleteButton = ({
  goalId,
  columnId,
}: TGoalDeleteButton) => {
  const { onDeleteGoalClick } = useDeleteGoal({ goalId, columnId });

  return (
    <OptionsMenuButton onClick={onDeleteGoalClick}>
      <Typography color="red">Delete goal</Typography>
    </OptionsMenuButton>
  );
};
