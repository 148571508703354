import { useFormContext } from 'react-hook-form';

import { TextInput } from 'shared_DEPRECATED/components/Form/Input';
import Spacer from 'shared_DEPRECATED/components/Spacer';

import { SessionsFormCoachingToolsSection } from 'features/sessions/components/Form/CoachingTools/Section';

type SessionsFormCoachingToolsLinkFieldProps = {
  formKey: string;
};

export const SessionsFormCoachingToolsLinkField = ({
  formKey,
}: SessionsFormCoachingToolsLinkFieldProps) => {
  const { register } = useFormContext();

  return (
    <>
      <SessionsFormCoachingToolsSection title="External session tool name">
        <TextInput
          {...register(`${formKey}.unlistedToolName`)}
          //@ts-ignore
          placeholder="Add the name here"
        />
      </SessionsFormCoachingToolsSection>
      <Spacer size="sm zr" />
      <SessionsFormCoachingToolsSection title="Tool description link">
        <TextInput
          {...register(`${formKey}.link`)}
          //@ts-ignore
          placeholder="Add any related link for tool materials"
        />
      </SessionsFormCoachingToolsSection>
    </>
  );
};
