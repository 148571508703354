import PropTypes from 'prop-types';

import { Typography } from 'shared/components/Typography';

const SidebarHeaderTitle = ({ children }) => (
  <Typography as="h3" fontWeight="semiBold" type="meta" color="black">
    {children}
  </Typography>
);

SidebarHeaderTitle.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SidebarHeaderTitle;
