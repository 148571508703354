import Linkify from 'linkify-react';

import classNames from 'classnames/bind';
import { Typography } from 'shared';

import styles from 'features/sessions/components/NextSessionForm/MiroBoardLinkField/Link.module.css';

const cx = classNames.bind(styles);

type TNextSessionFormMiroBoardLinkView = {
  text: string;
};

export const NextSessionFormMiroBoardLinkView = ({
  text,
}: TNextSessionFormMiroBoardLinkView) => (
  <Typography type="meta" color="black" wordBreak="break-word">
    <Linkify
      options={{
        target: '_blank',
        className: cx('link'),
      }}
    >
      {text}
    </Linkify>
  </Typography>
);
