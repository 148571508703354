import { useCallback } from 'react';

import { PrioritizeGoalCallback } from '../types';
import { useGoalDetailsPrioritizeMutation } from './mutation/useGoalDetailsPrioritize';
import { useOpenPrioritizedGoalsSettingModal } from './useOpenPrioritizedGoalsSettingModal';
import { usePrioritizedGoalsLimitReached } from './usePrioritizedGoalsLimitReached';

export const usePrioritizeToggle = () => {
  const isPrioritizedGoalsLimitReached = usePrioritizedGoalsLimitReached();
  const prioritizeOnDetails = useGoalDetailsPrioritizeMutation();
  const openPrioritizedGoalsModal = useOpenPrioritizedGoalsSettingModal();

  return useCallback<PrioritizeGoalCallback>(
    (goalId, isPrioritized) => {
      if (isPrioritizedGoalsLimitReached && !isPrioritized) {
        openPrioritizedGoalsModal();
        return;
      }

      prioritizeOnDetails.mutate({
        goalId,
        isPrioritized,
      });
    },
    [
      isPrioritizedGoalsLimitReached,
      openPrioritizedGoalsModal,
      prioritizeOnDetails,
    ]
  );
};
