import {
  GoalDeepDiveFormSection,
  GoalDeepDiveFormDescription,
  GoalDeepDiveFormEditor,
} from 'features/goal';

export const GoalDeepDiveFormQualitiesSection = () => (
  <GoalDeepDiveFormSection step={2} title={'Qualities and skills'}>
    <GoalDeepDiveFormDescription text="Who do you need to be to achieve this goal? What qualities do you need to demonstrate?" />
    <GoalDeepDiveFormEditor
      keyField="deepDive.qualitiesAndSkills"
      id="qualitiesAndSkills"
    />
  </GoalDeepDiveFormSection>
);
