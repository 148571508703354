import { toastConfig, toastErrorConfig } from 'lib/reactToastify/config';
import { toast, ToastOptions } from 'react-toastify';

import { logError } from 'lib/sentry/logError';
import { noop } from 'shared_DEPRECATED/utils/function';

import { UsersConfirmDialogContent } from 'features/admin/users/components/ConfirmDialogContent';
import { useSessionDeleteMutation } from 'features/sessions/hooks';

import { modal, SIZES } from 'shared/components/Modal';

export const useSessionDeletion = (
  sessionId: string,
  onSuccess: () => void = noop
) => {
  const deleteMutation = useSessionDeleteMutation(sessionId);

  const onSessionDelete = async () => {
    try {
      await deleteMutation.mutateAsync();

      toast.success('Session was successfully deleted', {
        icon: false,
        ...toastConfig,
      } as ToastOptions);
      onSuccess();
    } catch (e) {
      toast.error(
        "There's an error with deleting session.",
        toastErrorConfig as ToastOptions
      );

      logError(e);
    }
  };

  const openConfirmationModal = () => {
    modal.open({
      content: (
        <UsersConfirmDialogContent
          title="Delete session note?"
          bodyText="All progress will be lost, you won’t be able to revert changes."
          onSubmit={onSessionDelete}
          submitButtonText="Delete"
        />
      ),
      props: { size: SIZES.SM, title: 'Delete session modal' },
    });
  };

  return { openConfirmationModal };
};
