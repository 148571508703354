import { ReactNode } from 'react';

import { Divider } from 'shared_DEPRECATED/components/Divider';
import { Spacers } from 'shared_DEPRECATED/components/Spacer';

type TFormSection = { children: ReactNode };

export const GoalAddFormSection = ({ children }: TFormSection) => (
  <Spacers sizes={['zr zr lg', 'zr zr md']}>
    <Spacers sizes={['zr zr lg', 'zr zr md']}>{children}</Spacers>
    <Divider />
  </Spacers>
);
