import { useFormContext } from 'react-hook-form';

import { TextInput } from 'shared_DEPRECATED/components/Form/Input';

export const NextSessionFormMiroBoardLinkEdit = () => {
  const { register, getValues } = useFormContext();
  const fieldKey = getValues('nextSessionFieldKey') || '';

  return (
    <TextInput
      {...register(`${fieldKey}.coachingClientNote.dashboardLink`)}
      //@ts-ignore
      placeholder="Add miro link here"
      dataTestid="miro-link-text-field"
    />
  );
};
