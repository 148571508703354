import {
  sendNotificationsFilterAppliedAnalyticsEvent,
  sendNotificationsOpenAnalyticsEvent,
} from 'app/analytics';
import { UserMode } from 'shared_DEPRECATED/types/User';

import { useUserMode } from 'features/user/hooks';

export const useNotificationsAnalytics = () => {
  const userMode = useUserMode() as UserMode;

  const sendNotificationsOpenEvent = () =>
    sendNotificationsOpenAnalyticsEvent({ userMode });

  const sendNotificationsFilterAppliedEvent = (filter: {
    unread: boolean;
    email: string | null;
  }) => {
    const type = [
      !filter.unread ? 'all' : 'unread_only',
      !filter.email ? 'all' : filter.email,
    ].join(' / ');
    sendNotificationsFilterAppliedAnalyticsEvent({ type, userMode });
  };

  return {
    sendNotificationsOpenEvent,
    sendNotificationsFilterAppliedEvent,
  };
};
