import {
  autoUpdate,
  flip,
  offset,
  shift,
  useFloating,
} from '@floating-ui/react-dom-interactions';
import PropTypes from 'prop-types';

import { noop } from 'shared_DEPRECATED/utils/function';

import { PLACEMENTS } from '../config';
import { FloatingBody } from './FloatingBody';
import { FloatingContainer } from './FloatingContainer';

const FloatingPopoverProps = {
  children: PropTypes.node.isRequired,
  anchor: PropTypes.node.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  offsetTop: PropTypes.number,
  placement: PropTypes.string,
  isAutoUpdate: PropTypes.bool,
  dataTestid: PropTypes.string,
};

export const FloatingPopoverControlled = ({
  children,
  anchor,
  isOpen,
  onClose = noop,
  offsetTop = 0,
  placement = PLACEMENTS.BOTTOM_START,
  isAutoUpdate = false,
  dataTestid = '',
}) => {
  const { x, y, reference, floating, strategy } = useFloating({
    middleware: [offset(offsetTop), flip(), shift()],
    placement,
    whileElementsMounted: isAutoUpdate ? autoUpdate : null,
  });

  return (
    <FloatingContainer isOpen={isOpen} onClose={onClose}>
      <div ref={reference} data-testid={dataTestid}>
        {anchor}
      </div>
      {isOpen && (
        <FloatingBody ref={floating} position={strategy} top={y} left={x}>
          {children}
        </FloatingBody>
      )}
    </FloatingContainer>
  );
};

FloatingPopoverControlled.propTypes = FloatingPopoverProps;
