import PropTypes from 'prop-types';

import { MainHeader } from 'app/layout/components/Header';
import classNames from 'classnames/bind';

import styles from 'app/layout/components/Layout/Layout.module.css';

const cx = classNames.bind(styles);

const CoachLayout = ({ children }) => (
  <div className={styles.layout}>
    <div className={cx('main-wrapper')}>
      <MainHeader />
      <main className={cx('main-content', 'main-content--coach')}>
        {children}
      </main>
    </div>
  </div>
);

CoachLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default CoachLayout;
