import { useColorMode } from '@chakra-ui/react';
import { useOutletContext, useParams } from 'react-router-dom';

import Button, { VARIANTS } from 'shared_DEPRECATED/components/Button';
import Spacer from 'shared_DEPRECATED/components/Spacer';

import { SessionsModalContent } from 'features/sessions/components/Modal';
import { SprintsType } from 'features/sprint/config/types';

import { modal, SIZES } from 'shared/components/Modal';
import { Typography } from 'shared/components/Typography';

type TCoachSessionsCreateButton = { nextSessionStartDate?: string };

export const CoachSessionsCreateButton = ({
  nextSessionStartDate,
}: TCoachSessionsCreateButton) => {
  const { sprints } = useOutletContext<{ sprints: SprintsType }>();
  const { email: userEmail } = useParams();
  const twoRecentSprints = sprints.slice(0, 2);
  const { colorMode: theme } = useColorMode();
  const isThemeLight = theme === 'light';

  const handleModalOpen = () => {
    modal.open({
      content: <SessionsModalContent key={userEmail} />,
      props: { size: SIZES.SM, title: 'Create session modal' },
      context: { sprints: twoRecentSprints, nextSessionStartDate },
    });
  };

  return (
    <Button variant={VARIANTS.PRIMARY} onClick={handleModalOpen}>
      <Spacer size="md md">
        <Spacer size="sm sm">
          <Typography
            color={isThemeLight ? 'white' : 'black'}
            type="meta"
            fontWeight="medium"
            dataTestid="add-new-sesssion-btn"
          >
            + Add new session
          </Typography>
        </Spacer>
      </Spacer>
    </Button>
  );
};
