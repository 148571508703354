import { publish } from 'shared_DEPRECATED/utils/events';

export const SIDEBAR_OPEN_EVENT = 'sidebar:open';
export const SIDEBAR_CLOSE_EVENT = 'sidebar:close';
export const SIDEBAR_SET_CONTEXT_EVENT = 'sidebar:setContext';

/**
 * @param {object} sidebarConfig
 * @param {React.ReactNode} sidebarConfig.content - content of the sidebar
 * @param {object} [sidebarConfig.context] - sidebar context
 * @param {object} [sidebarConfig.props] - props of the sidebar
 * @param {string} [sidebarConfig.props.title] - title of the sidebar
 * @example open({ content: 'content', props: {title: 'title'}, context: {foo: 'bar'} })
 */

export const open = (sidebarConfig) =>
  publish(SIDEBAR_OPEN_EVENT, sidebarConfig);

export const close = () => publish(SIDEBAR_CLOSE_EVENT);

/**
 * @param {object} context - sidebar context
 * @example setContext({ foo: 'bar' })
 */

export const setContext = (context) =>
  publish(SIDEBAR_SET_CONTEXT_EVENT, context);
