import { Flex } from 'shared_DEPRECATED/components/Flex';

import { Typography } from 'shared/components/Typography';

type TGoalAddDialogHeader = {
  title?: string;
};

export const GoalAddDialogHeader = ({
  title = 'Add the goal',
}: TGoalAddDialogHeader) => (
  <Flex flexDirection="column" width="100%" alignItems="flex-start">
    <Typography as="h3" type="heading" fontWeight="semiBold" color="black">
      {title}
    </Typography>
    <Typography color="gray" fontWeight="medium" type="small">
      or dream / vision
    </Typography>
  </Flex>
);
