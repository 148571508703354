import { ACTION_TYPES } from 'shared_DEPRECATED/components/Reactions/config';

import { ChallengeNotesReplies } from 'features/challengeNotes/components/Replies';
import ChallengeNotesSidebar from 'features/challengeNotes/components/Sidebar/Sidebar';

import { sidebar } from 'shared/components/Sidebar';

const reactionActions = {
  [ACTION_TYPES.ADD]: (reactions, emoji, userInfo) => {
    const reactionUsers = reactions[emoji] || [];
    return { ...reactions, [emoji]: [...reactionUsers, userInfo] };
  },
  [ACTION_TYPES.REMOVE]: (reactions, emoji, userInfo) => {
    const reactionsCopy = { ...reactions };
    const filteredUsers = reactions[emoji].filter(
      (user) => user.email !== userInfo.email
    );

    if (filteredUsers.length === 0) {
      delete reactionsCopy[emoji];
    } else {
      reactionsCopy[emoji] = filteredUsers;
    }

    return reactionsCopy;
  },
};

export const updateReactions = ({ reactions, emoji, userInfo, type }) => {
  return reactionActions[type](reactions, emoji, userInfo);
};

export const updateReplyReactions = ({
  replies,
  replyId,
  type,
  emoji,
  userInfo,
}) => {
  return replies.map((reply) => {
    if (reply.replyId === replyId) {
      return {
        ...reply,
        reactions: reactionActions[type](reply.reactions, emoji, userInfo),
      };
    }
    return reply;
  });
};

export const openNotesSidebar = ({ sprint, user }) =>
  sidebar.open({
    content: <ChallengeNotesSidebar />,
    props: { title: 'Challenge Notes Sidebar' },
    context: { sprint, user },
  });

export const openNotesRepliesSidebar = ({ sprint, note, user }) =>
  sidebar.open({
    content: <ChallengeNotesReplies />,
    props: { title: 'Challenge Note Replies Sidebar' },
    context: { sprint, note, user },
  });
