import { useFormContext } from 'react-hook-form';

import { ButtonIcon } from 'shared_DEPRECATED/components/Button';
import { Flex } from 'shared_DEPRECATED/components/Flex';
import { HotjarSuppression } from 'shared_DEPRECATED/components/Hotjar';
import { iconNames } from 'shared_DEPRECATED/components/Icon';
import Spacer from 'shared_DEPRECATED/components/Spacer';

import {
  SessionsFormCoachingToolsLink,
  SessionsFormCoachingToolsSection,
} from 'features/sessions/components/Form/CoachingTools';
import { CoachingToolNote } from 'features/sessions/config/types';

import { Badge } from 'shared/components/Badge';
import { Typography } from 'shared/components/Typography';

type SessionsFormCoachingToolsCardViewProps = {
  coachingTool: CoachingToolNote;
  onDelete: () => void;
  formKey: string;
};

export const SessionsFormCoachingToolsCardView = ({
  coachingTool,
  onDelete,
  formKey,
}: SessionsFormCoachingToolsCardViewProps) => {
  const { setValue } = useFormContext();
  const { tool, toolEffectivenessRate, notes, link, unlistedToolName } =
    coachingTool;

  return (
    <>
      <SessionsFormCoachingToolsSection>
        <Flex>
          <Typography type="smallHeading" fontWeight="semiBold" color="black">
            {tool.label}
          </Typography>
          <Flex gap="var(--spacing04)">
            <ButtonIcon
              onClick={() => setValue(`${formKey}.editMode`, true)}
              icon={iconNames.pen}
            />
            <ButtonIcon onClick={onDelete} icon={iconNames.bin} />
          </Flex>
        </Flex>
      </SessionsFormCoachingToolsSection>
      <SessionsFormCoachingToolsSection>
        <Flex gap="var(--spacing04)" justifyContent="flex-start">
          <Typography type="meta" color="black">
            Coach perceived rate of effectiveness
          </Typography>
          <Badge bgColor="green">
            <Typography type="small" color="white">
              {toolEffectivenessRate}
            </Typography>
          </Badge>
        </Flex>
      </SessionsFormCoachingToolsSection>
      {unlistedToolName && (
        <SessionsFormCoachingToolsSection>
          <Typography type="meta" fontWeight="semiBold" color="black">
            External session tool name
          </Typography>
          <Spacer size="md zr" />
          <Typography type="meta" color="black">
            {unlistedToolName}
          </Typography>
        </SessionsFormCoachingToolsSection>
      )}
      {link && <SessionsFormCoachingToolsLink link={link} />}
      {notes && (
        <>
          <Typography type="meta" fontWeight="semiBold" color="black">
            Session specific notes
          </Typography>
          <Spacer size="md zr" />
          <HotjarSuppression>
            <Typography type="meta" fontWeight="regular" color="black">
              {notes}
            </Typography>
          </HotjarSuppression>
        </>
      )}
    </>
  );
};
