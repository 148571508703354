import { useLocation } from 'react-router-dom';

import { USER_TYPES } from 'shared_DEPRECATED/types/User';
import { getPathParams } from 'shared_DEPRECATED/utils';

import { USER_MODES } from 'features/user/config';

export const useUserMode = () => {
  const { pathname } = useLocation();
  let [firstPathParam] = getPathParams(pathname);

  return USER_MODES[firstPathParam] || USER_TYPES.USER;
};
