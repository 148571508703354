export const SIZES = {
  SM: 'sm',
  MD: 'md',
};

export const SWITCH_DIMENSIONS = {
  [SIZES.SM]: {
    width: '1.75rem',
    height: '1rem',
  },
  [SIZES.MD]: {
    width: '3.75rem',
    height: '2rem',
  },
};

//TODO: Replace with colors from new palette https://hosinc.atlassian.net/browse/HOS-1104
export const COLORS = {
  primary: {
    checked: 'var(--bgCompAccent)',
    unchecked: 'var(--fgSecondary)',
  },
  secondary: {
    checked: '#6673991F',
    unchecked: '#73798C66',
  },
};
