import PropTypes from 'prop-types';

import { useHasUnreadNotificationsQuery } from 'app/notifications/hooks';
import classNames from 'classnames/bind';
import { Icon, iconNames } from 'shared_DEPRECATED/components/Icon';

import { ButtonIcon, VARIANTS } from 'shared/components/Button';

import styles from 'app/notifications/components/Notifications.module.css';

const cx = classNames.bind(styles);

const notificationButtonProps = {
  showNotifications: PropTypes.bool,
  setShowNotifications: PropTypes.func.isRequired,
};

export const NotificationsButton = ({ setShowNotifications }) => {
  const hasUnreadNotifications = useHasUnreadNotificationsQuery();

  const imgClassNames = cx({
    'notification__btn-icon': true,
    'notification__btn-icon--unread': hasUnreadNotifications,
  });

  return (
    <div className={imgClassNames}>
      <ButtonIcon
        ariaLabel="notifcations"
        icon={<Icon name={iconNames.notification} width={16} height={16} />}
        onClick={setShowNotifications}
        dataTestid="notifications-header-btn"
        size="sm"
        variant={VARIANTS.TERTIARY}
      />
    </div>
  );
};

NotificationsButton.propTypes = notificationButtonProps;
