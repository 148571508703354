import { Flex } from 'shared_DEPRECATED/components/Flex';
import { mapIconTypographyColor } from 'shared_DEPRECATED/components/Form/SaveIndicator/config';
import { Icon, iconNames } from 'shared_DEPRECATED/components/Icon';
import Spacer from 'shared_DEPRECATED/components/Spacer';
import { Nullable } from 'shared_DEPRECATED/types';
import { dateUtils } from 'shared_DEPRECATED/utils';

import { Typography } from 'shared/components/Typography';

export const FormSaveIndicatorSaved = ({
  savedAt,
}: {
  savedAt?: Nullable<string>;
}) => (
  <Flex justifyContent="flex-start" alignItems="center">
    <Spacer size="zr sm zr zr">
      <Icon name={iconNames.check} stroke={mapIconTypographyColor.green} />
    </Spacer>
    <Typography type="small" color="green" dataTestid="save-indicator-saved">
      Saved {dateUtils(dateUtils.localDate(savedAt)).timeAgoDate()}
    </Typography>
  </Flex>
);
