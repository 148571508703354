import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { setRangeForNode } from 'shared_DEPRECATED/utils';

type NodeHighlightPropsType = { nodeId: string };

export const useNodeHighlight = ({ nodeId }: NodeHighlightPropsType) => {
  const { state, pathname } = useLocation();
  const navigate = useNavigate();

  return useCallback(
    (targetNode?: HTMLElement) => {
      if (
        state?.highlightedNodeId &&
        targetNode &&
        nodeId === state?.highlightedNodeId
      ) {
        navigate(pathname, {
          replace: true,
          state: { ...state, highlightedNodeId: null },
        });

        setRangeForNode(targetNode);
        targetNode.scrollIntoView({ block: 'center' });
      }
    },
    [navigate, nodeId, pathname, state]
  );
};
