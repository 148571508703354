import { Else, If, When, Then } from 'react-if';

import { Flex } from 'shared';

import { NextSessionFormMiroBoardLinkButton } from './Button';

type TNextSessionFormMiroBoardLinkActions = {
  onSave: () => void;
  onEdit: () => void;
  onCopy: () => void;
  isEditMode: boolean;
  isVisible: boolean;
  canLinkBeCopied: boolean;
};

export const NextSessionFormMiroBoardLinkActions = ({
  onSave,
  onEdit,
  onCopy,
  isEditMode,
  isVisible,
  canLinkBeCopied,
}: TNextSessionFormMiroBoardLinkActions) => (
  <When condition={isVisible}>
    <If condition={isEditMode}>
      <Then>
        <NextSessionFormMiroBoardLinkButton onClick={onSave}>
          Save
        </NextSessionFormMiroBoardLinkButton>
      </Then>
      <Else>
        <Flex alignItems="center" gap="8px">
          <When condition={canLinkBeCopied}>
            <NextSessionFormMiroBoardLinkButton onClick={onCopy}>
              Copy link
            </NextSessionFormMiroBoardLinkButton>
          </When>
          <NextSessionFormMiroBoardLinkButton onClick={onEdit}>
            Edit
          </NextSessionFormMiroBoardLinkButton>
        </Flex>
      </Else>
    </If>
  </When>
);
