import Box from 'shared_DEPRECATED/components/Box';

type DividerPropTypes = {
  color?: string;
  strokeWidth?: number;
};

const Divider = ({
  color = 'var(--divPrimary)',
  strokeWidth = 1,
}: DividerPropTypes) => {
  return (
    <Box style={{ width: '100%', height: 'auto' }}>
      <Box
        style={{
          backgroundColor: color,
          height: `${strokeWidth}px`,
          width: '100%',
        }}
      />
    </Box>
  );
};

export default Divider;
