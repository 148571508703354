import { useParams } from 'react-router-dom';

import {
  FloatingTooltip as Tooltip,
  PLACEMENTS,
} from 'shared_DEPRECATED/components/Floating';
import { Icon, iconNames } from 'shared_DEPRECATED/components/Icon';
import Spacer from 'shared_DEPRECATED/components/Spacer';

import { useParticipantQuery } from 'features/participants/hooks';

import { Typography } from 'shared/components/Typography';

export const MomentumFeedItemMaximumTrend = () => {
  const { email } = useParams();
  const { participant, isLoading } = useParticipantQuery(email);

  return (
    <>
      <Typography type="small" color="green">
        Maximum
      </Typography>
      {isLoading ? null : (
        <Tooltip
          anchor={<Icon name={iconNames.info} />}
          offsetValue={8}
          placement={PLACEMENTS.TOP}
          width="12rem"
        >
          <Typography color="white" fontWeight="medium" type="meta">
            Maximum Momentum
          </Typography>
          <Spacer size="xsm zr zr" />
          <Typography color="white" type="small">
            This challenge is positively impacting {participant.userName}'s
            momentum.
          </Typography>
        </Tooltip>
      )}
    </>
  );
};
