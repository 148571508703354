import { useParams, useNavigate } from 'react-router-dom';

import useSprintQuery from 'features/sprint/hooks/query/useSprint';

const DRAFT_SPRINT_UNAVAILABLE_ERROR = 'draft_unavailable_during_active_sprint';

export const useCurrentSprint = () => {
  const { sprintId, email } = useParams();
  const navigate = useNavigate();

  const navigateToParticipantPage = () => {
    navigate(`/coach/participants/${email}`, { replace: true });
  };

  return useSprintQuery({
    sprintId,
    options: {
      enabled: Boolean(sprintId),
      onError: (error) => {
        if (error.name === DRAFT_SPRINT_UNAVAILABLE_ERROR) {
          navigateToParticipantPage();
        }
      },
    },
  });
};
