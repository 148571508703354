import PropTypes from 'prop-types';

import { ButtonIcon, VARIANTS } from 'shared';
import { Flex } from 'shared_DEPRECATED/components/Flex';
import { Icon, iconNames } from 'shared_DEPRECATED/components/Icon';

import {
  FrequencyDialogBody,
  FrequencyDialogButtons,
  FrequencyDialogHeader,
} from 'features/challenge/components/FrequencyDialog';
import { INITIAL_FREQUENCY } from 'features/challenge/config';
import {
  WeekdayTargetType,
  WeeklyTargetType,
} from 'features/challenge/config/propTypes';
import { useSetFrequency } from 'features/challenge/hooks/useSetFrequency';

import { ModalBody, ModalFooter, ModalHeader } from 'shared/components/Modal';

const FrequencyDialogContentPropTypes = {
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  challengeTarget: PropTypes.oneOfType([WeekdayTargetType, WeeklyTargetType]),
  sprintWeeksCount: PropTypes.number.isRequired,
  shouldCloseOnSubmit: PropTypes.bool,
};

export const FrequencyDialogContentWithBackButton = ({
  challengeTarget,
  onSave,
  onCancel,
  sprintWeeksCount,
  shouldCloseOnSubmit = false,
}) => {
  const { frequency, setFrequency, handleSaveBtnClick } = useSetFrequency({
    challengeTarget,
    sprintWeeksCount,
    onFrequencySave: onSave,
  });

  return (
    <>
      <ModalHeader>
        <Flex
          gap="var(--spacing04)"
          alignItems="flex-start"
          justifyContent="flex-start"
        >
          <ButtonIcon
            icon={<Icon name={iconNames.arrowBigLeft} />}
            onClick={onCancel}
            dataTestid="challenge-frequency-dialog-back"
            variant={VARIANTS.TERTIARY}
            size="sm"
          />
          <FrequencyDialogHeader />
        </Flex>
      </ModalHeader>
      <ModalBody>
        <FrequencyDialogBody
          onFrequencyChange={(frequency) =>
            setFrequency({ ...INITIAL_FREQUENCY, ...frequency })
          }
          frequency={frequency}
          sprintWeeksCount={sprintWeeksCount}
        />
      </ModalBody>
      <ModalFooter>
        <FrequencyDialogButtons
          onSave={handleSaveBtnClick}
          onCancel={onCancel}
          shouldCloseOnSubmit={shouldCloseOnSubmit}
        />
      </ModalFooter>
    </>
  );
};

FrequencyDialogContentWithBackButton.propTypes =
  FrequencyDialogContentPropTypes;
