import PropTypes from 'prop-types';

import Box from 'shared_DEPRECATED/components/Box';
import Spacer from 'shared_DEPRECATED/components/Spacer';
import { firstLetterUpperCase } from 'shared_DEPRECATED/utils';

const SidebarSectionPropTypes = {
  borderLocation: PropTypes.oneOf(['top', 'bottom']),
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
};

const SidebarSection = ({ children, borderLocation = 'bottom', title }) => (
  <Box
    style={{
      [`border${firstLetterUpperCase(borderLocation)}`]:
        'var(--border-secondary)',
    }}
    title={title}
  >
    <Spacer size="lg">{children}</Spacer>
  </Box>
);

SidebarSection.propTypes = SidebarSectionPropTypes;

export default SidebarSection;
