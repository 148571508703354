import { useMomentumFeedSprintScores } from 'features/momentum/hooks/useFeedSprintScores';
import { ISprint } from 'features/sprint/config/types';

import { useSidebarContext } from 'shared/components/Sidebar/context';

export const useWeekChartData = () => {
  const { sprint } = useSidebarContext() as { sprint: ISprint };
  const sprintMomentumData = useMomentumFeedSprintScores();

  const week = sprint.weeks[sprint.currentWeekNumber - 1];
  const data = week.map((date, index) => {
    const prevScore = sprintMomentumData.find(
      (item) =>
        item.date === sprint.weeks[sprint.currentWeekNumber - 2]?.[index]
    )?.score;
    const momentumItem = sprintMomentumData.find((item) => item.date === date);

    return momentumItem
      ? {
          ...momentumItem,
          prevScore,
        }
      : {
          date,
          score: undefined,
          prevScore,
        };
  });

  const referenceDotData = data.find((item, index) => {
    const isNextItemScoreAvailable = Boolean(data[index + 1]?.score);

    return item.score && !isNextItemScoreAvailable;
  });

  return {
    data,
    referenceDotData,
  };
};
