import { useFormContext, useWatch } from 'react-hook-form';

import { useCopyToClipboard } from 'shared_DEPRECATED/hooks';
import useToggle from 'shared_DEPRECATED/hooks/useToggle';

import { extractLinksFromString } from 'shared/utils/links';

export const useNextSessionMiroBoard = () => {
  const { getValues } = useFormContext();
  const fieldKey = getValues('nextSessionFieldKey') || '';

  const text =
    useWatch({ name: `${fieldKey}.coachingClientNote.dashboardLink` }) || '';

  const [editMode, toggleEditMode] = useToggle({ defaultValue: !text.length });

  const link = extractLinksFromString(text)[0] ?? '';

  const onCopy = useCopyToClipboard({
    clipboardText: link,
    clipboardTextName: 'miro link',
  });

  const onSave = () => {
    toggleEditMode();
  };

  return {
    onSave,
    onCopy,
    editMode,
    toggleEditMode,
    text,
    canLinkBeCopied: !!link.length,
  };
};
