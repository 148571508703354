import { Flex } from 'shared_DEPRECATED/components/Flex';
import Spacer from 'shared_DEPRECATED/components/Spacer';

import { Typography } from 'shared/components/Typography';

export const ChallengeNotesFilterEmptyState = () => {
  return (
    <Flex
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      height="100%"
    >
      <Spacer size="xlg">
        <Typography type="largeHeading" textAlign="center">
          👋
        </Typography>
        <Typography
          type="meta"
          color="black"
          fontWeight="medium"
          textAlign="center"
          dataTestid="notes-sidebar-empty-list"
        >
          No matches.
        </Typography>
        <Typography
          type="meta"
          color="gray"
          fontWeight="regular"
          textAlign="center"
          dataTestid="notes-sidebar-empty-list-details"
        >
          Try other filter rules.
        </Typography>
      </Spacer>
    </Flex>
  );
};
