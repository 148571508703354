import { BaseChallenge } from 'features/challenge/BaseChallenge';
import {
  DueDateTargetProgressType,
  IBaseChallengeComputedProps,
  INonRepeatedChallenge,
} from 'features/challenge/config/types';

import { CHALLENGE_FIELD_TYPES } from './config';

export class NonRepeatedChallenge
  extends BaseChallenge
  implements INonRepeatedChallenge, IBaseChallengeComputedProps
{
  target: DueDateTargetProgressType;
  type: typeof CHALLENGE_FIELD_TYPES[keyof typeof CHALLENGE_FIELD_TYPES];

  constructor(challenge: INonRepeatedChallenge) {
    super(challenge);

    this.target = challenge.target;
    this.type = CHALLENGE_FIELD_TYPES.oneTimeChallenges;
  }
}
