import { ReactNode, forwardRef } from 'react';

import Box from 'shared_DEPRECATED/components/Box';
import { Divider } from 'shared_DEPRECATED/components/Divider';

type TBoardDropZone = {
  canDrop: boolean;
  isOver: boolean;
  isLast?: boolean;
  children: ReactNode;
};

export const BoardDropZone = forwardRef<HTMLDivElement, TBoardDropZone>(
  ({ canDrop, isOver, isLast, children }, ref) => (
    <Box
      style={{
        width: '100%',
        height: isLast ? '20px' : 'auto',
      }}
      ref={ref}
    >
      <Divider
        color={isOver && canDrop ? 'var(--fgInteractive)' : 'transparent'}
        strokeWidth={3}
      />
      {children}
    </Box>
  )
);
