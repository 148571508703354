import { useFormContext } from 'react-hook-form';

import Box from 'shared_DEPRECATED/components/Box';
import { Flex } from 'shared_DEPRECATED/components/Flex';
import { RadioButton } from 'shared_DEPRECATED/components/Form/Radio';
import Spacer from 'shared_DEPRECATED/components/Spacer';

import { Typography } from 'shared/components/Typography';

const effectivenessRates = ['1', '2', '3', '4', '5'];

export const SessionsFormCoachingToolsEffectivenessRate = ({
  formKey,
  currentRate,
}: {
  formKey: string;
  currentRate: string;
}) => {
  const { register } = useFormContext();

  return (
    <Flex flexDirection="column" alignItems="center" gap="var(--spacing04)">
      <Typography type="body" fontWeight="medium" color="black">
        Coach Perceived Rate of Effectiveness
      </Typography>
      <Typography type="meta" color="gray">
        On a scale of 1 - 5, rate how effective this coaching tool was for this
        participant.
      </Typography>
      <Flex gap="var(--spacing06)">
        {effectivenessRates.map((rate) => (
          <Box
            key={rate}
            as="label"
            style={{
              cursor: 'pointer',
            }}
          >
            <Flex
              flexDirection="column"
              alignItems="flex-start"
              dataTestid="session-tool-rate-btn"
            >
              <RadioButton
                checked={currentRate === rate}
                {...register(`${formKey}.toolEffectivenessRate`)}
                //@ts-ignore
                value={rate}
              />
              <Spacer size="lg zr zr sm">
                <Typography color="black">{rate}</Typography>
              </Spacer>
            </Flex>
          </Box>
        ))}
      </Flex>
    </Flex>
  );
};
