import PropTypes from 'prop-types';
import { useQueryClient } from 'react-query';

import { getNoteRepliesQueryKey } from 'api/utils';
import { useNotesAnalytics } from 'app/analytics/useNotesAnalytics';

import {
  ChallengeNotesCard,
  ChallengeNotesCardReactions,
} from 'features/challengeNotes/components/Card';
import { ChallengeNotesProofCard } from 'features/challengeNotes/components/Card/ProofCard';
import { NOTE_TYPES } from 'features/challengeNotes/config';
import { NoteType } from 'features/challengeNotes/config/propTypes';
import { updateReactions } from 'features/challengeNotes/utils';

import { useSidebarContext } from 'shared/components/Sidebar/context';

const ChallengeNotesCardPropTypes = {
  note: NoteType,
  onReactionsError: PropTypes.func.isRequired,
  onReactionsMutate: PropTypes.func.isRequired,
  onReactionsSuccess: PropTypes.func.isRequired,
};

const ChallengeNotesCardWithReactions = ({
  note,
  onReactionsError,
  onReactionsMutate,
  onReactionsSuccess,
}) => (
  <ChallengeNotesCard note={note}>
    <ChallengeNotesCardReactions
      noteType={note.noteType}
      noteStatus={note.status}
      noteId={note.noteId}
      reactions={note.reactions}
      userEmail={note.userEmail}
      onError={onReactionsError}
      onMutate={onReactionsMutate}
      onSuccess={onReactionsSuccess}
    />
  </ChallengeNotesCard>
);

ChallengeNotesCardWithReactions.propTypes = ChallengeNotesCardPropTypes;

const ChallengeNotesCardProofWithReactions = ({
  note,
  onReactionsError,
  onReactionsMutate,
  onReactionsSuccess,
}) => (
  <ChallengeNotesProofCard note={note}>
    <ChallengeNotesCardReactions
      noteType={note.noteType}
      noteStatus={note.status}
      noteId={note.noteId}
      reactions={note.reactions}
      userEmail={note.userEmail}
      onError={onReactionsError}
      onMutate={onReactionsMutate}
      onSuccess={onReactionsSuccess}
    />
  </ChallengeNotesProofCard>
);

ChallengeNotesCardProofWithReactions.propTypes = ChallengeNotesCardPropTypes;

const noteComponentsMap = {
  [NOTE_TYPES.CHALLENGE_NOTE]: ChallengeNotesCardWithReactions,
  [NOTE_TYPES.CHALLENGE_PROOF_NOTE]: ChallengeNotesCardProofWithReactions,
};

export const ChallengeNotesRepliesCard = ({ note }) => {
  const { user } = useSidebarContext();
  const NoteComponent = noteComponentsMap[note.noteType];
  const noteRepliesQueryKey = getNoteRepliesQueryKey(note.noteId);
  const { sendNoteReplyReactionAddedAnalyticsEvent } = useNotesAnalytics();

  const queryClient = useQueryClient();

  const onReactionsError = () => {
    queryClient.invalidateQueries(noteRepliesQueryKey);
  };

  const onReactionsMutate = ({ emoji, type }) => {
    queryClient.setQueryData(noteRepliesQueryKey, (old) => {
      const updatedNote = {
        ...old.note,
        reactions: updateReactions({
          reactions: old.note.reactions,
          emoji,
          type,
          userInfo: user,
        }),
      };

      return {
        replies: old.replies,
        note: updatedNote,
      };
    });
  };

  return (
    <NoteComponent
      note={note}
      onReactionsError={onReactionsError}
      onReactionsMutate={onReactionsMutate}
      onReactionsSuccess={sendNoteReplyReactionAddedAnalyticsEvent}
    />
  );
};

ChallengeNotesRepliesCard.propTypes = { note: NoteType };
