import { ReactNode } from 'react';

import { Flex, FlexItem } from 'shared_DEPRECATED/components/Flex';

type FileActionsProps = {
  children: ReactNode;
};

export const FileActions = ({ children }: FileActionsProps) => (
  <FlexItem flexGrow={0} flexShrink={0}>
    <Flex gap="var(--spacing01)">{children}</Flex>
  </FlexItem>
);
