import { createContext, ReactNode, useContext, useMemo } from 'react';

import EventEmitter from 'events';

import { useAuthToken } from 'features/auth/hooks/useAuthToken';
import { OutgoingSendMessages } from 'features/chat/config/types/Message';

import { useSocket } from './useSocket';

type SocketProviderProps = {
  children: ReactNode;
};

export const Emitter = new EventEmitter();

export const on = Emitter.on.bind(Emitter);
export const off = Emitter.off.bind(Emitter);

const defaultContextValue = {
  emit: (data: OutgoingSendMessages) => {},
  isSocketReady: false,
};

export const SocketContext = createContext(defaultContextValue);

export function SocketProvider({ children }: SocketProviderProps) {
  const token = useAuthToken();

  const { emit, isSocketReady } = useSocket(token);

  const providerValue = useMemo(
    () => ({
      isSocketReady,
      emit,
    }),
    [isSocketReady, emit]
  );

  return (
    <SocketContext.Provider value={providerValue}>
      {children}
    </SocketContext.Provider>
  );
}

export const useSocketContext = () => useContext(SocketContext);
