import Linkify from 'linkify-react';

import Spacer from 'shared_DEPRECATED/components/Spacer';

import { SessionsFormCoachingToolsSection } from 'features/sessions/components/Form/CoachingTools/Section';

import { Typography } from 'shared/components/Typography';

type TSessionsFormCoachingToolsLink = { link: string };

export const SessionsFormCoachingToolsLink = ({
  link,
}: TSessionsFormCoachingToolsLink) => (
  <SessionsFormCoachingToolsSection>
    <Typography type="meta" fontWeight="semiBold">
      Tool description link
    </Typography>
    <Spacer size="md zr" />
    <Linkify options={{ target: '_blank' }}>{link}</Linkify>
  </SessionsFormCoachingToolsSection>
);
