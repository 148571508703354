import { iconNames } from 'shared_DEPRECATED/components/Icon';
import { Nullable } from 'shared_DEPRECATED/types';
import { dateUtils } from 'shared_DEPRECATED/utils';

import {
  FocusAreaType,
  GoalBreakdownType,
  GoalBreakdownChallengeType,
  BaseColumnType,
  ColumnItemsType,
} from 'features/goal';

const removeChallenge = (
  goalBreakdown: GoalBreakdownType,
  updatedChallenge: GoalBreakdownChallengeType
): GoalBreakdownType => {
  const updatedGoalBreakdown = { ...goalBreakdown };

  if (updatedChallenge.parentFocusAreaId) {
    const oldFocusAreaIndex = updatedGoalBreakdown.focusAreas.findIndex(
      (focusArea) => focusArea.id === updatedChallenge.parentFocusAreaId
    );

    const oldFocusArea = updatedGoalBreakdown.focusAreas[oldFocusAreaIndex];
    const challengeIndex = oldFocusArea.challenges.findIndex(
      (challenge) =>
        challenge.customChallengeId === updatedChallenge.customChallengeId
    );

    oldFocusArea.challenges.splice(challengeIndex, 1);
  } else {
    const challengeIndex = updatedGoalBreakdown.challenges.findIndex(
      (challenge) =>
        challenge.customChallengeId === updatedChallenge.customChallengeId
    );

    updatedGoalBreakdown.challenges.splice(challengeIndex, 1);
  }

  return updatedGoalBreakdown;
};

const addChallenge = (
  goalBreakdown: GoalBreakdownType,
  updatedChallenge: GoalBreakdownChallengeType,
  focusAreaId?: string
): GoalBreakdownType => {
  const updatedGoalBreakdown = { ...goalBreakdown };

  let targetChallenges;

  if (!focusAreaId) {
    targetChallenges = updatedGoalBreakdown.challenges;
  } else {
    const focusAreaIndex = updatedGoalBreakdown.focusAreas.findIndex(
      (focusArea) => focusArea.id === focusAreaId
    );

    targetChallenges =
      updatedGoalBreakdown.focusAreas[focusAreaIndex].challenges;
  }

  targetChallenges.splice(updatedChallenge.index, 0, updatedChallenge);

  return updatedGoalBreakdown;
};

export const replaceChallenge = ({
  goalBreakdown,
  updatedChallenge,
  focusAreaId,
}: {
  goalBreakdown: GoalBreakdownType;
  updatedChallenge: GoalBreakdownChallengeType;
  focusAreaId?: string;
}): GoalBreakdownType => {
  let newGoalBreakdown;

  newGoalBreakdown = removeChallenge(goalBreakdown, updatedChallenge);
  newGoalBreakdown = addChallenge(
    newGoalBreakdown,
    updatedChallenge,
    focusAreaId
  );

  return newGoalBreakdown;
};

export const moveFocusArea = ({
  goalBreakdown,
  updatedFocusArea,
}: {
  goalBreakdown: GoalBreakdownType;
  updatedFocusArea: FocusAreaType & { index: number };
}): GoalBreakdownType => {
  const updatedGoalBreakdown = { ...goalBreakdown };

  const focusAreaIndex = updatedGoalBreakdown.focusAreas.findIndex(
    (focusArea) => focusArea.id === updatedFocusArea.id
  );

  updatedGoalBreakdown.focusAreas.splice(focusAreaIndex, 1);

  updatedGoalBreakdown.focusAreas.splice(
    updatedFocusArea.index,
    0,
    updatedFocusArea
  );

  return updatedGoalBreakdown;
};

export const isItemNextToTheDropZone = (
  itemIndex: number,
  dropZoneIndex: number
) => itemIndex === dropZoneIndex || itemIndex + 1 === dropZoneIndex;

export const canItemBeDroppedInDropZone = ({
  parentId,
  itemId,
  index,
  dropZoneIndex,
}: {
  parentId?: string;
  itemId: string;
  index: number;
  dropZoneIndex: number;
}) => {
  const isItemOutsideOfParent = !parentId || !(parentId === itemId);

  return (
    isItemOutsideOfParent || !isItemNextToTheDropZone(index, dropZoneIndex)
  );
};

export const canItemBeDroppedInChallengeDropZone = ({
  parentFocusAreaId,
  index,
  dropZoneIndex,
}: {
  parentFocusAreaId?: string;
  index: number;
  dropZoneIndex: number;
}) => !!parentFocusAreaId || !isItemNextToTheDropZone(index, dropZoneIndex);

export const canFocusAreaBeDroppedInDropZone = ({
  index,
  dropZoneIndex,
}: {
  index: number;
  dropZoneIndex: number;
}) => !isItemNextToTheDropZone(index, dropZoneIndex);

export const reorder = <T extends BaseColumnType>(
  originalArray: ColumnItemsType<T>,
  startIndex: number,
  endIndex: number
) => {
  const result = [...originalArray];
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

export const remove = <T extends BaseColumnType>(
  arr: ColumnItemsType<T>,
  index: number
) => [...arr.slice(0, index), ...arr.slice(index + 1)];

export const insert = <T extends BaseColumnType>(
  arr: ColumnItemsType<T>,
  index: number,
  newItem: T
) => [...arr.slice(0, index), newItem, ...arr.slice(index)];

export const updateChild = <T extends BaseColumnType>(
  children: ColumnItemsType<T>,
  curIndex: number,
  fieldName: string,
  updateOperation: (nodeChildrenField: ColumnItemsType<T>) => ColumnItemsType<T>
) => {
  const updatedChildren = [...children];
  const nodeChildren = updatedChildren[curIndex];

  updatedChildren[curIndex] = {
    ...nodeChildren,
    [fieldName]: updateOperation(nodeChildren[fieldName]),
  };

  return updatedChildren;
};

export const reorderChildren = <T extends BaseColumnType>(
  children: ColumnItemsType<T>,
  dropZonePath: number[],
  itemPath: number[],
  fieldName: string
): ColumnItemsType<T> => {
  const [dropZoneIndex, ...restOfDropZonePath] = dropZonePath;
  const [itemIndex, ...restOfItemPath] = itemPath;

  if (dropZonePath.length === 1) {
    return reorder(children, itemIndex, dropZoneIndex);
  }

  return updateChild(children, dropZoneIndex, fieldName, (nodeChildrenField) =>
    reorderChildren(
      nodeChildrenField,
      restOfDropZonePath,
      restOfItemPath,
      fieldName
    )
  );
};

export const removeChildFromChildren = <T extends BaseColumnType>(
  children: ColumnItemsType<T>,
  itemPath: number[],
  fieldName: string
): ColumnItemsType<T> => {
  const [curIndex, ...restOfItemPath] = itemPath;

  if (itemPath.length === 1) {
    return remove(children, curIndex);
  }

  return updateChild(children, curIndex, fieldName, (nodeChildrenField) =>
    removeChildFromChildren(nodeChildrenField, restOfItemPath, fieldName)
  );
};

export const addChildToChildren = <T extends BaseColumnType>(
  children: ColumnItemsType<T>,
  dropZonePath: number[],
  item: T,
  fieldName: string
): ColumnItemsType<T> => {
  const [curIndex, ...restOfDropZonePath] = dropZonePath;

  if (dropZonePath.length === 1) {
    return insert(children, curIndex, item);
  }

  return updateChild(children, curIndex, fieldName, (nodeChildrenField) =>
    addChildToChildren(nodeChildrenField, restOfDropZonePath, item, fieldName)
  );
};

export const handleMoveWithinParent = <T extends BaseColumnType>(
  layout: ColumnItemsType<T>,
  dropZonePath: number[],
  itemPath: number[],
  fieldName: string
) => reorderChildren(layout, dropZonePath, itemPath, fieldName);

export const handleMoveToDifferentParent = <T extends BaseColumnType>(
  layout: ColumnItemsType<T>,
  dropZonePath: number[],
  itemPath: number[],
  item: T,
  fieldName: string
) => {
  let updatedLayout = layout;

  updatedLayout = removeChildFromChildren(updatedLayout, itemPath, fieldName);

  updatedLayout = addChildToChildren(
    updatedLayout,
    dropZonePath,
    item,
    fieldName
  );

  return updatedLayout;
};

export const getGoalDeadlineStyles = ({
  isDeadlineExpired,
  isCompleted,
}: {
  isDeadlineExpired: boolean;
  isCompleted: boolean;
}) => {
  if (isCompleted) {
    return {
      badgeColor: 'lightGreen',
      textColor: 'green',
      iconName: iconNames.filledTick,
      iconColor: 'var(--fgSuccess)',
    };
  }

  if (isDeadlineExpired) {
    return {
      badgeColor: 'lightRed',
      textColor: 'red',
      iconName: iconNames.clock,
      iconColor: 'var(--fgDanger)',
    };
  }

  return {
    badgeColor: 'lightBlue',
    textColor: 'blue',
    iconName: iconNames.clock,
    iconColor: 'var(--fgInteractive)',
  };
};

export const getGoalDeadlineStatus = (deadline?: Nullable<string>) =>
  dateUtils().isSameOrAfter(deadline);

export const getGoalFormLimitErrorText = (limit: number) =>
  `The text is too long. The max. length is ${limit} symbols.`;
