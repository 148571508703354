import { useCallback } from 'react';

import {
  sendChallengeAddedAnalyticsEvent,
  sendChallengeAddedFromLibAnalyticsEvent,
  sendChallengeMarkedAnalyticsEvent,
  sendChallengeRemovedAnalyticsEvent,
  sendFrequencySelectedAnalyticsEvent,
  sendTargetSelectedAnalyticsEvent,
} from 'app/analytics';
import { UserMode } from 'shared_DEPRECATED/types/User';

import { CHALLENGE_TYPES } from 'features/challenge/config';
import { useUserMode } from 'features/user/hooks';

import { SprintStateValues } from '../config/types';

export const useChallengeAnalytics = () => {
  const userMode = useUserMode() as UserMode;
  const sendChallengeAddedFromLibEvent = useCallback(() => {
    sendChallengeAddedFromLibAnalyticsEvent({ userMode });
  }, [userMode]);

  const sendChallengeRemovedEvent = useCallback(() => {
    sendChallengeRemovedAnalyticsEvent({ userMode });
  }, [userMode]);

  const sendTargetSelectedEvent = useCallback(() => {
    sendTargetSelectedAnalyticsEvent({ userMode });
  }, [userMode]);

  const sendFrequencySelectedEvent = useCallback(() => {
    sendFrequencySelectedAnalyticsEvent({ userMode });
  }, [userMode]);

  const sendChallengeAddedEvent = useCallback(
    (sprintState: SprintStateValues) =>
      sendChallengeAddedAnalyticsEvent({
        type: sprintState,
        userMode,
      }),
    [userMode]
  );

  const sendChallengeMarkedEvent = useCallback(
    ({
      type,
      isTargetChallenge,
      status = '',
    }: {
      type: string;
      isTargetChallenge: boolean;
      status?: string;
    }) => {
      // user_mode:
      const challengeType =
        type === CHALLENGE_TYPES.NON_REPEATING ? 'one_time' : 'repeated';
      sendChallengeMarkedAnalyticsEvent({
        userMode,
        status,
        type: challengeType,
        isTargetChallenge,
      });
    },
    [userMode]
  );

  return {
    sendChallengeAddedEvent,
    sendChallengeAddedFromLibEvent,
    sendChallengeRemovedEvent,
    sendTargetSelectedEvent,
    sendFrequencySelectedEvent,
    sendChallengeMarkedEvent,
  };
};
