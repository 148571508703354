import { toastConfig } from 'lib/reactToastify/config';
import { lazy, Suspense, useCallback } from 'react';
import { toast } from 'react-toastify';

import Loader from 'shared_DEPRECATED/components/Loader';
import lazyRetry from 'shared_DEPRECATED/utils/lazyRetry';

import { useEditGoalFocusAreasMutation } from 'features/goal';

import { modal, SIZES } from 'shared/components/Modal';

const GoalAddFocusAreaDialogContent = lazy(() =>
  lazyRetry(
    () => import('features/goal/components/FocusArea/Add/Dialog'),
    'GoalAddFocusAreaDialog'
  )
);

type TUseEditGoalFocusArea = {
  focusAreaId: string;
  title: string;
};

export const useEditGoalFocusArea = ({
  focusAreaId,
  title,
}: TUseEditGoalFocusArea) => {
  const { mutateAsync } = useEditGoalFocusAreasMutation();

  const onSubmit = useCallback(
    async ({ title }: { title: string }) => {
      try {
        await mutateAsync({ title, focusAreaId });
      } catch (err) {
        toast.error(
          "There's an error with editing the focus area. Please try again later.",
          toastConfig
        );
      }
    },
    [focusAreaId, mutateAsync]
  );

  const openEditGoalFocusAreaModal = useCallback(() => {
    modal.open({
      content: (
        <Suspense fallback={<Loader size="sm" />}>
          <GoalAddFocusAreaDialogContent onSubmit={onSubmit} title={title} />
        </Suspense>
      ),
      props: {
        size: SIZES.MD,
      },
    });
  }, [onSubmit, title]);

  return {
    onEditFocusAreaClick: openEditGoalFocusAreaModal,
  };
};
