import Box from 'shared_DEPRECATED/components/Box';
import { Flex, FlexItem } from 'shared_DEPRECATED/components/Flex';
import Image from 'shared_DEPRECATED/components/Image/Image';

type FileImageProps = {
  src: string;
};

export const FileImage = ({ src }: FileImageProps) => {
  return (
    <FlexItem flexGrow={0} flexShrink={0}>
      <Box
        style={{
          width: '2rem',
          height: '2rem',
        }}
      >
        <Flex
          height="100%"
          width="100%"
          justifyContent="center"
          alignItems="center"
        >
          <Image src={src} />
        </Flex>
      </Box>
    </FlexItem>
  );
};
