import PropTypes from 'prop-types';

import { Flex } from 'shared_DEPRECATED/components/Flex';

export const ChallengeNotesCardHeader = ({ children }) => {
  return <Flex>{children}</Flex>;
};

ChallengeNotesCardHeader.propTypes = {
  children: PropTypes.node.isRequired,
};
