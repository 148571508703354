import { useCallback } from 'react';

import { ANALYTICS_EVENTS } from './config';
import { useSendAnalytics } from './useSendAnalytics';

export const usePlanningAnalyticsEvents = () => {
  const sendAnalyticsEvent = useSendAnalytics();

  const sendPlanningChallengeAddedEvent = useCallback(
    (source: 'backlog' | 'sprintDraft') => {
      sendAnalyticsEvent({
        event: ANALYTICS_EVENTS.PLANNING_CHALLENGE_ADDED,
        data: { source },
      });
    },
    [sendAnalyticsEvent]
  );

  const sendPlanningChallengeEditedEvent = useCallback(() => {
    sendAnalyticsEvent({
      event: ANALYTICS_EVENTS.PLANNING_CHALLENGE_EDITED,
    });
  }, [sendAnalyticsEvent]);

  const sendPlanningChallengeRemovedEvent = useCallback(() => {
    sendAnalyticsEvent({
      event: ANALYTICS_EVENTS.PLANNING_CHALLENGE_REMOVED,
    });
  }, [sendAnalyticsEvent]);

  const sendPlanningChallengeMovedEvent = useCallback(() => {
    sendAnalyticsEvent({
      event: ANALYTICS_EVENTS.PLANNING_CHALLENGE_MOVED,
    });
  }, [sendAnalyticsEvent]);

  const sendPlanningChallengeOrderChangedEvent = useCallback(() => {
    sendAnalyticsEvent({
      event: ANALYTICS_EVENTS.PLANNING_CHALLENGE_ORDER_CHANGED,
    });
  }, [sendAnalyticsEvent]);

  const sendPlanningSprintDraftAddedEvent = useCallback(() => {
    sendAnalyticsEvent({
      event: ANALYTICS_EVENTS.PLANNING_SPRINT_DRAFT_ADDED,
    });
  }, [sendAnalyticsEvent]);

  const sendPlanningSprintDraftDeletedEvent = useCallback(() => {
    sendAnalyticsEvent({
      event: ANALYTICS_EVENTS.PLANNING_SPRINT_DRAFT_DELETED,
    });
  }, [sendAnalyticsEvent]);

  const sendPlanningSprintStartEvent = useCallback(() => {
    sendAnalyticsEvent({
      event: ANALYTICS_EVENTS.PLANNING_START_SPRINT,
    });
  }, [sendAnalyticsEvent]);

  const sendPlanningViewPastSprintClickedEvent = useCallback(() => {
    sendAnalyticsEvent({
      event: ANALYTICS_EVENTS.PLANNING_VIEW_PAST_SPRINT_CLICKED,
    });
  }, [sendAnalyticsEvent]);

  const sendPlanningViewCurrentSprintClickedEvent = useCallback(() => {
    sendAnalyticsEvent({
      event: ANALYTICS_EVENTS.PLANNING_VIEW_CURRENT_SPRINT_CLICKED,
    });
  }, [sendAnalyticsEvent]);

  const sendPlanningFilterAppliedEvent = useCallback(() => {
    sendAnalyticsEvent({
      event: ANALYTICS_EVENTS.FILTER_APPLIED,
    });
  }, [sendAnalyticsEvent]);

  return {
    sendPlanningChallengeAddedEvent,
    sendPlanningChallengeEditedEvent,
    sendPlanningChallengeRemovedEvent,
    sendPlanningChallengeMovedEvent,
    sendPlanningChallengeOrderChangedEvent,
    sendPlanningSprintDraftAddedEvent,
    sendPlanningSprintDraftDeletedEvent,
    sendPlanningSprintStartEvent,
    sendPlanningViewPastSprintClickedEvent,
    sendPlanningViewCurrentSprintClickedEvent,
    sendPlanningFilterAppliedEvent,
  };
};
