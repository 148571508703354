import {
  autoUpdate,
  flip,
  offset,
  shift,
  useFloating,
  useHover,
  useInteractions,
} from '@floating-ui/react-dom-interactions';
import PropTypes from 'prop-types';
import { useState } from 'react';

import { FloatingBody } from 'shared_DEPRECATED/components/Floating/Popover/FloatingBody';
import { PLACEMENTS } from 'shared_DEPRECATED/components/Floating/config';

const FloatingPopoverHoverPropTypes = {
  anchor: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
  openDelay: PropTypes.number,
  placement: PropTypes.oneOf(Object.values(PLACEMENTS)),
};

export const FloatingPopoverUncontrolledHover = ({
  anchor,
  children,
  openDelay = 800,
  placement = PLACEMENTS.BOTTOM_START,
}) => {
  const [open, setOpen] = useState(false);

  const { x, y, reference, floating, strategy, context } = useFloating({
    open,
    onOpenChange: setOpen,
    placement,
    whileElementsMounted: autoUpdate,
    middleware: [offset(0), flip(), shift()],
  });

  const hover = useHover(context, {
    move: false,
    delay: { open: openDelay, close: 0 },
  });

  const { getReferenceProps, getFloatingProps } = useInteractions([hover]);

  return (
    <div ref={reference} {...getReferenceProps()}>
      {anchor}
      {open && (
        <FloatingBody
          ref={floating}
          position={strategy}
          top={y ?? 0}
          left={x ?? 0}
          {...getFloatingProps()}
        >
          {children}
        </FloatingBody>
      )}
    </div>
  );
};

FloatingPopoverUncontrolledHover.propTypes = FloatingPopoverHoverPropTypes;
