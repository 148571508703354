import { useMutation } from 'react-query';

import { logError } from 'lib/sentry/logError';
import { useMutationHTTPRequest } from 'shared_DEPRECATED/hooks';

import { CoachingMode } from 'features/participants/config/types';

type useCoachingModeMutationPropsType = {
  userEmail: string;
  onUpdateQuery: ({
    userEmail,
    fieldKey,
  }: {
    userEmail: string;
    fieldKey: string;
  }) => (updateMode: CoachingMode) => void;
};

export const useCoachingModeMutation = ({
  userEmail,
  onUpdateQuery,
}: useCoachingModeMutationPropsType) => {
  const { request } = useMutationHTTPRequest();

  const updateParticipantsQuery = onUpdateQuery({
    userEmail,
    fieldKey: 'coachingMode',
  });

  return useMutation<
    void,
    unknown,
    { updatedMode: CoachingMode; currentMode: CoachingMode }
  >(
    async ({ updatedMode }) => {
      await request({
        url: '/api/users/coaching-mode/set',
        body: {
          userEmail,
          coachingMode: updatedMode,
        },
      });
    },
    {
      onMutate: ({ updatedMode }) => {
        updateParticipantsQuery(updatedMode);
      },
      onError: (err, { currentMode }) => {
        updateParticipantsQuery(currentMode);
        logError(err);
      },
    }
  );
};
