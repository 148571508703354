import Box from 'shared_DEPRECATED/components/Box';
import { Flex } from 'shared_DEPRECATED/components/Flex';

import { SprintsType } from 'features/sprint/config/types';

import { Dropdown, DropdownItem } from 'shared/components/Dropdown';
import { Typography } from 'shared/components/Typography';

type SprintDropdownProps = {
  sprints: SprintsType;
  selectedSprintId: string;
  setSelectedSprintId: (sprintId: string) => void;
};

export const SprintDropdown = ({
  sprints,
  selectedSprintId,
  setSelectedSprintId,
}: SprintDropdownProps) => (
  <Typography type="meta">
    <Dropdown
      onItemSelect={
        ((selectedSprintId: string) => {
          setSelectedSprintId(selectedSprintId);
        }) as () => void
      }
      anchorContent={
        <Box style={{ maxWidth: '10rem' }}>
          <Typography cropped={true}>
            {
              sprints.find((sprint) => sprint.sprintId === selectedSprintId)
                ?.title
            }
          </Typography>
        </Box>
      }
      selectedValue={selectedSprintId}
    >
      <Box style={{ overflow: 'scroll', maxHeight: '60vh' }}>
        {sprints.map((sprint) => (
          <Box key={sprint.sprintId} style={{ width: '15rem' }} role="listbox">
            <Flex alignItems="stretch" flexDirection="column">
              <DropdownItem value={sprint.sprintId}>
                {sprint.title}
              </DropdownItem>
            </Flex>
          </Box>
        ))}
      </Box>
    </Dropdown>
  </Typography>
);
