import PropTypes from 'prop-types';

import Loader from 'shared_DEPRECATED/components/Loader/Loader';

import { ChallengeNotesEmptyState } from 'features/challengeNotes/components/ChallengeNotesEmptyState';
import { ChallengeNotesFilterEmptyState } from 'features/challengeNotes/components/Filter';
import {
  useChallengeNotesContext,
  useChallengeNotesFilterContext,
} from 'features/challengeNotes/context';
import { USER_MODES } from 'features/user/config';
import { useCurrentModeUser } from 'features/user/hooks/useCurrentModeUser';

const ChallengeNotesContentContainerPropTypes = {
  children: PropTypes.node.isRequired,
};

export const ChallengeNotesContentContainer = ({ children }) => {
  const { isLoading, notes } = useChallengeNotesContext();
  const { notesFiltered } = useChallengeNotesFilterContext();
  const userMode = useCurrentModeUser();
  const emptyStateText =
    userMode === USER_MODES.dashboard
      ? 'The ability to add notes from the web will be added soon.'
      : 'Get insights from participant about the challenge or blockers.';

  if (isLoading || !notes) {
    return <Loader />;
  }

  if (notesFiltered && notes.length === 0) {
    return <ChallengeNotesFilterEmptyState />;
  }

  if (notes.length === 0) {
    return <ChallengeNotesEmptyState text={emptyStateText} />;
  }

  return children;
};

ChallengeNotesContentContainer.propTypes =
  ChallengeNotesContentContainerPropTypes;
