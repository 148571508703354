import Button, { VARIANTS } from 'shared_DEPRECATED/components/Button';
import { Flex, FlexItem } from 'shared_DEPRECATED/components/Flex';
import Spacer from 'shared_DEPRECATED/components/Spacer/Spacer';

import { Typography } from 'shared/components/Typography';

type CoachingToolsCardEditButtonsProps = {
  canBeDeleted: boolean;
  onDelete: () => void;
  onSave: () => void;
  disabled: boolean;
};
export const CoachingToolsCardEditButtons = ({
  canBeDeleted,
  onDelete,
  onSave,
  disabled,
}: CoachingToolsCardEditButtonsProps) => {
  return (
    <Flex gap="var(--spacing04)" width="100%">
      {canBeDeleted && (
        <FlexItem flexGrow={1}>
          <Flex alignItems="stretch" flexDirection="column" width="100%">
            <Button variant={VARIANTS.SECONDARY} onClick={onDelete}>
              <Spacer size="md">
                <Typography type="meta" color="gray" textAlign="center">
                  Delete
                </Typography>
              </Spacer>
            </Button>
          </Flex>
        </FlexItem>
      )}
      <FlexItem flexGrow={1}>
        <Flex alignItems="stretch" flexDirection="column" width="100%">
          <Button
            variant={VARIANTS.PRIMARY}
            disabled={disabled}
            onClick={onSave}
            dataTestid="rate-session-tool-save-btn"
          >
            <Spacer size="md">
              <Typography
                type="meta"
                color="whiteTotal"
                textAlign="center"
                // dataTestid="rate-session-tool-save-btn"
              >
                Save
              </Typography>
            </Spacer>
          </Button>
        </Flex>
      </FlexItem>
    </Flex>
  );
};
