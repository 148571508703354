import { lazy } from 'react';

import LazyFeatureFlagComponent from 'featureFlags/components/LazyComponent';
import { PLANNING_FEATURE_FLAG } from 'featureFlags/config';
import lazyRetry from 'shared_DEPRECATED/utils/lazyRetry';

const Planning = lazy(() =>
  lazyRetry(() => import('app/pages/planning/Planning'), 'Planning')
);

export const PlanningWithFeatureFlag = (props: object) => (
  <LazyFeatureFlagComponent
    featureFlag={PLANNING_FEATURE_FLAG}
    lazyComponent={Planning}
    {...props}
  />
);
