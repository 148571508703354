import {
  IMPROVED_GROUP_VIEW_FEATURE_FLAG,
  RenderIfFFEnabled,
} from 'featureFlags';
import { OptionsMenu } from 'shared_DEPRECATED/components/Floating/Menu/Options';
import { iconNames } from 'shared_DEPRECATED/components/Icon';

import {
  GoalMenuEditButton,
  GoalMenuDeleteButton,
  GoalCardType,
} from 'features/goal';
import { usePrioritizeToggleOnBoard } from 'features/goal/hooks/usePrioritizeToggleOnBoard';

import { GoalCardMenuPrioritizeButton } from './Menu/PrioritizeButton';

export const GoalCardMenu = ({
  goalId,
  columnId,
  name,
  dimensions,
  deadline,
  deepDive,
  columnLabel,
  completedAt,
  note,
  prioritizedAt,
}: GoalCardType) => {
  const togglePrioritizeOnBoard = usePrioritizeToggleOnBoard();

  return (
    //@ts-ignore
    <OptionsMenu icon={iconNames.ellipsisHorizontal}>
      <GoalMenuEditButton
        goalId={goalId}
        name={name}
        columnId={columnId}
        deadline={deadline}
        dimensions={dimensions}
        deepDive={deepDive}
        columnLabel={columnLabel}
        completedAt={completedAt}
        note={note}
      />
      <RenderIfFFEnabled featureFlag={IMPROVED_GROUP_VIEW_FEATURE_FLAG}>
        <GoalCardMenuPrioritizeButton
          goalId={goalId}
          prioritizedAt={prioritizedAt}
          columnId={columnId}
          togglePrioritize={togglePrioritizeOnBoard}
        />
      </RenderIfFFEnabled>
      <GoalMenuDeleteButton goalId={goalId} columnId={columnId} />
    </OptionsMenu>
  );
};
