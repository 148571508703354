import { ReactNode } from 'react';

import Button from 'shared_DEPRECATED/components/Button';
import { Flex } from 'shared_DEPRECATED/components/Flex';
import Spacer from 'shared_DEPRECATED/components/Spacer';
import { SWITCH_DIMENSIONS } from 'shared_DEPRECATED/components/Switch/theme/config';

type Sizes = 'sm' | 'md';

type TSwitchButton = {
  checked: boolean;
  onChange: (checked: boolean) => void;
  disabled?: boolean;
  dataTestid?: string;
  ariaLabel?: string;
  children: ReactNode | null;
  size?: Sizes;
};

const SwitchButton = ({
  checked = false,
  onChange = () => {},
  disabled = false,
  dataTestid = '',
  ariaLabel = '',
  children = null,
  size = 'sm',
}: TSwitchButton) => (
  <Button
    onClick={() => onChange(!checked)}
    disabled={disabled}
    dataTestid={`${dataTestid}-button${checked ? '-checked' : ''}`}
    ariaLabel={ariaLabel}
  >
    <Spacer size="xsm">
      <Flex
        width={`calc(${SWITCH_DIMENSIONS[size].width} - 0.25rem)`}
        justifyContent={checked ? 'flex-end' : 'flex-start'}
      >
        {children}
      </Flex>
    </Spacer>
  </Button>
);

export default SwitchButton;
