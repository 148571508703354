import { useQuery } from 'react-query';

import { useQueryHTTPRequest } from 'api/hooks/useHTTPRequest';

import { DraftSprint, ParticipantSprint, Sprint } from 'features/sprint';
import { QUERY_KEYS, SPRINT_TYPES, URLS } from 'features/sprint/config';
import { useSprintType } from 'features/sprint/hooks/useSprintType';
import { isDraftSprint } from 'features/sprint/utils';

const getSprintClass = (sprintType, isDraft) => {
  if (isDraft) {
    return DraftSprint;
  }

  return sprintType === SPRINT_TYPES.COACHED ? ParticipantSprint : Sprint;
};

const useSprintQuery = ({ sprintId, options }) => {
  const sprintType = useSprintType();
  const { request } = useQueryHTTPRequest();

  return useQuery(
    QUERY_KEYS.SPRINT(sprintId),
    () => request({ url: URLS.SPRINT(sprintId) }),
    {
      select: (sprint) => {
        const isDraft = isDraftSprint(sprint.state);
        const SprintClass = getSprintClass(sprintType, isDraft);

        return new SprintClass({ ...sprint, type: sprintType });
      },
      ...options,
    }
  );
};

export default useSprintQuery;
