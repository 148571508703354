import { When } from 'react-if';
import { For } from 'react-loops';
import { useQueryClient } from 'react-query';

import { useNotesAnalytics } from 'app/analytics/useNotesAnalytics';
import { SidebarBody, Typography, useSidebarContext } from 'shared';
import Box from 'shared_DEPRECATED/components/Box/Box';
import Spacer from 'shared_DEPRECATED/components/Spacer';

import {
  ChallengeNotesCard,
  ChallengeNotesCardReactions,
  ChallengeNotesCardReplies,
} from 'features/challengeNotes/components/Card';
import { ChallengeNotesProofCard } from 'features/challengeNotes/components/Card/ProofCard';
import { ChallengeNotesContentContainer } from 'features/challengeNotes/components/ContentContainer';
import { NOTE_TYPES } from 'features/challengeNotes/config';
import { useChallengeNotesContext } from 'features/challengeNotes/context';
import { useChallengeNotesQueryKey } from 'features/challengeNotes/hooks';
import { updateReactions } from 'features/challengeNotes/utils';
import { groupBySprintWeek } from 'features/sprint/utils';

import { ChallengeNotesChallengeFilter } from '../ChallengeFilter';

const challengeNotesCardsByType = {
  [NOTE_TYPES.CHALLENGE_NOTE]: ChallengeNotesCard,
  [NOTE_TYPES.CHALLENGE_PROOF_NOTE]: ChallengeNotesProofCard,
};

export const ChallengeNotesSidebarBody = () => {
  const { notes } = useChallengeNotesContext();
  const { sprint, user } = useSidebarContext();
  const queryClient = useQueryClient();
  const challengeNotesQueryKey = useChallengeNotesQueryKey();
  const { sendNoteReactionAddedAnalyticsEvent } = useNotesAnalytics();

  const onReactionsError = (_, __, context) => {
    queryClient.setQueryData(
      challengeNotesQueryKey,
      context.previousChallengeNotes
    );
  };

  const onReactionsMutate =
    (note) =>
    ({ emoji, type }) => {
      const previousChallengeNotes = queryClient.getQueryData(
        challengeNotesQueryKey
      );

      queryClient.setQueryData(challengeNotesQueryKey, (old) =>
        old.map((noteItem) => {
          if (noteItem.noteId === note.noteId) {
            return {
              ...noteItem,
              reactions: updateReactions({
                reactions: noteItem.reactions,
                emoji,
                type,
                userInfo: user,
              }),
            };
          }
          return noteItem;
        })
      );

      return { previousChallengeNotes };
    };

  return (
    <SidebarBody dataTestid="notes-sidebar-body">
      <ChallengeNotesContentContainer>
        <When condition={Boolean(notes)}>
          {() => (
            <For in={groupBySprintWeek(notes, sprint)}>
              {(sectionNotes, { key: sectionTitle, index }) => (
                <Box as="section" title={`${sectionTitle} challenge notes`}>
                  <Spacer size={`${index === 0 ? 'zr' : 'lg'} lg zr`}>
                    <Typography
                      as="h4"
                      color="gray"
                      fontWeight="semiBold"
                      type="small"
                    >
                      {sectionTitle}
                    </Typography>
                  </Spacer>
                  <For of={sectionNotes}>
                    {(note) => {
                      const ChallengeNoteCardComponent =
                        challengeNotesCardsByType[note.noteType];

                      return (
                        <ChallengeNoteCardComponent note={note}>
                          <>
                            <ChallengeNotesCardReplies
                              noteId={note.noteId}
                              repliesCount={note.repliesCount}
                              userEmail={note.userEmail}
                            />
                            <ChallengeNotesCardReactions
                              noteStatus={note.status}
                              noteType={note.noteType}
                              noteId={note.noteId}
                              reactions={note.reactions}
                              userEmail={note.userEmail}
                              onError={onReactionsError}
                              onMutate={onReactionsMutate(note)}
                              onSuccess={sendNoteReactionAddedAnalyticsEvent}
                            />
                          </>
                        </ChallengeNoteCardComponent>
                      );
                    }}
                  </For>
                </Box>
              )}
            </For>
          )}
        </When>
      </ChallengeNotesContentContainer>
      <Spacer size="xlg zr zr" />
      <ChallengeNotesChallengeFilter />
    </SidebarBody>
  );
};
