import { useAuth0 } from '@auth0/auth0-react';
import { Suspense, useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { SocketProvider } from 'app/socket';
import Box from 'shared_DEPRECATED/components/Box';
import Loader from 'shared_DEPRECATED/components/Loader';
import { USER_TYPES } from 'shared_DEPRECATED/types/User';
import { getPathParams } from 'shared_DEPRECATED/utils';

import { UNAUTHORIZED_PATHS } from 'features/auth/config';
import { USER_TYPE_REDIRECTS } from 'features/user/config';
import { useUserContext } from 'features/user/context';
import { NewVersionBanner } from 'features/version/components/NewVersionBanner';

import { ModalContainer } from 'shared/components/Modal';
import { SidebarContainer } from 'shared/components/Sidebar';

import AdminLayout from './AdminLayout';
import CoachLayout from './CoachLayout';
import { UnauthorizedLayout } from './UnauthorizedLayout';
import UserLayout from './UserLayout';

const LAYOUT_MAPPER = {
  [USER_TYPES.ADMIN]: {
    component: AdminLayout,
    requiresAuth: true,
  },
  [USER_TYPES.COACH]: {
    component: CoachLayout,
    requiresAuth: true,
  },
  [USER_TYPES.USER]: {
    component: UserLayout,
    requiresAuth: true,
  },
  [UNAUTHORIZED_PATHS.RESET_PASSWORD]: {
    component: UnauthorizedLayout,
    requiresAuth: false,
  },
};

const Layout = () => {
  const { pathname, search } = useLocation();
  const navigate = useNavigate();

  const { user, isLoading: isUserLoading } = useUserContext();
  const [layout, setLayout] = useState({});
  const [firstPathParam] = getPathParams(pathname);

  const {
    isLoading: isAuthLoading,
    isAuthenticated,
    loginWithRedirect,
  } = useAuth0();

  useEffect(() => {
    if (!isAuthLoading && !isAuthenticated) {
      if (
        LAYOUT_MAPPER[firstPathParam] &&
        !LAYOUT_MAPPER[firstPathParam].requiresAuth
      ) {
        return setLayout(LAYOUT_MAPPER[firstPathParam]);
      }

      loginWithRedirect({
        appState: {
          returnTo: `${pathname}${search}`,
        },
      });
    }
  }, [
    loginWithRedirect,
    isAuthLoading,
    isAuthenticated,
    pathname,
    search,
    firstPathParam,
  ]);

  useEffect(() => {
    if (isAuthenticated && pathname === '/' && !isUserLoading && user.type) {
      navigate(`${USER_TYPE_REDIRECTS[user.type]}`);
    }
  }, [isAuthenticated, navigate, pathname, user.type, isUserLoading]);

  useEffect(() => {
    if (isAuthenticated && user.type) {
      setLayout(
        LAYOUT_MAPPER[firstPathParam] || LAYOUT_MAPPER[USER_TYPES.USER]
      );
    }
  }, [isAuthenticated, pathname, user.type, firstPathParam]);

  if (isUserLoading || isAuthLoading) {
    return (
      <Box
        style={{
          width: '100%',
          height: '100vh',
        }}
      >
        <Loader />
      </Box>
    );
  }

  const LayoutComponent = layout?.component;

  return (
    <>
      {LayoutComponent ? (
        <LayoutComponent>
          <SocketProvider>
            <Outlet />
            <ToastContainer style={{ width: 'auto' }} />
            <NewVersionBanner />
            <Suspense fallback={null}>
              <ModalContainer />
            </Suspense>
            <Suspense fallback={null}>
              <SidebarContainer />
            </Suspense>
          </SocketProvider>
        </LayoutComponent>
      ) : null}
    </>
  );
};

export default Layout;
