import { useState } from 'react';

import { TARGET_TYPES, TARGET_TYPES_KEYS } from 'features/challenge/config';
import {
  ChallengeTargetProgressType,
  DueDateTargetType,
  WeekdayTargetType,
  WeeklyTargetType,
} from 'features/challenge/config/types';
import { useChallengeAnalytics } from 'features/sprint/hooks/useChallengeAnalytics';

type CustomChallengeTargetProgressType = Omit<
  ChallengeTargetProgressType,
  'weekly' | 'weekday' | 'dueDate'
> & {
  weekly: WeeklyTargetType | number | null;
  weekday: WeekdayTargetType | null;
  dueDate: DueDateTargetType | null;
};

export const useSetFrequency = ({
  challengeTarget,
  sprintWeeksCount,
  onFrequencySave,
}: {
  challengeTarget: ChallengeTargetProgressType;
  sprintWeeksCount: number;
  onFrequencySave: (frequency: CustomChallengeTargetProgressType) => void;
}) => {
  const [frequency, setFrequency] = useState(() => {
    const initialFrequency: CustomChallengeTargetProgressType = {
      type: challengeTarget.type,
      weekly: null,
      weekday: null,
      dueDate: null,
    };

    Object.values(TARGET_TYPES_KEYS).forEach((targetType) => {
      if (
        targetType === TARGET_TYPES_KEYS.WEEKLY &&
        TARGET_TYPES_KEYS.WEEKLY in challengeTarget
      ) {
        initialFrequency.weekly =
          new Set(challengeTarget[TARGET_TYPES_KEYS.WEEKLY]).size === 1
            ? challengeTarget[targetType][0]
            : challengeTarget.weekly;
      } else if (
        targetType === TARGET_TYPES_KEYS.WEEKDAY &&
        TARGET_TYPES_KEYS.WEEKDAY in challengeTarget
      ) {
        initialFrequency.weekday = challengeTarget.weekday;
      } else if (
        targetType === TARGET_TYPES_KEYS.DUE_DATE &&
        TARGET_TYPES_KEYS.DUE_DATE in challengeTarget
      ) {
        initialFrequency.dueDate = challengeTarget.dueDate;
      }
    });

    return initialFrequency;
  });

  const { sendFrequencySelectedEvent } = useChallengeAnalytics();

  const handleSaveBtnClick = () => {
    const newFrequency = { ...frequency };

    sendFrequencySelectedEvent();

    if (
      frequency.type === TARGET_TYPES.WEEKLY &&
      typeof frequency.weekly === 'number'
    ) {
      newFrequency[TARGET_TYPES_KEYS.WEEKLY] = new Array(sprintWeeksCount).fill(
        frequency[TARGET_TYPES_KEYS.WEEKLY]
      );
    }

    onFrequencySave(newFrequency);
  };

  return {
    frequency,
    handleSaveBtnClick,
    setFrequency,
  };
};
