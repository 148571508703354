import { useFilters, useSortBy, useTable } from 'react-table';

import { columnsData } from 'features/participants/config/tableColumnsData';

const defaultSorting = [
  {
    id: 'nextCoachingSessionAppointment',
    desc: false,
  },
  {
    id: 'userInfo',
    desc: false,
  },
];

export const useParticipantsTable = (participants) => {
  return useTable(
    {
      disableSortRemove: true,
      columns: columnsData,
      data: participants,
      initialState: { hiddenColumns: ['status'], sortBy: defaultSorting },
      autoResetSortBy: false,
    },
    useFilters,
    useSortBy
  );
};
