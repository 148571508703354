import PropTypes from 'prop-types';

import { getPictureUrl } from 'shared_DEPRECATED/utils';

import {
  ChallengeNotesCardBody,
  ChallengeNotesCardContainer,
  ChallengeNotesCardFailReasons,
  ChallengeNotesCardFooter,
  ChallengeNotesCardHeader,
  ChallengeNotesCardImageAttachment,
  ChallengeNotesCardMenu,
  ChallengeNotesCardTextWithHighlight,
  ChallengeNotesCardTitle,
  ChallengeNotesCardUserInfo,
} from 'features/challengeNotes/components/Card';
import { NOTE_STATUS } from 'features/challengeNotes/config';
import { ChallengeNoteType } from 'features/challengeNotes/config/propTypes';

import { useSidebarContext } from 'shared/components/Sidebar/context';

const ChallengeNotesCardPropTypes = {
  children: PropTypes.node,
  note: ChallengeNoteType,
};

export const ChallengeNotesCard = ({ children, note }) => {
  const { sprint } = useSidebarContext();

  const challengeTitle =
    sprint.getChallenge(note.data.challengeId)?.title ?? '';

  return (
    <ChallengeNotesCardContainer
      unread={note.status === NOTE_STATUS.UNREAD}
      dataTestid="user-challenge-note-card"
    >
      <ChallengeNotesCardHeader>
        <ChallengeNotesCardUserInfo
          name={note.authorName || note.authorEmail}
          pictureUrl={getPictureUrl(note.authorPicture)}
          date={note.data.statusDate}
          noteReadStatus={note.status}
        />
        <ChallengeNotesCardMenu
          noteId={note.noteId}
          noteReadStatus={note.status}
          challengeId={note.data.challengeId}
          userEmail={note.userEmail}
        />
      </ChallengeNotesCardHeader>
      <ChallengeNotesCardBody>
        <ChallengeNotesCardTitle
          title={challengeTitle}
          status={note.data.status}
        />
        <ChallengeNotesCardFailReasons reasons={note.data.failReasons} />
        <ChallengeNotesCardTextWithHighlight nodeId={note.noteId}>
          {note.data.text}
        </ChallengeNotesCardTextWithHighlight>
        <ChallengeNotesCardImageAttachment src={note.data.picture?.url} />
      </ChallengeNotesCardBody>
      <ChallengeNotesCardFooter>{children}</ChallengeNotesCardFooter>
    </ChallengeNotesCardContainer>
  );
};

ChallengeNotesCard.propTypes = ChallengeNotesCardPropTypes;
