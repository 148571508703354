import { VStack } from '@chakra-ui/react';
import { ReactNode } from 'react';

type TSessionSidebar = {
  children: ReactNode;
  width: string;
};

export const SessionSidebar = ({ children, width }: TSessionSidebar) => (
  <VStack
    align="stretch"
    spacing="12px"
    px="16px"
    pt="12px"
    pb="32px"
    w={width}
    bg="var(--bgSecondary)"
    h="100%"
    overflowY="auto"
    borderRight="var(--border-secondary)"
    borderLeft="var(--border-secondary)"
    data-testid="session-sidebar"
  >
    {children}
  </VStack>
);
