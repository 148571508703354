import { Flex, FlexItem } from 'shared_DEPRECATED/components/Flex';
import { colors } from 'shared_DEPRECATED/components/Typography/Typography';

import { ChallengeStatusIcon } from 'features/challenge/components/StatusIcon';
import { DAILY_STATUS } from 'features/challenge/config';
import {
  MomentumFeedItemChallengeFrequency,
  MomentumFeedItemChallengeTitle,
  MomentumFeedItemTrend,
} from 'features/momentum/components/Feed/Item/';

type MomentumFeedItemProps = {
  challengeFrequency: string;
  challengeStatus: DAILY_STATUS;
  challengeTargetAmount?: string;
  challengeTitle: string;
  textColor: keyof typeof colors;
  trend: number;
  trendSign: '-' | '+';
};

export const MomentumFeedItem = ({
  challengeFrequency,
  challengeStatus,
  challengeTargetAmount,
  challengeTitle,
  textColor,
  trend,
  trendSign,
}: MomentumFeedItemProps) => (
  <Flex>
    <Flex alignItems="start" gap="var(--spacing02)">
      {challengeStatus && <ChallengeStatusIcon status={challengeStatus} />}
      <FlexItem>
        <MomentumFeedItemChallengeTitle>
          {challengeTitle}
        </MomentumFeedItemChallengeTitle>
        <MomentumFeedItemChallengeFrequency
          color="gray"
          frequency={challengeFrequency}
          targetAmount={challengeTargetAmount}
        />
      </FlexItem>
    </Flex>
    <MomentumFeedItemTrend
      textColor={textColor}
      trend={trend}
      trendSign={trendSign}
    />
  </Flex>
);
