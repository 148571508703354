import PropTypes from 'prop-types';

import Box from 'shared_DEPRECATED/components/Box';

const FRAME_SIZE = {
  LG: 'LG',
  SM: 'SM',
};

const FRAME_CONFIG = {
  LG: {
    width: '2.5rem',
    height: '2.5rem',
  },
  SM: {
    width: '1.75rem',
    height: '1.75rem',
  },
  XSM: {
    width: '1.25rem',
    height: '1.25rem',
  },
};

const ImageFramePropTypes = {
  children: PropTypes.node.isRequired,
  isIcon: PropTypes.bool,
  size: PropTypes.oneOf(Object.keys(FRAME_CONFIG)),
  borderRadius: PropTypes.string,
};

export const ImageFrame = ({
  children,
  isIcon = true,
  size = FRAME_SIZE.LG,
  borderRadius = 'var(--border-radius)',
}) => (
  <Box
    style={{
      display: 'table',
      backgroundColor: isIcon ? 'var(--bgCompSecondaryTiny)' : 'transparent',
      borderRadius,
      height: FRAME_CONFIG[size].height,
      width: FRAME_CONFIG[size].width,
      overflow: 'hidden',
    }}
  >
    <Box
      style={{
        display: 'table-cell',
        verticalAlign: 'middle',
        textAlign: 'center',
      }}
    >
      {children}
    </Box>
  </Box>
);

ImageFrame.propTypes = ImageFramePropTypes;

export default ImageFrame;
