import {
  FloatingTooltip as Tooltip,
  PLACEMENTS,
} from 'shared_DEPRECATED/components/Floating';
import { Icon, TIconName } from 'shared_DEPRECATED/components/Icon';

import { ParticipantsTableActionWrapper } from 'features/participants/components/Table/Actions/Wrapper';

import { Typography } from 'shared/components/Typography';

type ParticipantsTableActionDisabledPropTypes = {
  label: string;
  icon: TIconName;
};

export const ParticipantsTableActionDisabled = ({
  label,
  icon,
}: ParticipantsTableActionDisabledPropTypes) => (
  <Tooltip
    anchor={
      <ParticipantsTableActionWrapper>
        <Icon name={icon} stroke="var(--fgTertiary)" width={18} height={18} />
      </ParticipantsTableActionWrapper>
    }
    offsetValue={8}
    placement={PLACEMENTS.BOTTOM_START}
  >
    <Typography type="small" color="white">
      {label}
    </Typography>
  </Tooltip>
);
