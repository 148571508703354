import airbnbPropTypes from 'airbnb-prop-types';
import PropTypes from 'prop-types';

import { Flex } from 'shared_DEPRECATED/components/Flex';
import { SPACER_SIZES } from 'shared_DEPRECATED/components/Spacer';

import { Typography } from 'shared/components/Typography';

import RadioButton from './RadioButton';

const RadioButtonGroupPropTypes = {
  /**
   * Radio buttons to render inside the radio button group.
   * @type {React.ReactNode}
   * @memberof RadioButtonGroupPropTypes
   * @name children
   * @required
   * @example <RadioButtonGroup><RadioButton /></RadioButtonGroup>
   */
  children: airbnbPropTypes.childrenOfType(RadioButton),
  /**
   * The size of the spacer between the radio buttons.
   * @type {string}
   * @memberof RadioButtonGroupPropTypes
   * @name gap
   * @default '1.25rem'
   * @example <RadioButtonGroup gap="1rem" />
   */
  gap: PropTypes.string,
  /**
   * Title to render above the radio button group.
   * @type {string}
   * @memberof RadioButtonGroupPropTypes
   * @name title
   * @required
   * @example <RadioButtonGroup title="Title" />
   */
  title: PropTypes.string.isRequired,
};

const defaultGap = `calc(${SPACER_SIZES.sm} + ${SPACER_SIZES.lg})`;

const RadioButtonGroup = ({ children, gap = defaultGap, title }) => (
  <Flex alignItems="flex-start" flexDirection="column" gap={defaultGap}>
    <Typography type="small" fontWeight="semiBold">
      {title}
    </Typography>
    <Flex alignItems="stretch" flexDirection="column" gap={gap}>
      {children}
    </Flex>
  </Flex>
);

RadioButtonGroup.propTypes = RadioButtonGroupPropTypes;

export default RadioButtonGroup;
