import { useCallback } from 'react';
import { useDrop } from 'react-dnd';
import { UseMutateAsyncFunction } from 'react-query';

type TUseBoardDrop<T> = {
  onDrop: UseMutateAsyncFunction<any, unknown, T, { previousValue: unknown }>;
  accept: string[];
  handleCanDrop: (item: T) => boolean;
  dropZoneIndex: number;
};

export const useBreakdownBoardDrop = <T,>({
  onDrop,
  accept,
  handleCanDrop,
  dropZoneIndex,
}: TUseBoardDrop<T>) => {
  const [{ isOver, canDrop }, drop] = useDrop({
    accept,
    drop: useCallback(
      async (item: any) => await onDrop({ ...item, index: dropZoneIndex }),
      [onDrop, dropZoneIndex]
    ),
    canDrop: handleCanDrop,
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  return { drop, isOver, canDrop };
};
