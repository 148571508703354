import { queryKeys } from 'api/config';

export const getSharedSprintsQueryKey = (email: string) => [
  queryKeys.sharedSprints,
  email,
];

export const getParticipantsSprintsQueryKey = (email: string) => [
  queryKeys.participantsSprints,
  email,
];

export const getChallengeNotesQueryKey = (queryString: string) => [
  queryKeys.challengeNotes,
  queryString,
];

export const getNoteRepliesQueryKey = (noteId: string) => [
  queryKeys.noteReplies,
  noteId,
];
