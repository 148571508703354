import { createContext, useContext } from 'react';

export const DragStateContext = createContext({
  opacity: 1,
  isDragging: false,
});

export const useDragStateContext = () => {
  const context = useContext(DragStateContext);

  if (!context) {
    throw new Error(
      'useDragStateContext must be used within a DragStateContext.Provider'
    );
  }

  return context;
};
