import { Input as ChakraInput, InputProps } from '@chakra-ui/react';
import { forwardRef } from 'react';

type TextInputProps = {
  dataTestid: string;
} & InputProps;

export const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
  ({ dataTestid = '', ...props }: TextInputProps, ref) => (
    <ChakraInput data-testid={dataTestid} ref={ref} {...props} />
  )
);
