import { ReactNode } from 'react';

import Box from 'shared_DEPRECATED/components/Box';
import { Flex } from 'shared_DEPRECATED/components/Flex';
import { HotjarSuppression } from 'shared_DEPRECATED/components/Hotjar';
import Spacer from 'shared_DEPRECATED/components/Spacer';
import { capitalize } from 'shared_DEPRECATED/utils';

import { AddChallengeButton, GoalFocusAreaMenu } from 'features/goal';

import { Typography } from 'shared/components/Typography';

type TGoalFocusArea = {
  children: ReactNode;
  title: string;
  focusAreaId: string;
};

export const GoalFocusArea = ({
  children,
  title,
  focusAreaId,
}: TGoalFocusArea) => (
  <Box style={{ width: '100%' }}>
    <Spacer size="sm">
      <Flex width="100%" alignItems="flex-start">
        <Spacer size="sm">
          <HotjarSuppression>
            <Typography
              type="body"
              color="black"
              fontWeight="medium"
              wordBreak="break-word"
            >
              {capitalize(title)}
            </Typography>
          </HotjarSuppression>
        </Spacer>
        <Flex gap="8px">
          <AddChallengeButton focusAreaId={focusAreaId} />
          <GoalFocusAreaMenu focusAreaId={focusAreaId} title={title} />
        </Flex>
      </Flex>
    </Spacer>
    <Spacer size="zr sm">
      <Flex flexDirection="column">{children}</Flex>
    </Spacer>
  </Box>
);
