import { Box } from '@chakra-ui/react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { useDrop } from 'react-dnd';
import { NativeTypes } from 'react-dnd-html5-backend';

import { LexicalEditor } from 'lexical';

import { Typography } from 'shared/components/Typography';

type TextEditorDragAndDropProps = {
  children: React.ReactNode;
  onDrop: (editor: LexicalEditor, file: File) => void;
};

export const TextEditorDragAndDrop = ({
  children,
  onDrop,
}: TextEditorDragAndDropProps) => {
  const [editor] = useLexicalComposerContext();

  const [{ canDrop, isOver }, drop] = useDrop(() => ({
    accept: [NativeTypes.FILE],
    canDrop() {
      return true;
    },
    async drop(item: { files: File[] }) {
      const [file] = item.files;
      onDrop(editor, file);
    },
    collect: (monitor) => {
      return {
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      };
    },
  }));

  const isActive = canDrop && isOver;

  return (
    <Box
      ref={drop}
      style={{
        position: 'relative',
        height: '100%',
      }}
    >
      <Box
        style={{
          zIndex: 2,
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          display: isActive ? 'flex' : 'none',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: 'rgba(0, 0, 0, 0.3)',
        }}
      >
        <Typography color="white" type="meta">
          Release to drop
        </Typography>
      </Box>
      {children}
    </Box>
  );
};
