import Box from 'shared_DEPRECATED/components/Box';
import Button, { VARIANTS } from 'shared_DEPRECATED/components/Button';
import { Flex } from 'shared_DEPRECATED/components/Flex';
import { Icon, iconNames } from 'shared_DEPRECATED/components/Icon';
import { Spacers } from 'shared_DEPRECATED/components/Spacer';

import { useDeleteGoal } from 'features/goal';

type TGoalDeleteButton = {
  goalId: string;
  columnId: string;
};

export const GoalDeleteButton = ({ goalId, columnId }: TGoalDeleteButton) => {
  const { onDeleteGoalClick } = useDeleteGoal({ goalId, columnId });

  return (
    <Box
      style={{
        borderRadius: '50%',
        overflow: 'hidden',
        border: 'var(--border-secondary)',
      }}
    >
      <Button variant={VARIANTS.TERTIARY} onClick={onDeleteGoalClick}>
        <Spacers sizes={['md', 'sm']}>
          <Flex alignItems="center" justifyContent="center">
            <Icon
              name={iconNames.binFilled}
              width={16}
              height={18}
              fill="var(--fgPrimary)"
            />
          </Flex>
        </Spacers>
      </Button>
    </Box>
  );
};
