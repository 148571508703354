import { FormProvider, useForm } from 'react-hook-form';

import { VARIANTS } from 'shared_DEPRECATED/components/Button';
import { Flex } from 'shared_DEPRECATED/components/Flex';
import Spacer, { Spacers } from 'shared_DEPRECATED/components/Spacer';

import {
  SessionsModalDatePicker,
  SessionsModalPhaseSelector,
  SessionsModalSprintSelector,
} from 'features/sessions/components/Modal';
import { SESSION_PHASE } from 'features/sessions/config';
import { useSessionCreate } from 'features/sessions/hooks';
import { getDefaultSessionModalFormData } from 'features/sessions/utils';
import { SprintsType } from 'features/sprint/config/types';
import { getSprintsWithoutDraft } from 'features/sprint/utils';

import {
  ModalBody,
  ModalFooter,
  ModalHeader,
  useModalContext,
} from 'shared/components/Modal';
import { ModalButton } from 'shared/components/Modal/ModalButton';
import { Typography } from 'shared/components/Typography';

export const SessionsModalContent = () => {
  const {
    sprints,
    nextSessionStartDate,
  }: { sprints: SprintsType; nextSessionStartDate: string } = useModalContext();
  const notDraftSprints = getSprintsWithoutDraft(sprints);

  const formMethods = useForm({
    defaultValues: getDefaultSessionModalFormData(
      sprints,
      nextSessionStartDate
    ),
  });

  const coachingSessionPhase = formMethods.watch('coachingSessionPhase');

  const handleSessionCreate = useSessionCreate();

  return (
    <FormProvider {...formMethods}>
      <ModalHeader>
        <Typography as="h3" type="body" fontWeight="semiBold" color="black">
          Create session
        </Typography>
      </ModalHeader>
      <ModalBody>
        <Flex
          flexDirection="column"
          width="100%"
          alignItems="flex-start"
          gap="var(--spacing03)"
        >
          <SessionsModalPhaseSelector sprints={notDraftSprints} />
          {coachingSessionPhase === SESSION_PHASE.SPRINT && (
            <SessionsModalSprintSelector sprints={notDraftSprints} />
          )}

          <SessionsModalDatePicker />
        </Flex>
      </ModalBody>
      <ModalFooter>
        <ModalButton variant={VARIANTS.SECONDARY}>
          <Spacers sizes={['md', 'sm']}>
            <Typography color="black" fontWeight="medium" type="meta">
              Cancel
            </Typography>
          </Spacers>
        </ModalButton>
        <Spacer size="zr md" />
        <ModalButton
          onClick={() => formMethods.handleSubmit(handleSessionCreate)()}
          variant={VARIANTS.PRIMARY}
          dataTestid="create-session-save-bnt"
        >
          <Spacers sizes={['md', 'sm']}>
            <Typography color="whiteTotal" fontWeight="medium" type="meta">
              Save
            </Typography>
          </Spacers>
        </ModalButton>
      </ModalFooter>
    </FormProvider>
  );
};
