import { ReactNode, useMemo, useRef } from 'react';
import { useDrag } from 'react-dnd';

import Box from 'shared_DEPRECATED/components/Box';

import { DragStateContext } from 'shared/components/Board';

type TBoardColumnItem<T> = {
  item: T;
  path?: number[];
  children: ReactNode;
  type: string;
};

export const BoardColumnItem = <T,>({
  item,
  path,
  children,
  type,
}: TBoardColumnItem<T>) => {
  const ref = useRef(null);

  const [{ opacity, isDragging }, drag] = useDrag({
    type: type,
    item: { ...item, path },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
      opacity: monitor.isDragging() ? 0.4 : 1,
    }),
  });

  drag(ref);

  const value = useMemo(() => ({ opacity, isDragging }), [opacity, isDragging]);

  return (
    <DragStateContext.Provider value={value}>
      <Box
        ref={ref}
        style={{
          width: '100%',
          cursor: isDragging ? 'grabbing' : 'grab',
        }}
      >
        {children}
      </Box>
    </DragStateContext.Provider>
  );
};
