import { NavLink, useLocation } from 'react-router-dom';
import useBreadcrumbs from 'use-react-router-breadcrumbs';

import { routeBreadcrumbs } from 'app/layout/config';
import { Icon, iconNames } from 'shared_DEPRECATED/components/Icon';
import { routeNames } from 'shared_DEPRECATED/utils/config';

import { ACCOUNTABILITY_PARTNER_SPRINT_ROUTE_PATH } from 'features/router/config';

import styles from 'app/layout/components/Header/Breadcrumbs/Breadcrumbs.module.css';

const Breadcrumbs = () => {
  const { pathname } = useLocation();
  let breadcrumbs = useBreadcrumbs(routeBreadcrumbs, {
    excludePaths: [
      '/',
      `${routeNames.COACH}`,
      `${routeNames.DASHBOARD}/sprints`,
      `${routeNames.CONNECTIONS}`,
      `${routeNames.CONNECTIONS}/:email`,
      `${routeNames.CONNECTIONS}/:email/sprints`,
      ACCOUNTABILITY_PARTNER_SPRINT_ROUTE_PATH,
      `/groups`,
      `/groups/:groupId`,
    ],
  });

  return (
    <ul className={styles.breadcrumbs}>
      {breadcrumbs.map(({ match, breadcrumb: breadcrumbText }, index) => {
        const breadcrumbItem =
          pathname === match.pathname ? (
            breadcrumbText
          ) : (
            <NavLink
              className={styles['breadcrumbs__link']}
              to={match.pathname}
            >
              {breadcrumbText}
            </NavLink>
          );

        return (
          <li key={match.pathname} className={styles['breadcrumbs__item']}>
            {breadcrumbItem}
            {index !== breadcrumbs.length - 1 && (
              <Icon name={iconNames.arrowRight} width={6} height={9} />
            )}
          </li>
        );
      })}
    </ul>
  );
};

export default Breadcrumbs;
