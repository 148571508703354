import styles from './RawHtml.module.css';

export const RawHtml = ({ htmlString }: { htmlString: string }) => (
  <div
    className={styles['raw-html']}
    dangerouslySetInnerHTML={{
      __html: htmlString,
    }}
  />
);
