import {
  Modal as ChakraModal,
  ModalOverlay,
  ModalContent,
} from '@chakra-ui/react';
import { ReactNode } from 'react';

import { MODAL_SIZES } from 'shared/components/Modal/types';

type ModalProps = {
  children: ReactNode;
  title: string;
  size: MODAL_SIZES;
  onClose: () => void;
  isOpen: boolean;
  isCentered: boolean;
};

export const Modal = ({
  children = null,
  size,
  onClose,
  isOpen,
  isCentered = true,
  title,
  ...props
}: ModalProps) => (
  <ChakraModal
    size={size}
    isOpen={isOpen}
    onClose={onClose}
    isCentered={isCentered}
    scrollBehavior="inside"
    {...props}
  >
    <ModalOverlay />
    <ModalContent>{children}</ModalContent>
  </ChakraModal>
);
