import { UserMode } from '../../shared_DEPRECATED/types/User';
import { ANALYTICS_EVENTS } from './config';
import { sendAnalyticsEvent } from './utils';

export const sendNotificationsOpenAnalyticsEvent = ({
  userMode,
}: {
  userMode: UserMode;
}) => {
  sendAnalyticsEvent({
    event: ANALYTICS_EVENTS.NOTIFICATIONS_SIDEBAR_OPEN,
    data: {
      user_mode: userMode,
    },
  });
};

export const sendNotificationsFilterAppliedAnalyticsEvent = ({
  type,
  userMode,
}: {
  type: string;
  userMode: UserMode;
}) => {
  sendAnalyticsEvent({
    event: ANALYTICS_EVENTS.NOTIFICATIONS_SIDEBAR_OPEN,
    data: {
      type,
      userMode,
    },
  });
};
