import { toastConfig } from 'lib/reactToastify/config';
import { useCallback } from 'react';
import { useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { queryKeys } from 'api/config';
import { v4 as uuidv4 } from 'uuid';

import {
  INonRepeatedChallenge,
  IRepeatedChallenge,
} from 'features/challenge/config/types';
import {
  useOpenChallengeModal,
  useCreateCustomChallengeMutation,
} from 'features/challenge/hooks';
import { useInitialChallenge } from 'features/challenge/hooks/useInitialChallenge';
import {
  GoalBreakdownType,
  GoalBreakdownChallengeType,
  useAddGoalChallengeMutation,
} from 'features/goal';

type TUseCreateGoalChallenge = {
  focusAreaId?: string;
};

export const useCreateGoalChallenge = ({
  focusAreaId,
}: TUseCreateGoalChallenge) => {
  const initialChallenge = useInitialChallenge();
  const openChallengeModal = useOpenChallengeModal();
  const { goalId } = useParams();

  const queryClient = useQueryClient();

  const { mutateAsync: createChallenge } = useCreateCustomChallengeMutation({
    options: {
      onMutate: async (challenge: GoalBreakdownChallengeType) => {
        await queryClient.cancelQueries({
          queryKey: [queryKeys.goalBreakdown, goalId],
        });

        const previousValue = queryClient.getQueryData([
          queryKeys.goalBreakdown,
          goalId,
        ]);

        queryClient.setQueryData<GoalBreakdownType>(
          [queryKeys.goalBreakdown, goalId],
          //@ts-ignore
          (oldVal) => {
            const temporaryFocusAreaId = uuidv4();
            const newChallenge = {
              ...challenge,
              customChallengeId: temporaryFocusAreaId,
            };

            if (focusAreaId) {
              return {
                ...oldVal!,
                focusAreas: oldVal!.focusAreas.map((focusArea) =>
                  focusAreaId === focusArea.id
                    ? {
                        ...focusArea,
                        challenges: [newChallenge, ...focusArea.challenges],
                      }
                    : focusArea
                ),
              };
            }

            return {
              ...oldVal!,
              challenges: [newChallenge, ...oldVal!.challenges],
            };
          }
        );

        return { previousValue };
      },
      onError: (
        err: Error,
        _: any,
        context: { previousValue?: GoalBreakdownType }
      ) => {
        queryClient.setQueryData(
          [queryKeys.goalBreakdown, goalId],
          context?.previousValue
        );
      },
    },
  });

  const { mutateAsync: addChallenge } = useAddGoalChallengeMutation({
    focusAreaId,
  });

  const onSubmit = useCallback(
    async (
      challenge: {
        customChallengeId: string;
      } & (INonRepeatedChallenge | IRepeatedChallenge)
    ) => {
      try {
        const { customChallengeId } = await createChallenge(challenge);

        await addChallenge({ challengeId: customChallengeId });
      } catch (err) {
        toast.error(
          "There's an error with creating the challenge. Please try again later.",
          toastConfig
        );
      }
    },
    [createChallenge, addChallenge]
  );

  const onCreateGoalChallengeBtnClick = () =>
    openChallengeModal({
      challenge: initialChallenge,
      onChallengeSubmit: onSubmit,
      title: 'Create Challenge',
      sprint: null,
    });

  return { onCreateGoalChallengeBtnClick };
};
