import { DrawerBody } from '@chakra-ui/react';
import PropTypes from 'prop-types';

import { ErrorBoundary } from 'shared_DEPRECATED/components/Error/ErrorBoundary';
import { GeneralWidgetError } from 'shared_DEPRECATED/components/Error/GeneralWidgetError';

const SidebarBody = ({ children, dataTestid = '' }) => (
  <DrawerBody p={0} data-testid={dataTestid}>
    <ErrorBoundary FallbackComponent={GeneralWidgetError}>
      {children}
    </ErrorBoundary>
  </DrawerBody>
);

SidebarBody.propTypes = {
  /**
   * Children to render inside the sidebar body.
   * @type {React.ReactNode}
   * @required
   * @name children
   * @example <SidebarBody><SidebarSection>Content 1</SidebarSection><SidebarSection>Content 2</SidebarSection></SidebarBody>
   */
  children: PropTypes.node.isRequired,
  dataTestid: PropTypes.string,
};

export default SidebarBody;
