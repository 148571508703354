import {
  GoalDeepDiveFormDescription,
  GoalDeepDiveFormSection,
  GoalDeepDiveFormEditor,
} from 'features/goal';

export const GoalDeepDiveFormWhySection = () => (
  <GoalDeepDiveFormSection step={1} title={'What’s your ‘why’?'}>
    <GoalDeepDiveFormDescription text="What would it mean for you to achieve this? How would it positively impact your life?" />
    <GoalDeepDiveFormEditor keyField="deepDive.why" id="why" />
  </GoalDeepDiveFormSection>
);
