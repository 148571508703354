import { setUser as setSentryUser } from '@sentry/react';
import React, { ReactNode, useContext, useEffect, useMemo } from 'react';

import { User } from 'features/user/User';
import { IUser } from 'features/user/config/types';
import { useUserQuery } from 'features/user/hooks/query/useUser';

const UserContext = React.createContext({
  user: new User({} as IUser),
  isLoading: false,
});

type UserProviderPropTypes = {
  children: ReactNode;
};

export const UserProvider = ({ children }: UserProviderPropTypes) => {
  const { data: user, isLoading } = useUserQuery();

  useEffect(() => {
    if (user && !isLoading) {
      setSentryUser(user);
    }
  }, [user, isLoading]);

  const providerValue = useMemo(
    () => ({
      user: user ? user : new User({} as IUser),
      isLoading,
    }),
    [user, isLoading]
  );

  return (
    <UserContext.Provider value={providerValue}>
      {children}
    </UserContext.Provider>
  );
};

export const useUserContext = () => {
  const userContext = useContext(UserContext);

  if (!userContext) {
    throw new Error('useUserContext must be used in UserProvider');
  }

  return userContext;
};
