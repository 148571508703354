import { Flex, FlexItem } from 'shared_DEPRECATED/components/Flex';
import { HotjarSuppression } from 'shared_DEPRECATED/components/Hotjar';
import { Spacers } from 'shared_DEPRECATED/components/Spacer';

import {
  ChallengeInfoImage,
  ChallengeInfoName,
  ChallengeInfoPrivacy,
} from 'features/challenge/components/Info/';
import { RequireEmojiOrPicture } from 'features/challenge/config/types';

type TChallengeInfoProps = {
  title: string;
  children?: React.ReactNode;
  isPrivate?: boolean;
  isCropped?: boolean;
} & RequireEmojiOrPicture;

export const ChallengeInfo = ({
  emoji,
  pictureFileUrl,
  pictureUrl,
  title,
  children,
  isPrivate,
  isCropped,
}: TChallengeInfoProps) => (
  <Flex>
    <FlexItem flexShrink={0}>
      <Spacers sizes={['zr md zr zr', 'zr sm zr zr']}>
        <ChallengeInfoImage
          title={title}
          emoji={emoji}
          pictureUrl={pictureUrl}
          pictureFileUrl={pictureFileUrl || ''}
        />
      </Spacers>
    </FlexItem>
    <HotjarSuppression>
      <Flex flexDirection="column" alignItems="start">
        <Flex gap="0.25rem">
          <ChallengeInfoName isCropped={isCropped}>{title}</ChallengeInfoName>
          <ChallengeInfoPrivacy isPrivate={isPrivate} />
        </Flex>
        {children}
      </Flex>
    </HotjarSuppression>
    <Spacers sizes={['zr sm zr']} />
  </Flex>
);
