import { ReactNode } from 'react';

import { Box, Typography } from 'shared';

type TSessionSidebarLabel = {
  children: ReactNode;
};

export const SessionSidebarLabel = ({ children }: TSessionSidebarLabel) => (
  <Box pb="12px">
    <Typography as="h2" fontWeight="medium" type="heading">
      {children}
    </Typography>
  </Box>
);
