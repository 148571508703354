import { Radio } from 'shared';
import { Flex } from 'shared_DEPRECATED/components/Flex';
import {
  SELECT_BUTTON_SIZES,
  SELECT_BUTTON_TYPES,
  SelectButton,
} from 'shared_DEPRECATED/components/Form/SelectButton';
import { Spacers } from 'shared_DEPRECATED/components/Spacer';
import { range } from 'shared_DEPRECATED/utils';

import { CHECKBOX_BUTTONS_GAP, TARGET_TYPES } from 'features/challenge/config';
import { WeeklyTargetType } from 'features/challenge/config/types';

type ChallengeFrequencyDialogNumberWeeklyFieldProps = {
  frequency: {
    type: string;
    weekly: WeeklyTargetType | number;
  };
  onFrequencyChange: (frequency: { type: string; weekly: number }) => void;
};

export const ChallengeFrequencyDialogNumberWeeklyField = ({
  frequency,
  onFrequencyChange,
}: ChallengeFrequencyDialogNumberWeeklyFieldProps) => {
  const checked =
    frequency.type === TARGET_TYPES.WEEKLY &&
    typeof frequency.weekly === 'number';
  const onRadioButtonChange = () =>
    onFrequencyChange({
      type: TARGET_TYPES.WEEKLY,
      weekly: Array.isArray(frequency.weekly) ? frequency.weekly[0] : 1,
    });

  return (
    <Flex flexDirection="column" alignItems="start">
      <Radio
        isChecked={checked}
        onChange={onRadioButtonChange}
        data-testid="challenge-frequency-weekly-radio"
      >
        Number of days weekly
      </Radio>
      <Spacers sizes={['sm zr', 'xsm zr']} />
      {checked && (
        <>
          <Flex justifyContent="start" gap={CHECKBOX_BUTTONS_GAP}>
            <Spacers sizes={['zr zr zr xsm']} />
            {range(1, 7).map((day) => {
              const checked = frequency.weekly === day;
              const onSelectButtonChange = () =>
                onFrequencyChange({
                  type: TARGET_TYPES.WEEKLY,
                  weekly: day,
                });

              return (
                <SelectButton
                  type={SELECT_BUTTON_TYPES.SINGLE}
                  checked={checked}
                  key={day}
                  minWidth="3.25rem"
                  onChange={onSelectButtonChange}
                  size={SELECT_BUTTON_SIZES.SM}
                  dataTestid={`challenge-frequency-daily-day-${day}`}
                >
                  {day}
                </SelectButton>
              );
            })}
          </Flex>
          <Spacers sizes={['sm zr', 'xsm zr']} />
        </>
      )}
    </Flex>
  );
};
