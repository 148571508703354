import { ChakraProvider } from '@chakra-ui/react';
import { StrictMode } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import {
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
} from 'react-router-dom';
import { injectStyle } from 'react-toastify/dist/inject-style';

import appRoutes from 'app/AppRoutes';
import 'lib/sentry/init';

import { theme } from 'shared/theme';

import './App.module.css';
import './index.css';

injectStyle();

const router = createBrowserRouter(createRoutesFromElements(appRoutes));

export const App = () => (
  <StrictMode>
    <DndProvider backend={HTML5Backend}>
      <ChakraProvider theme={theme}>
        <RouterProvider router={router} />
      </ChakraProvider>
    </DndProvider>
  </StrictMode>
);

export default App;
