import Box from 'shared_DEPRECATED/components/Box';
import { Flex } from 'shared_DEPRECATED/components/Flex';
import Spacer from 'shared_DEPRECATED/components/Spacer';

import {
  GoalBreakdownBoardFocusAreas,
  GoalBreakdownBoardChallenges,
  GoalBreakdownChallengeType,
  canItemBeDroppedInChallengeDropZone,
  FocusAreaType,
  GoalBreakdownEmptyState,
} from 'features/goal';

import { BoardColumn } from 'shared/components/Board';

const handleCanDrop =
  () => (challengeIndex: number) => (item: GoalBreakdownChallengeType) =>
    canItemBeDroppedInChallengeDropZone({
      parentFocusAreaId: item.parentFocusAreaId,
      index: item.index,
      dropZoneIndex: challengeIndex,
    });

type TGoalBreakdownContent = {
  focusAreas: FocusAreaType[];
  challenges: GoalBreakdownChallengeType[];
};

export const GoalBreakdownContent = ({
  focusAreas,
  challenges,
}: TGoalBreakdownContent) => {
  const isGoalBreakdownFilled = challenges.length || focusAreas.length;

  return (
    <BoardColumn>
      {!isGoalBreakdownFilled ? (
        <GoalBreakdownEmptyState />
      ) : (
        <>
          <Box style={{ width: '100%' }}>
            <Spacer size="zr sm md">
              <Flex flexDirection="column" width="100%">
                <GoalBreakdownBoardChallenges
                  challenges={challenges}
                  canDrop={handleCanDrop()}
                />
              </Flex>
            </Spacer>
          </Box>
          <Box style={{ width: '100%' }}>
            <Spacer size="zr sm">
              <GoalBreakdownBoardFocusAreas focusAreas={focusAreas} />
            </Spacer>
          </Box>
        </>
      )}
    </BoardColumn>
  );
};
