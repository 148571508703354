import PropTypes from 'prop-types';
import React, { useContext, useReducer, useMemo } from 'react';
import { useQueryClient } from 'react-query';

import { noop } from 'shared_DEPRECATED/utils/function';

import {
  NOTE_FILTERS,
  NOTE_FILTERS_COMPONENT_CONFIG,
  NOTE_FILTERS_DEFAULT_VALUE,
} from 'features/challengeNotes/config';
import { useChallengeNotesQueryKeyWithoutFilterContext } from 'features/challengeNotes/hooks';

const initialState = {
  filter: NOTE_FILTERS_DEFAULT_VALUE,
  filterActive: false,
  notesFiltered: false,
};

const ChallengeNotesFilterContext = React.createContext({
  ...initialState,
  setFilter: noop,
});

const filterReducer = (state, action) => {
  switch (action.type) {
    case 'set': {
      const newFilter = {
        ...state.filter,
        ...action.payload,
      };

      const filterActive = Object.keys(NOTE_FILTERS_COMPONENT_CONFIG).some(
        (filterKey) => newFilter[filterKey]
      );

      const challengeFilterActive =
        newFilter[NOTE_FILTERS.FILTER_CHALLENGE_IDS];
      const notesFiltered = filterActive || challengeFilterActive;

      return {
        filter: newFilter,
        filterActive,
        notesFiltered,
      };
    }
    default:
      return state;
  }
};

export const ChallengeNotesFilterProvider = ({ children }) => {
  const [state, dispatch] = useReducer(filterReducer, {
    ...initialState,
    filter: initialState.filter,
  });
  const queryClient = useQueryClient();
  const challengeNotesQueryKey = useChallengeNotesQueryKeyWithoutFilterContext(
    state.filter
  );

  const providerValue = useMemo(
    () => ({
      ...state,
      setFilter: (newFilter) => {
        queryClient.cancelQueries(challengeNotesQueryKey);
        dispatch({ type: 'set', payload: newFilter });
      },
    }),
    [state, queryClient, challengeNotesQueryKey]
  );

  return (
    <ChallengeNotesFilterContext.Provider value={providerValue}>
      {children}
    </ChallengeNotesFilterContext.Provider>
  );
};

ChallengeNotesFilterProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useChallengeNotesFilterContext = () => {
  const context = useContext(ChallengeNotesFilterContext);

  if (context === undefined) {
    throw new Error(
      'useChallengeNotesFilterContext must be used within a ChallengeNotesFilterProvider'
    );
  }

  return context;
};
