import PropTypes from 'prop-types';

import { ErrorMessage } from 'shared_DEPRECATED/components/Form/ErrorMessage/ErrorMessage';
import { useFormFieldError } from 'shared_DEPRECATED/hooks';

export const FormErrorMessage = ({ fieldName, dataTestid = '' }) => {
  const error = useFormFieldError(fieldName);

  return error ? (
    <ErrorMessage message={error.message} dataTestid={dataTestid} />
  ) : null;
};

FormErrorMessage.propTypes = {
  fieldName: PropTypes.string.isRequired,
  dataTestid: PropTypes.string,
};
