import { OptionsMenuButton } from 'shared_DEPRECATED/components/Floating/Menu/Options';

import { useDeleteGoalFocusArea } from 'features/goal';

import { Typography } from 'shared/components/Typography';

type TGoalFocusAreaDeleteDialog = {
  focusAreaId: string;
};

export const GoalFocusAreaDeleteDialog = ({
  focusAreaId,
}: TGoalFocusAreaDeleteDialog) => {
  const { onDeleteFocusAreaClick } = useDeleteGoalFocusArea({ focusAreaId });

  return (
    <OptionsMenuButton onClick={onDeleteFocusAreaClick}>
      <Typography color="red">Delete</Typography>
    </OptionsMenuButton>
  );
};
