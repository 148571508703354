import PropTypes from 'prop-types';

import { PictureType } from 'shared_DEPRECATED/config/propTypes';

export const notificationStatus = Object.freeze({
  read: 'read',
  unread: 'unread',
});

export const notificationType = Object.freeze({
  SPRINT_SHARED: 'SPRINT_SHARED',
  SPRINT_TITLE_CHANGED: 'SPRINT_TITLE_CHANGED',
  SPRINT_CHALLENGE_TITLE_CHANGED: 'SPRINT_CHALLENGE_TITLE_CHANGED',
  SPRINT_CHALLENGE_FREQUENCY_CHANGED: 'SPRINT_CHALLENGE_FREQUENCY_CHANGED',
  SPRINT_CHALLENGE_ADDED: 'SPRINT_CHALLENGE_ADDED',
  SPRINT_CHALLENGE_REMOVED: 'SPRINT_CHALLENGE_REMOVED',
  CHALLENGE_NOTE_CREATED: 'CHALLENGE_NOTE_CREATED',
  CHALLENGE_NOTE_REPLY_ADDED: 'CHALLENGE_NOTE_REPLY_ADDED',
  SESSION_RECAP_NOTE_REPLY_ADDED: 'SESSION_RECAP_NOTE_REPLY_ADDED',
  DIMENSIONS_ASSESSMENT_ADDED: 'DIMENSIONS_ASSESSMENT_ADDED',
  COACH_ASSIGNED_TO_CLIENT: 'COACH_ASSIGNED_TO_CLIENT',
  COACH_REMOVED_FROM_CLIENT: 'COACH_REMOVED_FROM_CLIENT',
});

export const CHALLENGE_NOTE_NOTIFICATION_TYPES = new Set([
  notificationType.CHALLENGE_NOTE_CREATED,
  notificationType.CHALLENGE_NOTE_REPLY_ADDED,
  notificationType.SESSION_RECAP_NOTE_REPLY_ADDED,
]);

export const SPRINT_NOTIFICATION_TYPES = new Set([
  notificationType.SPRINT_SHARED,
  notificationType.SPRINT_TITLE_CHANGED,
  notificationType.SPRINT_CHALLENGE_TITLE_CHANGED,
  notificationType.SPRINT_CHALLENGE_FREQUENCY_CHANGED,
  notificationType.SPRINT_CHALLENGE_ADDED,
  notificationType.SPRINT_CHALLENGE_REMOVED,
]);

export const ASSESSMENT_NOTIFICATION_TYPES = new Set([
  notificationType.DIMENSIONS_ASSESSMENT_ADDED,
  notificationType.COACH_ASSIGNED_TO_CLIENT,
]);

export const NO_REDIRECT_NOTIFICATION_TYPES = new Set([
  notificationType.COACH_REMOVED_FROM_CLIENT,
]);

export const notificationProps = PropTypes.shape({
  notificationId: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  sharerEmail: PropTypes.string,
  sharerName: PropTypes.string,
  sharerPicture: PictureType,
  sprintId: PropTypes.string,
  sprintTitle: PropTypes.string,
  status: PropTypes.oneOf(Object.values(notificationStatus)).isRequired,
  type: PropTypes.oneOf(Object.values(notificationType)),
  editorEmail: PropTypes.string,
  editorName: PropTypes.string,
  editorPicture: PictureType,
  before: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.number),
  ]),
  after: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.number),
  ]),
  challengeTitle: PropTypes.string,
});

export const notificationFilterProps = PropTypes.shape({
  unread: PropTypes.bool.isRequired,
  email: PropTypes.string,
});
