import PropTypes from 'prop-types';

import { Typography } from 'shared/components/Typography';

const ErrorMessagePropTypes = {
  message: PropTypes.string.isRequired,
  dataTestid: PropTypes.string,
};
export const ErrorMessage = ({ message, dataTestid = '' }) => (
  <Typography type="small" color="red" dataTestid={dataTestid}>
    {message}
  </Typography>
);

ErrorMessage.propTypes = ErrorMessagePropTypes;
