import { useCallback } from 'react';
import { useParams } from 'react-router-dom';

import {
  sendSessionRecapStartedAnalyticsEvent,
  sendSessionRecapSentAnalyticsEvent,
  sendSessionRecapScheduledAnalyticsEvent,
} from 'app/analytics';
import { UserMode } from 'shared_DEPRECATED/types/User';
import { dateUtils } from 'shared_DEPRECATED/utils';

import { useUserContext } from 'features/user/context';
import { useUserMode } from 'features/user/hooks';

export const useSessionAnalyticsEvents = () => {
  const { sessionId } = useParams();
  const { user } = useUserContext();
  const userMode = useUserMode() as UserMode;

  const sendSessionRecapStartEvent = useCallback(
    () =>
      sendSessionRecapStartedAnalyticsEvent({
        userMode,
        sessionId: sessionId!,
        coachId: user?.userId ?? '',
        startRecapAt: dateUtils.utc().valueOf(),
      }),
    [sessionId, user?.userId, userMode]
  );

  const sendSessionRecapFinishEvent = useCallback(
    ({ hasAttachments }: { hasAttachments: boolean }) =>
      sendSessionRecapSentAnalyticsEvent({
        userMode,
        sessionId: sessionId!,
        coachId: user?.userId ?? '',
        finishRecapAt: dateUtils.utc().valueOf(),
        hasAttachments,
      }),
    [sessionId, user?.userId, userMode]
  );

  const sendSessionRecapScheduledEvent = useCallback(
    ({ hasAttachments }: { hasAttachments: boolean }) =>
      sendSessionRecapScheduledAnalyticsEvent({
        userMode,
        sessionId: sessionId!,
        coachId: user?.userId ?? '',
        finishRecapAt: dateUtils.utc().valueOf(),
        hasAttachments,
      }),
    [sessionId, user?.userId, userMode]
  );

  return {
    sendSessionRecapStartEvent,
    sendSessionRecapFinishEvent,
    sendSessionRecapScheduledEvent,
  };
};
