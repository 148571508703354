import { Button } from 'shared';

import { useCreateGoalChallenge } from 'features/goal';

import { VARIANTS } from 'shared/components/Button';
import { Typography } from 'shared/components/Typography';

export const GoalFocusAreaMenuAddChallengeButton = () => {
  const { onCreateGoalChallengeBtnClick } = useCreateGoalChallenge({});

  return (
    <Button
      variant={VARIANTS.TERTIARY}
      onClick={onCreateGoalChallengeBtnClick}
      width="100%"
    >
      <Typography>Challenge</Typography>
    </Button>
  );
};
