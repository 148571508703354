import { HeaderGroup } from 'react-table';

import { Flex } from 'shared_DEPRECATED/components/Flex';
import { Icon, iconNames } from 'shared_DEPRECATED/components/Icon';
import Spacer from 'shared_DEPRECATED/components/Spacer';

import {
  ParticipantSessionTableItemType,
  SessionTableItemType,
} from 'features/sessions/config/types';

import { Typography } from 'shared/components/Typography';

export const SessionsTableHeaderCell = ({
  data,
}: {
  data:
    | HeaderGroup<SessionTableItemType>
    | HeaderGroup<ParticipantSessionTableItemType>;
}) => {
  const { isSortedDesc, canSort, isSorted } = data;
  const activeSortIcon = isSortedDesc ? iconNames.sortDesc : iconNames.sortAsc;

  return (
    <Spacer size="lg">
      <Flex justifyContent="flex-start" dataTestid="sessions-table-header">
        <Typography
          fontWeight="medium"
          type="small"
          whiteSpace="nowrap"
          color="black"
        >
          {data.render('Header')}
        </Typography>
        {canSort && (
          <Spacer as="span" size="zr zr zr md">
            <Icon name={isSorted ? activeSortIcon : iconNames.sort} />
          </Spacer>
        )}
      </Flex>
    </Spacer>
  );
};
