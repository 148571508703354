import Box from 'shared_DEPRECATED/components/Box';
import { Flex } from 'shared_DEPRECATED/components/Flex';
import { Icon } from 'shared_DEPRECATED/components/Icon';

import { DAILY_STATUS, STATUS_ICONS } from 'features/challenge/config';

type ChallengeStatusIconProps = {
  status: DAILY_STATUS;
};

const statusIconColor = {
  [DAILY_STATUS.FAIL]: 'var(--fgDanger)',
  [DAILY_STATUS.SUCCESS]: 'var(--fgSuccess)',
  [DAILY_STATUS.EMPTY]: 'var(--fgSecondary)',
};

export const ChallengeStatusIcon = ({
  status = DAILY_STATUS.EMPTY,
}: ChallengeStatusIconProps) => {
  const backgroundColor =
    statusIconColor[status as keyof typeof statusIconColor];

  return (
    <Box
      as="span"
      style={{
        display: 'inline-block',
        width: '1rem',
        height: '1rem',
        borderRadius: 'var(--border-radius4)',
        backgroundColor,
      }}
      data-testid="user-challenge-note-challenge-status"
    >
      <Flex
        justifyContent="center"
        alignItems="center"
        height="100%"
        width="100%"
      >
        <Icon name={STATUS_ICONS[status as keyof typeof STATUS_ICONS]} />
      </Flex>
    </Box>
  );
};
