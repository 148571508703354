import { useCallback, useEffect } from 'react';

import { logError } from 'lib/sentry/logError';
import { STORAGE_MAP, STORAGE_TYPE } from 'shared_DEPRECATED/config';

interface BrowserStorageProps<T> {
  storageType?: typeof STORAGE_TYPE[keyof typeof STORAGE_TYPE];
  storageKey: string;
  raw?: boolean;
  initialValue?: T;
}
export const useBrowserStorage = <T>({
  storageType = STORAGE_TYPE.LOCAL,
  storageKey,
  raw = false,
  initialValue,
}: BrowserStorageProps<T>) => {
  const storage = STORAGE_MAP[storageType];

  const setStorageValue = useCallback(
    (value: T) => {
      try {
        const serializedValue = raw ? String(value) : JSON.stringify(value);

        storage.setItem(storageKey, serializedValue);
      } catch (error) {
        // If user is in private mode or has storage restriction
        // sessionStorage can throw an error. Also, JSON.stringify can throw.
        logError(error);
      }
    },
    [raw, storage, storageKey]
  );

  const removeStorageValue = useCallback(() => {
    try {
      storage.removeItem(storageKey);
    } catch (error) {
      logError(error);
    }
  }, [storage, storageKey]);

  const getStorageValue = useCallback(
    () => storage.getItem(storageKey),
    [storage, storageKey]
  );

  useEffect(() => {
    initialValue && setStorageValue(initialValue);
  }, [initialValue, setStorageValue]);

  return { setStorageValue, removeStorageValue, getStorageValue };
};
