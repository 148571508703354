import Button, { VARIANTS } from 'shared_DEPRECATED/components/Button';
import { Flex } from 'shared_DEPRECATED/components/Flex';
import { Icon, iconNames } from 'shared_DEPRECATED/components/Icon';
import Spacer from 'shared_DEPRECATED/components/Spacer';

type ReactionsExpandButtonPropTypes = {
  onClick: () => void;
};

export const ReactionsExpandButton = ({
  onClick,
}: ReactionsExpandButtonPropTypes) => (
  <Button onClick={onClick} variant={VARIANTS.PRIMARY}>
    <Spacer size="sm">
      <Flex alignItems="center" justifyContent="center">
        <Icon
          name={iconNames.chevronDown}
          width={8}
          height={8}
          stroke="var(--white)"
        />
      </Flex>
    </Spacer>
  </Button>
);
