import PropTypes from 'prop-types';

import { notificationFilterProps } from 'app/notifications/config';
import { Avatar, SIZES } from 'shared_DEPRECATED/components/Avatar';
import Box from 'shared_DEPRECATED/components/Box';
import {
  Filter,
  FilterItem,
  FilterSection,
} from 'shared_DEPRECATED/components/Filter';
import { Flex } from 'shared_DEPRECATED/components/Flex';
import Spacer from 'shared_DEPRECATED/components/Spacer';
import { getPictureUrl } from 'shared_DEPRECATED/utils';

import { useParticipantsQuery } from 'features/participants/hooks/query/useParticipants';

import { Typography } from 'shared/components/Typography';

const notificationsFilterProps = {
  setFilter: PropTypes.func.isRequired,
  filter: notificationFilterProps,
};

export const NotificationsFilter = ({ setFilter, filter }) => {
  const { participants } = useParticipantsQuery();

  const onFilterChange = (newFilter) => {
    setFilter({ ...filter, ...newFilter });
  };

  return (
    <Filter isActive={filter.unread || !!filter.email}>
      <FilterSection>
        <FilterItem
          active={!filter.unread}
          onChange={() => onFilterChange({ unread: false })}
        >
          <Typography color="black" type="small">
            All notifications
          </Typography>
        </FilterItem>
        <FilterItem
          active={filter.unread}
          onChange={() => onFilterChange({ unread: true })}
        >
          <Typography color="black" type="small">
            Unread only
          </Typography>
        </FilterItem>
      </FilterSection>
      <FilterItem
        onChange={() => onFilterChange({ email: null })}
        active={!filter.email}
      >
        <Typography color="black" type="small">
          Show all participants
        </Typography>
      </FilterItem>
      {participants.map(({ userInfo }) => {
        const { email, fullName, picture } = userInfo;
        return (
          <FilterItem
            key={userInfo.email}
            onChange={() => onFilterChange({ email })}
            active={filter.email === email}
          >
            <Flex>
              <Spacer size="zr md zr zr">
                <Avatar
                  name={fullName || email}
                  size={SIZES.SM}
                  src={getPictureUrl(picture)}
                />
              </Spacer>
              <Box style={{ width: '9rem' }}>
                <Typography color="black" type="small" cropped>
                  {fullName || email}
                </Typography>
              </Box>
            </Flex>
          </FilterItem>
        );
      })}
    </Filter>
  );
};

NotificationsFilter.propTypes = notificationsFilterProps;
