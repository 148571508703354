import { ReactNode } from 'react';

import { Flex } from 'shared_DEPRECATED/components/Flex';

type ReactionsButtonsRowPropTypes = {
  children: ReactNode;
};

export const ReactionsButtonsRow = ({
  children,
}: ReactionsButtonsRowPropTypes) => (
  <Flex
    alignItems="center"
    justifyContent="flex-start"
    flexWrap="wrap"
    gap="0.25rem"
  >
    {children}
  </Flex>
);
