import PropTypes from 'prop-types';

import { PICTURE_STATUSES } from 'shared_DEPRECATED/config/index';

const PictureShape = {
  key: PropTypes.string,
  url: PropTypes.string,
  blurHash: PropTypes.string,
  status: PropTypes.oneOf(Object.values(PICTURE_STATUSES)),
};

export const PictureType = PropTypes.shape(PictureShape);

export const ReactionType = PropTypes.shape({
  email: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  name: PropTypes.string,
  picture: PictureType,
});
