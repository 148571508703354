import { CodeHighlightNode, CodeNode } from '@lexical/code';
import { HashtagNode } from '@lexical/hashtag';
import { AutoLinkNode, LinkNode } from '@lexical/link';
import { ListItemNode, ListNode } from '@lexical/list';
import { HeadingNode, QuoteNode } from '@lexical/rich-text';
import { Klass, LexicalNode } from 'lexical';

import { ImageNode } from './ImageNode/ImageNode';

export const editorNodes: Array<Klass<LexicalNode>> = [
  ListNode,
  ListItemNode,
  HeadingNode,
  QuoteNode,
  CodeNode,
  HashtagNode,
  CodeHighlightNode,
  LinkNode,
  AutoLinkNode,
  ImageNode,
];
