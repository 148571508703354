import { useState } from 'react';

import {
  NotificationsEmptyFilter,
  NotificationsEmptyList,
  NotificationsHeader,
  NotificationsItem,
} from 'app/notifications/components';
import { useNotificationsQuery } from 'app/notifications/hooks';
import { isValidNotification } from 'app/notifications/utils';
import { GeneralWidgetError } from 'shared_DEPRECATED/components/Error/GeneralWidgetError';
import Loader from 'shared_DEPRECATED/components/Loader';

import { SidebarBody } from 'shared/components/Sidebar';

import { useNotificationsAnalytics } from '../hooks/useNotificationsAnalytics';
import styles from 'app/notifications/components/Notifications.module.css';

export const NotificationsSidebar = () => {
  const [filter, setFilter] = useState({ unread: false, email: null });

  const { notifications, isFetching, error } = useNotificationsQuery({
    status: filter.unread ? 'unread' : null,
    client: filter.email || null,
  });
  const { sendNotificationsFilterAppliedEvent } = useNotificationsAnalytics();

  const isFilterSelected = filter.unread || filter.email;

  const showEmptyFilterMessage =
    !isFetching && isFilterSelected && notifications.length === 0;

  const handleFilterChange = (newFilter) => {
    setFilter(newFilter);
    sendNotificationsFilterAppliedEvent(newFilter);
  };

  return (
    <>
      <NotificationsHeader filter={filter} setFilter={handleFilterChange} />
      <SidebarBody>
        {error ? (
          <GeneralWidgetError />
        ) : (
          <>
            {isFetching && <Loader />}
            {!isFetching && !isFilterSelected && notifications.length === 0 && (
              <NotificationsEmptyList />
            )}
            {showEmptyFilterMessage && <NotificationsEmptyFilter />}
            <div className={styles.notification__content}>
              {notifications.map((notification) =>
                isValidNotification(notification) ? (
                  <NotificationsItem
                    key={notification.notificationId}
                    notification={notification}
                  />
                ) : null
              )}
            </div>
          </>
        )}
      </SidebarBody>
    </>
  );
};
