import { ReactNode } from 'react';

import { Spacers } from 'shared_DEPRECATED/components/Spacer';

type TNavigation = {
  children: ReactNode;
};

export const Navigation = ({ children }: TNavigation) => (
  <Spacers sizes={['lg lg zr', 'md zr']}>{children}</Spacers>
);
