import Button, { VARIANTS } from 'shared_DEPRECATED/components/Button';
import { Icon, iconNames } from 'shared_DEPRECATED/components/Icon';

import { ParticipantsTableActionDisabled } from 'features/participants/components/Table/Actions/Disabled';
import { ParticipantsTableActionWrapper } from 'features/participants/components/Table/Actions/Wrapper';
import { ParticipantsTableUserInfo } from 'features/participants/config/types';
import { NextSessionSidebar } from 'features/sessions/components/NextSessionForm';

import { sidebar } from 'shared/components/Sidebar';

type ParticipantsTableNextSessionHintProps = {
  appointmentId?: string;
  userInfo: ParticipantsTableUserInfo;
};

export const ParticipantsTableNextSessionHint = ({
  appointmentId,
  userInfo,
}: ParticipantsTableNextSessionHintProps) => {
  const handleNextSessionHintClick = () => {
    sidebar.open({
      content: <NextSessionSidebar appointmentId={appointmentId!} />,
      context: { userInfo },
    });
  };

  return appointmentId ? (
    <Button
      variant={VARIANTS.TERTIARY}
      onClick={handleNextSessionHintClick}
      dataTestid="next-session-hint-button"
    >
      <ParticipantsTableActionWrapper>
        <Icon name={iconNames.clinicalNotes} width={18} height={18} />
      </ParticipantsTableActionWrapper>
    </Button>
  ) : (
    <ParticipantsTableActionDisabled
      label="User doesn't have sessions yet"
      icon={iconNames.clinicalNotes}
    />
  );
};
