import { VStack } from '@chakra-ui/react';
import { For } from 'react-loops';

import { Checkbox, Flex, Typography } from 'shared';

import { GoalImage } from 'features/goal/components/Image';
import { GoalExtended } from 'features/goal/config/types';

type GoalsListProps = {
  goals: GoalExtended[];
  onGoalToggle: (goalId: string) => void;
};

export const GoalPrioritizeModalList = ({
  goals,
  onGoalToggle,
}: GoalsListProps) => (
  <VStack alignItems="flex-start" gap="var(--spacing02)">
    <For of={goals}>
      {(goal) => (
        <GoalPrioritizeModalListItem
          goalId={goal.goalId}
          title={goal.title}
          imageUrl={goal.imageUrl}
          dimensions={goal.dimensions}
          prioritizedAt={goal.prioritizedAt}
          isDisabled={goal.isDisabled}
          isChecked={goal.isChecked}
          onGoalToggle={onGoalToggle}
        />
      )}
    </For>
  </VStack>
);

export const GoalPrioritizeModalListItem = ({
  goalId,
  title,
  imageUrl,
  dimensions,
  isDisabled,
  isChecked,
  onGoalToggle,
}: Omit<GoalExtended, 'name'> & { onGoalToggle: (goalId: string) => void }) => {
  const handleChange = () => {
    onGoalToggle(goalId);
  };

  return (
    <Flex justifyContent="space-between" alignItems="center" w="100%">
      <Flex gap="var(--spacing04)" opacity={isDisabled ? 0.5 : 1}>
        <GoalImage imageUrl={imageUrl} />
        <Flex
          direction="column"
          gap="var(--spacing00)"
          justifyContent="space-evenly"
        >
          <Typography type="meta" fontWeight="medium">
            {title}
          </Typography>
          <Typography
            type="small"
            color="gray"
            css={{ textTransform: 'capitalize' }}
          >
            {dimensions.join(' • ')}
          </Typography>
        </Flex>
      </Flex>
      <Checkbox
        isChecked={isChecked}
        isDisabled={isDisabled}
        onChange={handleChange}
      />
    </Flex>
  );
};
