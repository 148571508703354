import { useQuery } from 'react-query';

import { queryKeys } from 'api/config';
import { useQueryHTTPRequest } from 'api/hooks/useHTTPRequest';

import { SPRINT_WEEK_NUMBER } from 'features/participants/config';
import { ParticipantSprint } from 'features/sprint';
import { SPRINT_STATES } from 'features/sprint/config';

export const mapParticipantData = (participant) => {
  const {
    userEmail,
    userName: fullName,
    firstName,
    lastName,
    userPicture,
    lastActive,
    latestSprint,
    coachingStatus,
    coachingMode,
    coachedSprintsCount,
    unreadChallengeNotesNumber,
    unreadSessionRecapNotesNumber,
    nextCoachingSessionAppointment,
    unreadChatMessagesNumber,
    chatId,
    userId,
  } = participant;

  const hasSprint = !!latestSprint;
  const mappedSprint = hasSprint && new ParticipantSprint(latestSprint);

  return {
    userInfo: {
      email: userEmail,
      picture: userPicture,
      fullName,
      firstName,
      lastName,
      userId,
    },
    sprintDuration: hasSprint ? mappedSprint.duration : null,
    status: hasSprint ? mappedSprint.state : SPRINT_STATES.MISSING,
    unreadNotesNumber: hasSprint
      ? unreadChallengeNotesNumber + unreadSessionRecapNotesNumber
      : null,
    latestSprint: mappedSprint,
    lastActive,
    coachingStatus,
    coachingMode,
    coachedSprintsCount,
    sprintType: mappedSprint?.sprintTypeLabel,
    currentWeekNumber: hasSprint
      ? mappedSprint.weekNumberLabel
      : SPRINT_WEEK_NUMBER.NO_SPRINT,
    nextCoachingSessionAppointment,
    unreadChatMessagesNumber,
    chatId,
    momentumScores: mappedSprint.momentumScores,
  };
};

export const useParticipantsQuery = () => {
  const { request } = useQueryHTTPRequest();
  const { data, isFetching, isLoading } = useQuery(
    queryKeys.participants,
    () => request({ url: `/api/coach/clients` }),

    {
      select: (data) => data?.items.map(mapParticipantData),
    }
  );

  return { participants: data || [], isFetching, isLoading };
};
