import PropTypes from 'prop-types';

import Spacer from 'shared_DEPRECATED/components/Spacer';
import { noop } from 'shared_DEPRECATED/utils/function';

import { VARIANTS } from 'shared/components/Button';
import { ModalButton } from 'shared/components/Modal/ModalButton';

const FrequencyDialogButtonsPropTypes = {
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  shouldCloseOnSubmit: PropTypes.bool,
};

export const FrequencyDialogButtons = ({
  onSave,
  onCancel = noop,
  shouldCloseOnSubmit = true,
}) => (
  <>
    <ModalButton
      variant={VARIANTS.SECONDARY}
      onClick={onCancel}
      dataTestid="challenge-frequency-dialog-cancel"
      shouldClose={shouldCloseOnSubmit}
    >
      Cancel
    </ModalButton>
    <Spacer size="zr md zr sm" />
    <ModalButton
      onClick={onSave}
      variant={VARIANTS.PRIMARY}
      dataTestid="challenge-frequency-dialog-save"
      shouldClose={shouldCloseOnSubmit}
    >
      Save
    </ModalButton>
  </>
);

FrequencyDialogButtons.propTypes = FrequencyDialogButtonsPropTypes;
