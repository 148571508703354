import { toastConfig } from 'lib/reactToastify/config';
import { lazy, Suspense, useCallback } from 'react';
import { toast } from 'react-toastify';

import Loader from 'shared_DEPRECATED/components/Loader';
import lazyRetry from 'shared_DEPRECATED/utils/lazyRetry';

import { useDeleteGoalFocusAreaMutation } from 'features/goal';

import { modal, SIZES } from 'shared/components/Modal';

const GoalDeleteFocusAreaDialogContent = lazy(() =>
  lazyRetry(
    () => import('features/goal/components/FocusArea/Menu/Delete/Dialog'),
    'GoalDeleteFocusAreaDialog'
  )
);

type TUseDeleteGoalFocusArea = {
  focusAreaId: string;
};

export const useDeleteGoalFocusArea = ({
  focusAreaId,
}: TUseDeleteGoalFocusArea) => {
  const { mutateAsync } = useDeleteGoalFocusAreaMutation({
    focusAreaId,
  });

  const onSubmit = useCallback(async () => {
    try {
      await mutateAsync();
    } catch (err) {
      toast.error(
        "There's an error with deleting the focus area. Please try again later.",
        toastConfig
      );
    }
  }, [mutateAsync]);

  const openDeleteFocusAreaModal = useCallback(() => {
    modal.open({
      content: (
        <Suspense fallback={<Loader size="sm" />}>
          <GoalDeleteFocusAreaDialogContent onSubmit={onSubmit} />
        </Suspense>
      ),
      props: {
        size: SIZES.MD,
      },
    });
  }, [onSubmit]);

  return {
    onDeleteFocusAreaClick: openDeleteFocusAreaModal,
  };
};
