export const JIRA_SCRIPT_LINK =
  'https://hosinc.atlassian.net/s/d41d8cd98f00b204e9800998ecf8427e-T/tod1zk/b/4/c95134bc67d3a521bb3f4331beb9b804/_/download/batch/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector.js?locale=en-US&collectorId=23a3f710';

export const JIRA_SETTINGS_GLOBAL_FIELD = 'ATL_JQ_PAGE_PROPS';
export const USER_ID_FIELD = 'customfield_10065';
export const WEB_BUILD_FIELD = 'customfield_10141';

export type TFeedbackFormData = {
  fullname: string;
  email: string;
  [USER_ID_FIELD]: string;
  [WEB_BUILD_FIELD]: string;
};

export const FEEDBACK_DEFAULT_FORM_DATA: TFeedbackFormData = {
  fullname: '',
  email: '',
  [USER_ID_FIELD]: '',
  [WEB_BUILD_FIELD]: '',
};

export const FEEDBACK_CALLBACK_EVENT = 'feedback:setCallback';
