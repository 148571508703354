import { useCallback, useEffect, useState } from 'react';

import { off, on, useSocketContext } from 'app/socket';
import { Nullable } from 'shared_DEPRECATED/types';

import {
  EVENT_TYPE,
  OUTGOING_EVENT_TYPE,
} from 'features/chat/config/types/Message';
import { ChatMember } from 'features/chat/config/types/types';

export const useChatMemberOnlineStatus = (
  chatId: Nullable<string>,
  email: string
) => {
  const { emit, isSocketReady } = useSocketContext();
  const [isOnline, setIsOnline] = useState<boolean>(false);

  const handleMembersUpdate = useCallback(
    ({ members }: { members: ChatMember[] }) => {
      const chatMember = members.find(
        (member: ChatMember) => member.userEmail === email
      );

      if (!chatMember) return;

      setIsOnline(chatMember.online);
    },
    [email]
  );

  const handleOnlineStatusUpdate = useCallback(
    ({ online, userEmail }: { online: boolean; userEmail: string }) => {
      if (email !== userEmail) return;

      setIsOnline(online);
    },
    [email]
  );

  useEffect(() => {
    if (isSocketReady) {
      emit({
        event: OUTGOING_EVENT_TYPE.CHAT_GET_MEMBERS,
        chatId,
      });
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSocketReady, chatId]);

  useEffect(() => {
    on(EVENT_TYPE.CHAT_MEMBER_ONLINE_STATUS_UPDATE, handleOnlineStatusUpdate);
    on(EVENT_TYPE.CHAT_MEMBERS_UPDATE, handleMembersUpdate);

    return () => {
      off(
        EVENT_TYPE.CHAT_MEMBER_ONLINE_STATUS_UPDATE,
        handleOnlineStatusUpdate
      );
      off(EVENT_TYPE.CHAT_MEMBERS_UPDATE, handleMembersUpdate);
    };
  }, [handleOnlineStatusUpdate, handleMembersUpdate]);

  return isOnline;
};
