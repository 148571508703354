import PropTypes from 'prop-types';
import { forwardRef } from 'react';

import BaseInput from 'shared_DEPRECATED/components/Form/Input/BaseInput';

const EmailInputPropTypes = {
  /**
   * @type {string}
   * @memberof EmailInputPropTypes
   * @name defaultValue
   * @example <BaseInput defaultValue="John Doe" />
   */
  defaultValue: PropTypes.string,
  /**
   * @type {string}
   * @memberof EmailInputPropTypes
   * @name name
   * @example <BaseInput name="John Doe" />
   */
  name: PropTypes.string.isRequired,
  /**
   * @type {string}
   * @memberof EmailInputPropTypes
   * @name placeholder
   * @example <BaseInput placeholder="John Doe" />
   */
  placeholder: PropTypes.string,
  /**
   * @type {('sm'|'lg')}
   * @required
   * @memberof EmailInputPropTypes
   * @name size
   * @example <BaseInput size="sm" />
   */
  size: PropTypes.oneOf(['sm', 'lg']),
  /**
   * @type {boolean}
   * @required
   * @memberof EmailInputPropTypes
   * @name error
   * @example <BaseInput error />
   */
  error: PropTypes.bool,
};

const EmailInput = forwardRef(
  ({ defaultValue = '', name, placeholder, ...props }, ref) => (
    <BaseInput
      defaultValue={defaultValue}
      name={name}
      placeholder={placeholder}
      ref={ref}
      type="email"
      {...props}
    />
  )
);

EmailInput.propTypes = EmailInputPropTypes;

export default EmailInput;
