import {
  arrow,
  autoUpdate,
  flip,
  offset,
  shift,
  useDismiss,
  useFloating,
  useFocus,
  useHover,
  useInteractions,
  useRole,
} from '@floating-ui/react-dom-interactions';
import PropTypes from 'prop-types';
import { useRef, useState } from 'react';

import { Flex } from 'shared_DEPRECATED/components/Flex';
import Spacer from 'shared_DEPRECATED/components/Spacer';

import Box from '../../Box';
import { PLACEMENTS } from '../config';
import TooltipArrow from './TooltipArrow';

const FloatingTooltipProps = {
  /**
   * The content of the tooltip.
   * @type {React.ReactNode}
   * @required
   * @memberof FloatingTooltipProps
   * @name children
   * @example <FloatingTooltip>Hello World</FloatingTooltip>
   */
  children: PropTypes.node.isRequired,
  /**
   * The offset value of the tooltip.
   * @type {number}
   * @default 0
   * @memberof FloatingTooltipProps
   * @name offsetValue
   * @example <FloatingTooltip offsetValue={8}>Hello World</FloatingTooltip>
   */
  offsetValue: PropTypes.number,
  /**
   * The anchor of the tooltip.
   * @type {React.ReactNode}
   * @required
   * @default null
   * @memberof FloatingTooltipProps
   * @name anchor
   * @example <FloatingTooltip anchor={<Button>Hello World</Button>}>Hello World</FloatingTooltip>
   */
  anchor: PropTypes.node.isRequired,
  /**
   * The flag which determines whether the tooltip should be enabled.
   * @type {boolean}
   * @default true
   * @memberof FloatingTooltipProps
   * @name enabled
   * @example <FloatingTooltip enabled={true}>Hello World</FloatingTooltip>
   */
  enabled: PropTypes.bool,
  /**
   * The placement of the tooltip.
   * @type {'top' | 'bottom' | 'left' | 'right' | 'top-start' | 'top-end' | 'bottom-start' | 'bottom-end' | 'left-start' | 'left-end' | 'right-start' | 'right-end'}
   * @default 'bottom'
   * @memberof FloatingTooltipProps
   * @name placement
   * @example <FloatingTooltip placement='top'>Hello World</FloatingTooltip>
   */
  placement: PropTypes.oneOf(Object.values(PLACEMENTS)),
  /**
   * The width of the tooltip.
   * @type {number}
   * @default auto
   * @memberof FloatingTooltipProps
   * @name width
   * @example <FloatingTooltip width={200}>Hello World</FloatingTooltip>
   */
  width: PropTypes.string,
  dataTestid: PropTypes.string,
};

export const FloatingTooltip = ({
  children,
  anchor,
  enabled = true,
  offsetValue = 0,
  placement = PLACEMENTS.BOTTOM,
  width = 'auto',
  dataTestid = null,
}) => {
  const arrowRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const {
    context,
    x,
    y,
    reference,
    floating,
    strategy,
    middlewareData: { arrow: { x: arrowX, y: arrowY } = {} },
    placement: actualPlacement,
  } = useFloating({
    open: isOpen,
    middleware: [
      offset(offsetValue),
      flip(),
      shift(),
      arrow({ element: arrowRef }),
    ],
    onOpenChange: setIsOpen,
    placement,
    whileElementsMounted: autoUpdate,
  });

  const staticSide = {
    top: 'bottom',
    right: 'left',
    bottom: 'top',
    left: 'right',
  }[actualPlacement.split('-')[0]];

  const { getReferenceProps, getFloatingProps } = useInteractions([
    useHover(context, {
      delay: {
        open: 800,
        close: 0,
      },
      enabled,
    }),
    useFocus(context),
    useRole(context, { role: 'tooltip' }),
    useDismiss(context, { referencePointerDown: true }),
  ]);

  return (
    <>
      <div {...getReferenceProps({ ref: reference })} data-testid={dataTestid}>
        <Flex flexDirection="column">{anchor}</Flex>
      </div>
      {isOpen && (
        <Box
          {...getFloatingProps({
            ref: floating,
            style: {
              backgroundColor: 'var(--bgCompPrimaryInverse)',
              filter: 'var(--tooltip-shadow)',
              borderRadius: 'var(--border-radius2)',
              zIndex: 1,
              position: strategy,
              top: y ?? 0,
              left: x ?? 0,
              width,
            },
          })}
        >
          <Spacer size="md lg">
            {children}
            <TooltipArrow
              staticSide={staticSide}
              x={arrowX}
              y={arrowY}
              ref={arrowRef}
            />
          </Spacer>
        </Box>
      )}
    </>
  );
};

FloatingTooltip.propTypes = FloatingTooltipProps;
