import Box from 'shared_DEPRECATED/components/Box';
import { Flex } from 'shared_DEPRECATED/components/Flex';
import Spacer from 'shared_DEPRECATED/components/Spacer';

import { FailReasonsType } from 'features/challengeNotes/config/propTypes';

import { Typography } from 'shared/components/Typography';

const ChallengeNotesCardsFailReasonsPropTypes = {
  reasons: FailReasonsType,
};

export const ChallengeNotesCardFailReasons = ({ reasons }) => {
  return reasons.length > 0 ? (
    <Spacer size="zr zr sm zr">
      <Flex flexWrap="wrap" justifyContent="flex-start">
        {reasons.map((reason) => (
          <Spacer size="zr sm sm zr" key={reason}>
            <Box
              style={{
                borderRadius: 'var(--border-radius4)',
                backgroundColor: 'var(--bgCompSecondaryBig)',
              }}
            >
              <Spacer size="xsm md">
                <Typography
                  type="small"
                  color="black"
                  dataTestid="user-challenge-note-fail-reason"
                >
                  {reason}
                </Typography>
              </Spacer>
            </Box>
          </Spacer>
        ))}
      </Flex>
    </Spacer>
  ) : null;
};

ChallengeNotesCardFailReasons.propTypes =
  ChallengeNotesCardsFailReasonsPropTypes;
