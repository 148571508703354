export const appendNestedLiWithSiblings = (
  parentUl: Element,
  childLi: Element,
  className: string
) => {
  const nextSibling = childLi.nextElementSibling;
  parentUl.appendChild(childLi);

  if (!!nextSibling && nextSibling.classList.contains(className)) {
    appendNestedLiWithSiblings(parentUl, nextSibling, className);
  }
};

//this util fix issue with nested lists in Quill https://github.com/quilljs/quill/issues/979
export const getHtmlStringWithNestedLists = (
  htmlString: string,
  className: string = 'ql-indent-1'
) => {
  const parser = new DOMParser();
  const htmlDoc = parser.parseFromString(htmlString, 'text/html');

  const topLevelListItems = htmlDoc.querySelectorAll(
    `ol > li:not(.${className}) + li.${className}`
  );

  Array.from(topLevelListItems).forEach((topLevelListItem) => {
    const ulElement = htmlDoc.createElement('ul');
    const parentElementForNestedList =
      topLevelListItem.previousElementSibling ||
      topLevelListItem.parentElement!;

    appendNestedLiWithSiblings(ulElement, topLevelListItem, className);

    parentElementForNestedList.appendChild(ulElement);
  });

  return htmlDoc.querySelector('body')!.innerHTML;
};

export const getHtmlStringWithFlatLists = (htmlString: string) => {
  const parser = new DOMParser();
  const htmlDoc = parser.parseFromString(htmlString, 'text/html');

  const nestedUlItems = htmlDoc.querySelectorAll('li>ul');

  Array.from(nestedUlItems).forEach((nestedUlItem) => {
    const liWithNestedUl = nestedUlItem.parentElement!;
    const childLiItems = nestedUlItem.children;

    Array.from(childLiItems).forEach((nestedLiItem) => {
      liWithNestedUl.append(nestedLiItem);
    });

    nestedUlItem.remove();
  });

  return htmlDoc.querySelector('body')!.innerHTML;
};
