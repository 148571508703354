import { useColorMode } from '@chakra-ui/react';

import Box from 'shared_DEPRECATED/components/Box';
import { Flex } from 'shared_DEPRECATED/components/Flex';
import {
  FloatingPopover,
  PLACEMENTS,
} from 'shared_DEPRECATED/components/Floating';
import EmojiPicker from 'shared_DEPRECATED/components/Form/EmojiPicker';
import {
  ReactionsPopoverButton,
  ReactionsButtonsRow,
  ReactionsCommon,
  ReactionsSelectionRow,
} from 'shared_DEPRECATED/components/Reactions';
import { ACTION_TYPES } from 'shared_DEPRECATED/components/Reactions/config';
import {
  ReactionActionType,
  ReactionsType,
} from 'shared_DEPRECATED/components/Reactions/types';
import Spacer from 'shared_DEPRECATED/components/Spacer';
import useMultipleToggle from 'shared_DEPRECATED/hooks/useMultipleToggle';

import styles from 'shared_DEPRECATED/components/Reactions/Reactions.module.css';

const PICKER = {
  SMALL: 'smallPicker',
  BIG: 'bigPicker',
};

export type ReactionsPropsType = {
  emojis: string[];
  reactions?: ReactionsType;
  onEmojiSelect: (data: { emoji: string; type: ReactionActionType }) => void;
  theme?: 'light' | 'dark';
  options: any;
};

export const Reactions = ({
  emojis,
  onEmojiSelect,
  reactions = {},
  theme = 'light',
  options,
}: ReactionsPropsType) => {
  const { state, toggleAll, toggleOpen, clearAll } = useMultipleToggle({
    toggleNames: [PICKER.SMALL, PICKER.BIG],
  });

  const { colorMode: currentTheme } = useColorMode();

  const isThemeLight = currentTheme === 'light';
  const newTheme = isThemeLight ? 'light' : 'dark';

  const handleEmojiSelect = (emoji: string) => {
    const isMyReaction = reactions[emoji]?.isMy;
    const type = isMyReaction ? ACTION_TYPES.REMOVE : ACTION_TYPES.ADD;

    onEmojiSelect({
      emoji,
      type,
    });
  };

  return (
    <Box>
      <div className={styles.reactions}>
        <ReactionsButtonsRow>
          <ReactionsSelectionRow
            onEmojiSelect={handleEmojiSelect}
            reactions={reactions}
            theme={theme}
          />
          <FloatingPopover
            onClose={clearAll}
            isOpen={state[PICKER.SMALL] || state[PICKER.BIG]}
            placement={PLACEMENTS.TOP}
            anchor={
              <ReactionsPopoverButton
                isClicked={state[PICKER.SMALL]}
                onClick={() => {
                  state[PICKER.BIG]
                    ? toggleOpen(PICKER.BIG)
                    : toggleOpen(PICKER.SMALL);
                }}
                dataTestid="add-new-reaction-button"
                theme={theme}
              />
            }
            offsetTop={4}
            isAutoUpdate={true}
          >
            <Spacer size="sm md">
              {state[PICKER.BIG] ? (
                <EmojiPicker
                  onEmojiSelect={({ native: emoji }) => {
                    toggleOpen(PICKER.BIG);
                    handleEmojiSelect(emoji);
                  }}
                  options={{ ...options, theme: newTheme }}
                />
              ) : (
                <Flex>
                  <ReactionsCommon
                    emojis={emojis}
                    onClick={(emoji) => {
                      toggleOpen(PICKER.SMALL);
                      handleEmojiSelect(emoji);
                    }}
                    toggleAll={toggleAll}
                  />
                </Flex>
              )}
            </Spacer>
          </FloatingPopover>
        </ReactionsButtonsRow>
      </div>
    </Box>
  );
};
