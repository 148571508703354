import { useParams } from 'react-router-dom';

import useSprintQuery from 'features/sprint/hooks/query/useSprint';

const SprintBreadcrumb = () => {
  const { sprintId } = useParams();

  const { data: sprint } = useSprintQuery({
    sprintId,
  });

  return <span>{sprint?.title}</span>;
};

export default SprintBreadcrumb;
