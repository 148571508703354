import { useCallback } from 'react';

import { NotificationsButton } from 'app/notifications/components/NotificationsButton';
import { NotificationsSidebar } from 'app/notifications/components/NotificationsSidebar';

import { sidebar } from 'shared/components/Sidebar';

import { useNotificationsAnalytics } from '../hooks/useNotificationsAnalytics';

export const Notifications = () => {
  const { sendNotificationsOpenEvent } = useNotificationsAnalytics();
  const openSidebar = useCallback(() => {
    sidebar.open({
      content: <NotificationsSidebar />,
      props: { title: 'Notifications' },
    });
    sendNotificationsOpenEvent();
  }, [sendNotificationsOpenEvent]);

  return <NotificationsButton setShowNotifications={openSidebar} />;
};
