import { Link } from 'react-router-dom';

import { Flex } from 'shared_DEPRECATED/components/Flex';
import { Icon, iconNames } from 'shared_DEPRECATED/components/Icon';
import Spacer from 'shared_DEPRECATED/components/Spacer';

import { useGoalsNavigationLink } from 'features/goal';

import { Typography } from 'shared/components/Typography';

export const GoalBackButton = () => {
  const url = useGoalsNavigationLink();

  return (
    <Link style={{ textDecoration: 'none' }} to={url}>
      <Flex>
        <Icon
          name={iconNames.arrowLeft}
          width={11}
          height={11}
          stroke="var(--fgSecondary)"
        />
        <Spacer size="sm" />
        <Typography color="gray" type="meta">
          Back to goals
        </Typography>
      </Flex>
    </Link>
  );
};
