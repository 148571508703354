import { ReactNode } from 'react';

import { Box } from 'shared';

type TBoardWrapper = {
  children: ReactNode;
  fraction: number;
};

export const BoardColumnWrapper = ({ children, fraction }: TBoardWrapper) => (
  <Box
    borderRadius="var(--border-radius)"
    overflow="hidden"
    flex={`1 1 ${100 / fraction}%`}
    display="flex"
    flexDirection="column"
    height="100%"
    backgroundColor="var(--bgCompSecondaryBig)"
  >
    {children}
  </Box>
);
