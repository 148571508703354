import { useEffect, useState } from 'react';

import Box from 'shared_DEPRECATED/components/Box';
import Button from 'shared_DEPRECATED/components/Button';
import {
  FloatingTooltip as Tooltip,
  PLACEMENTS,
} from 'shared_DEPRECATED/components/Floating';
import Spacer from 'shared_DEPRECATED/components/Spacer';

import { useIsLatestBuildVersion } from 'features/version/utils/version';

import { Typography } from 'shared/components/Typography';

export const NewVersionBanner = () => {
  const isLatestVersion = useIsLatestBuildVersion();

  const [shouldShowBanner, setShouldShowBanner] = useState(false);
  useEffect(() => {
    if (isLatestVersion !== undefined) {
      setShouldShowBanner(!isLatestVersion);
    }
  }, [isLatestVersion]);
  return (
    <Box
      style={{
        position: 'fixed',
        top: '-2rem',
        left: '50%',
        zIndex: 3,
        backgroundColor: 'var(--bgCompPrimaryInverse)',
        borderRadius: '0 0 0.5rem 0.5rem',
        textAlign: 'center',
        transitionDuration: '200ms',
        transform: shouldShowBanner
          ? 'translate(0, 2rem)'
          : 'translate(0, -2rem)',
      }}
    >
      <Tooltip
        anchor={
          <Spacer size="sm md xsm">
            <Button onClick={() => window.location.reload()}>
              <Typography color="white">New version available!</Typography>
            </Button>
          </Spacer>
        }
        placement={PLACEMENTS.BOTTOM}
      >
        <Typography color="white">Click to update</Typography>
      </Tooltip>
    </Box>
  );
};
