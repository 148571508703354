import {
  File,
  FileActions,
  FileIcon,
  FileTitle,
} from 'shared_DEPRECATED/components/File';

type UploadedFilePropTypes = {
  contentType: string;
  name: string;
  children?: React.ReactNode;
};

export const UploadedFile = ({
  contentType,
  name,
  children,
}: UploadedFilePropTypes) => (
  <File>
    <FileIcon contentType={contentType} />
    <FileTitle title={name} contentType={contentType} />
    <FileActions>{children}</FileActions>
  </File>
);
