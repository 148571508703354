import { useMutation, useQueryClient } from 'react-query';

import { queryKeys } from 'api/config';
import { logError } from 'lib/sentry/logError';
import { useMutationHTTPRequest } from 'shared_DEPRECATED/hooks';

import { Participants } from 'features/participants/config/types';
import { NextCoachingSessionAppointment } from 'features/sessions/config/types';
import { mapNextSessionPostData } from 'features/sessions/utils';

export const useNextSessionUpdateMutation = (appointmentId: string) => {
  const queryClient = useQueryClient();
  const { request } = useMutationHTTPRequest();

  return useMutation(
    (session: NextCoachingSessionAppointment) =>
      request({
        url: `/api/coach/coaching-session-appointments/${appointmentId}/update`,
        body: mapNextSessionPostData(session),
      }),
    {
      onSuccess: (newNextSession: NextCoachingSessionAppointment) => {
        const nextSessionQueryData = queryClient.getQueryData([
          'coach-session',
          newNextSession.coachingSessionId,
        ]);
        const participantsQueryData = queryClient.getQueryData(
          queryKeys.participants
        );

        if (nextSessionQueryData) {
          queryClient.setQueryData(
            ['coach-session', newNextSession.coachingSessionId],
            (oldSession) => ({
              ...oldSession!,
              nextCoachingSessionAppointment: newNextSession,
            })
          );
        }

        if (participantsQueryData) {
          queryClient.setQueryData<Participants | undefined>(
            queryKeys.participants,
            (oldVal) => ({
              ...oldVal,
              items: oldVal!.items.map((participant) =>
                participant.userEmail === newNextSession.clientEmail
                  ? {
                      ...participant,
                      nextCoachingSessionAppointment: {
                        ...participant.nextCoachingSessionAppointment!,
                        startAt: newNextSession.startAt,
                      },
                    }
                  : participant
              ),
            })
          );
        }

        queryClient.setQueryData(
          ['coach-session-appointment', appointmentId],
          () => newNextSession
        );
      },
      onError: (e) => {
        logError(e);
      },
    }
  );
};
