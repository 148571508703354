import { ReactNode } from 'react';

import Box from 'shared_DEPRECATED/components/Box';
import { EditorProvider } from 'shared_DEPRECATED/components/TextEditor/context';

type TextEditorProps = { children: ReactNode; dataTestId?: string };
export const TextEditorContainer = ({
  children,
  dataTestId = 'text-editor',
}: TextEditorProps) => (
  <Box dataTestid={dataTestId}>
    <EditorProvider>{children}</EditorProvider>
  </Box>
);
