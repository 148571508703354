import PropTypes from 'prop-types';

import ImageComponent from 'shared_DEPRECATED/components/Image';
import {
  IMAGE_PLACEHOLDERS,
  IMAGE_TYPES,
} from 'shared_DEPRECATED/components/Image/config';

import { AVATAR_DIMENSIONS, SIZES } from './config';

const AvatarPropTypes = {
  /**
   * Size of the avatar
   * @type {('sm'|'md'|'lg')}
   * @required
   * @memberof AvatarPropTypes
   * @name size
   * @example <Avatar size="sm" />
   */
  size: PropTypes.oneOf(Object.values(SIZES)).isRequired,
  /**
   * Source of the avatar image
   * @type {string}
   * @memberof AvatarPropTypes
   * @name src
   * @example <Avatar src="https://example.com/avatar.png" />
   */
  src: PropTypes.string,
  /**
   * Name of the the user
   * @type {string}
   * @memberof AvatarPropTypes
   * @name name
   * @example <Avatar name="John Doe" />
   */
  name: PropTypes.string,
};

const Avatar = ({
  size,
  src = IMAGE_PLACEHOLDERS['avatar'],
  name = 'User',
}) => (
  <ImageComponent
    src={src}
    width={AVATAR_DIMENSIONS[size]}
    height={AVATAR_DIMENSIONS[size]}
    alt={`${name} avatar`}
    borderRadius={50}
    type={IMAGE_TYPES.AVATAR}
  />
);

Avatar.propTypes = AvatarPropTypes;

export default Avatar;
