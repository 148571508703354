import { useCallback } from 'react';

import { useSprintDurationFeatureFlag } from 'featureFlags/hooks/useSprintDurationFeatureFlag';

import { ChallengeTargetProgressType } from 'features/challenge/config/types';
import { getChallengeFrequencyText } from 'features/challenge/utils';

export const useGetChallengeFrequencyText = () => {
  const sprintDuration = useSprintDurationFeatureFlag();

  return useCallback(
    (target: ChallengeTargetProgressType, sprintWeeksCount = sprintDuration) =>
      getChallengeFrequencyText(target, sprintWeeksCount),
    [sprintDuration]
  );
};
