import { IconButton } from '@chakra-ui/react';
import { MouseEventHandler } from 'react';

import { Icon, iconNames } from 'shared_DEPRECATED/components/Icon';

type PrioritizeButtonProps = {
  onClick: MouseEventHandler<HTMLButtonElement>;
  isPrioritized: boolean;
  size?: 'xs' | 'md';
  dataTestid?: string;
};

export const GoalPrioritizeToggleButton = ({
  onClick,
  isPrioritized,
  dataTestid,
  size = 'xs',
}: PrioritizeButtonProps) => {
  const colors = getColors(isPrioritized);

  return (
    <IconButton
      data-testid={dataTestid}
      size={size}
      aria-label="prioritize goal"
      isRound
      _focus={{
        outline: 'none',
      }}
      variant="outline"
      icon={
        <Icon
          name={iconNames.flagNew}
          fill={colors.fill}
          stroke={colors.stroke}
        />
      }
      onClick={onClick}
    />
  );
};

const getColors = (isPrioritized: boolean) =>
  isPrioritized
    ? {
        fill: 'var(--fgInteractive)',
        stroke: 'var(--fgInteractive)',
      }
    : {
        fill: 'var(--white)',
        stroke: '#5F6368',
        bg: 'var(--white)',
        border: 'var(--borderSecondary)',
      };
