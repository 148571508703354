import { useQueryClient, useMutation } from 'react-query';
import { useParams } from 'react-router-dom';

import { queryKeys } from 'api/config';
import { logError } from 'lib/sentry/logError';
import { useMutationHTTPRequest } from 'shared_DEPRECATED/hooks';

type TUseAddGoalChallengeMutation = {
  focusAreaId?: string;
};

export const useAddGoalChallengeMutation = ({
  focusAreaId,
}: TUseAddGoalChallengeMutation) => {
  const { goalId } = useParams();

  const queryClient = useQueryClient();
  const { request } = useMutationHTTPRequest();

  return useMutation(
    ({ challengeId }: { challengeId: string }) =>
      request({
        url: `/api/web/goals/${goalId}/breakdown/challenges/add`,
        body: focusAreaId ? { challengeId, focusAreaId } : { challengeId },
      }),
    {
      onSettled: () => {
        queryClient.invalidateQueries([queryKeys.goalBreakdown, goalId]);
      },
      onError: (err) => {
        logError(err);
      },
    }
  );
};
