import { iconNames } from 'shared_DEPRECATED/components/Icon';
import { TYPOGRAPHY_COLORS } from 'shared_DEPRECATED/components/Typography';

export type MomentumRecordType = {
  date: string;
  score: number;
};

export type MomentumType = MomentumRecordType[];

export enum MOMENTUM_TREND_TYPE {
  UP = 'up',
  DOWN = 'down',
}

export const MOMENTUM_TREND_CONFIG = {
  [MOMENTUM_TREND_TYPE.UP]: {
    sign: '+' as const,
    textColor: 'green',
    icon: {
      color: TYPOGRAPHY_COLORS.green,
      name: iconNames.arrowBigUp,
      rotation: '45',
    },
  },
  [MOMENTUM_TREND_TYPE.DOWN]: {
    sign: '-' as const,
    textColor: 'red',
    icon: {
      color: TYPOGRAPHY_COLORS.red,
      name: iconNames.arrowBigDown,
      rotation: '-45',
    },
  },
};

export const MOMENTUM_DEFAULT_SCORE = 50;
