import dayjs from 'dayjs';
import 'dayjs/locale/en';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import calendar from 'dayjs/plugin/calendar';
import duration, { Duration, DurationUnitType } from 'dayjs/plugin/duration';
import isBetween from 'dayjs/plugin/isBetween';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isToday from 'dayjs/plugin/isToday';
import localeData from 'dayjs/plugin/localeData';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { DateType } from 'shared_DEPRECATED/types/Date';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(calendar);
dayjs.extend(localizedFormat);
dayjs.extend(relativeTime);
dayjs.extend(isToday);
dayjs.extend(isBetween);
dayjs.extend(localeData);
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);
dayjs.extend(duration);
dayjs.extend(advancedFormat);

export const DATE_TIME_FORMAT = 'ddd, D MMM';

const dateUtils = function (date: DateType = dayjs()) {
  return new DateUtils(date);
};

class DateUtils {
  private initialDate: dayjs.Dayjs;

  constructor(date: DateType = dayjs()) {
    this.initialDate = dayjs.isDayjs(date) ? date.clone() : dayjs(date);
  }

  public add(amount: number, unit?: dayjs.ManipulateType | undefined): this {
    this.initialDate = this.initialDate.add(amount, unit);

    return this;
  }

  public calendar(
    referenceTime?: DateType,
    formats?: object | undefined
  ): string {
    return this.initialDate.calendar(referenceTime, formats);
  }

  public date(): number;
  public date(date: number): this;
  public date(date?: number): this | number {
    if (!date) {
      return this.initialDate.date();
    }

    this.initialDate = this.initialDate.date(date);

    return this;
  }

  public day(): number;
  public day(day: number): this;
  public day(day?: number): this | number {
    if (!day && day !== 0) {
      return this.initialDate.day();
    }

    this.initialDate = this.initialDate.day(day);

    return this;
  }

  public diff(
    dateToCompare: DateType,
    unit?: dayjs.OpUnitType | undefined,
    float?: boolean | undefined
  ): number {
    return this.initialDate.diff(dateToCompare, unit, float);
  }

  public format(format: string | undefined = 'YYYY-MM-DD'): string {
    return this.initialDate.format(format);
  }

  public get(unit: dayjs.UnitType): number {
    return this.initialDate.get(unit);
  }

  public hour(): number;
  public hour(hour: number): this;
  public hour(hour?: number): this | number {
    if (!hour && hour !== 0) {
      return this.initialDate.hour();
    }

    this.initialDate = this.initialDate.hour(hour);

    return this;
  }

  public isAfter(
    dateToCompare: DateType,
    unit?: dayjs.OpUnitType | undefined
  ): boolean {
    return this.initialDate.isAfter(dateToCompare, unit);
  }

  public isBetween(
    a: DateType,
    b: DateType,
    c?: dayjs.OpUnitType | null,
    d?: '()' | '[]' | '[)' | '(]'
  ): boolean {
    return this.initialDate.isBetween(a, b, c, d);
  }

  public isBefore(dateToCompare: DateType): boolean {
    return this.initialDate.isBefore(dateToCompare);
  }

  public isSame(
    dateToCompare: DateType,
    unit?: dayjs.OpUnitType | undefined
  ): boolean {
    return this.initialDate.isSame(dateToCompare, unit);
  }

  public isSameOrAfter(
    dateToCompare: DateType,
    unit?: dayjs.OpUnitType | undefined
  ): boolean {
    return this.initialDate.isSameOrAfter(dateToCompare, unit);
  }

  public isSameOrBefore(
    dateToCompare: DateType,
    unit?: dayjs.OpUnitType | undefined
  ): boolean {
    return this.initialDate.isSameOrBefore(dateToCompare, unit);
  }

  public isToday(): boolean {
    return this.initialDate.isToday();
  }

  public minute(): number;
  public minute(minute: number): this;
  public minute(minute?: number): this | number {
    if (!minute && minute !== 0) {
      return this.initialDate.minute();
    }

    this.initialDate = this.initialDate.minute(minute);

    return this;
  }

  public set(unit: dayjs.UnitType, value: number): this {
    this.initialDate = this.initialDate.set(unit, value);

    return this;
  }

  public startDayDateObject() {
    const formattedDate = this.initialDate.format('YYYY-MM-DD');

    return dayjs.utc(formattedDate, 'YYYY-MM-DD').toDate();
  }

  public startOf(unit: dayjs.OpUnitType): this {
    this.initialDate = this.initialDate.startOf(unit);

    return this;
  }

  public subtract(
    amount: number,
    unit?: dayjs.ManipulateType | undefined
  ): this {
    this.initialDate = this.initialDate.subtract(amount, unit);

    return this;
  }

  public timeAgoDate() {
    return dayjs.utc(this.initialDate).local().fromNow();
  }

  public toDate(): Date {
    return this.initialDate.toDate();
  }

  public formatAsISOString(): string {
    // we don't use dayjs toISOString because it returns the date with Z at the end
    // and backend doesn't accept it
    return this.initialDate.format('YYYY-MM-DDTHH:mm:ss');
  }

  public toISOString(): string {
    return this.initialDate.toISOString();
  }

  public toString(): string {
    return this.initialDate.toString();
  }

  public tz(
    timezone?: string | undefined,
    keepLocalTime?: boolean | undefined
  ): this {
    this.initialDate = this.initialDate.tz(timezone, keepLocalTime);

    return this;
  }

  public ceilMinutes(amount: number): this {
    this.initialDate = this.initialDate
      .add(amount - (this.get('minutes') % amount), 'minutes')
      .startOf('minutes');
    return this;
  }

  public unixTimestamp() {
    return this.initialDate.unix();
  }

  public year(): number;
  public year(year: number): this;
  public year(year?: number): this | number {
    if (!year) {
      return this.initialDate.year();
    }

    this.initialDate = this.initialDate.year(year);

    return this;
  }
}

dateUtils.abbreviatedMonthDate = (
  date: DateType,
  isLocal: boolean = true
): string => {
  const dateObject = isLocal ? dateUtils.localDate(date) : dateUtils(date);

  const includeYear = !new DateUtils().isSame(date, 'year');
  const formatString = includeYear ? 'MMM D, YYYY' : 'MMM D';

  return dateObject.format(formatString);
};

dateUtils.duration = (
  input: number,
  unit?: DurationUnitType | undefined
): Duration => {
  return dayjs.duration(input, unit);
};

dateUtils.isCurrentDateBeforeDate = (date: DateType): boolean => {
  const currentDate = dateUtils().startDayDateObject();

  return dateUtils(currentDate).isBefore(dateUtils.utc(date, 'YYYY-MM-DD'));
};

dateUtils.localDate = (date: DateType): dayjs.Dayjs => {
  return dateUtils.utc(date).local();
};

dateUtils.optionalDateUnixTimestamp = (date: DateType): number => {
  return date ? new DateUtils(date).unixTimestamp() : 0;
};

dateUtils.periodDate = (start: DateType, end: DateType): string => {
  const startDate = dayjs.utc(start, 'YYYY-MM-DD').startOf('day');
  const endDate = dayjs.utc(end, 'YYYY-MM-DD').startOf('day');
  const startYear = startDate.get('year');
  const endYear = endDate.get('year');
  const startMonth = startDate.get('month');
  const endMonth = endDate.get('month');

  const formattedStartDate =
    startYear !== endYear
      ? startDate.format('MMM D, YYYY')
      : startDate.format('MMM D');
  const formattedEndDate =
    startMonth !== endMonth
      ? endDate.format('MMM D, YYYY')
      : endDate.format('D, YYYY');

  return `${formattedStartDate} - ${formattedEndDate}`;
};

dateUtils.getDaysBetween = (start: DateType, end: DateType): DateType[] => {
  const range = [];
  let current = dayjs(start);

  while (!current.isAfter(end)) {
    range.push(current);
    current = current.add(1, 'days');
  }

  return range;
};

dateUtils.sortingDiff = (date1: DateType, date2: DateType): number => {
  return (
    dateUtils.optionalDateUnixTimestamp(date1) -
    dateUtils.optionalDateUnixTimestamp(date2)
  );
};

dateUtils.timestampFromFormattedDate = (date: string): number => {
  return dayjs.utc(date, 'YYYY-MM-DD').startOf('day').valueOf();
};

dateUtils.todayTimeAgoOrDate = (date: DateType): string => {
  return dateUtils.localDate(date).isToday()
    ? dateUtils.localDate(date).fromNow()
    : dateUtils.abbreviatedMonthDate(date);
};

dateUtils.tz = (): string => {
  return dayjs.tz.guess();
};

dateUtils.utc = (
  date: DateType = dayjs(),
  format?: string,
  strict?: boolean | undefined
): dayjs.Dayjs => {
  return dayjs.utc(date, format, strict);
};

// https://github.com/iamkun/dayjs/issues/1886
//  when updateLocale is called, weekStart is not updated
//  so we need to update it manually if monday is the first day of the week
dateUtils.weekDays = (
  key: 'weekdays' | 'weekdaysShort' | 'weekdaysMin'
): string[] => {
  const localeDayjs = dayjs.localeData();
  const firstDayOfWeek = localeDayjs.firstDayOfWeek();
  const weekDays =
    firstDayOfWeek === 0
      ? localeDayjs[key]().reduce((acc: string[], day, index) => {
          const newIndex = index === 0 ? 6 : index - 1;
          acc[newIndex] = day;

          return acc;
        }, [])
      : localeDayjs[key]();

  return weekDays;
};

dateUtils.relativeDateFormat = (date: DateType) => {
  const currentDayDate = dateUtils().format(DATE_TIME_FORMAT);
  const prevDayDate = dateUtils().subtract(1, 'day').format(DATE_TIME_FORMAT);
  const formattedDate = dateUtils.localDate(date).format(DATE_TIME_FORMAT);

  if (formattedDate === currentDayDate) {
    return 'Today';
  }

  if (formattedDate === prevDayDate) {
    return 'Yesterday';
  }

  return formattedDate;
};

export { dateUtils, DateUtils };
