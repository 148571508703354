import PropTypes from 'prop-types';

import Box from 'shared_DEPRECATED/components/Box';
import { Flex } from 'shared_DEPRECATED/components/Flex';
import Spacer from 'shared_DEPRECATED/components/Spacer';

export const File = ({ children, dataTestid = 'file-component' }) => (
  <Box
    style={{
      backgroundColor: 'var(--bgCompSecondaryMid)',
      overflow: 'hidden',
      borderRadius: 'var(--border-radius)',
    }}
    dataTestid={dataTestid}
  >
    <Spacer size="md">
      <Flex justifyContent="space-between" alignItems="center">
        {children}
      </Flex>
    </Spacer>
  </Box>
);

File.propTypes = { children: PropTypes.node, dataTestid: PropTypes.string };
