import { CSSProperties } from 'react';

import Box from 'shared_DEPRECATED/components/Box/Box';

import { userInfo } from 'features/user/config/defaults';

import { Typography } from 'shared/components/Typography';

type UserSubtitleProps = {
  children: React.ReactNode;
  maxWidth?: CSSProperties['maxWidth'];
};

export const UserInfoSubtitle = ({
  children,
  maxWidth = userInfo.maxWidth,
}: UserSubtitleProps) => (
  <Box style={{ maxWidth }}>
    <Typography
      color="gray"
      fontWeight="regular"
      type="small"
      cropped={true}
      data-testid="participant-info-email"
    >
      {children}
    </Typography>
  </Box>
);
