import { Typography } from 'shared/components/Typography';

type TGoalTitle = {
  title: string;
};

export const GoalTitle = ({ title }: TGoalTitle) => (
  <Typography type="largeHeading" fontWeight="semiBold" color="black">
    {title}
  </Typography>
);
