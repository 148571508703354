import PropTypes from 'prop-types';

import Box from 'shared_DEPRECATED/components/Box';
import Button from 'shared_DEPRECATED/components/Button';
import { Flex } from 'shared_DEPRECATED/components/Flex';
import { Icon, iconNames } from 'shared_DEPRECATED/components/Icon';
import Spacer from 'shared_DEPRECATED/components/Spacer';

import { openNotesRepliesSidebar } from 'features/challengeNotes/utils';

import { useSidebarContext } from 'shared/components/Sidebar/context';
import { Typography } from 'shared/components/Typography';

const ChallengeNotesCardRepliesPropTypes = {
  noteId: PropTypes.string.isRequired,
  userEmail: PropTypes.string.isRequired,
  repliesCount: PropTypes.number.isRequired,
};

export const ChallengeNotesCardReplies = ({
  repliesCount,
  noteId,
  userEmail,
}) => {
  const { sprint, user } = useSidebarContext();

  const handleNoteClick = () => {
    openNotesRepliesSidebar({
      sprint,
      note: {
        noteId,
        sprintId: sprint.sprintId,
        userEmail,
      },
      user,
    });
  };

  return (
    <Button
      onClick={handleNoteClick}
      dataTestid="user-challenge-note-replies-btn"
    >
      <Box>
        <Flex alignItems="flex-start" justifyContent="flex-start">
          <Spacer as="span" size="zr sm zr zr">
            <Icon
              name={iconNames.message}
              stroke="var(--fgSecondary)"
              hoverStroke="var(--fgPrimary)"
            />
          </Spacer>
          <Typography
            color="gray"
            hoverColor="black"
            type="small"
            dataTestid="user-challenge-note-replies-label"
          >
            {`${repliesCount} ${repliesCount === 1 ? 'reply' : 'replies'}`}
          </Typography>
        </Flex>
      </Box>
    </Button>
  );
};

ChallengeNotesCardReplies.propTypes = ChallengeNotesCardRepliesPropTypes;
