import { UserMode } from 'shared_DEPRECATED/types/User';

import { ANALYTICS_EVENTS } from './config';
import { sendAnalyticsEvent } from './utils';

export const sendSprintStartedAnalyticsEvent = ({
  userMode,
}: {
  userMode: UserMode;
}) => {
  sendAnalyticsEvent({
    event: ANALYTICS_EVENTS.SPRINT_STARTED,
    data: {
      user_mode: userMode,
    },
  });
};

export const sendSprintRestartedAnalyticsEvent = ({
  userMode,
}: {
  userMode: UserMode;
}) => {
  sendAnalyticsEvent({
    event: ANALYTICS_EVENTS.SPRINT_RESTARTED,
    data: {
      user_mode: userMode,
    },
  });
};

export const sendSprintFinishedAnalyticsEvent = ({
  userMode,
}: {
  userMode: UserMode;
}) => {
  sendAnalyticsEvent({
    event: ANALYTICS_EVENTS.SPRINT_FINISHED,
    data: {
      user_mode: userMode,
    },
  });
};

export const sendSprintWithCoachChangedAnalyticsEvent = ({
  userMode,
}: {
  userMode: UserMode;
}) => {
  sendAnalyticsEvent({
    event: ANALYTICS_EVENTS.SPRINT_WITH_COACH_CHANGED,
    data: {
      user_mode: userMode,
    },
  });
};

export const sendSprintReflectionAnswerEditedAnalyticsEvent = ({
  userMode,
}: {
  userMode: UserMode;
}) => {
  sendAnalyticsEvent({
    event: ANALYTICS_EVENTS.SPRINT_REFLECTION_ANSWER_EDITED,
    data: {
      user_mode: userMode,
    },
  });
};

export const sendHistorySidebarOpenedAnalyticsEvent = ({
  userMode,
}: {
  userMode: UserMode;
}) => {
  sendAnalyticsEvent({
    event: ANALYTICS_EVENTS.HISTORY_SIDEBAR_OPEN,
    data: {
      user_mode: userMode,
    },
  });
};
