import { useCallback } from 'react';

import useSearch from 'shared_DEPRECATED/hooks/useSearch';

import { useCoachTimezonesQuery } from 'features/coach/settings/hooks';

export const useTimezonesSearch = () => {
  const { data: timezones } = useCoachTimezonesQuery();

  const predicate = useCallback(
    (timezone: { value: string }, query: string) =>
      timezone.value.toLowerCase().includes(query.trim().toLowerCase()),
    []
  );

  const {
    query,
    filteredCollection: filteredTimezones,
    handleSearchChange,
  } = useSearch(timezones, predicate, {
    debounce: 500,
  });

  return {
    query,
    timezones: query ? filteredTimezones : timezones,
    handleTimezoneSearch: handleSearchChange,
  };
};
