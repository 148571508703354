import { useLayoutEffect, useRef } from 'react';

import { isRefObject } from 'shared_DEPRECATED/utils/typeGuards';

import styles from 'shared_DEPRECATED/components/Spacer/Spacer.module.css';

export const sizes = {
  zr: 0,
  xsm: 'var(--spacing00)', // 2px
  sm: 'var(--spacing01)', // 4px
  md: 'var(--spacing02)', // 8px
  lg: 'var(--spacing04)', // 16px
  xlg: 'var(--spacing08)', // 40px
  xxlg: 'var(--spacing12)', // 80px
};

type SpacerProps = {
  as?: keyof JSX.IntrinsicElements | React.JSXElementConstructor<any>;
  children?: React.ReactNode;
  size?: string;
  height?: string;
  dataTestid?: string | null;
};

const Spacer = ({
  as: Component = 'div',
  children,
  size,
  height = 'auto',
  dataTestid = null,
}: SpacerProps) => {
  const element = useRef<HTMLElement | React.RefObject<HTMLElement> | null>(
    null
  );

  useLayoutEffect(() => {
    if (isRefObject(element) && element.current && size) {
      const elementRef = element.current;
      const sizesArray = size.split(' ');
      for (const space of sizesArray) {
        if (!(space in sizes)) {
          console.error(`Invalid size value: ${space}`);
          return;
        }
      }
      const spaces = sizesArray
        .map((space) => sizes[space as keyof typeof sizes])
        .join(' ');
      elementRef.style.setProperty('--spacing', spaces);
      elementRef.style.setProperty('--height', height);
      elementRef.style.setProperty(
        '--display',
        Component === 'span' ? 'inline-block' : 'block'
      );
    }
  }, [element, size, Component, height]);

  return (
    <Component className={styles.spacer} ref={element} data-testid={dataTestid}>
      {children}
    </Component>
  );
};

export default Spacer;
