import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

import 'shared_DEPRECATED/components/Logo/Logo.module.css';

const LogoPropTypes = {
  src: PropTypes.string.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
};

const Logo = ({ src, width = 'auto', height = 'auto' }) => (
  <NavLink to="/">
    <img className="logo" height={height} width={width} src={src} alt="hOS" />
  </NavLink>
);

Logo.propTypes = LogoPropTypes;

export default Logo;
