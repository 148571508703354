import { EMPTY_CELL_VALUE } from 'shared_DEPRECATED/config/table';

import { Typography } from 'shared/components/Typography';

export const ParticipantsTableSprintDurationCell = ({
  value,
}: {
  value: string;
}) => (
  <Typography
    fontWeight="regular"
    whiteSpace="nowrap"
    type="small"
    data-testid="participant-sprint-period"
    color="black"
  >
    {value || EMPTY_CELL_VALUE}
  </Typography>
);
