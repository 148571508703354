import { ButtonGroup } from 'shared';

import { ChallengeNotesReadAllButton } from 'features/challengeNotes/components/ChallengeNotesReadAllButton';
import { ChallengeNotesFilter } from 'features/challengeNotes/components/Filter';
import { ChallengeNotesSidebarBody } from 'features/challengeNotes/components/Sidebar/SidebarBody';
import { ChallengeNotesFilterProvider } from 'features/challengeNotes/context/filter';
import { ChallengeNotesProvider } from 'features/challengeNotes/context/notes';

import { SidebarHeaderWithoutBorder } from 'shared/components/Sidebar';

const ChallengeNotesSidebar = () => {
  return (
    <ChallengeNotesFilterProvider>
      <ChallengeNotesProvider>
        <SidebarHeaderWithoutBorder title="Notes">
          <ButtonGroup size="xs">
            <ChallengeNotesReadAllButton />
            <ChallengeNotesFilter />
          </ButtonGroup>
        </SidebarHeaderWithoutBorder>
        <ChallengeNotesSidebarBody />
      </ChallengeNotesProvider>
    </ChallengeNotesFilterProvider>
  );
};

export default ChallengeNotesSidebar;
