import { UserMode } from 'shared_DEPRECATED/types/User';

import { ANALYTICS_EVENTS } from './config';
import { sendAnalyticsEvent } from './utils';

export const sendChallengeMarkedAnalyticsEvent = ({
  type,
  status,
  isTargetChallenge,
  userMode,
}: {
  type: string;
  status: string;
  isTargetChallenge: boolean;
  userMode: UserMode;
}) =>
  sendAnalyticsEvent({
    event: ANALYTICS_EVENTS.CHALLENGE_MARKED,
    data: {
      user_mode: userMode,
      type,
      status,
      is_target_challenge: isTargetChallenge,
    },
  });

export const sendChallengeAddedAnalyticsEvent = ({
  type,
  userMode,
}: {
  type: string;
  userMode: UserMode;
}) =>
  sendAnalyticsEvent({
    event: ANALYTICS_EVENTS.CHALLENGE_ADDED,
    data: {
      user_mode: userMode,
      type,
    },
  });

export const sendChallengeAddedFromLibAnalyticsEvent = ({
  userMode,
}: {
  userMode: UserMode;
}) =>
  sendAnalyticsEvent({
    event: ANALYTICS_EVENTS.CHALLENGE_ADDED_FROM_LIB,
    data: {
      user_mode: userMode,
    },
  });

// TODO: find out how to diff challenge editing from sprint editing
export const sendChallengeEditedAnalyticsEvent = ({
  userMode,
}: {
  userMode: UserMode;
}) =>
  sendAnalyticsEvent({
    event: ANALYTICS_EVENTS.CHALLENGE_EDITED,
    data: {
      user_mode: userMode,
    },
  });

export const sendChallengeRemovedAnalyticsEvent = ({
  userMode,
}: {
  userMode: UserMode;
}) =>
  sendAnalyticsEvent({
    event: ANALYTICS_EVENTS.CHALLENGE_REMOVED,
    data: {
      user_mode: userMode,
    },
  });

export const sendTargetSelectedAnalyticsEvent = ({
  userMode,
}: {
  userMode: UserMode;
}) =>
  sendAnalyticsEvent({
    event: ANALYTICS_EVENTS.TARGET_SELECTED,
    data: {
      user_mode: userMode,
    },
  });

export const sendFrequencySelectedAnalyticsEvent = ({
  userMode,
}: {
  userMode: UserMode;
}) =>
  sendAnalyticsEvent({
    event: ANALYTICS_EVENTS.FREQUENCY_SELECTED,
    data: {
      user_mode: userMode,
    },
  });
