import { useColorMode } from '@chakra-ui/react';
import {
  ButtonHTMLAttributes,
  DOMAttributes,
  HTMLAttributes,
  ReactNode,
  useLayoutEffect,
  useRef,
} from 'react';

import classNames from 'classnames/bind';
import { VARIANTS } from 'shared_DEPRECATED/components/Button/config';
import { btnVariantIconColors } from 'shared_DEPRECATED/utils/config';

import styles from 'shared_DEPRECATED/components/Button/Button.module.css';

const cx = classNames.bind(styles);

export type ButtonPropTypes = {
  ariaLabel?: string;
  children?: ReactNode;
  variant?: VARIANTS | undefined;
  dataTestid?: string;
} & ButtonHTMLAttributes<HTMLButtonElement> &
  DOMAttributes<HTMLButtonElement> &
  HTMLAttributes<HTMLButtonElement>;

const Button = ({
  ariaLabel,
  children,
  className = '',
  disabled = false,
  onClick,
  onMouseEnter,
  role = 'button',
  type = 'button',
  value = 'default',
  variant,
  dataTestid = '',
  form,
}: ButtonPropTypes) => {
  const { colorMode: theme } = useColorMode();
  const isThemeLight = theme === 'light';

  const element = useRef<HTMLButtonElement>(null);
  const btnClassName = cx('btn', 'btn--button', className, {
    [`btn--${variant}`]: variant,
    'btn--invert': !isThemeLight,
  });

  useLayoutEffect(() => {
    const elementRef = element.current;
    if (elementRef) {
      elementRef.style.setProperty(
        '--currentColor',
        variant && variant in btnVariantIconColors
          ? (btnVariantIconColors[variant as VARIANTS] as string)
          : 'var(--black)'
      );
    }
  }, [element, variant]);

  return (
    <button
      aria-label={ariaLabel}
      className={btnClassName}
      disabled={disabled}
      ref={element}
      type={type}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      value={value}
      data-testid={dataTestid}
      role={role}
      form={form}
    >
      {children}
    </button>
  );
};

export default Button;
