import { BoxProps } from '@chakra-ui/react';

import { Box } from 'shared';

type TBoardColumnHeader = BoxProps;

export const BoardColumnHeader = ({
  children,
  minHeight = 'auto',
}: TBoardColumnHeader) => (
  <Box
    width="100%"
    minHeight={minHeight}
    borderBottom="var(--border-secondary)"
  >
    {children}
  </Box>
);
