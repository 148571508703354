import { ReactNode } from 'react';

import Box from 'shared_DEPRECATED/components/Box';
import Button from 'shared_DEPRECATED/components/Button';
import Spacer from 'shared_DEPRECATED/components/Spacer';

type ReactionsButtonPropTypes = {
  children: ReactNode;
  onClick: () => void;
  dataTestid: string;
};

export const ReactionsButton = ({
  children,
  onClick,
  dataTestid = '',
}: ReactionsButtonPropTypes) => {
  return (
    <Button onClick={onClick} dataTestid={dataTestid}>
      <Box
        style={{
          backgroundColor: 'transparent',
          borderRadius: 'var(--border-radius4)',
          width: '35px',
        }}
        hoverStyle={{
          backgroundColor: 'var(--bgSecondary)',
        }}
      >
        <Spacer size="xsm md">
          <Spacer size="sm zr">{children}</Spacer>
        </Spacer>
      </Box>
    </Button>
  );
};
