import { Typography } from 'shared/components/Typography';

const SIZES = {
  XSM: 'xsm',
  SM: 'sm',
  MD: 'md',
  LG: 'lg',
} as const;

const TYPOGRAPHY_TYPES = {
  [SIZES.XSM]: 'small',
  [SIZES.SM]: 'heading',
  [SIZES.MD]: 'largeHeading',
  [SIZES.LG]: 'extraLargeHeading',
} as const;

type TMomentumScore = {
  score: number;
  size: typeof SIZES[keyof typeof SIZES];
};

export const MomentumScore = ({ score, size }: TMomentumScore) => (
  <Typography type={TYPOGRAPHY_TYPES[size]} fontWeight="semiBold" color="black">
    {Math.ceil(score)}
  </Typography>
);
