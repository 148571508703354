import { useColorMode } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import { useLayoutEffect, useRef } from 'react';
import { Link } from 'react-router-dom';

import classNames from 'classnames/bind';
import Box from 'shared_DEPRECATED/components/Box';
import { btnVariantIconColors } from 'shared_DEPRECATED/utils/config';

import styles from 'shared_DEPRECATED/components/Button/Button.module.css';

const cx = classNames.bind(styles);
const ButtonLinkPropTypes = {
  children: PropTypes.node.isRequired,
  variant: PropTypes.oneOf(Object.keys(btnVariantIconColors)),
  to: PropTypes.string.isRequired,
  dataTestid: PropTypes.string,
};
const ButtonLink = ({ children, variant, to, dataTestid }) => {
  const element = useRef(null);
  const { colorMode: theme } = useColorMode();
  const isThemeLight = theme === 'light';

  useLayoutEffect(() => {
    const elementRef = element.current;
    if (elementRef) {
      elementRef.style.setProperty(
        '--currentColor',
        btnVariantIconColors[variant] || 'var(--black)'
      );
    }
  }, [element, variant]);

  return (
    <Link
      className={cx(
        'btn',
        'btn--link',
        `btn--${variant}`,
        `btn--${!isThemeLight ? 'invert' : ''}`
      )}
      ref={element}
      to={to}
      datatestid={dataTestid}
    >
      <Box
        hoverStyle={{
          backgroundColor: 'var(--bgSecondary)',
        }}
      >
        {children}
      </Box>
    </Link>
  );
};

ButtonLink.propTypes = ButtonLinkPropTypes;

export default ButtonLink;
