import { useParams } from 'react-router-dom';

import { useOpenPrioritizedModal } from 'features/goal/hooks/useOpenPrioritizeGoalsModal';
import { useOpenPrioritizedGoalsLimitReachedModal } from 'features/goal/hooks/useOpenPrioritizedGoalsLimitReachedModal';
import { usePrioritizedGoalsLimitReached } from 'features/goal/hooks/usePrioritizedGoalsLimitReached';
import { useParticipantQuery } from 'features/participants/hooks';

export const useOpenPrioritizedGoalsSettingModal = () => {
  const { email } = useParams();
  const { participant } = useParticipantQuery(email);
  const { userName } = participant;

  const openPrioritizedModal = useOpenPrioritizedModal();
  const isPrioritizedGoalsLimitReached = usePrioritizedGoalsLimitReached();

  const openPrioritizedGoalsLimitReachedModal =
    useOpenPrioritizedGoalsLimitReachedModal({
      name: userName,
      onSubmit: openPrioritizedModal,
    });

  if (isPrioritizedGoalsLimitReached) {
    return openPrioritizedGoalsLimitReachedModal;
  } else {
    return openPrioritizedModal;
  }
};
