import { Button, Typography } from 'shared';

type TNextSessionFormMiroBoardLinkButton = {
  onClick: () => void;
  children: string;
};

export const NextSessionFormMiroBoardLinkButton = ({
  onClick,
  children,
}: TNextSessionFormMiroBoardLinkButton) => (
  <Button onClick={onClick} size="xs" p="0" m="0" _focus={{ outline: 'none' }}>
    <Typography color="blue">{children}</Typography>
  </Button>
);
