import Box from 'shared_DEPRECATED/components/Box';

import { getDimensionsText } from 'features/lifeDimensions/utils';

import { Typography } from 'shared/components/Typography';

type TLifeDimensionsProps = {
  dimensions: string[];
  visibleDimensionsCount?: number;
  maxWidth?: string;
};

export const LifeDimensions = ({
  dimensions,
  visibleDimensionsCount,
  maxWidth = '100%',
}: TLifeDimensionsProps) => (
  <Box style={{ maxWidth }}>
    <Typography
      whiteSpace="pre-wrap"
      color="gray"
      type="small"
      fontWeight="regular"
    >
      {getDimensionsText(dimensions, visibleDimensionsCount)}
    </Typography>
  </Box>
);
