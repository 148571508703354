import { openNotesSidebar } from 'features/challengeNotes/utils';

import { SidebarSubHeader } from 'shared/components/Sidebar';
import { useSidebarContext } from 'shared/components/Sidebar/context';

export const ChallengeNotesRepliesHeader = () => {
  const { sprint, user } = useSidebarContext();

  const handleBackBtnClick = () => {
    openNotesSidebar({ sprint, user });
  };

  return (
    <SidebarSubHeader
      mainSidebarTitle="Notes"
      onBackButtonClick={handleBackBtnClick}
    />
  );
};
