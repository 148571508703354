import { DrawerCloseButton } from '@chakra-ui/react';

import { VARIANTS } from 'shared/components/Button';

const SidebarSubHeaderCloseButton = () => (
  <DrawerCloseButton
    data-testid="notes-sidebar-close-btn"
    variant={VARIANTS.TERTIARY}
  />
);

export default SidebarSubHeaderCloseButton;
