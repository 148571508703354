import {
  GetTokenSilentlyOptions,
  OAuthError,
  RedirectLoginOptions,
  useAuth0,
} from '@auth0/auth0-react';
import { useCallback } from 'react';

import { getPathParams } from 'shared_DEPRECATED/utils';

import { UNAUTHORIZED_PATHS } from 'features/auth/config';

export const getAccessToken = async (
  getAccessTokenSilently: (
    options?: GetTokenSilentlyOptions
  ) => Promise<string>,
  loginWithRedirect: (options?: RedirectLoginOptions) => Promise<void>
) => {
  let token;

  try {
    token = await getAccessTokenSilently();
  } catch (e: unknown) {
    if ((e as OAuthError).error === 'login_required') {
      const [firstPathParam] = getPathParams(window.location.pathname);

      if (Object.values(UNAUTHORIZED_PATHS).includes(firstPathParam)) {
        return;
      }

      await loginWithRedirect();
      return;
    }
    throw new Error((e as Error).message);
  }

  return token;
};

export const useGetAccessToken = () => {
  const { getAccessTokenSilently, loginWithRedirect } = useAuth0();

  return useCallback(
    async () => await getAccessToken(getAccessTokenSilently, loginWithRedirect),
    [getAccessTokenSilently, loginWithRedirect]
  );
};
