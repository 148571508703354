import {
  ModalHeader as ChakraHeader,
  ModalCloseButton,
} from '@chakra-ui/react';
import { ReactChild } from 'react';

import Spacer from 'shared_DEPRECATED/components/Spacer';

type ModalHeaderProps = {
  children?: ReactChild | null;
  onClick?: () => void;
};

export const ModalHeader = ({ children = null }: ModalHeaderProps) => (
  <>
    <Spacer size="zr lg zr zr">
      <ChakraHeader>{children}</ChakraHeader>
    </Spacer>
    <ModalCloseButton data-testid="modal-close" />
  </>
);
