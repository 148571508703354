import Box from 'shared_DEPRECATED/components/Box';
import { Flex } from 'shared_DEPRECATED/components/Flex';

import {
  GoalDeepDiveFormWhySection,
  GoalDeepDiveFormQualitiesSection,
  GoalDeepDiveFormObstaclesSection,
  GoalDeepDiveFormSupportSection,
  GoalDeepDiveFormNoteSection,
} from 'features/goal';

export const GoalDeepDiveForm = () => (
  <Box style={{ width: '100%', height: '100%', overflowY: 'auto' }}>
    <Flex
      flexDirection="column"
      height="100%"
      width="100%"
      alignItems="stretch"
    >
      <GoalDeepDiveFormNoteSection />
      <GoalDeepDiveFormWhySection />
      <GoalDeepDiveFormQualitiesSection />
      <GoalDeepDiveFormObstaclesSection />
      <GoalDeepDiveFormSupportSection />
    </Flex>
  </Box>
);
