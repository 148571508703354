import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';

import {
  HeaderMenuPopoverButton,
  HeaderMenuOptionButton,
  HeaderMenuUserInfo,
} from 'app/layout/components/Header/Menu';
import { Divider } from 'shared_DEPRECATED/components/Divider';
import { Flex } from 'shared_DEPRECATED/components/Flex';
import { FloatingPopover } from 'shared_DEPRECATED/components/Floating';
import Spacer from 'shared_DEPRECATED/components/Spacer';
import useToggle from 'shared_DEPRECATED/hooks/useToggle';

import { useCoachSettingsEnabled } from 'features/coach/settings/hooks';

type HeaderMenuPropTypes = {
  name: string;
  url: string;
  email: string;
};

const HeaderMenu = ({ name, email, url }: HeaderMenuPropTypes) => {
  const { logout } = useAuth0();
  const isCoachProfileFormPageDisabled = useCoachSettingsEnabled();

  const [isOpen, toggleOpen] = useToggle();
  const navigate = useNavigate();

  return (
    <FloatingPopover
      onClose={toggleOpen}
      isOpen={isOpen}
      anchor={
        <HeaderMenuPopoverButton
          onClick={toggleOpen}
          name={name}
          pictureUrl={url}
          dataTestid="UserMenu_popoverButton"
        />
      }
      offsetTop={4}
      isAutoUpdate={true}
      dataTestid="header-menu"
    >
      <Flex
        alignItems="center"
        flexDirection="column"
        justifyContent="flex-start"
      >
        <Spacer size="lg">
          <HeaderMenuUserInfo name={name} email={email} pictureUrl={url} />
        </Spacer>
        {!isCoachProfileFormPageDisabled && (
          <HeaderMenuOptionButton
            dataTestid="UserMenuOptionButton_profileSettings"
            onClick={() => {
              toggleOpen();
              navigate('coach/settings');
            }}
          >
            Settings
          </HeaderMenuOptionButton>
        )}
        <Divider />
        <HeaderMenuOptionButton
          dataTestid="UserMenuOptionButton_logOut"
          onClick={() => {
            toggleOpen();
            logout({ returnTo: window.location.origin });
          }}
        >
          Log out
        </HeaderMenuOptionButton>
      </Flex>
    </FloatingPopover>
  );
};

export default HeaderMenu;
