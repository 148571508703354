import { Center } from '@chakra-ui/react';

import { $createHeadingNode, HeadingTagType } from '@lexical/rich-text';
import { $setBlocksType } from '@lexical/selection';
import {
  $createParagraphNode,
  $getSelection,
  $isRangeSelection,
  LexicalEditor,
} from 'lexical';
import Typography from 'shared_DEPRECATED/components/Typography';

import { Dropdown, DropdownItem } from '../../Dropdown';
import { BlockType, blockTypes } from '../constants';

type HeadingFormatterProps = {
  blockType: BlockType;
  editor: LexicalEditor;
};

const dropdownOptions = [blockTypes.paragraph, blockTypes.h1, blockTypes.h2];

export const HeadingFormatter = ({
  blockType,
  editor,
}: HeadingFormatterProps) => {
  const formatParagraph = () => {
    editor.update(() => {
      const selection = $getSelection();
      if ($isRangeSelection(selection)) {
        $setBlocksType(selection, () => $createParagraphNode());
      }
    });
  };

  const formatHeading = (headingSize: HeadingTagType) => () => {
    if (blockType !== headingSize) {
      editor.update(() => {
        const selection = $getSelection();
        $setBlocksType(selection, () => $createHeadingNode(headingSize));
      });
    }
  };

  const commandsMap: Partial<Record<BlockType, (value?: any) => void>> = {
    [blockTypes.paragraph.key]: formatParagraph,
    [blockTypes.h1.key]: formatHeading('h1'),
    [blockTypes.h2.key]: formatHeading('h2'),
  };

  const handleItemSelect = (value: string | number) => {
    const callback = commandsMap[value as BlockType];
    if (callback) {
      callback();
    }
  };

  return (
    <Center mx="2" zIndex="3">
      <Dropdown
        onItemSelect={handleItemSelect}
        selectedValue={blockType}
        anchorContent={
          <Typography
            type="small"
            color="black"
            dataTestid="participant-coaching-mode"
          >
            {blockTypes[blockType].label}
          </Typography>
        }
      >
        {dropdownOptions.map((block) => (
          <DropdownItem key={block.key} value={block.key}>
            <Typography type="small">{block.label}</Typography>
          </DropdownItem>
        ))}
      </Dropdown>
    </Center>
  );
};
