import { ButtonProps } from '@chakra-ui/react';

import { Button, VARIANTS } from 'shared/components/Button';

import { modal } from '.';

type ModalButtonPropTypes = {
  variant?: typeof VARIANTS[keyof typeof VARIANTS];
  dataTestid?: string;
  shouldClose?: boolean;
} & ButtonProps;

export const ModalButton = ({
  children,
  onClick,
  shouldClose = true,
  ...props
}: ModalButtonPropTypes) => (
  <Button
    onClick={(e) => {
      onClick?.(e);
      shouldClose && modal.close();
    }}
    {...props}
  >
    {children}
  </Button>
);
