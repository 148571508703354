import { Typography, TYPOGRAPHY_COLORS } from 'shared/components/Typography';

type MomentumFeedItemChallengeTitleProps = {
  children: string;
  color?: TYPOGRAPHY_COLORS;
};

export const MomentumFeedItemChallengeTitle = ({
  children,
  color = 'black',
}: MomentumFeedItemChallengeTitleProps) => (
  <Typography type="small" fontWeight="medium" color={color}>
    {children}
  </Typography>
);
