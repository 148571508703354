import * as linkify from 'linkifyjs';

export function extractLinksFromString(text: string): Array<string> {
  return linkify.find(text).map((link) => link.href);
}

export function extractDomainName(text: string): string {
  const links = extractLinksFromString(text);

  return links.length > 0 ? new URL(links[0]).hostname : '';
}
