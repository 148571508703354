import { toastErrorConfig } from 'lib/reactToastify/config';
import { toast } from 'react-toastify';

import { Nullable } from 'shared_DEPRECATED/types';

import { SessionDateSelector } from 'features/participants/components/Table';
import { ParticipantsTableRowDataType } from 'features/participants/config/types';
import { useSessionDateMutation } from 'features/participants/hooks';

type SessionDateCellProps = {
  value: {
    appointmentId: string;
    startAt: string;
  };
  row: ParticipantsTableRowDataType;
};
export const SessionDateCell = ({
  value: sessionAppointment,
  row,
}: SessionDateCellProps) => {
  const sessionDateMutation = useSessionDateMutation({
    userEmail: row.original.userInfo.email,
    appointmentId: sessionAppointment?.appointmentId,
  });

  const currentDateString = sessionAppointment?.startAt;

  const handleSessionDateMutation = async (
    updatedDateString: Nullable<string>
  ) => {
    try {
      await sessionDateMutation.mutateAsync({
        currentDateString,
        updatedDateString,
      });
    } catch (e) {
      toast.error(
        'Unable to update session date. Try again later.',
        toastErrorConfig
      );
    }
  };

  return (
    <SessionDateSelector
      sessionDateString={currentDateString}
      onDateChange={handleSessionDateMutation}
      key={currentDateString}
    />
  );
};
