import { openNotesRepliesSidebar } from 'features/challengeNotes/utils';
import { useUserContext } from 'features/user/context';

export const useNoteRepliesSidebar = (notification) => {
  const { user } = useUserContext();

  const openNoteSidebar = () =>
    openNotesRepliesSidebar({
      note: {
        sprintId: notification.sprintId,
        challengeId: notification.challengeId,
        noteId: notification.noteId || notification.coachSessionNoteId,
        userEmail: notification.sharerEmail,
      },
      user,
    });

  return openNoteSidebar;
};
