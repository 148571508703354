import Box from 'shared_DEPRECATED/components/Box';

import { Typography } from 'shared/components/Typography';

type TGoalTitle = {
  name: string;
};

export const GoalCardTitle = ({ name }: TGoalTitle) => (
  <Box>
    <Typography
      color="black"
      type="small"
      fontWeight="medium"
      wordBreak="break-word"
    >
      {name}
    </Typography>
  </Box>
);
