import { Flex } from 'shared_DEPRECATED/components/Flex';

import {
  TABLE_CELL_DEFAULT_VALUE,
  SESSION_ATTENDANCE_STATUS,
} from 'features/sessions/config';
import { SessionsTableRowDataType } from 'features/sessions/config/types';
import {
  getSessionDurationText,
  getSessionTimeFrame,
} from 'features/sessions/utils';

import { Badge } from 'shared/components/Badge';
import { Typography } from 'shared/components/Typography';

type SessionsTableTimeCellProps = {
  value: number;
  row: SessionsTableRowDataType;
};

export const SessionsTableTimeCell = ({
  value: sessionDuration,
  row,
}: SessionsTableTimeCellProps) => {
  const durationText = getSessionDurationText(sessionDuration);
  const timeFrameText = getSessionTimeFrame(
    row.original.startAt,
    row.original.endAt
  );
  const attendance = row.original.attendance;

  return (
    <Flex gap="var(--spacing02)" justifyContent="flex-start">
      {attendance === SESSION_ATTENDANCE_STATUS.ATTENDED ? (
        <>
          <Typography
            type="small"
            color="gray"
            fontWeight="regular"
            whiteSpace="nowrap"
            dataTestid="session-time-frame-cell"
          >
            {timeFrameText}
          </Typography>
          <Badge bgColor="gray">
            <Typography
              type="small"
              whiteSpace="nowrap"
              color="black"
              dataTestid="session-duration-cell"
            >
              {durationText}
            </Typography>
          </Badge>
        </>
      ) : (
        <Typography
          type="small"
          color="gray"
          fontWeight="regular"
          whiteSpace="nowrap"
        >
          {TABLE_CELL_DEFAULT_VALUE}
        </Typography>
      )}
    </Flex>
  );
};
