import { useMemo } from 'react';

const useReactionsData = (noteReactions, currentUserEmail) => {
  return useMemo(
    () =>
      Object.entries(noteReactions).reduce((reactions, [emoji, users]) => {
        reactions[emoji] = {
          count: users.length,
          isMy: false,
          tooltipText: '',
        };

        users.reduce((reactionMetaData, user) => {
          const isCurrentUser = user.email === currentUserEmail;
          const userName = isCurrentUser ? 'You' : user.name;
          const tooltipTextSeparator =
            reactionMetaData.tooltipText.length > 0 ? ', ' : '';

          reactionMetaData.isMy = reactionMetaData.isMy || isCurrentUser;
          reactionMetaData.tooltipText += `${tooltipTextSeparator}${userName}`;

          return reactionMetaData;
        }, reactions[emoji]);

        return reactions;
      }, {}),
    [noteReactions, currentUserEmail]
  );
};

export default useReactionsData;
