import PropTypes from 'prop-types';

import { Flex } from 'shared_DEPRECATED/components/Flex';
import Spacer from 'shared_DEPRECATED/components/Spacer';

import { ChallengeStatusIcon } from 'features/challenge/components/StatusIcon';
import { CHALLENGE_NOTE_STATUS } from 'features/challengeNotes/config';

import { Typography } from 'shared/components/Typography';

const ChallengeNotesCardTitlePropTypes = {
  status: PropTypes.oneOf(Object.values(CHALLENGE_NOTE_STATUS)).isRequired,
  title: PropTypes.string.isRequired,
};

export const ChallengeNotesCardTitle = ({ status, title }) => {
  return (
    <Spacer size="md zr">
      <Flex justifyContent="flex-start">
        <ChallengeStatusIcon status={status} />
        <Spacer size="zr zr zr md">
          <Typography
            type="small"
            fontWeight="semiBold"
            color="black"
            dataTestid="user-challenge-note-challenge-title"
          >
            {title}
          </Typography>
        </Spacer>
      </Flex>
    </Spacer>
  );
};

ChallengeNotesCardTitle.propTypes = ChallengeNotesCardTitlePropTypes;
