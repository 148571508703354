import PropTypes from 'prop-types';

import Box from 'shared_DEPRECATED/components/Box';
import { Flex } from 'shared_DEPRECATED/components/Flex';

import { SidebarSection } from 'shared/components/Sidebar';

const SidebarHeaderContainer = ({ children }) => (
  <SidebarSection>
    <Box
      style={{
        minHeight: '16px',
      }}
    >
      <Flex as="header">{children}</Flex>
    </Box>
  </SidebarSection>
);

SidebarHeaderContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SidebarHeaderContainer;
