import {
  createContext,
  ReactNode,
  RefObject,
  useCallback,
  useContext,
  useMemo,
  useRef,
} from 'react';
import ReactQuill from 'react-quill';

import Quill from 'quill';
import { Nullable } from 'shared_DEPRECATED/types';

type EditorContextProps = {
  getEditorInstance: () => Nullable<Quill>;
  editorRef: RefObject<any>;
};

const EditorContext = createContext<Nullable<EditorContextProps>>(null);

type EditorProviderProps = {
  children: ReactNode;
};

export const EditorProvider = ({ children }: EditorProviderProps) => {
  const editorRef = useRef<ReactQuill>(null);

  const getEditorInstance = useCallback(() => {
    const ReactQuillRef = editorRef.current;
    if (!ReactQuillRef) {
      return null;
    }

    return ReactQuillRef.getEditor();
  }, []);

  const providerValue = useMemo(
    () => ({ getEditorInstance, editorRef }),
    [getEditorInstance]
  );

  return (
    <EditorContext.Provider value={providerValue}>
      {children}
    </EditorContext.Provider>
  );
};

export const useEditorContext = () => {
  const editorContext = useContext(EditorContext);

  if (!editorContext) {
    throw new Error('useEditorContext must be used in EditorContextProvider');
  }

  return editorContext;
};
