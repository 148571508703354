import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const useOnRouteChange = (handler) => {
  const { pathname } = useLocation();

  useEffect(() => {
    return () => handler();
  }, [pathname, handler]);
};
