import { ReactNode, useLayoutEffect, useRef } from 'react';

import { Nullable } from 'shared_DEPRECATED/types';

import styles from 'shared_DEPRECATED/components/Flex/Flex.module.css';

type FlexPropTypes = {
  as?: string | React.ComponentType<any>;
  children: ReactNode;
  justifyContent?: string;
  alignItems?: string;
  flexDirection?: string;
  flexWrap?:
    | 'wrap'
    | 'nowrap'
    | 'wrap-reverse'
    | 'inherit'
    | 'initial'
    | 'unset';
  gap?: string;
  width?: string;
  height?: string;
  dataTestid?: Nullable<string>;
};

const Flex = ({
  as: Component = 'div',
  children,
  justifyContent = 'space-between',
  alignItems = 'center',
  flexDirection = 'row',
  flexWrap = 'nowrap',
  //TODO: width and height should be removed in favour of using Box component
  width = 'auto',
  height = 'auto',
  gap = 'normal',
  dataTestid = null,
}: FlexPropTypes) => {
  const element = useRef<HTMLElement>(null);

  useLayoutEffect(() => {
    const elementRef = element.current;
    if (elementRef) {
      elementRef.style.setProperty('--justify-content', justifyContent);
      elementRef.style.setProperty('--align-items', alignItems);
      elementRef.style.setProperty('--flex-direction', flexDirection);
      elementRef.style.setProperty('--flex-wrap', flexWrap);
      elementRef.style.setProperty('--width', width);
      elementRef.style.setProperty('--height', height);
      elementRef.style.setProperty('--gap', gap);
    }
  }, [
    element,
    justifyContent,
    alignItems,
    flexDirection,
    width,
    height,
    gap,
    flexWrap,
  ]);

  return (
    <Component className={styles.flex} ref={element} data-testid={dataTestid}>
      {children}
    </Component>
  );
};

export default Flex;
