import { toastConfig, toastErrorConfig } from 'lib/reactToastify/config';
import { toast, ToastOptions } from 'react-toastify';

import { capitalize } from 'shared_DEPRECATED/utils';

type TUseCopyToClipboard = {
  clipboardText: string;
  clipboardTextName: string;
};

export const useCopyToClipboard =
  ({ clipboardText, clipboardTextName }: TUseCopyToClipboard) =>
  async () => {
    try {
      await navigator.clipboard.writeText(clipboardText);

      toast.success(`${capitalize(clipboardTextName)} copied to clipboard!`, {
        icon: false,
        ...toastConfig,
      } as ToastOptions);
    } catch (e) {
      toast.error(
        `There's an error with copying ${clipboardTextName}. Please try again later.`,
        toastErrorConfig as ToastOptions
      );
    }
  };
