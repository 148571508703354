import { ReactNode } from 'react';

export const HotjarSuppression = ({
  children,
  as = 'div',
}: {
  children: ReactNode;
  as?: 'span' | 'div';
}) => {
  const Component = as;

  return <Component className="data-hj-suppress">{children}</Component>;
};
