import { ReactNode } from 'react';

import { Typography } from 'shared';

type TSessionSidebarFieldsetLabel = { children: ReactNode };

export const SessionSidebarFieldsetLabel = ({
  children,
}: TSessionSidebarFieldsetLabel) => (
  <Typography type="small" fontWeight="medium" color="black">
    {children}
  </Typography>
);
