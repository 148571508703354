import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import { useQueryHTTPRequest } from 'shared_DEPRECATED/hooks';

import { SessionType } from 'features/sessions/config/types';
import { SessionSprint } from 'features/sprint';

export const useCoachSessionsQuery = () => {
  const { email: userId } = useParams();
  const { request } = useQueryHTTPRequest();

  return useQuery(
    ['coach-sessions', userId],
    () => request({ url: `/api/coach/clients/${userId}/coaching-sessions` }),
    {
      select: ({ coachingSessions, ...data }) => ({
        ...data,
        sessions: coachingSessions.map((session: SessionType) => ({
          ...session,
          sprintDetails:
            session.sprintDetails && new SessionSprint(session.sprintDetails),
        })),
      }),
    }
  );
};
