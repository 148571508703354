import { toastErrorConfig } from 'lib/reactToastify/config';
import { toast } from 'react-toastify';

import Box from 'shared_DEPRECATED/components/Box';
import { Flex } from 'shared_DEPRECATED/components/Flex';

import {
  COACHING_MODE,
  COACHING_MODE_LABELS,
} from 'features/participants/config';
import { CoachingMode } from 'features/participants/config/types';
import { useCoachingModeMutation } from 'features/participants/hooks/mutation/useCoachingMode';
import { useUpdateParticipantsQuery } from 'features/participants/hooks/query/useUpdateParticipants';

import { Dropdown, DropdownItem } from 'shared/components/Dropdown';
import { Typography } from 'shared/components/Typography';

type ParticipantsTableCoachingModeCellPropTypes = {
  value: CoachingMode;
  row: {
    original: {
      userInfo: {
        email: string;
        name: string;
      };
    };
  };
};

export const ParticipantsTableCoachingModeCell = ({
  value: currentMode,
  row,
}: ParticipantsTableCoachingModeCellPropTypes) => {
  const userEmail = row.original.userInfo.email;
  const coachingModeMutation = useCoachingModeMutation({
    userEmail,
    onUpdateQuery: useUpdateParticipantsQuery,
  });

  const handleModeChange = async (updatedMode: CoachingMode) => {
    if (updatedMode !== currentMode) {
      try {
        await coachingModeMutation.mutateAsync({
          currentMode,
          updatedMode,
        });
      } catch (e) {
        toast.error(
          'Unable to update coaching mode. Try again later.',
          toastErrorConfig
        );
      }
    }
  };

  return (
    <Dropdown
      onItemSelect={handleModeChange as (val: string | number) => void}
      selectedValue={currentMode}
      anchorContent={
        <Typography
          type="small"
          color="black"
          dataTestid="participant-coaching-mode"
        >
          {COACHING_MODE_LABELS[currentMode]}
        </Typography>
      }
      anchorSpacing={['md', 'xsm sm']}
    >
      <Box style={{ width: '10rem' }} role="listbox">
        <Flex alignItems="stretch" flexDirection="column">
          {Object.values(COACHING_MODE).map((mode) => (
            <DropdownItem
              key={mode}
              value={mode}
              dataTestid="participant-coaching-mode-dropdown-option"
            >
              <Typography type="small" color="black">
                {COACHING_MODE_LABELS[mode]}
              </Typography>
            </DropdownItem>
          ))}
        </Flex>
      </Box>
    </Dropdown>
  );
};
