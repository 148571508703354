import { Column } from 'react-table';

import {
  SessionsTableAttendanceCell,
  SessionsTableBaseCell,
  SessionsTableCoachingToolsCell,
  SessionsTableDateCell,
  SessionsTableTimeCell,
  SessionsTableTitleCell,
} from 'features/sessions/components/Table';
import { SessionsTableDeleteButtonCell } from 'features/sessions/components/Table/DeleteButtonCell';
import { SessionTableItemType } from 'features/sessions/config/types';
import { sortSessionsByStartDate } from 'features/sessions/utils';

export const coachSessionsTableColumnsData: Column<SessionTableItemType>[] = [
  {
    Header: 'Session title',
    accessor: 'title',
    Cell: SessionsTableTitleCell,
  },
  {
    Header: 'Attendance',
    accessor: 'attendance',
    Cell: SessionsTableAttendanceCell,
  },
  {
    Header: 'Session date',
    accessor: 'startAt',
    Cell: SessionsTableDateCell,
    sortType: sortSessionsByStartDate,
  },
  {
    Header: 'Session time',
    accessor: 'sessionDuration',
    Cell: SessionsTableTimeCell,
  },
  {
    Header: 'Week',
    accessor: 'sprintWeekNumber',
    Cell: SessionsTableBaseCell,
  },
  {
    Header: 'Tools used',
    accessor: 'coachingTools',
    Cell: SessionsTableCoachingToolsCell,
    disableSortBy: true,
  },
  {
    accessor: 'coachingSessionId',
    Cell: SessionsTableDeleteButtonCell,
    disableSortBy: true,
  },
];

export const COACH_SESSIONS_TABLE_COLUMNS_QTY =
  coachSessionsTableColumnsData.length;
