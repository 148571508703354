import { OptionsMenuButton } from 'shared_DEPRECATED/components/Floating/Menu/Options';
import { Nullable } from 'shared_DEPRECATED/types';

import { PrioritizeGoalCallback } from 'features/goal/types';

export const GoalCardMenuPrioritizeButton = ({
  goalId,
  prioritizedAt,
  columnId,
  togglePrioritize,
}: {
  goalId: string;
  columnId: string;
  prioritizedAt: Nullable<string>;
  togglePrioritize: PrioritizeGoalCallback;
}) => {
  const isPrioritized = !!prioritizedAt;

  const handleMenuClick = () => {
    togglePrioritize(goalId, isPrioritized, columnId);
  };

  return (
    <OptionsMenuButton onClick={handleMenuClick}>
      {`${isPrioritized ? 'Unset' : 'Set'} as prioritized`}
    </OptionsMenuButton>
  );
};
