import PropTypes from 'prop-types';

import Box from 'shared_DEPRECATED/components/Box';
import { getFileIconConfig } from 'shared_DEPRECATED/components/File/utils';
import { Flex, FlexItem } from 'shared_DEPRECATED/components/Flex';
import { Icon } from 'shared_DEPRECATED/components/Icon';

export const FileIcon = ({ contentType }) => (
  <FlexItem flexGrow={0} flexShrink={0}>
    <Box
      style={{
        width: '2rem',
        height: '2rem',
        borderRadius: 'var(--border-radius)',
        backgroundColor: 'var(--bgCompPrimaryInverse)',
      }}
    >
      <Flex
        height="100%"
        width="100%"
        justifyContent="center"
        alignItems="center"
      >
        <Icon name={getFileIconConfig(contentType)} />
      </Flex>
    </Box>
  </FlexItem>
);

FileIcon.propTypes = {
  contentType: PropTypes.string.isRequired,
};
