import Button, { VARIANTS } from 'shared_DEPRECATED/components/Button';
import { Flex } from 'shared_DEPRECATED/components/Flex';
import { Icon, iconNames } from 'shared_DEPRECATED/components/Icon';
import Spacer from 'shared_DEPRECATED/components/Spacer';

import { useCreateGoalChallenge } from 'features/goal';

type TAddChallengeButton = {
  focusAreaId: string;
};

export const AddChallengeButton = ({ focusAreaId }: TAddChallengeButton) => {
  const { onCreateGoalChallengeBtnClick } = useCreateGoalChallenge({
    focusAreaId,
  });

  return (
    <Button
      type="button"
      variant={VARIANTS.TERTIARY}
      onClick={onCreateGoalChallengeBtnClick}
    >
      <Spacer size="sm">
        <Flex
          justifyContent="center"
          alignItems="center"
          height="100%"
          width="100%"
        >
          <Icon width={15} height={15} name={iconNames.plus} />
        </Flex>
      </Spacer>
    </Button>
  );
};
