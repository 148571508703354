import { Button } from 'shared';

import { useFeedback } from 'features/feedback/hooks';

import { Typography } from 'shared/components/Typography';

export const FeedbackButton = () => {
  const { openFeedbackDialog } = useFeedback();

  return openFeedbackDialog ? (
    <Button
      onClick={openFeedbackDialog}
      bg="var(--bgCompPrimaryInverse)"
      borderRadius="0.5rem"
      _hover={{
        bg: 'var(--bgCompPrimaryInverse)',
      }}
      size="xs"
    >
      <Typography color="white">Feedback</Typography>
    </Button>
  ) : (
    <></>
  );
};
