import { Quill } from 'react-quill';

export const COLOR_SELECTOR_TYPE = {
  COLOR: 'color',
  BACKGROUND: 'background',
};

export const BASE_COLORS = [
  'yellow',
  'green',
  'blue',
  'red',
  'orange',
  'indigo',
  'violet',
];

export const FONT_BUTTON_TYPES = {
  BOLD: 'bold',
  ITALIC: 'italic',
  UNDERLINE: 'underline',
  STRIKE: 'strike',
};

export type TextEditorFontButtonType =
  typeof FONT_BUTTON_TYPES[keyof typeof FONT_BUTTON_TYPES];

export const TEXT_EDITOR_LIST_TYPES = {
  ORDERED: 'ordered',
  BULLET: 'bullet',
};

export type TextEditorListType =
  typeof TEXT_EDITOR_LIST_TYPES[keyof typeof TEXT_EDITOR_LIST_TYPES];

export const customKeyboardBindings = {
  indent: {
    key: 'tab',
    format: ['list'],
    handler: function (range: any, context: any) {
      //prevent multi level nesting for lists
      if (context.format.list === 'ordered' && context.format.indent === 1) {
        return false;
      } else {
        //default behaviour, detail: https://github.com/quilljs/quill/blob/develop/modules/keyboard#L361-L363
        if (context.collapsed && context.offset !== 0) return true;
        //@ts-ignore
        this.quill.format('indent', '+1', Quill.sources.USER);
      }
    },
  },
};
