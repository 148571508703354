import { useColorMode } from '@chakra-ui/react';
import PropTypes from 'prop-types';

import Button from 'shared_DEPRECATED/components/Button';
import { Icon, iconNames } from 'shared_DEPRECATED/components/Icon';

import styles from './Filter.module.css';

const FilterItemProps = {
  onChange: PropTypes.func.isRequired,
  active: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  dataTestid: PropTypes.string,
};

export const FilterItem = ({
  onChange,
  active,
  children,
  dataTestid = null,
}) => {
  const { colorMode: theme } = useColorMode();

  return (
    <Button
      className={styles.filter__item}
      onClick={onChange}
      dataTestid={dataTestid}
    >
      {active && (
        <Icon
          className={styles['filter__check-icon']}
          name={iconNames.check}
          stroke={theme === 'light' ? 'black' : 'white'}
        />
      )}
      {children}
    </Button>
  );
};

FilterItem.propTypes = FilterItemProps;
