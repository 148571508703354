import { useMutation, useQueryClient } from 'react-query';

import { queryKeys } from 'api/config';
import { useMutationHTTPRequest } from 'api/hooks/useHTTPRequest';

import { Participant } from 'features/participants/config/types';
import { EditableUserInfo } from 'features/user/config/types';

type TUseEditParticipantInfoMutation = {
  userEmail: string;
};

export const getParticipantFullName = ({
  firstName,
  lastName,
}: EditableUserInfo) => `${firstName} ${lastName}`;

export const useEditParticipantInfoMutation = ({
  userEmail,
}: TUseEditParticipantInfoMutation) => {
  const { request } = useMutationHTTPRequest();

  const queryClient = useQueryClient();

  const updateParticipantQuery = (userName: string) => {
    queryClient.setQueryData(
      `${queryKeys.participant}-${userEmail}`,
      (data) =>
        data && {
          ...data,
          userName,
        }
    );
  };

  return useMutation<
    void,
    unknown,
    { updatedUserInfo: EditableUserInfo },
    {
      previousParticipantInfo: Participant;
    }
  >(
    async ({ updatedUserInfo }) =>
      await request({
        url: `/api/coach/clients/${userEmail}/profile/edit`,
        body: updatedUserInfo,
      }),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(queryKeys.participants);
        await queryClient.invalidateQueries([
          `${queryKeys.participant}-${userEmail}`,
        ]);
      },
      onMutate: ({ updatedUserInfo }) => {
        const previousParticipantInfo = queryClient.getQueryData(
          `${queryKeys.participant}-${userEmail}`
        ) as Participant;

        updateParticipantQuery(getParticipantFullName(updatedUserInfo));

        return { previousParticipantInfo };
      },
      onError: (
        _,
        __,
        context: { previousParticipantInfo: Participant } | undefined
      ) => {
        if (context) {
          queryClient.setQueryData(
            `${queryKeys.participant}-${userEmail}`,
            context.previousParticipantInfo
          );
        }
      },
    }
  );
};
